export enum NotificationType {
  Info = 'info',
  Error = 'error'
}

export interface Notification {
  id: number,
  body: string,
  type?: NotificationType
}

export interface CreateNotification {
  body: string;
  type?: NotificationType;
}
