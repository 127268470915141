export enum Colors {
  MainBackground = '#ffffff',
  Blue900 = '#093075',
  Blue800 = '#214E9D',

  Blue700 = '#4775C7',
  Blue600 = '#90A6CE',
  Blue500 = '#DDE4F1',
  Blue400 = '#F6F9FF',

  Grey900 = '#101010',
  Grey800 = '#7D8498',
  Grey750 = '#ADB3C2',
  Grey700 = '#BDC2CE',
  Grey600 = '#EEF1F5',
  Grey500 = '#F7F7F7',

  Red900 = '#CD2014',
  Red800 = '#E7483D',

  Green900 = '#16A13D',
  Green800 = '#26BB50',

  Orange900 = '#D16C05',
  Orange800 = '#EE7A05',

  Yellow900 = '#DDBC0C',
  Yellow800 = '#EEC905',

  White = '#ffffff',

  Backdrop = 'rgb(16,16,16, 0.7)',
}
