import { differenceInDays, differenceInHours, differenceInMinutes, format, isSameDay } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Appeal } from '../../../../models/appeal';
import { appealDatesFields } from '../../constants/appeal-dates.enum';

export const formatDateHelper = (date: string, createdAt: string, isAlwaysFull: boolean): string => {
  const dateObj = new Date(date);
  const createdAtObj = new Date(createdAt);

  if (isAlwaysFull || !isSameDay(dateObj, createdAtObj)) {
    return format(dateObj, 'dd.MM.yyyy HH:mm', { locale: ru });
  }
  return format(dateObj, 'HH:mm', { locale: ru });
};

export const calculateTimeDifference = (date1: string, date2: string) => {
  const date1Obj = new Date(date1);
  const date2Obj = new Date(date2);

  const days = differenceInDays(date2Obj, date1Obj);
  const hours = differenceInHours(date2Obj, date1Obj) % 24;
  const minutes = differenceInMinutes(date2Obj, date1Obj) % 60;

  const daysStr = days > 0 ? `${days}д ` : '';
  const hoursStr = hours > 0 ? `${hours}ч ` : '';
  const minutesStr = minutes > 0 ? `${minutes}м ` : '';

  return `${daysStr}${hoursStr}${minutesStr}`.trim();
};

export const findNextAvailableDate = (dates: Appeal, currentIndex: number) => {
  for (let i = currentIndex + 1; i < appealDatesFields.length; i++) {
    const nextDateField = appealDatesFields[i];
    if (dates[nextDateField]) {
      return dates[nextDateField];
    }
  }
  return undefined;
};
