import { all, call, put, takeLatest } from 'redux-saga/effects';
import { NotificationsService } from 'app/services/notifications-service/notifications.service';
import { NotificationsActions } from '.';

function* getNotificationsWorker() {
  try {
    const response = yield call(NotificationsService.getNotifications);

    yield put(NotificationsActions.GetNotifications.success(response));
  } catch (e: any) {
    yield put(NotificationsActions.GetNotifications.failure(e));
  }
}

function* addNotificationsWorker() {
  yield put(NotificationsActions.AddNotification.success());
}

export default function watcher() {
  return all([
    takeLatest(NotificationsActions.GetNotifications.init, getNotificationsWorker),
    takeLatest(NotificationsActions.AddNotification.init, addNotificationsWorker),
  ]);
}
