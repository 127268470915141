import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Header, TextRegularBig } from 'app/typography/text';
import { selectSelectedIncident } from '../../../../../store/incidents/incidents.selectors';
import { Container, HeaderContainer, StatusIcon } from './styled';
import { CenteredFlexWithSpacing, ColumnFlexWithPadding, FlexWithSpacing } from '../../../../typography/flex';
import { TextRow } from '../../../Appeal/components/AppealInfoHeader/styled';

import { formatDate } from '../../../../helpers/date/time';
import { IncidentLabelStatusToLabelMap, IncidentStatus, IncidentTypeToLabelMap } from '../../../../models/incident/incident';
import { incidentStatusDict, incidentStatusIconDict } from '../../../Incidents/components/IncidentStatusTitle/IncidentStatusTitle';
import { TabsPanel } from '../../../../components/TabsPanel/TabsPanel';
import { ModalService } from '../../../../services/modal-service/modal.service';
import { IncidentForm } from '../../../Incidents/components/IncidentForm/IncidentForm';
import { GetToWorkButton } from '../../styled';
import { IncidentsActions } from './components/IncidentsActions/IncidentsActions';

type HeaderProps = {
  activeTabIndex: number;
  onActiveTabChange: (index: number) => void;
};

export const IncidentHeader = React.memo<HeaderProps>(props => {
  const incident = useSelector(selectSelectedIncident, shallowEqual);

  const tabsList = React.useMemo(
    () => [
      { title: 'Общая информация', index: 0 },
      // { title: 'История изменений', index: 1 }, //todo добавить позже
    ],
    []
  );

  const onGetToWorkButtonHandler = React.useCallback(() => {
    if (!incident?.id) return;
    ModalService.openModal(IncidentForm, { incidentId: incident?.id, getToWork: true });
  }, [incident]);

  const header = React.useMemo(() => {
    return `${IncidentTypeToLabelMap[incident?.incident_type ?? '']} №${incident?.number ?? 0}`;
  }, [incident?.incident_type, incident?.number]);

  return (
    <Container>
      <HeaderContainer>
        <FlexWithSpacing spacing="30px">
          <FlexWithSpacing spacing="10px">
            <ColumnFlexWithPadding spacing="8px">
              <TextRow spacing="16px ">
                <Header>{header}</Header>
                <TextRow spacing="5px">
                  <StatusIcon color={incidentStatusDict[incident?.status || 0]} icon={incidentStatusIconDict[incident?.status || 0]} />
                  <TextRegularBig>{IncidentLabelStatusToLabelMap[incident?.status || 0]}</TextRegularBig>
                </TextRow>

                <TextRow spacing="0px">{formatDate(incident?.created_at, 'HH:mm:ss')}</TextRow>
              </TextRow>
            </ColumnFlexWithPadding>
          </FlexWithSpacing>
        </FlexWithSpacing>

        <CenteredFlexWithSpacing spacing="15px">
          {incident?.status === IncidentStatus.New && (
            <GetToWorkButton mod="primary" onClick={onGetToWorkButtonHandler}>
              Взять в работу
            </GetToWorkButton>
          )}

          <IncidentsActions incidentId={incident?.id} status={incident?.status} />
        </CenteredFlexWithSpacing>
      </HeaderContainer>

      <TabsPanel activeTabIndex={props.activeTabIndex} onTabIndexChange={props.onActiveTabChange} tabsList={tabsList} />
    </Container>
  );
});

IncidentHeader.displayName = 'IncidentHeader';
