export enum ResponsibleField {
  Master = 'master',
  MasterPhone = 'masterPhone',
  Dispatcher = 'dispatcher',
  UtilityCompany = 'utilityCompany',
  UtilityCompanyPhone = 'appealRequisitionType',
  PersonalAccount = 'personalAccount',
  SupplierByAddress = 'supplierByAddress',
  SectorByAddress = 'sectorByAddress',
}

export const responsibleFieldsNameMap = {
  [ResponsibleField.Master]: 'Мастер',
  [ResponsibleField.MasterPhone]: 'Контактный номер мастера',
  [ResponsibleField.Dispatcher]: 'Ответственный диспетчер',
  [ResponsibleField.UtilityCompany]: 'Поставщик по телефону',
  [ResponsibleField.UtilityCompanyPhone]: 'Телефон поставщика',
  [ResponsibleField.PersonalAccount]: 'Л/с',
  [ResponsibleField.SupplierByAddress]: 'Поставщик по адресу',
  [ResponsibleField.SectorByAddress]: 'Участок по адресу',
};

export const responsibleFields: ResponsibleField[] = [ResponsibleField.Master, ResponsibleField.Dispatcher, ResponsibleField.MasterPhone];

export const requiredResponsibleFields: ResponsibleField[] = [
  ResponsibleField.Dispatcher,
  ResponsibleField.UtilityCompany,
  ResponsibleField.UtilityCompanyPhone,
  ResponsibleField.PersonalAccount,
];
