import styled from 'styled-components';

import { ColumnFlexbox, Flexbox } from 'app/typography/flex';
import { Colors } from 'app/themes/colors';
import { Hint } from 'app/typography/text';

export const MESSAGE_ROW_CLASS = 'message_row';

export const MessageListContainer = styled(ColumnFlexbox)`
  height: 100%;
  padding: 0 20px;
  overflow: auto;
`;

export const MessageRow = styled(Flexbox)`
  transition: all 700ms;

  :first-of-type {
    margin-top: auto;
  }

  &.${MESSAGE_ROW_CLASS}.enter {
    opacity: 0;
  }

  &.${MESSAGE_ROW_CLASS}.enter-active {
    opacity: 1;
  }

  &.${MESSAGE_ROW_CLASS}.exit {
    opacity: 1;
  }

  &.${MESSAGE_ROW_CLASS}.exit-active {
    opacity: 0;
  }
`;

export const Message = styled.div<{ my?: boolean }>`
  position: relative;
  min-width: 50px;
  background: ${Colors.Grey600};
  border-radius: 6px;
  margin: 5px 0;
  padding: 10px 20px 18px 10px;
  cursor: text;
  white-space: pre-wrap;

  ${({ my }) =>
    my &&
    `
    background: ${Colors.Blue600};
    color: ${Colors.MainBackground};
    margin-left: auto;
    
    ${MessageTime} {
      color: ${Colors.Grey600};
    }
  `}
`;

export const MessageSender = styled.div`
  font-weight: 500;
  margin-bottom: 4px;
`;

export const MessageTime = styled(Hint)`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0 10px 5px 0;
  color: ${Colors.Grey700};
  white-space: nowrap;
`;

export const MessageDate = styled.div`
  color: ${Colors.Grey700};
  font-size: 14px;
  line-height: 16px;
  margin: 15px auto;
`;

export const Status = styled(Flexbox)`
  justify-content: space-between;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 15px;
`;

export const StatusTime = styled(Hint)`
  color: ${Colors.Grey700};
  white-space: nowrap;
`;

export const EndOfMessageList = styled.div``;
