import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ConfigurationSelectors } from 'store/configuration';
import { Icon, Menu } from 'app/components/shared';
import { getAppealsActionsOptions } from 'app/helpers/appeals';
import { Colors } from 'app/themes/colors';
import { useAppealActions } from 'app/helpers/hooks/use-appeal-actions';
import { AppealConsumer } from 'app/models/appeal/appeal-consumer';
import { Appeal } from 'app/models/appeal';
import { OptionMenu } from 'app/models/common';
import { LoadComments } from '../../../../../store/comments/comments.actions';

export const AppealActionsMenu = cellProps => {
  const appeal = cellProps.row.original as Appeal;
  const actions = useSelector(ConfigurationSelectors.selectAppealsActions);
  const dispatch = useDispatch();

  const { dispatchAction } = useAppealActions(appeal);

  const appealActionsOptions: OptionMenu[] = getAppealsActionsOptions(
    appeal.type,
    appeal.status,
    appeal.consumer as AppealConsumer,
    actions
  ).map(option => ({
    label: option.label,
    callback: () => {
      dispatchAction(option.value);
      dispatch(LoadComments.init({ id: String(appeal.id), entity: 'appeal' }));
    },
  }));

  return (
    <Menu options={appealActionsOptions}>
      <MenuIcon icon="/assets/icons.svg#menu" />
    </Menu>
  );
};

const MenuIcon = styled(Icon)`
  fill: ${Colors.Grey700} !important;
`;
