import styled from 'styled-components';
import { Icon } from 'app/components/shared';
import { Colors } from 'app/themes/colors';
import { TextRegularBig } from 'app/typography/text';

export const EvaluationIcon = styled(Icon)<{ iconColor: string }>`
  height: 15px;
  width: 15px;
  fill: ${props => props.iconColor} !important;
`;

export const NoComment = styled(TextRegularBig)`
  color: ${Colors.Grey700};
  font-style: italic;
`;
