import { createSlice } from '@reduxjs/toolkit';
import { Mailing, MailingsQueryParams } from 'app/models/mailing';
import { EntityState } from '../models/entity-state';
import { initialEntityState } from '../constants/initial-entity-state';
import * as MailingsActions from './mailings.actions';
import { MAILINGS_STORE_NAME } from './mailings.actions';
import { MailingTemplates } from '../../app/services/mailings-service/dtos/get-mailing-templates-request';

export interface MailingsState extends EntityState {
  mailings: Mailing[];
  mailingsCount: number;
  mailingTemplates: MailingTemplates;
  query?: MailingsQueryParams;
  error?: any;
}

export const mailingsInitialState: MailingsState = {
  mailings: [],
  mailingsCount: 0,
  mailingTemplates: { templates: [] },
  query: undefined,
  error: undefined,
  ...initialEntityState,
};

const mailingsSlice = createSlice({
  name: MAILINGS_STORE_NAME,
  initialState: mailingsInitialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(MailingsActions.GetMailings.init, (state, { payload }) => {
        state.loading = true;
        state.query = payload || mailingsInitialState.query;
      })
      .addCase(MailingsActions.GetMailings.success, (state, { payload }) => {
        state.loading = false;
        if (!payload.results) return;
        state.mailings = state.query?.page === 1 ? payload.results : state.mailings.concat(payload.results);
        state.mailingsCount = payload.count;
      })
      .addCase(MailingsActions.GetMailings.failure, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(MailingsActions.CreateMailing.init, state => {
        state.creating = true;
      })
      .addCase(MailingsActions.CreateMailing.success, state => {
        state.creating = false;
      })
      .addCase(MailingsActions.CreateMailing.failure, (state, { payload }) => {
        state.creating = false;
        state.error = payload;
      })
      .addCase(MailingsActions.UpdateMailing.init, state => {
        state.updating = true;
      })
      .addCase(MailingsActions.UpdateMailing.success, (state, { payload }) => {
        state.updating = false;
        state.mailings = state.mailings.map(mailing => (mailing.id === payload.id ? payload : mailing));
      })
      .addCase(MailingsActions.UpdateMailing.failure, (state, { payload }) => {
        state.updating = false;
        state.error = payload;
      })
      .addCase(MailingsActions.DeleteMailing.init, state => {
        state.deleting = true;
      })
      .addCase(MailingsActions.DeleteMailing.success, (state, { payload }) => {
        state.deleting = false;
        state.mailings = state.mailings.filter(mailing => mailing.id !== payload.id);
      })
      .addCase(MailingsActions.DeleteMailing.failure, (state, { payload }) => {
        state.deleting = false;
        state.error = payload;
      })
      .addCase(MailingsActions.GetNumberRecipients.init, state => {
        state.loading = true;
      })
      .addCase(MailingsActions.GetNumberRecipients.success, state => {
        state.loading = false;
      })
      .addCase(MailingsActions.GetNumberRecipients.failure, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(MailingsActions.GetMailingTemplates.init, state => {
        state.loading = true;
      })
      .addCase(MailingsActions.GetMailingTemplates.success, (state, { payload }) => {
        state.loading = false;
        state.mailingTemplates = payload;
      })
      .addCase(MailingsActions.GetMailingTemplates.failure, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })

      .addCase(MailingsActions.SetActiveStatusTab, (state, { payload }) => {
        state.query = { ...state.query, status: payload };
      })
      .addDefaultCase(() => {}),
});

export const mailingsReducer = mailingsSlice.reducer;
