import React from 'react';
import { AccessDeniedDialog, CloseButton } from './styled';
import { DialogProps } from '../../shared';
import { HeaderSmall } from '../../../typography/text';
import { useLockBodyScroll } from '../../../helpers';
import { Icons } from '../../../constants/icons';

type AccessDeniedModalProps = { errorText: string } & Pick<DialogProps, 'onClose'>;
export const AccessDeniedModal = (props: AccessDeniedModalProps) => {
  const body = React.useMemo(() => {
    return (
      <>
        <Icons.Forbidden />
        <HeaderSmall>{props.errorText}</HeaderSmall>
        <CloseButton onClick={props.onClose}>Понятно</CloseButton>
      </>
    );
  }, [props.errorText, props.onClose]);

  useLockBodyScroll();

  return <AccessDeniedDialog onClose={props.onClose} body={body} />;
};
