export interface QueryFilters {
  [key: string]: any;
}

// todo пересмотреть фильтры, привести к универсальному виду
export enum DefaultFilterKey {
  AddressSearch = 'address__address__contains',
  ConsumersSearch = 'fio__contains',
  CallsSearch = 'appeal__number__contains',
  MastersSearch = 'fullName__icontains',
  SuppliersSearch = 'name__icontains',
  FirstDate = 'createdAt__date__gte',
  SecondDate = 'createdAt__date__lte',
  DateMoreThan = 'createdAt__gt',
  CallType = 'type_calls',
  AppealStatus = 'status',
}
