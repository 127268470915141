import styled from 'styled-components';
import { ColumnFlexbox } from '../../../../typography/flex';
import { Colors } from '../../../../themes/colors';
import { Header, TextRegularBig } from '../../../../typography/text';
import { StyledLink } from '../../../../components/shared';

export const Container = styled(ColumnFlexbox)`
  gap: 20px;
  padding: 20px 24px;
  width: 350px;
  background-color: ${Colors.White};
  border-radius: 10px;
`;

export const Title = styled(Header)`
  font-weight: 700;
`;

export const Link = styled(StyledLink)`
  color: ${Colors.Blue800};
  font-weight: 500;
`;

export const ActionButton = styled(TextRegularBig)`
  color: ${Colors.Blue800};
  font-weight: 500;
  cursor: pointer;
`;

export const ExternalLink = styled.a`
  color: ${Colors.Blue800};
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
`;
