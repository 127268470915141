import { Icon } from 'app/components/shared';
import { Appeal } from 'app/models/appeal';
import { Flexbox } from 'app/typography/flex';
import styled, { keyframes } from 'styled-components';

export const AppealCustomNumber = cellProps => {
  const appeal = cellProps.row.original as Appeal;

  return (
    <Container>
      {(appeal.isEmergency || appeal.isUrgent) && <StyledIcon icon="/assets/icons.svg#attention" />}
      {!!appeal.newMsgCount && <MsgIcon icon="/assets/icons.svg#message" />}
      <div>{cellProps.value}</div>
    </Container>
  );
};

const Container = styled(Flexbox)`
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  max-width: 100px;
`;

const StyledIcon = styled(Icon)`
  flex-shrink: 0;
  fill: #f00 !important;
  margin-left: -10px;
`;

const blink = keyframes`
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
`;

const MsgIcon = styled(Icon)`
  color: #ee7a05;
  flex-shrink: 0;
  animation: ${blink} 1s infinite;

  &:not(:first-child) {
    margin-left: -10px;
  }
`;
