import { createSelector } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-extraneous-dependencies
import createCachedSelector from 're-reselect';
import { AppState } from '../../types/AppState.type';
import { defaultCachedSelectorKeySelector } from '../../utils/selectors';
import { IncidentId } from '../../app/models/incident/incident';

export const selectIncidentsState = (state: AppState) => state.incidentsState;

const selectIncidentsSelector = (() => {
  const selector = createCachedSelector([selectIncidentsState], incidentState => {
    return incidentState?.incidents;
  })(defaultCachedSelectorKeySelector);

  return (state: AppState) => selector(state);
})();

export const selectIncidents = (state: AppState) => selectIncidentsSelector(state);

const selectIncidentByIdSelector = (() => {
  const selector = createCachedSelector(
    [selectIncidentsState, (_S: AppState, incidentId: IncidentId | undefined) => incidentId],
    (incidents, incidentId) => {
      if (!incidentId) return undefined;

      return incidents.incidents.find(incident => incident.id === incidentId);
    }
  )(defaultCachedSelectorKeySelector);

  return (state: AppState, incidentId: IncidentId | undefined) => selector(state, incidentId);
})();

export const selectIncidentById = (incidentId: IncidentId | undefined) => (state: AppState) =>
  selectIncidentByIdSelector(state, incidentId);

export const selectIncidentsQuery = createSelector(selectIncidentsState, state => {
  return state.query;
});

export const selectIncidentsAreLoading = createSelector(selectIncidentsState, state => state.loading);
export const selectIncidentsTotalCount = createSelector(selectIncidentsState, state => state.incidentsTotalCount);
export const selectSelectedIncident = createSelector(selectIncidentsState, state => state.selectedIncident);
export const selectRelatedAppeals = createSelector(selectIncidentsState, state => state.relatedAppeals);

export const selectIncidentsChosenFilter = createSelector(selectIncidentsState, state => state.chosenFilters);

export const selectIsFiltered = createSelector(selectIncidentsState, state => {
  // eslint-disable-next-line
  const { address, ...filters } = state.chosenFilters;
  return Object.values(filters).filter(Boolean).length > 0;
});
