/* eslint-disable no-unused-vars */
import { FC } from 'react';
import { Button, Icon } from './styled';
import { ButtonNativeProps } from '../Button';

interface FilterButtonProps {
  isFiltered?: boolean;
}

export const FilterButton: FC<FilterButtonProps & ButtonNativeProps> = ({ isFiltered, ref, ...rest }) => (
  <Button isFiltered={isFiltered} {...rest}>
    <Icon icon="/assets/icons.svg#filter" />
  </Button>
);
