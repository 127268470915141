import styled from 'styled-components';
import { CenteredFlexWithSpacing } from 'app/typography/flex';
import { Colors } from 'app/themes/colors';
import { AudioPlayer } from '../AudioPlayer';
import { Icon } from '../IconBackground';

export const PlayButton = styled.div`
  position: relative;
`;

export const DownloadButton = styled.div``;

export const CallButton = styled.div``;

export const StyledPlayer = styled(AudioPlayer)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;

export const MenuComponent = styled(CenteredFlexWithSpacing)`
  justify-content: flex-end;
`;

export const StyledIcon = styled(Icon)`
  fill: ${Colors.Grey700} !important;

  :hover {
    fill: ${Colors.Blue600} !important;
  }

  :active {
    fill: ${Colors.Blue700} !important;
  }
`;
