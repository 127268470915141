import { Notification, CreateNotification } from 'app/models/notifications/notification';
import { getCreateActionFromStore, getCreateActionTrio } from '../helpers/action-create';

export const NOTIFICATIONS_STORE = 'notifications-state';

const createAction = getCreateActionFromStore(NOTIFICATIONS_STORE);
const createActionTrio = getCreateActionTrio(NOTIFICATIONS_STORE);

export const GetNotifications = createActionTrio<void, Notification[]>('get notifications');

export const AddNotification = createActionTrio<CreateNotification, void>('add notification');

export const ClearNotifications = createAction('clear notifications');
