import React from 'react';
import { useDispatch } from 'react-redux';
import { AnyAction } from '@reduxjs/toolkit';
import { IncidentId } from '../../../../../../../models/incident/incident';
import { StyledDialog } from './styled';
import { ModalProps } from '../../../../../../../services/modal-service/modal.service';
import { HeaderSmall } from '../../../../../../../typography/text';
import { EditRelatedAppealsTable } from '../EditRelatedAppealsTable/EditRelatedAppealsTable';
import { AddAppealsToIncident, GetIncidentById, GetRelatedAppeals } from '../../../../../../../../store/incidents/incidents.actions';
import { useActionListener } from '../../../../../../../helpers/actions/action-listener.hook';
import { LoadComments } from '../../../../../../../../store/comments/comments.actions';
import { useLockBodyScroll } from '../../../../../../../helpers';

type EditAppealsModalProps = {
  incidentId: IncidentId | undefined;
} & ModalProps;

export const EditAppealsModal = ({ incidentId, onClose = () => {} }: EditAppealsModalProps) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!incidentId) return;
    dispatch(GetRelatedAppeals.init({ id: incidentId }));
  }, [dispatch, incidentId]);

  useActionListener((action: AnyAction) => {
    if (action.type === AddAppealsToIncident.success.type) {
      onClose();
      incidentId && dispatch(LoadComments.init({ id: String(incidentId), entity: 'incident' }));
      incidentId && dispatch(GetIncidentById.init(incidentId));
    }
  });

  useLockBodyScroll();

  return (
    <StyledDialog
      headerContent={<HeaderSmall>Добавление заявок в инцидент</HeaderSmall>}
      body={<EditRelatedAppealsTable incidentId={incidentId} />}
      onClose={onClose}
    />
  );
};
