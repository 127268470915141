import styled from 'styled-components';

import { Colors } from 'app/themes/colors';

export const CallScriptContainer = styled.div`
  padding: 0 16px;
  background: ${Colors.MainBackground};
  border-radius: 10px;
`;

export const Heading = styled.div`
  margin-bottom: 15px;
  font-weight: 500;
`;

export const Paragraph = styled.div`
  font-size: 14px;

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
`;
