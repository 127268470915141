import styled from 'styled-components';

import { Header } from 'app/typography/text';
import { Colors } from 'app/themes/colors';
import { ColumnFlexbox, Flexbox } from 'app/typography/flex';

export const ChatWindow = styled(ColumnFlexbox)`
  height: 600px;
  min-width: 350px;
  max-width: 350px;
  background: ${Colors.MainBackground};
  border-radius: 10px 0 0 10px;

  overflow: hidden;
`;

export const ChatHeader = styled(Flexbox)`
  padding: 20px 24px 20px;
  justify-content: space-between;
  text-align: center;
`;

export const ChatTitle = styled(Header)`
  font-weight: 700;
`;
