class LocalStorageService {
  private get namespaceKey() {
    return `[${this.namespace}]`;
  }

  private buildKey(key: string) {
    return this.namespace ? `${this.namespaceKey}${key}` : key;
  }

  setItem<T>(key: string, value: T) {
    key = this.buildKey(key);
    localStorage.setItem(key, JSON.stringify(value));
  }

  removeItem(key: string) {
    key = this.buildKey(key);
    localStorage.removeItem(key);
  }

  getItem<T>(key: string): T {
    key = this.buildKey(key);
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : undefined;
  }

  clear() {
    this.namespace ? this.clearNamespaceStorage() : localStorage.clear();
  }

  private clearNamespaceStorage() {
    const allKeys = Object.keys(localStorage).filter(k => k.startsWith(this.namespaceKey));

    for (const key of allKeys) {
      localStorage.removeItem(key);
    }
  }

  // eslint-disable-next-line no-empty-function
  constructor(private namespace: string = '') {}
}

const LocalStorageServiceToken = 'LOCAL_STORAGE_SERVICE';

export { LocalStorageService, LocalStorageServiceToken };
