export const APPEAL_TYPES = {
  communal: '31426508-9be5-4990-a12c-6319d6316a7a',
  commercial: '03d364de-1718-433a-810c-675e8d3f1442',
  housing: 'e0fca130-73d3-463b-a03e-9758796f8f1e',
} as const;

export const APPEAL_KINDS = {
  electricity: '39b0c48a-21a4-4ed1-a86f-764f573a8699',
} as const;

export const APPEAL_CATEGORIES = {
  requisition: '9e1bcbdb-b267-4dd3-b571-8c8d36061cb7',
  complaint: '87d8cc4e-aa94-4d75-aa8a-a02a9728d5c1',
  suggestion: 'f5f01249-b13e-4cad-b045-5497b02a78fd',
  consulting: 'ffc28a2b-3b7e-4788-a8d5-0d6e02bc28d3',
  service: '13ffb0f6-3afe-496c-bd49-a9545892ab22',
  none: 'None',
} as const;

export const APPEAL_STATUS = {
  new: 'bbe38dd4-81c6-4da5-8204-4e9c53cb62d6',
  inTheWork: '81eb69fa-1ca0-4bb5-8917-72af46389f9f',
  postponed: '23d73ac6-4ab1-4c2b-becb-03f544b7fa06',
  canceled: '6f828784-b6ef-4800-8c92-e4d9aa0652f6',
  completed: '519391d8-7209-4131-8e15-dc9bc8f36f61',
  closed: 'd169cae1-aa7a-47f7-b92f-b194b553c8ba',
} as const;
