import { Icon, IconBackgroundProps } from 'app/components/shared/IconBackground';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

type IconClickableProps = {
  tooltip?: string;
  navigateLink?: string;
} & IconBackgroundProps;

export const IconClickable = ({ tooltip, navigateLink, ...rest }: IconClickableProps) => {
  const navigate = useNavigate();

  const onClick = () => {
    if (navigateLink) {
      navigate(navigateLink);
    }
  };

  return <ClickableIcon tooltip={tooltip} onClick={onClick} {...rest} />;
};

const ClickableIcon = styled(Icon)`
  cursor: pointer;
`;
