import React from 'react';
import { Container, IconsContainer, MessageIcon, SideBar, UtilitiesIcon } from './styled';
import { Chat } from '../../../../../../components/Chat';
import { UtilityInfo } from '../../../UtilityInfo/UtilityInfo';

enum ACTIONS {
  CHAT = 'chat',
  UTILITIES = 'utilities',
  EMERGENCY_SERVICE = 'emergency',
}

export const SideBarMenu = React.memo(() => {
  const [activeComponent, setActiveComponent] = React.useState<string | null>(null);

  const isChat = activeComponent === ACTIONS.CHAT;
  const isUtilities = activeComponent === ACTIONS.UTILITIES;
  // const isEmergency = activeComponent === ACTIONS.EMERGENCY_SERVICE;

  const content = React.useMemo(() => {
    if (!activeComponent) return null;

    const contentDict = {
      [ACTIONS.CHAT]: <Chat />,
      [ACTIONS.UTILITIES]: <UtilityInfo />,
      [ACTIONS.EMERGENCY_SERVICE]: <div style={{ width: '350px', background: 'white', textAlign: 'center' }}>Экстренные службы</div>,
    };

    return contentDict[activeComponent];
  }, [activeComponent]);

  const handleClick = React.useCallback(
    (componentName: string) => {
      activeComponent === componentName ? setActiveComponent(null) : setActiveComponent(componentName);
    },
    [activeComponent]
  );

  return (
    <Container>
      {content}

      <SideBar>
        <IconsContainer onClick={() => handleClick(ACTIONS.CHAT)} active={isChat}>
          <MessageIcon active={isChat ? 'true' : undefined} />
        </IconsContainer>
        <IconsContainer onClick={() => handleClick(ACTIONS.UTILITIES)} active={isUtilities}>
          <UtilitiesIcon active={isUtilities ? 'true' : undefined} />
        </IconsContainer>
        {/* <IconsContainer onClick={() => handleClick(ACTIONS.EMERGENCY_SERVICE)} active={isEmergency}> */}
        {/*  <EmergencyIcon active={isEmergency ? 'true' : undefined} /> */}
        {/* </IconsContainer> */}
      </SideBar>
    </Container>
  );
});
