import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { pdfjs } from 'react-pdf';
import { useLockBodyScroll } from '../../../../helpers';
import { StyledDialog, StyledDocument, StyledPage } from './styled';
import { HeaderSmall } from '../../../../typography/text';
import { ModalProps } from '../../../../services/modal-service/modal.service';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type PdfViewerProps = {
  link: string | undefined;
};

export const PdfViewer = React.memo<PdfViewerProps>(props => {
  const { link } = props;

  const [numPages, setNumPages] = React.useState<number | null>(null);

  if (!link) return null;

  return (
    <StyledDocument error="Не удалось загрузить документ..." file={link} onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
      {numPages &&
        Array.from({ length: numPages }, (_, index) => index + 1).map((pageNumber, index) => (
          <StyledPage key={index} pageNumber={pageNumber} />
        ))}
    </StyledDocument>
  );
});

type PdwViewerDialogProps = {
  link: string | undefined;
  onClose(): void;
} & ModalProps;
export const PdwViewerDialog = (props: PdwViewerDialogProps) => {
  useLockBodyScroll();

  return (
    <StyledDialog headerContent={<HeaderSmall>Регламент</HeaderSmall>} body={<PdfViewer link={props.link} />} onClose={props.onClose} />
  );
};
