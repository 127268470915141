import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { AppealsSelectors } from 'store/appeals';
import { APPEAL_STATUS } from 'app/constants/appeals';
import { Colors } from '../../../../themes/colors';
import { Icon } from '../../../../components/shared';
import { StrongTextRegular } from '../../../../typography/text';
import { Appeal } from '../../../../models/appeal/appeal';

export const statusColor = {
  [APPEAL_STATUS.new]: Colors.Yellow800,
  [APPEAL_STATUS.canceled]: Colors.Red800,
  [APPEAL_STATUS.closed]: Colors.Blue900,
  [APPEAL_STATUS.inTheWork]: Colors.Orange800,
  [APPEAL_STATUS.postponed]: Colors.Grey750,
  [APPEAL_STATUS.completed]: Colors.Green800,
};

export const statusIcon = {
  [APPEAL_STATUS.new]: '/assets/icons.svg#new',
  [APPEAL_STATUS.canceled]: '/assets/icons.svg#cancel',
  [APPEAL_STATUS.closed]: '/assets/icons.svg#closed',
  [APPEAL_STATUS.inTheWork]: '/assets/icons.svg#in progress',
  [APPEAL_STATUS.postponed]: '/assets/icons.svg#in progress',
  [APPEAL_STATUS.completed]: '/assets/icons.svg#done',
};

export const AppealStatusTitle = cellProps => {
  const appeal = cellProps.row.original as Appeal;
  const { status } = appeal;
  const appealStatusLabel = useSelector(AppealsSelectors.selectAppealStatusLabelById(status));

  return (
    <div>
      <StatusIcon color={statusColor[status]} icon={statusIcon[status]} />
      <StatusLabel color={statusColor[status]}>{appealStatusLabel}</StatusLabel>
    </div>
  );
};

const StatusIcon = styled(Icon)<{ color: string }>`
  fill: ${props => props.color} !important;
  margin-right: 5px;
`;

const StatusLabel = styled(StrongTextRegular)<{ color: string }>`
  color: ${props => props.color} !important;
`;
