import { all, call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { Master } from 'app/models/master';
import { MasterService } from 'app/services/master-service/master.service';
import { UpdateMasterRequest } from 'app/services/master-service/dtos/update-master.request';
import { CreateMasterRequest } from 'app/services/master-service/dtos/create-master.request';
import { Option } from 'app/models/common/option';
import { TableDto } from 'app/services/models/table.dto';
import { MastersQueryParams } from 'app/models/master/masters-query-params';
import * as MastersActions from './masters.actions';

function* loadMastersWatcher({ payload }: PayloadAction<MastersQueryParams>) {
  try {
    const response: TableDto<Master> = yield call(MasterService.getMasters, payload);

    yield put(
      MastersActions.LoadMasters.success({
        masters: response.results,
        mastersTotalCount: response.count,
        page: response.page,
      })
    );
  } catch (e: any) {
    yield put(MastersActions.LoadMasters.failure(e));
  }
}

function* updateMastersWatcher({ payload }: PayloadAction<UpdateMasterRequest>) {
  try {
    const response: Master = yield call(MasterService.updateMaster, payload);

    yield put(MastersActions.UpdateMaster.success(response));
  } catch (e: any) {
    yield put(MastersActions.UpdateMaster.failure(e));
  }
}

function* createMastersWatcher({ payload }: PayloadAction<CreateMasterRequest>) {
  try {
    const response: Master = yield call(MasterService.createMaster, payload);

    yield put(MastersActions.CreateMaster.success(response));
  } catch (e: any) {
    yield put(MastersActions.CreateMaster.failure(e));
  }
}

function* mastersPositionsWatcher() {
  try {
    const response: Option<number>[] = yield call(MasterService.getMastersPositions);

    yield put(MastersActions.GetMastersPositions.success(response));
  } catch (e: any) {
    yield put(MastersActions.GetMastersPositions.failure(e));
  }
}

function* deleteMasterWatcher({ payload }: PayloadAction<{ id: string }>) {
  try {
    yield call(MasterService.deleteMaster, payload.id);

    yield put(MastersActions.DeleteMaster.success({ id: payload.id }));
  } catch (e: any) {
    yield put(MastersActions.DeleteMaster.failure(e));
  }
}

export default function* watcher() {
  return yield all([
    takeLatest(MastersActions.LoadMasters.init.type, loadMastersWatcher),
    takeLatest(MastersActions.UpdateMaster.init.type, updateMastersWatcher),
    takeLatest(MastersActions.CreateMaster.init.type, createMastersWatcher),
    takeLatest(MastersActions.GetMastersPositions.init.type, mastersPositionsWatcher),
    takeLatest(MastersActions.DeleteMaster.init.type, deleteMasterWatcher),
  ]);
}
