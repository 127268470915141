import styled from 'styled-components';
import { css } from 'styled-components/macro';
import { media } from '../../../../../styles/media';
import { ColumnCenteredFlexWithPadding, ColumnFlexWithPadding, FlexWithSpacing } from '../../../../typography/flex';
import { Colors } from '../../../../themes/colors';
import { Button, FormField } from '../../../../components/shared';
import { TextRegularBig } from '../../../../typography/text';
import { ChatInput } from '../../../../components/Chat/components/ChatInput/ChatInput';

export const CommonInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 20px;
  width: 100%;
  margin-top: 20px;

  & > * {
    margin-right: auto;
  }

  ${media.md`
    flex-direction: row;
  `}
`;

export const VerticalContainer = styled(ColumnCenteredFlexWithPadding)`
  width: 100%;
`;

export const Item = styled(ColumnFlexWithPadding)`
  align-self: start;
  width: 100%;
  background-color: ${Colors.White};
  padding: 20px 24px;
  border-radius: 10px;
`;

export const Info = styled(FlexWithSpacing)`
  flex-wrap: wrap;
`;

export const FormFieldInfoItem = styled(FormField)<{ isUseFirstChild?: boolean; isUseThirdChild?: boolean }>`
  flex-basis: 48%;

  ${({ isUseFirstChild }) =>
    isUseFirstChild &&
    css`
      &:first-child {
        flex-basis: 100%;
      }
    `}${({ isUseThirdChild }) =>
    isUseThirdChild &&
    css`
      &:nth-child(3) {
        flex-basis: 100%;
      }
    `}
`;

export const ActionButton = styled(TextRegularBig)`
  color: ${Colors.Blue800};
  font-weight: 500;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const Address = styled.p`
  font-size: 16px;
  line-height: 19px;
  width: 100%;
`;

export const SubmitButton = styled(Button)`
  width: 110px;
  display: block;
  padding: 12px 15px;
  margin-top: 20px;
  margin-left: auto;
`;

export const GreyText = styled.p`
  font-size: 16px;
  color: ${Colors.Grey800};
  font-weight: 500;

  & span {
    font-weight: 400;
    color: ${Colors.Grey700};
    margin-left: 16px;
  }
`;
export const CommentInput = styled(ChatInput)`
  width: 100%;
  padding: 0;
  border-top: none;
`;

export const Comments = styled(ColumnFlexWithPadding)`
  max-height: 550px;
  overflow-y: auto;
`;
