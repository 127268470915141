import styled from 'styled-components';
import { Button, Checkbox, Dialog } from 'app/components/shared';
import { IconClickable } from 'app/components/shared/IconClickable';
import { Colors } from 'app/themes/colors';

export const CloseAppealDialogComponent = styled(Dialog)`
  width: 425px;
`;

export const EvaluationIcon = styled(IconClickable)<{ color: string; disabled: boolean }>`
  fill: ${props => props.color} !important;
  height: 30px;
  width: 30px;
  ${props => (props.disabled ? 'cursor: not-allowed' : '')}
`;

export const StyledCheckbox = styled(Checkbox)`
  padding: 8px 10px;
  background-color: ${Colors.Blue400};
  border-radius: 6px;
`;

export const CloseAppealButton = styled(Button)`
  height: 46px;
  width: 120px;
`;
