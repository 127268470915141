import styled from 'styled-components';
import { ColumnFlexWithPadding } from '../../../typography/flex';
import { Button, Input, Table } from '../../../components/shared';

import { Colors } from '../../../themes/colors';

export const Container = styled(ColumnFlexWithPadding)`
  padding: 20px 30px;
`;

export const EditNotificationsButton = styled(Button)`
  &&& {
    padding: 12px 16px;
    min-height: 40px;
    white-space: nowrap;
    box-shadow: none;
  }
`;

export const SupplierNameInput = styled(Input)`
  width: 395px;
  height: 40px;
`;

export const Status = styled.span<{ isActive: boolean }>`
  font-weight: 500;
  color: ${({ isActive }) => (isActive ? Colors.Orange800 : Colors.Grey700)};
`;

export const TableComponent = styled(Table)`
  padding: 0 20px;
  overflow: inherit !important;
  min-height: calc(100vh - 72px - 137px - 40px);

  td,
  th {
    &:last-child {
      text-align: left;
    }
  }

  .actions {
    width: 45px !important;
  }
` as typeof Table;
