import { AppealsActions, AppealsSelectors } from 'store/appeals';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { GreyTextBig, HeaderSmall, TextRegularBig } from 'app/typography/text';
import { FormField } from 'app/components/shared';
import { Appeal } from 'app/models/appeal';
import { useAction } from 'app/helpers/actions/use-action';
import { webphoneActions } from 'store/webphone';
import { profileSelectors } from 'store/profile';
import { useBind } from 'app/helpers/hooks/use-bind';
import { PagesRoutes } from 'app/constants/route-path';
import { formatPhoneNumber } from 'app/helpers/phone/phone';
import { getAdditionalFields } from 'app/helpers/appeals';
import { SuppliersSelectors } from 'store/suppliers';
import React from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { AboutAppealField, aboutAppealFields, aboutAppealFieldTileMap } from '../../constants/about-appeal-field.enum';
import { ResponsibleField, responsibleFields, responsibleFieldsNameMap } from '../../constants/responsible-field.enum';
import { Comments, CommonInfoContainer, FormFieldInfoItem, Info, Item, RedText, TextButton, VerticalContainer } from './styled';
import { ActionButton, Link } from '../../styled';
import { aboutAppealFieldConditions, responsibleFieldConditions } from './fields-conditions';
import { getAppealKind, getAppealType } from '../../helpers/functions';
import { PersonalInfoField, personalInfoFieldNameMap, personalInfoFields } from '../../constants/consumer-field.enum';
import { selectCommentsIds } from '../../../../../store/comments/comments.selectors';
import { CommentInput } from '../../../Incident/sections/CommonInfo/styled';
import { CreateComment } from '../../../../../store/comments/comments.actions';
import { CommentItem } from '../../../Incident/sections/CommonInfo/sections/components/CommentItem/CommentItem';
import { IncidentField, incidentInfoFieldNameMap, incidentInfoFields } from '../../constants/incident-field.enum';
import { IncidentLabelStatusToLabelMap } from '../../../../models/incident/incident';
import { DateFormat, localFormat } from '../../../../helpers/date/time';
import { SupplierId } from '../../../../models/supplier';
import { VerticallyCenteredFlexWithSpaceBetween } from '../../../../typography/flex';
import { EditAppealIncidents } from '../../../../../store/appeals/appeals.actions';
import { ModalService } from '../../../../services/modal-service/modal.service';
import { ConfirmModal } from '../../../../components/Modals/ConfirmModal/ConfirmModal';
import { IncidentsTable } from './components/IncidentsTable/IncidentsTable';
import { IncidentForm } from '../../../Incidents/components/IncidentForm/IncidentForm';

export const EMPTY_FIELD_TEXT = 'Не назначено';
const EMPTY_MANAGEMENT_COMPANY_FIELD_TEXT = 'Не определено';

export const CommonInfo = React.memo(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const appeal = useSelector(AppealsSelectors.selectSelectedAppeal, shallowEqual);

  const currentDispatcher = useSelector(profileSelectors.selectProfile, shallowEqual);
  const appealsKinds = useSelector(AppealsSelectors.selectAppealKinds, shallowEqual);
  const appealTypes = useSelector(AppealsSelectors.selectAppealTypes, shallowEqual);
  const appealAdditionalFields = useSelector(AppealsSelectors.selectAppealAdditionalFields, shallowEqual);
  const supplier = useSelector(
    SuppliersSelectors.selectSupplierBySupplierId(
      appeal?.mcAddress || appeal?.managementCompanyId || (appeal?.managementCompany?.id as SupplierId)
    ),
    shallowEqual
  );
  const openSelectMasterDialog = useAction(AppealsActions.openSelectMasterDialog);
  const updateAppeal = useAction(AppealsActions.UpdateAppeal.init);
  const call = useAction(webphoneActions.InitOutgoingRinging.init);
  const commentsIds = useSelector(selectCommentsIds, shallowEqual);

  const callPhone = React.useCallback(
    (phone?: string) => {
      const prefix = appeal?.managementCompany?.prefix || supplier?.prefix;
      if (phone) {
        call({ phone, options: { prefix }, appealId: appeal?.id });
      }
    },
    [appeal?.id, appeal?.managementCompany?.prefix, call, supplier?.prefix]
  );

  const callConsumer = useBind(callPhone, appeal?.consumer?.phone);
  const callUtilityCompany = useBind(callPhone, appeal?.managementCompany?.phone);
  const callHandyman = useBind(callPhone, appeal?.handyman?.phone);

  const getIsUrgentFiledValue = React.useCallback((appeal: Appeal) => {
    if (appeal.isUrgent) {
      if (appeal.isEmergency) {
        return <RedText>Срочная, авария</RedText>;
      }
      return <RedText>Срочная</RedText>;
    }
    if (appeal.isEmergency) {
      return <RedText>Авария</RedText>;
    }
    return 'Не срочная';
  }, []);

  const getIsPaymentFiledValue = React.useCallback((appeal: Appeal) => {
    if (appeal.isPayment) {
      return <TextRegularBig>Платная</TextRegularBig>;
    }
    return undefined;
  }, []);

  const getAboutAppealField = React.useCallback(
    (field: AboutAppealField) => {
      if (appeal) {
        switch (field) {
          case AboutAppealField.AppealRequisitionType:
            return getAppealType(appealTypes, appeal);
          case AboutAppealField.AppealRequisitionKind:
            return getAppealKind(appealsKinds, appeal);
          case AboutAppealField.IsUrgent:
            return getIsUrgentFiledValue(appeal);
          case AboutAppealField.isPayment:
            return getIsPaymentFiledValue(appeal);
          default:
            return appeal[field] || EMPTY_FIELD_TEXT;
        }
      }
      return EMPTY_FIELD_TEXT;
    },
    [appeal, appealTypes, appealsKinds, getIsPaymentFiledValue, getIsUrgentFiledValue]
  );

  const handleAssignMasterClick = React.useCallback(
    (appeal: Appeal) => () => {
      openSelectMasterDialog({ appeal });
    },
    [openSelectMasterDialog]
  );

  const handleAssignMeClick = React.useCallback(
    (appeal: Appeal) => () => {
      if (!currentDispatcher) return;
      updateAppeal({
        id: appeal.id,
        consumerId: appeal.consumer.id,
        addressId: appeal.address.id,
        address: appeal.address.address,
        dispatcherId: currentDispatcher.id,
      });
    },
    [currentDispatcher, updateAppeal]
  );

  const handleUtilityCompanyClick = React.useCallback(() => {
    if (!appeal?.managementCompany) return;

    navigate(
      generatePath(PagesRoutes.PAGES.SUPPLIER, {
        supplierId: appeal.managementCompany.id,
      })
    );
  }, [appeal?.managementCompany, navigate]);

  const getResponsibleField = React.useCallback(
    (field: ResponsibleField) => {
      if (appeal) {
        switch (field) {
          case ResponsibleField.Master:
            return appeal.handyman?.fullName || <TextButton onClick={handleAssignMasterClick(appeal)}>Назначить мастера</TextButton>;
          case ResponsibleField.MasterPhone:
            return appeal.handyman?.phone ? (
              <ActionButton onClick={callHandyman}>{formatPhoneNumber(appeal.handyman?.phone)}</ActionButton>
            ) : (
              EMPTY_FIELD_TEXT
            );
          case ResponsibleField.Dispatcher:
            return appeal.dispatcher?.fullName || <TextButton onClick={handleAssignMeClick(appeal)}>Назначить на себя</TextButton>;
          case ResponsibleField.UtilityCompany:
            return appeal.managementCompany?.name ? (
              <ActionButton onClick={handleUtilityCompanyClick}>
                {appeal.managementCompany.name} {appeal.managementCompany.prefix && `*${appeal.managementCompany.prefix}*`}
              </ActionButton>
            ) : (
              <RedText>{EMPTY_MANAGEMENT_COMPANY_FIELD_TEXT}</RedText>
            );
          case ResponsibleField.UtilityCompanyPhone:
            return appeal.managementCompany?.phone ? (
              <ActionButton onClick={callUtilityCompany}>{formatPhoneNumber(appeal.managementCompany.phone)}</ActionButton>
            ) : (
              <RedText>{EMPTY_MANAGEMENT_COMPANY_FIELD_TEXT}</RedText>
            );
          case ResponsibleField.PersonalAccount:
            return appeal.personalAccount || <RedText>{EMPTY_MANAGEMENT_COMPANY_FIELD_TEXT}</RedText>;
          case ResponsibleField.SupplierByAddress:
            return appeal.address?.manageCompany || <RedText>{EMPTY_MANAGEMENT_COMPANY_FIELD_TEXT}</RedText>;
          case ResponsibleField.SectorByAddress:
            return appeal.address?.manageCompanySector || <RedText>{EMPTY_MANAGEMENT_COMPANY_FIELD_TEXT}</RedText>;
          default:
            return EMPTY_FIELD_TEXT;
        }
      }
      return EMPTY_FIELD_TEXT;
    },
    [appeal, callHandyman, callUtilityCompany, handleAssignMasterClick, handleAssignMeClick, handleUtilityCompanyClick]
  );

  const getPersonalInfoField = React.useCallback(
    (field: PersonalInfoField) => {
      if (appeal) {
        const { consumer } = appeal;
        const address = appeal?.address?.fias_level ? (
          appeal?.address?.address
        ) : (
          <>
            <GreyTextBig>Не распознан: </GreyTextBig> {appeal?.address?.address}
          </>
        );

        switch (field) {
          case PersonalInfoField.Contactor:
            return (
              <Link
                to={generatePath(PagesRoutes.PAGES.CONSUMER, {
                  consumerId: String(consumer?.id),
                })}
              >
                {consumer?.fio}
              </Link>
            );
          case PersonalInfoField.ContactorPhone:
            return consumer?.phone ? (
              <ActionButton onClick={callConsumer}>{formatPhoneNumber(consumer?.phone)}</ActionButton>
            ) : (
              EMPTY_FIELD_TEXT
            );
          case PersonalInfoField.Address:
            return appeal?.address ? address : EMPTY_FIELD_TEXT;

          case PersonalInfoField.UtilityCompanyByPhone:
            return appeal.managementCompany?.name ? (
              <ActionButton onClick={handleUtilityCompanyClick}>
                {appeal.managementCompany.name} {appeal.managementCompany.prefix && `*${appeal.managementCompany.prefix}*`}
              </ActionButton>
            ) : (
              EMPTY_MANAGEMENT_COMPANY_FIELD_TEXT
            );

          case PersonalInfoField.UtilityCompanyByAddress:
            return appeal?.address?.manageCompany ? appeal?.address?.manageCompany : EMPTY_FIELD_TEXT;

          case PersonalInfoField.SectorByAddress:
            return appeal?.address?.manageCompanySector ? appeal?.address?.manageCompanySector : EMPTY_FIELD_TEXT;

          default:
            return '';
        }
      }
      return '';
    },
    [appeal, callConsumer, handleUtilityCompanyClick]
  );
  const getIncidentInfoField = React.useCallback(
    (field: IncidentField) => {
      if (!appeal) return '';

      const { incident } = appeal;

      switch (field) {
        case IncidentField.Number:
          return (
            <Link
              to={generatePath(PagesRoutes.PAGES.INCIDENT, {
                incidentId: String(incident?.id),
              })}
            >
              {incident?.number}
            </Link>
          );
        case IncidentField.Type:
          return incident?.incident_type === 'accident' ? 'Авария' : 'Плановые работы';

        case IncidentField.Status:
          return incident?.status ? IncidentLabelStatusToLabelMap[incident?.status] : 'Не определено';

        case IncidentField.Supplier:
          return incident?.supplier?.name || EMPTY_FIELD_TEXT;

        case IncidentField.CreatedAt:
          return incident?.created_at ? localFormat(new Date(incident[field]), DateFormat.LocalDateAndTime) : EMPTY_FIELD_TEXT;

        case IncidentField.PlannedDateEnd:
          return incident?.planned_end_date ? localFormat(new Date(incident[field]), DateFormat.LocalDateAndTime) : EMPTY_FIELD_TEXT;

        default:
          return '';
      }
    },
    [appeal]
  );

  const handleSendMessage = React.useCallback(
    (message: string) => {
      if (!appeal?.id) return;
      dispatch(CreateComment.init({ id: String(appeal.id), text: message, entity: 'appeal' }));
    },
    [appeal?.id, dispatch]
  );

  const onDeleteIncident = React.useCallback(() => {
    if (!appeal?.id) return;
    dispatch(EditAppealIncidents.init({ id: appeal?.id, incident: null }));
  }, [appeal?.id, dispatch]);

  const handleOpenConfirmDeleteModal = React.useCallback(() => {
    if (!appeal?.id) return;
    const incident = appeal?.incident;
    const text = incident?.incident_type === 'accident' ? 'Авария' : 'Плановые работы ';

    ModalService.openModal(ConfirmModal, {
      title: 'Отвязка заявки от инцидента',
      text: `Вы действительно хотите отвязать заявку ${appeal?.number} от инцидента ${text} №${incident?.number}?`,
      onClick: onDeleteIncident,
      textButton: 'Отвязать',
      onClose: () => {},
    });
  }, [appeal?.id, appeal?.incident, appeal?.number, onDeleteIncident]);

  const handleOpenCreateNewIncidentModal = React.useCallback(() => {
    ModalService.openModal(IncidentForm, { getToWork: false, appealId: appeal?.id });
  }, [appeal?.id]);

  return (
    <CommonInfoContainer>
      <VerticalContainer spacing="20px">
        <Item spacing="20px">
          <HeaderSmall>О заявке</HeaderSmall>
          <Info spacing="20px">
            {aboutAppealFields
              .filter(field => aboutAppealFieldConditions(field, appeal))
              .map(field => {
                return (
                  <FormFieldInfoItem isUseFirstChild spacing="0" key={field} placeholder={aboutAppealFieldTileMap[field]}>
                    <TextRegularBig>{getAboutAppealField(field)}</TextRegularBig>
                  </FormFieldInfoItem>
                );
              })}

            {/* //todo уточнить что это за поля и нужны ли */}
            {appeal?.additionalFields &&
              getAdditionalFields(appealAdditionalFields, appeal.type, appeal.appealRequisitionType, appeal.appealRequisitionKind).map(
                ({ id, label, options }) => {
                  let value = appeal.additionalFields.find(field => field.field === id)?.value;
                  if (options && value) {
                    value = options.find(option => option.value === value)?.label;
                  }
                  return (
                    <FormField key={id} placeholder={label} spacing="5px">
                      <TextRegularBig>{value || <RedText>Не определено</RedText>}</TextRegularBig>
                    </FormField>
                  );
                }
              )}
          </Info>
        </Item>

        <Item spacing="20px">
          <HeaderSmall>Комментарии</HeaderSmall>
          <Info spacing="20px">
            <CommentInput sendMessage={handleSendMessage} placeholder="Введите комментарий" />
            <Comments spacing="24px">
              {commentsIds?.map(commentId => (
                <CommentItem key={commentId} commentId={commentId} />
              ))}
            </Comments>
          </Info>
        </Item>
      </VerticalContainer>

      <VerticalContainer spacing="20px">
        <Item spacing="10px">
          <HeaderSmall>Ответственные</HeaderSmall>
          <Info spacing="20px">
            {responsibleFields
              .filter(field => responsibleFieldConditions(field, appeal))
              .map(field => (
                <FormFieldInfoItem key={field} placeholder={responsibleFieldsNameMap[field]} spacing="0px">
                  <TextRegularBig>{getResponsibleField(field)}</TextRegularBig>
                </FormFieldInfoItem>
              ))}
          </Info>
        </Item>

        <Item spacing="10px">
          <HeaderSmall>Потребитель</HeaderSmall>

          <Info spacing="20px">
            {personalInfoFields.map(field => (
              <FormFieldInfoItem isUseThirdChild key={field} placeholder={personalInfoFieldNameMap[field]} spacing="0px">
                <TextRegularBig>{getPersonalInfoField(field)}</TextRegularBig>
              </FormFieldInfoItem>
            ))}
          </Info>
        </Item>

        {appeal?.incident && (
          <Item spacing="10px">
            <VerticallyCenteredFlexWithSpaceBetween>
              <HeaderSmall>Инцидент</HeaderSmall>
              <ActionButton onClick={handleOpenConfirmDeleteModal}>Отвязать</ActionButton>
            </VerticallyCenteredFlexWithSpaceBetween>

            <Info spacing="20px">
              {incidentInfoFields.map(field => (
                <FormFieldInfoItem key={field} placeholder={incidentInfoFieldNameMap[field]} spacing="0px">
                  <TextRegularBig>{getIncidentInfoField(field)}</TextRegularBig>
                </FormFieldInfoItem>
              ))}
            </Info>
          </Item>
        )}

        {appeal?.relatedIncidents && (
          <Item spacing="10px">
            <VerticallyCenteredFlexWithSpaceBetween>
              <HeaderSmall>Инцидент</HeaderSmall>
              <ActionButton onClick={handleOpenCreateNewIncidentModal}>Создать новый инцидент</ActionButton>
            </VerticallyCenteredFlexWithSpaceBetween>

            <IncidentsTable />
          </Item>
        )}
      </VerticalContainer>
    </CommonInfoContainer>
  );
});

CommonInfo.displayName = 'CommonInfo';
