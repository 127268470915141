import { HttpService } from '../http.service';
import { Service } from '../../models/services/service';
import { FetchServicesResponse } from './dto/fetch-services-response';

class ServicesServiceImpl {
  fetchServices(): Promise<Service[]> {
    return HttpService.get<FetchServicesResponse>('api/v1/services').then(res => res.data.data.services);
  }
}

export const ServicesService = new ServicesServiceImpl();
