import { createSelector } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-extraneous-dependencies
import createCachedSelector from 're-reselect';
import { AppState } from '../../types/AppState.type';
import { ProfileState } from './profile.reducer';
import { defaultCachedSelectorKeySelector } from '../../utils/selectors';

const selectProfileState: (state: AppState) => ProfileState = state => state.profileState;

export const selectProfileIsLoading = createSelector(selectProfileState, state => state.loading);

export const selectCreateInProgress = createSelector(selectProfileState, state => state.creating);

export const selectUpdateInProgress = createSelector(selectProfileState, state => state.updating);

export const selectProfile = createSelector(selectProfileState, state => state.profile);
export const selectProfilesBySupplierId = createSelector(selectProfileState, state => state.profilesBySupplierId);

export const selectError = createSelector(selectProfileState, state => {
  const error = state.error?.data;
  if (!error) return undefined;

  if ('data' in error) return undefined;

  return error?.message;
});

const profilesOptionsSelector = (() => {
  const selector = createCachedSelector([selectProfileState], profiles => {
    if (!profiles?.profilesBySupplierId.length) return [];

    return profiles?.profilesBySupplierId?.map(profile => ({
      key: profile.id,
      value: profile.id,
      label: profile.fullName,
    }));
  })(defaultCachedSelectorKeySelector);

  return (state: AppState) => selector(state);
})();

export const selectProfilesOptions = (state: AppState) => profilesOptionsSelector(state);
