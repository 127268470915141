import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { ModalDef } from '../models/modal-def';
import { SideModalService } from '../modal.service';

export function SideModal() {
  const [modals, setModals] = useState(SideModalService.modalList);
  const [rootContainer, setRootContainer] = useState<HTMLElement | undefined>(
    SideModalService.rootContainer
  );

  useEffect(() => {
    const handler = () => {
      setModals(SideModalService.modalList);
      setRootContainer(SideModalService.rootContainer);
    };

    SideModalService.onChange.addListener('', handler);

    return () => {
      SideModalService.onChange.removeListener('', handler);
    };
  }, []);

  return <SideModalInternal rootContainer={rootContainer} modalList={modals} />;
}

interface ModalInternalProps {
  modalList: ModalDef[];
  rootContainer: HTMLElement | undefined;
}

export function SideModalInternal({ rootContainer, modalList }: ModalInternalProps): JSX.Element {
  function renderModal(modal: ModalDef) {
    return <Backdrop key={modal.id}>{modal.element}</Backdrop>;
  }

  return (
    <>
      {rootContainer &&
        modalList.map(modal => ReactDOM.createPortal(renderModal(modal), rootContainer!, modal.id))}
    </>
  );
}

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
`;
