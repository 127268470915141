import { all, call, cancelled, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import history from 'app/history';
import { Consumer, ConsumerId, ConsumersQueryParams } from 'app/models/consumer';
import { PagesRoutes } from 'app/constants/route-path';
import { ConsumersService } from 'app/services/consumers-service';
import { AppealsService } from 'app/services/appeals-service/appeals.service';
import { Call } from 'app/models/call';
import { AccommodationService } from 'app/services/accommodation-service';
import { TableDto } from 'app/services/models/table.dto';
import { DEFAULT_ROWS_COUNT, MAX_ROWS_COUNT } from 'app/constants/scroll';
import { Appeal } from 'app/models/appeal';
import Axios from 'axios';
import { generatePath } from 'react-router-dom';
import * as ConsumerActions from './consumers.actions';
import { CreateConsumerPayload, UpdateConsumerPayload } from './models';

function* loadConsumersWatcher({ payload }: PayloadAction<ConsumersQueryParams>) {
  try {
    const response: TableDto<Consumer> = yield call(ConsumersService.getConsumers, {
      per_page: payload.per_page || DEFAULT_ROWS_COUNT,
      ...payload,
    });

    yield put(
      ConsumerActions.LoadConsumers.success({
        consumers: response.results,
        page: response.page,
        consumersTotalCount: response.count,
      })
    );
  } catch (e: any) {
    yield put(ConsumerActions.LoadConsumers.failure(e));
  }
}

function* loadSelectedConsumerWatcher({ payload }: PayloadAction<{ id: ConsumerId }>) {
  try {
    const response: Consumer = yield call(ConsumersService.getConsumer, payload.id);

    yield put(ConsumerActions.LoadSelectedConsumer.success(response));
  } catch (e: any) {
    yield put(ConsumerActions.LoadSelectedConsumer.failure(e));
  }
}

function* deleteConsumerWatcher({ payload }: PayloadAction<{ id: ConsumerId }>) {
  try {
    yield call(ConsumersService.deleteConsumer, payload.id);
    yield put(ConsumerActions.DeleteConsumer.success({ id: payload.id }));
  } catch (e: any) {
    yield put(ConsumerActions.DeleteConsumer.failure(e));
  }
}

function* navigateToUpdateConsumerPage({ payload }: PayloadAction<{ id: string }>) {
  try {
    yield put(ConsumerActions.LoadSelectedConsumer.init(payload));
    yield put(ConsumerActions.NavigateToUpdateConsumerPage.success());

    history.push(
      generatePath(PagesRoutes.PAGES.CONSUMER_UPDATE, {
        consumerId: String(payload?.id),
      })
    );
  } catch (e: any) {
    yield put(ConsumerActions.NavigateToUpdateConsumerPage.failure(e));
  }
}

function* createConsumer({ payload }: PayloadAction<CreateConsumerPayload>) {
  try {
    const consumer: Consumer = yield call(ConsumersService.createConsumer, payload);

    yield put(ConsumerActions.CreateConsumer.success(consumer));
    history.push(
      generatePath(PagesRoutes.PAGES.CONSUMER, {
        consumerId: String(consumer?.id),
      })
    );
  } catch (e: any) {
    yield put(ConsumerActions.CreateConsumer.failure(e));
  }
}

function* updateConsumer({ payload }: PayloadAction<UpdateConsumerPayload>) {
  try {
    const consumer: Consumer = yield call(ConsumersService.updateConsumer, payload.consumerDto);

    yield put(ConsumerActions.UpdateConsumer.success(consumer));

    history.push(
      generatePath(PagesRoutes.PAGES.CONSUMER, {
        consumerId: String(consumer?.id),
      })
    );
  } catch (e: any) {
    history.push(PagesRoutes.PAGES.CONSUMERS);
    yield put(ConsumerActions.UpdateConsumer.failure(e));
  }
}

function* deleteConsumerAccommodation({ payload }: PayloadAction<string>) {
  try {
    yield call(AccommodationService.deleteAccommodation, payload);

    yield put(ConsumerActions.DeleteConsumerAccommodation.success(payload));
  } catch (e: any) {
    yield put(ConsumerActions.DeleteConsumerAccommodation.failure(e));
  }
}

function* loadConsumerAppeals({ payload }: PayloadAction<string>) {
  const cancelSource = Axios.CancelToken.source();
  try {
    const appeals: TableDto<Appeal> = yield call(
      AppealsService.getAppeals,
      {
        address__address: payload,
        per_page: MAX_ROWS_COUNT,
      },
      cancelSource.token
    );
    yield put(ConsumerActions.LoadConsumerAppeals.success(appeals.results));
  } catch (e: any) {
    yield put(ConsumerActions.LoadConsumerAppeals.failure(e));
  } finally {
    if (yield cancelled()) {
      yield call(cancelSource.cancel);
    }
  }
}

function* loadConsumerCalls({ payload }: PayloadAction<string>) {
  try {
    const calls: Call[] = yield call(ConsumersService.getConsumerCalls, payload, {
      per_page: MAX_ROWS_COUNT,
    });
    yield put(ConsumerActions.LoadConsumerCalls.success(calls));
  } catch (e: any) {
    yield put(ConsumerActions.LoadConsumerCalls.failure(e));
  }
}

export default function* watcher() {
  yield all([
    takeLatest(ConsumerActions.LoadConsumers.init.type, loadConsumersWatcher),
    takeLatest(ConsumerActions.LoadSelectedConsumer.init.type, loadSelectedConsumerWatcher),
    takeLatest(ConsumerActions.CreateConsumer.init.type, createConsumer),
    takeLatest(ConsumerActions.UpdateConsumer.init.type, updateConsumer),
    takeLatest(ConsumerActions.DeleteConsumer.init.type, deleteConsumerWatcher),
    takeLatest(ConsumerActions.DeleteConsumerAccommodation.init.type, deleteConsumerAccommodation),
    takeLatest(ConsumerActions.NavigateToUpdateConsumerPage.init.type, navigateToUpdateConsumerPage),
    takeLatest(ConsumerActions.LoadConsumerAppeals.init.type, loadConsumerAppeals),
    takeLatest(ConsumerActions.LoadConsumerCalls.init.type, loadConsumerCalls),
  ]);
}
