class ToneImpl {
  status = 0;

  private context = new AudioContext();
  private osc1?: OscillatorNode;
  private osc2?: OscillatorNode;
  private readonly dtmfFrequencies = {
    1: { f1: 697, f2: 1209 },
    2: { f1: 697, f2: 1336 },
    3: { f1: 697, f2: 1477 },
    4: { f1: 770, f2: 1209 },
    5: { f1: 770, f2: 1336 },
    6: { f1: 770, f2: 1477 },
    7: { f1: 852, f2: 1209 },
    8: { f1: 852, f2: 1336 },
    9: { f1: 852, f2: 1477 },
    '*': { f1: 941, f2: 1209 },
    0: { f1: 941, f2: 1336 },
    '#': { f1: 941, f2: 1477 },
  };

  private setup(character: number | string) {
    const frequencyPair = this.dtmfFrequencies[character];

    this.osc1 = this.context.createOscillator();
    this.osc2 = this.context.createOscillator();
    this.osc1.frequency.value = frequencyPair.f1;
    this.osc2.frequency.value = frequencyPair.f2;

    const gainNode = this.context.createGain();
    gainNode.gain.value = 0.25;

    const filter = this.context.createBiquadFilter();
    filter.type = 'lowpass';

    this.osc1.connect(gainNode);
    this.osc2.connect(gainNode);

    gainNode.connect(filter);
    filter.connect(this.context.destination);
  }

  start(character: number | string) {
    this.setup(character);
    this.osc1?.start(0);
    this.osc2?.start(0);
    this.status = 1;
  }

  stop() {
    this.osc1?.stop(0);
    this.osc2?.stop(0);
    this.status = 0;
  }
}

export const Tone = new ToneImpl();
