import { HttpService } from '../http.service';
import { TableDto } from '../models/table.dto';
import { Incident, IncidentId } from '../../models/incident/incident';
import { CreateIncidentApiParams } from './dtos/create-incident.request';
import { IncidentsQueryParams } from './dtos/incidents-query-params';
import { EditIncidentApiParams } from './dtos/edit-incident';
import { AddAppealsToIncidentApiParams, AddAppealsToIncidentApiResponse } from './dtos/add-appeals-to-incident';
import { GetRelatedAppealsApiParams, GetRelatedAppealsApiResponse } from './dtos/get-related-appeals';
import { DEFAULT_ROWS_COUNT } from '../../constants/scroll';

class IncidentsServiceImpl {
  private static apiEndpoint = '/api/incidents';

  async getIncidents(params: IncidentsQueryParams): Promise<TableDto<Incident>> {
    const { per_page, page, ...queryFilters } = params;

    const queryParams = {
      per_page: per_page || DEFAULT_ROWS_COUNT,
      page,
      ...queryFilters,
    };

    const response = await HttpService.get<TableDto<Incident>>(`${IncidentsServiceImpl.apiEndpoint}/`, {
      params: queryParams,
    });
    return response.data;
  }

  async getIncident(id: IncidentId): Promise<Incident> {
    const response = await HttpService.get<Incident>(`${IncidentsServiceImpl.apiEndpoint}/${id}/`);
    return response.data;
  }

  async createIncident(createConsumerDto: CreateIncidentApiParams): Promise<Incident> {
    const response = await HttpService.post<Incident>(IncidentsServiceImpl.apiEndpoint, createConsumerDto);
    return response.data;
  }

  async editIncident(params: EditIncidentApiParams): Promise<Incident> {
    const res = await HttpService.put<Incident>(`${IncidentsServiceImpl.apiEndpoint}/${params.id}`, { ...params });
    return res.data;
  }

  async addAppealsToIncident(params: AddAppealsToIncidentApiParams): Promise<AddAppealsToIncidentApiResponse> {
    const res = await HttpService.post<AddAppealsToIncidentApiResponse>(`${IncidentsServiceImpl.apiEndpoint}/${params.id}/add_appeals`, {
      appeals: params.appeals,
    });
    return res.data;
  }

  async getRelatedAppeals(params: GetRelatedAppealsApiParams): Promise<GetRelatedAppealsApiResponse> {
    const res = await HttpService.get<GetRelatedAppealsApiResponse>(`${IncidentsServiceImpl.apiEndpoint}/${params.id}/related_appeals`);
    return res.data;
  }
}

export const IncidentsService = new IncidentsServiceImpl();
