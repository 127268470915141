import { ConsumerAddress } from 'app/models/consumer';
import { RedTextBig } from 'app/typography/text';
import {
  AccommodationColumn,
  AccommodationInfoTabContainer,
  Column,
  CustomerAccountsColumn,
  Heading,
  Label,
  ManagementCompanyColumn,
  Text,
  TextGroup,
} from './styled';

interface AccommodationInfoTabProps {
  accommodation?: ConsumerAddress;
}

enum AccommodationInfoField {
  Address = 'address',
  OwnerName = 'ownerName',
  OwnerPhone = 'ownerPhone',
  NumberOfOccupants = 'numberOfOccupants',
}

const aboutAccommodationFields = [
  AccommodationInfoField.Address,
  AccommodationInfoField.OwnerName,
  AccommodationInfoField.OwnerPhone,
  AccommodationInfoField.NumberOfOccupants,
];

const aboutAccommodationFieldLabelMap = {
  [AccommodationInfoField.Address]: 'Адрес',
  [AccommodationInfoField.OwnerName]: 'ФИО собственника',
  [AccommodationInfoField.OwnerPhone]: 'Телефон собственника',
  [AccommodationInfoField.NumberOfOccupants]: 'Количество проживающих',
};

export const AccommodationInfoTab = ({ accommodation }: AccommodationInfoTabProps) => {
  const getAboutAccommodationFieldValue = (field: AccommodationInfoField) => accommodation?.[field] || 'Не назначено';

  return (
    <AccommodationInfoTabContainer>
      <AccommodationColumn>
        <Heading>О помещении</Heading>
        <Column>
          {aboutAccommodationFields.map(field => (
            <TextGroup key={field}>
              <Label>{aboutAccommodationFieldLabelMap[field]}</Label>
              <Text>{getAboutAccommodationFieldValue(field)}</Text>
            </TextGroup>
          ))}
        </Column>
      </AccommodationColumn>
      {accommodation?.customerAccounts && (
        <CustomerAccountsColumn>
          <Heading>Лицевые счета</Heading>
          {accommodation.customerAccounts?.electricity && (
            <TextGroup>
              <Label>Электричество</Label>
              <Text>{accommodation.customerAccounts.electricity}</Text>
            </TextGroup>
          )}
          {accommodation.customerAccounts?.gas && (
            <TextGroup>
              <Label>Газ</Label>
              <Text>{accommodation.customerAccounts.gas}</Text>
            </TextGroup>
          )}
          {accommodation.customerAccounts?.waterSupply && (
            <TextGroup>
              <Label>Водоснабжение</Label>
              <Text>{accommodation.customerAccounts.waterSupply}</Text>
            </TextGroup>
          )}
          {accommodation.customerAccounts?.heatSupply && (
            <TextGroup>
              <Label>Теплоснабжение</Label>
              <Text>{accommodation.customerAccounts.heatSupply}</Text>
            </TextGroup>
          )}
          {accommodation.customerAccounts?.capitalRepairs && (
            <TextGroup>
              <Label>Капремонт</Label>
              <Text>{accommodation.customerAccounts.capitalRepairs}</Text>
            </TextGroup>
          )}
          {accommodation.customerAccounts?.securityAndEntryPhone && (
            <TextGroup>
              <Label>Охрана и домофон</Label>
              <Text>{accommodation.customerAccounts.securityAndEntryPhone}</Text>
            </TextGroup>
          )}
        </CustomerAccountsColumn>
      )}
      <ManagementCompanyColumn>
        <Heading>Поставщик</Heading>
        <TextGroup>
          <Label>Название</Label>
          <Text>{accommodation?.manageCompany || <RedTextBig>Не определено</RedTextBig>}</Text>
        </TextGroup>
        <TextGroup>
          <Label>Участок</Label>
          <Text>{accommodation?.manageCompanySector || <RedTextBig>Не определено</RedTextBig>}</Text>
        </TextGroup>
      </ManagementCompanyColumn>
    </AccommodationInfoTabContainer>
  );
};
