import { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { AppealsActions, AppealsSelectors } from 'store/appeals';
import { SuppliersSelectors } from 'store/suppliers';
import { ConfigurationSelectors } from 'store/configuration';
import { HeaderSmall, TextRegularBig } from 'app/typography/text';
import { FormField, Input, Select } from 'app/components/shared';
import { PAYMENT_STATUS_OPTIONS, PAYMENT_TYPE_OPTIONS, PaymentStatus, PaymentType } from 'app/models/payment';
import { useFormik } from 'formik';
import { UpdateAppealRequest } from 'app/services/appeals-service/dtos/update-appeal-request';
import { number, object, string } from 'yup';
import { Column, TabComponent } from '../../styled';
import { StyledButton, Link } from './styled';

enum PaymentFields {
  UtilityCompanyId = 'utilityCompanyId',
  Work = 'work',
  Amount = 'amount',
  PaymentType = 'paymentType',
  PaymentStatus = 'paymentStatus',
}

export const PaymentInfo = () => {
  const appeal = useSelector(AppealsSelectors.selectSelectedAppeal, shallowEqual);
  const percentTax = useSelector(ConfigurationSelectors.selectPercentTax, shallowEqual);
  const minTax = useSelector(ConfigurationSelectors.selectMinTax, shallowEqual);
  const suppliersOptions = useSelector(SuppliersSelectors.selectSuppliersOptions, shallowEqual);
  const works = useSelector(AppealsSelectors.selectAppealWorks, shallowEqual);
  const workOptions = useSelector(AppealsSelectors.selectAppealWorkOptions, shallowEqual);
  const notEdit = appeal?.paymentStatus === PaymentStatus.Paid;
  const dispatch = useDispatch();

  const [taxAmount, setTaxAmount] = useState(0);

  const initialValues = {
    [PaymentFields.UtilityCompanyId]: appeal?.utilityCompany?.id || appeal?.managementCompany?.id,
    [PaymentFields.Work]: appeal?.work,
    [PaymentFields.Amount]: +((appeal?.amount || 0) / 100).toFixed(2),
    [PaymentFields.PaymentType]: typeof appeal?.paymentType === 'number' ? appeal.paymentType : PaymentType.Cashless,
    [PaymentFields.PaymentStatus]: typeof appeal?.paymentStatus === 'number' ? appeal.paymentStatus : PaymentStatus.NotPaid,
  };

  const { values, handleBlur, handleChange, handleSubmit, touched, isValid, dirty, errors } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: object().shape({
      [PaymentFields.UtilityCompanyId]: string().required('Контрагент-исполнитель обязателен'),
      [PaymentFields.Work]: string().required('Вид работы обязателен'),
      [PaymentFields.Amount]: number().required('Сумма обязательна'),
      [PaymentFields.PaymentType]: number().required('Вид оплаты обязателен'),
    }),
    onSubmit: formValues => {
      if (appeal) {
        const { amount, ...form } = formValues;
        const payload: UpdateAppealRequest = {
          id: appeal.id,
          consumerId: appeal.consumer?.id,
          addressId: appeal.address?.id,
          dispatcherId: appeal.dispatcher?.id,
          address: appeal.address?.address,
          amount: +(amount * 100).toFixed(),
          taxAmount: +(taxAmount * 100).toFixed(),
          ...form,
        };

        dispatch(AppealsActions.UpdateAppeal.init(payload));
      }
    },
  });

  const disableStatus = useMemo(
    () => notEdit || +values[PaymentFields.PaymentType] === PaymentType.Cashless,
    [values[PaymentFields.PaymentType]]
  );

  const getError = (field: PaymentFields) => (touched[field] ? errors[field] : '');

  useEffect(() => {
    const calculatedTax = values[PaymentFields.Amount] * (percentTax / 100);
    setTaxAmount(+(calculatedTax > minTax ? calculatedTax : minTax).toFixed(2));
  }, [values[PaymentFields.Amount]]);

  useEffect(() => {
    const workPrice = works.find(work => work.id === values[PaymentFields.Work])?.price;
    if (workPrice) {
      const newAmount = +(workPrice / 100).toFixed(2);
      handleChange(PaymentFields.Amount)(newAmount.toString());
    } else {
      handleChange(PaymentFields.Work)('');
      handleChange(PaymentFields.Amount)('0');
    }
  }, [values[PaymentFields.Work], works]);

  useEffect(() => {
    dispatch(AppealsActions.GetAppealWorks.init({ supplier: values[PaymentFields.UtilityCompanyId], kind: appeal?.appealRequisitionKind }));
  }, [values[PaymentFields.UtilityCompanyId]]);

  return (
    <TabComponent>
      <Column spacing="20px" style={{ width: '100%' }}>
        <HeaderSmall>Информация об оплате</HeaderSmall>

        <FormField placeholder="Контрагент-исполнитель *" id={PaymentFields.UtilityCompanyId}>
          <Select
            options={suppliersOptions}
            id={PaymentFields.UtilityCompanyId}
            name={PaymentFields.UtilityCompanyId}
            value={values[PaymentFields.UtilityCompanyId]}
            onChange={handleChange(PaymentFields.UtilityCompanyId)}
            onBlur={handleBlur}
            error={getError(PaymentFields.UtilityCompanyId)}
            disabled={notEdit}
            search
          />
        </FormField>

        <FormField placeholder="Работа *" id={PaymentFields.Work}>
          <Select
            name={PaymentFields.Work}
            options={workOptions}
            id={PaymentFields.Work}
            value={values[PaymentFields.Work]}
            onChange={handleChange(PaymentFields.Work)}
            error={getError(PaymentFields.Work)}
            disabled={notEdit}
            onBlur={handleBlur}
          />
        </FormField>

        <FormField placeholder="Сумма к оплате *" id={PaymentFields.Amount}>
          <Input
            name={PaymentFields.Amount}
            type="number"
            id={PaymentFields.Amount}
            value={values[PaymentFields.Amount]}
            onChange={handleChange(PaymentFields.Amount)}
            onBlur={handleBlur}
            error={getError(PaymentFields.Amount)}
            readOnly={notEdit}
          />
        </FormField>

        <FormField placeholder="Сумма комиссии">
          <TextRegularBig>{taxAmount.toFixed(2)}</TextRegularBig>
        </FormField>

        <FormField placeholder="Вид оплаты *" id={PaymentFields.PaymentType}>
          <Select
            name={PaymentFields.PaymentType}
            options={PAYMENT_TYPE_OPTIONS}
            id={PaymentFields.PaymentType}
            value={values[PaymentFields.PaymentType]}
            onChange={handleChange(PaymentFields.PaymentType)}
            onBlur={handleBlur}
            error={getError(PaymentFields.PaymentType)}
            disabled={notEdit}
          />
        </FormField>

        <FormField placeholder="Состояние оплаты" id={PaymentFields.PaymentStatus}>
          <Select
            options={PAYMENT_STATUS_OPTIONS}
            id={PaymentFields.PaymentStatus}
            value={values[PaymentFields.PaymentStatus]}
            onChange={handleChange(PaymentFields.PaymentStatus)}
            error={getError(PaymentFields.PaymentStatus)}
            disabled={disableStatus}
          />
        </FormField>

        {appeal?.order?.receipt?.url && (
          <FormField placeholder="Чек">
            <Link href={appeal.order.receipt.url} target="_blank">
              {appeal.order.receipt.url}
            </Link>
          </FormField>
        )}

        {!notEdit && (
          <StyledButton mod="primary" disabled={!dirty || !isValid} onClick={() => handleSubmit()}>
            Сохранить
          </StyledButton>
        )}
      </Column>
    </TabComponent>
  );
};
