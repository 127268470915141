import React, { DetailedHTMLProps, InputHTMLAttributes, useState } from 'react';
import classNames from 'classnames';
import { FormFieldError } from '../FormFieldError';
import { DatepickerComponent, DatepickerContainer, ErrorHint } from './styled';
import { Input } from '../Input';

export const Datepicker = (
  props: {
    error?: string;
    isValid?: boolean;
    type: 'date' | 'time';
    className?: string;
    noError?: boolean;
  } & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
) => {
  const { value, type, className, error, noError, ...rest } = props;
  const [isFocused, setIsFocused] = useState(false);
  const [isPristine, setIsPristine] = useState(true);

  function getIsPristine() {
    return isPristine && (!value || value.toString().trim().length === 0);
  }

  const datepickerProps = {
    onFocus: () => {
      if (getIsPristine()) {
        setIsPristine(false);
      }
      setIsFocused(true);
    },
    onBlur: () => setIsFocused(false),
    onChange: e => {
      props.onChange!(e);
    },
    ...(rest as any),
    value,
  };

  const isInputInvalid = !!error && !isFocused && !getIsPristine() && !datepickerProps.disabled;

  return (
    <DatepickerContainer>
      <DatepickerComponent>
        <Input {...datepickerProps} className={classNames(className, isInputInvalid ? 'error' : '')} type={type} />
      </DatepickerComponent>
      {!noError && <ErrorHint>{isInputInvalid && error && <FormFieldError error={error} />}</ErrorHint>}
    </DatepickerContainer>
  );
};
