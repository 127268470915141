import { useEffect, useRef } from 'react';

export const useMemoCompare = <T>(
  next: T | undefined,
  compare: (previous: T | undefined, next: T | undefined) => boolean
) => {
  const previousRef = useRef<T>();
  const previous = previousRef.current;

  const isEqual = compare(previous, next);

  useEffect(() => {
    if (!isEqual) {
      previousRef.current = next;
    }
  });

  return isEqual ? previous : next;
};
