import { createSlice } from '@reduxjs/toolkit';
import { Service } from '../../app/models/services/service';
import { EntityState } from '../models/entity-state';
import { initialEntityState } from '../constants/initial-entity-state';
import { SERVICES_STORE_NAME } from './services.actions';
import * as ServicesActions from './services.actions';

export interface ServicesState extends EntityState {
  services: Service[];
}

export const servicesInitialState: ServicesState = {
  services: [],

  ...initialEntityState,
};

const servicesSlice = createSlice({
  name: SERVICES_STORE_NAME,
  initialState: servicesInitialState,
  reducers: {},

  extraReducers: builder =>
    builder
      .addCase(ServicesActions.LoadServices.init, state => {
        state.loading = true;
      })
      .addCase(ServicesActions.LoadServices.success, (state, { payload }) => {
        state.loading = false;
        state.services = payload;
      })
      .addCase(ServicesActions.LoadServices.failure, state => {
        state.loading = false;
      }),
});

export const servicesReducer = servicesSlice.reducer;
