import styled from 'styled-components';
import { ColumnFlexbox, VerticallyCenteredFlexWithSpaceBetween } from '../../../../typography/flex';
import { Colors } from '../../../../themes/colors';
import { Icon, IconClickable } from '../../../../components/shared';

export const Container = styled(ColumnFlexbox)`
  padding: 20px 24px 0;
  border-bottom: 1px solid ${Colors.Grey600};
  background-color: ${Colors.White};
  border-radius: 10px;
`;

export const HeaderContainer = styled(VerticallyCenteredFlexWithSpaceBetween)`
  position: relative;
  align-items: flex-start;
`;

export const StatusIcon = styled(Icon)<{ color: string }>`
  fill: ${props => props.color} !important;
`;

export const StyledClickableIcon = styled(IconClickable)`
  fill: ${Colors.Grey700} !important;

  :hover {
    fill: ${Colors.Blue600} !important;
  }

  :active {
    fill: ${Colors.Blue700} !important;
  }
`;
