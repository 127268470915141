import styled from 'styled-components';
import { Icon, Table } from 'app/components/shared';
import { Colors } from 'app/themes/colors';

export const OVERDUE_APPEAL_ROW_CLASS_NAME = 'overdue';
export const CLOSED_APPEAL_ROW_CLASS_NAME = 'closed';

export const AttentionIcon = styled(Icon)`
  margin-right: 20px;
`;

export const AppealsTable = styled(Table)`
  padding: 0 10px;
  overflow-y: auto;

  thead {
    border-top: none;
  }

  tbody .id {
    text-align: right;
    padding: 10px 10px 10px 0;
  }

  .${OVERDUE_APPEAL_ROW_CLASS_NAME} {
    color: ${Colors.Red800};
  }

  .${CLOSED_APPEAL_ROW_CLASS_NAME} {
    color: ${Colors.Grey700};
  }
` as typeof Table;
