import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { ActionButton, Container, ExternalLink, Link, Title } from './styled';
import { selectSelectedAppealSupplierId } from '../../../../../store/appeals/appeals.selectors';
import { selectSupplierBySupplierId } from '../../../../../store/suppliers/suppliers.selectors';
import { UtilityField, utilityFields, utilityFieldTitleMap } from './utility-enum-field';
import { RedText, TextRegularBig } from '../../../../typography/text';
import { PagesRoutes } from '../../../../constants/route-path';
import { useAction } from '../../../../helpers/actions/use-action';
import { webphoneActions } from '../../../../../store/webphone';
import { formatPhoneNumber } from '../../../../helpers/phone';
import { FormField } from '../../../../components/shared';

const EMPTY_FIELD_TEXT = 'Не назначено';
const EMPTY_MANAGEMENT_COMPANY_FIELD_TEXT = 'Не определено';

export const UtilityInfo = React.memo(() => {
  const supplierId = useSelector(selectSelectedAppealSupplierId, shallowEqual);
  const supplier = useSelector(selectSupplierBySupplierId(supplierId), shallowEqual);
  const call = useAction(webphoneActions.InitOutgoingRinging.init);

  const handleCallClick = React.useCallback(
    (phone?: string) => {
      if (!phone) return;

      call({ phone, options: { prefix: supplier?.prefix } });
    },
    [call]
  );

  const getFieldContent = React.useCallback(
    (field: UtilityField) => {
      if (!supplier) return undefined;

      switch (field) {
        case UtilityField.Name:
          return (
            <Link
              to={generatePath(PagesRoutes.PAGES.SUPPLIER, {
                supplierId: String(supplier.id),
              })}
            >
              {supplier.name}
            </Link>
          );

        case UtilityField.Phone:
          return supplier?.phone ? (
            <ActionButton onClick={() => handleCallClick(supplier.phone)}>{formatPhoneNumber(supplier.phone)}</ActionButton>
          ) : (
            <RedText>{EMPTY_FIELD_TEXT}</RedText>
          );

        case UtilityField.OwnerPhone:
          return supplier?.ownerPhone ? (
            <ActionButton onClick={() => handleCallClick(supplier.ownerPhone)}>{formatPhoneNumber(supplier.ownerPhone)}</ActionButton>
          ) : (
            <RedText>{EMPTY_FIELD_TEXT}</RedText>
          );

        case UtilityField.Address:
          return supplier?.address ? <TextRegularBig>{supplier.address}</TextRegularBig> : <RedText>{EMPTY_FIELD_TEXT}</RedText>;

        case UtilityField.Website:
          return supplier?.webSite ? (
            // todo уточнить, будет ли приходить без протокола (надо с протоколом)
            <ExternalLink href={supplier.webSite.startsWith('http') ? supplier.webSite : `http://${supplier.webSite}`} target="_blank">
              {supplier.webSite}
            </ExternalLink>
          ) : (
            <RedText>{EMPTY_MANAGEMENT_COMPANY_FIELD_TEXT}</RedText>
          );

        default:
          return supplier[field];
      }
    },
    [handleCallClick, supplier]
  );

  return (
    <Container>
      <Title>Поставщик</Title>

      {utilityFields?.map(field => (
        <FormField key={field} placeholder={utilityFieldTitleMap[field]} spacing="5px">
          <TextRegularBig>{getFieldContent(field)}</TextRegularBig>
        </FormField>
      ))}
    </Container>
  );
});
