import { ChosenFilters } from 'app/models/common/chosen-filters';
import { TableFilter } from 'app/models/configuration/table-structure';
import { AppealsFilters } from 'store/appeals/models/appeals-filters';

export const getChosenFilter = (
  inlineFilters: TableFilter[],
  sideFilters: TableFilter[]
): ChosenFilters => {
  const chosenFilter = {} as ChosenFilters;

  inlineFilters.concat(sideFilters).forEach(filter => {
    chosenFilter[filter.key] = undefined;
  });

  return chosenFilter;
};

export const resetSomeFilters = (filters: ChosenFilters, resetFilters: string[]): ChosenFilters => {
  const newFilters = { ...filters };

  resetFilters.forEach(filter => {
    newFilters[filter] = undefined;
  });

  return newFilters;
};

export const getAppealsFilters = (structure: any): AppealsFilters => {
  const filters: AppealsFilters = {};
  for (const key in structure) {
    filters[key] = {
      inlineFilters: structure[key].inlineFilters,
      sideFilters: structure[key].sideFilters,
    };
  }

  return filters;
};
