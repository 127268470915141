import styled from 'styled-components';

import { CenteredFlex } from 'app/typography/flex';
import { TextRegularBig } from 'app/typography/text';
import { Colors } from 'app/themes/colors';
import { Icon } from 'app/components/shared';

export const EmptyTable = styled(CenteredFlex)`
  flex-direction: column;
  height: 100%;
`;

export const Text = styled(TextRegularBig)`
  color: ${Colors.Grey700};
`;

export const FileIcon = styled(Icon)`
  color: ${Colors.Grey700};
  width: 40px;
  height: 40px;
  margin-bottom: 16px;
`;
