import { all } from 'redux-saga/effects';

import profileWatcher from './profile/profile.saga';
import consumersWatcher from './consumers/consumers.saga';
import appealsWatcher from './appeals/appeals.saga';
import configurationWatcher from './configuration/configuration.saga';
import webphoneWatcher from './webphone/webphone.saga';
import callsWatcher from './calls/calls.saga';
import mastersWatcher from './masters/masters.saga';
import suppliersWatcher from './suppliers/suppliers.saga';
import notificationsWatcher from './notifications/notifications.saga';
import mailingsWatcher from './mailings/mailings.saga';
import { statWatcher } from './stat';
import { speechNotificationWatcher } from './speech-notification';
import servicesWatcher from './services/services.saga';
import incidentsWatcher from './incidents/incidents.saga';
import commentsWatcher from './comments/comments.saga';

export default function* rootSaga() {
  yield all([
    profileWatcher(),
    consumersWatcher(),
    appealsWatcher(),
    configurationWatcher(),
    webphoneWatcher(),
    callsWatcher(),
    mastersWatcher(),
    suppliersWatcher(),
    notificationsWatcher(),
    mailingsWatcher(),
    statWatcher(),
    speechNotificationWatcher(),
    servicesWatcher(),
    incidentsWatcher(),
    commentsWatcher(),
  ]);
}
