import styled from 'styled-components';
import { Colors } from '../themes/colors';

export const Span = styled.span``;

export const Strong = styled(Span)`
  font-weight: 500;
`;

export const HeaderBig = styled(Strong)`
  font-size: 32px;
  line-height: 37px;
`;

export const Header = styled(Strong)`
  font-size: 26px;
  line-height: 30px;
`;

export const HeaderSmall = styled(Strong)`
  font-size: 18px;
  line-height: 21px;
`;

export const ItalicHeaderSmall = styled(Strong)`
  font-size: 18px;
  line-height: 21px;
  font-style: italic;
`;

export const Hint = styled(Span)`
  font-size: 12px;
  line-height: 16px;
`;

export const StrongHint = styled(Strong)`
  font-size: 12px;
  line-height: 16px;
`;

export const TextRegular = styled(Span)`
  font-size: 14px;
  line-height: 16px;
`;

export const StrongTextRegular = styled(Strong)`
  font-size: 14px;
  line-height: 16px;
`;

export const ItalicTextRegular = styled(Span)`
  font-size: 14px;
  line-height: 16px;
  font-style: italic;
`;

export const TextRegularBig = styled(Span)`
  font-size: 16px;
  line-height: 19px;
`;

export const StrongTextRegularBig = styled(Strong)`
  font-size: 16px;
  line-height: 19px;
`;
export const TextButton = styled(Strong)`
  font-size: 14px;
  line-height: 16px;
`;

export const ErrorHint = styled(Hint)`
  height: 1rem;
  color: ${Colors.Red800};
`;

export const RedText = styled(TextRegular)`
  color: ${Colors.Red800};
`;

export const RedTextBig = styled(TextRegularBig)`
  color: ${Colors.Red800};
`;
export const GreyTextBig = styled(TextRegularBig)`
  color: ${Colors.Grey700};
`;

export const GreyText = styled(TextRegular)`
  color: ${Colors.Grey700};
`;
