import Axios from 'axios';

import { Stat } from 'app/models/stat';

class StatsServiceImpl {
  private apiEndpoint = '/api/stats';

  // Todo: replace once API wil be ready
  loadStats = async (): Promise<Stat[]> => {
    const { data } = await Axios.get<Stat[]>(
      `https://68c77f30-31b5-473d-adc7-8ed4f3814951.mock.pstmn.io${this.apiEndpoint}`
    );

    return data;
  };
}

export const StatsService = new StatsServiceImpl();
