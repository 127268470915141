import styled from 'styled-components';
import { Colors } from 'app/themes/colors';
import { Icon as SVG } from '../../IconBackground';

export const Icon = styled(SVG)`
  color: ${Colors.Grey700};
`;

export const Button = styled.button<{ isFiltered?: boolean }>`
  position: relative;
  height: 40px;
  width: 40px;
  border: none;
  background: ${Colors.MainBackground};
  box-shadow: 0 0 0 1px ${Colors.Grey600};
  border-radius: 6px;
  cursor: pointer;

  :hover:not(:disabled) {
    box-shadow: 0 0 0 1px ${Colors.Blue600};

    ${Icon} {
      color: ${Colors.Blue600};
    }
  }

  :active:not(:disabled) {
    box-shadow: 0 0 0 1px ${Colors.Blue700};

    ${Icon} {
      color: ${Colors.Blue700};
    }
  }

  :disabled {
    cursor: not-allowed;
  }

  ${({ isFiltered }) =>
    isFiltered &&
    `
      &:before {
        content: '';
        width: 7px;
        height: 7px;
        background-color: ${Colors.Blue800};
        border-radius: 50%;
        position: absolute;
        right: 9px;
        top: 9px;
      }
    `}
`;
