import { useState } from 'react';
import { ModalProps } from 'app/services/modal-service/modal.service';
import { HeaderSmall } from 'app/typography/text';
import { FormField, Select } from 'app/components/shared';
import { SuppliersSelectors } from 'store/suppliers';
import { useDispatch, useSelector } from 'react-redux';
import { AppealsActions } from 'store/appeals';
import { useActionListener } from 'app/helpers/actions/action-listener.hook';
import { AnyAction } from '@reduxjs/toolkit';
import { SelectButton, SelectSupplierDialog, SelectSupplierDialogBody } from './styled';

interface AppealSelectSuplierDialogProps extends ModalProps {
  appealId: number;
}

export const AppealSelectSuplierDialog = ({ appealId, onClose = () => {} }: AppealSelectSuplierDialogProps) => {
  const suppliersOptions = useSelector(SuppliersSelectors.selectSuppliersOptions) || [];
  const [newSupplier, setNewSupplier] = useState<string>('');
  const dispatch = useDispatch();

  const handleChange = (value: string) => {
    setNewSupplier(value);
  };

  const handleClick = () => {
    dispatch(
      AppealsActions.ChangeAppealSupplier.init({
        appealId,
        managementCompany: newSupplier,
      })
    );
  };

  useActionListener((action: AnyAction) => {
    if (action.type === AppealsActions.ChangeAppealSupplier.success.type) {
      onClose();
    }
  });

  return (
    <SelectSupplierDialog
      headerContent={<HeaderSmall>Сменить поставщика</HeaderSmall>}
      body={
        <SelectSupplierDialogBody spacing="20px">
          <FormField placeholder="Выберите поставщика*">
            <Select options={suppliersOptions} value={newSupplier} onChange={handleChange} search />
          </FormField>
          <SelectButton disabled={!newSupplier} onClick={handleClick}>
            Перенести
          </SelectButton>
        </SelectSupplierDialogBody>
      }
      onClose={onClose}
    />
  );
};
