import { FC, MouseEvent, useMemo } from 'react';
import { AppealAttachedFile } from 'app/models/appeal/appeal';
import { environment } from 'app/environment';
import {
  AttachmentName,
  AttachmentPreviewContainer,
  AttachmentPreviewWrapper,
  DeleteButton,
  FileTypePlaceholder,
  IconPlaceholder,
  ImagePreview,
  Title,
} from './styled';

interface AttachmentPreviewProps {
  file: AppealAttachedFile;
  onDelete(id: string): void;
}

export const AttachmentPreview: FC<AttachmentPreviewProps> = ({ file, onDelete }) => {
  const imageTypes = ['jpg', 'jpeg', 'png', 'gif', 'svg'];

  const fileType = useMemo(() => {
    const splitName = file.name.split('.');
    return splitName[splitName.length - 1];
  }, []);

  const isImage = imageTypes.includes(fileType);

  const handleDeleteClick = (id: string) => (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    onDelete(id);
  };

  return (
    <AttachmentPreviewContainer
      href={`${environment.apiBaseUrl}api/appeals/files/${file.id}`}
      target="_blank"
    >
      <AttachmentPreviewWrapper>
        {isImage ? (
          <ImagePreview
            src={`${environment.apiBaseUrl}api/appeals/files/${file.id}`}
            alt="preview"
          />
        ) : (
          <>
            <IconPlaceholder icon="/assets/icons.svg#file" />
            <FileTypePlaceholder>{fileType}</FileTypePlaceholder>
          </>
        )}
      </AttachmentPreviewWrapper>
      <AttachmentName title={file.name}>
        <Title>{file.name}</Title>
        <DeleteButton
          icon="/assets/icons.svg#delete"
          tooltip="Удалить"
          onClick={handleDeleteClick(file.id)}
        />
      </AttachmentName>
    </AttachmentPreviewContainer>
  );
};
