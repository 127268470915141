import styled from 'styled-components';
import { ColumnCenteredFlexWithPadding } from 'app/typography/flex';
import { Colors } from 'app/themes/colors';

export const Wrapper = styled(ColumnCenteredFlexWithPadding)`
  background-color: ${Colors.Grey500};
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Img = styled.img`
  display: block;
  max-width: 150px;
  max-height: 150px;
`;

export const Title = styled.div`
  font-size: 35px;
  font-weight: 700;
`;
