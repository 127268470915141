import { ActionCreatorWithPayload, ActionCreatorWithPreparedPayload, createAction } from '@reduxjs/toolkit';
import { ErrorPayload } from '../models/error-payload';

type InitAction<TPayload = any, TKey extends string = string> = ActionCreatorWithPayload<TPayload, TKey>;

type SuccessAction<TPayload = any, TKey extends string = string> = InitAction<TPayload, TKey>;

type FailureAction<TPayload, TKey extends string = string> = ActionCreatorWithPreparedPayload<[payload: TPayload], TPayload, TKey>;

export interface ActionTrio<
  TAction = any,
  TResult = any,
  TError extends ErrorPayload = ErrorPayload,
  T extends string = string,
  TResultType extends string = string,
  TErrorType extends string = string
> {
  init: InitAction<TAction, T>;
  success: SuccessAction<TResult, TResultType>;
  failure: FailureAction<TError, TErrorType>;
}

function createIds<T extends string = string>(baseId: T) {
  return {
    init: `${baseId}` as const,
    success: `${baseId} success` as const,
    failure: `${baseId} failure` as const,
  };
}

export function createActionTrio<TAction = void, TResult = void, TError extends ErrorPayload = ErrorPayload, T extends string = string>(
  baseId: T
): ActionTrio<TAction, TResult, TError> {
  const ids = createIds(baseId);

  return {
    init: createAction<TAction>(ids.init),
    success: createAction<TResult>(ids.success),
    failure: createAction<(args: TError) => { payload: any; error: string }>(ids.failure, ({ message, ...rest }: TError) => ({
      payload: rest,
      error: message,
    })),
  } as ActionTrio<TAction, TResult, TError, typeof ids.init, typeof ids.success, typeof ids.failure>;
}
