import { createSelector } from '@reduxjs/toolkit';

import createCachedSelector from 're-reselect';
import { SuppliersState } from './suppliers.reducer';
import { AppState } from '../../types/AppState.type';
import { SupplierId } from '../../app/models/supplier';
import { defaultCachedSelectorKeySelector } from '../../utils/selectors';

const selectSuppliersState: (state: AppState) => SuppliersState = state => state.suppliersState;

export const selectSuppliersForTable = createSelector(selectSuppliersState, state => state.tableSuppliers);
export const selectSuppliers = createSelector(selectSuppliersState, state => state.suppliers);

export const selectSuppliersTotalCount = createSelector(selectSuppliersState, state => state.suppliersTotalCount);

export const selectSelectedSupplier = createSelector(selectSuppliersState, state => state.selectedSupplier);

export const selectSelectedSupplierAppeals = createSelector(selectSuppliersState, state => state.selectedSuppliersAppeals);

export const selectSupplierAppealsLoading = createSelector(selectSuppliersState, state => state.appealsLoading);

export const selectSelectedSupplierCalls = createSelector(selectSuppliersState, state => state.selectedSuppliersCalls);

export const selectSupplierCallsLoading = createSelector(selectSuppliersState, state => state.callsLoading);

export const selectChosenFilters = createSelector(selectSuppliersState, state => state.chosenFilters);

const suppliersPhoneLinesSelector = (() => {
  const selector = createCachedSelector([selectSuppliers], suppliers => {
    if (!suppliers?.length) return [];

    return suppliers?.map(supplier => supplier.prefix).filter(Boolean) as string[];
  })(defaultCachedSelectorKeySelector);

  return (state: AppState) => selector(state);
})();

export const selectSuppliersPhoneLines = (state: AppState) => suppliersPhoneLinesSelector(state);

export const selectIsFiltered = createSelector(selectSuppliersState, state => {
  // eslint-disable-next-line
  const { name__icontains, ...filters } = state.chosenFilters;
  return Object.values(filters).filter(Boolean).length > 0;
});

export const selectSuppliersLoading = createSelector(selectSuppliersState, state => state.loading);

const suppliersOptionsSelector = (() => {
  const selector = createCachedSelector([selectSuppliers], suppliers => {
    if (!suppliers?.length) return [];

    return suppliers.map(supplier => ({
      key: supplier.id,
      value: supplier.id,
      label: supplier.name,
    }));
  })(defaultCachedSelectorKeySelector);

  return (state: AppState) => selector(state);
})();

export const selectSuppliersOptions = (state: AppState) => suppliersOptionsSelector(state);

const supplierBySupplierIdSelector = (() => {
  const selector = createCachedSelector(
    [selectSuppliers, (_S: AppState, supplierId: SupplierId | undefined) => supplierId],
    (suppliers, supplierId) => {
      return suppliers?.find(supplier => supplier?.id === supplierId);
    }
  )(defaultCachedSelectorKeySelector);

  return (state: AppState, supplierId: SupplierId | undefined) => selector(state, supplierId);
})();

export const selectSupplierBySupplierId = (supplierId: SupplierId | undefined) => (state: AppState) =>
  supplierBySupplierIdSelector(state, supplierId);
