import { HttpService } from '../http.service';

class SettingsServiceImpl {

  loadSettings() {
    return HttpService.get('api/settings').then(resp => resp.data);
  }

}

export const SettingsService = new SettingsServiceImpl();
