/* eslint-disable no-prototype-builtins */
import React, { ReactNode } from 'react';
import { EventEmitter } from 'events';
import { sha512 } from 'js-sha512';
import { createGuid } from 'app/helpers/guid/create-guid';
import { exclude } from 'app/helpers/arrays/exclude';
import { ModalDef } from './models/modal-def';
import { isModalProps } from './models/modal-props';
import ScrollLockService from './scroll-lock.service';
import { dialogElementRef } from '../../helpers/modal-ref/modal-ref';

export interface ModalProps {
  onClose?: (result?: any) => void;
  children?: ReactNode;
}

class LayoutModalServiceImpl {
  constructor() {
    dialogElementRef.then(el => {
      this.rootContainer = el;
      this.emitChange();
    });
  }

  public rootContainer: HTMLElement | undefined = undefined;

  public modals: { [key: string]: ModalDef } = {};

  public modalsByTypeId: { [key: string]: ModalDef[] } = {};

  public modalList: ModalDef[] = [];

  public onChange = new EventEmitter();

  public getModalTypeId<T extends JSX.Element, TProps extends ModalProps>(
    Builder: (props: TProps) => T
  ) {
    let suffix: any;
    const source = Builder as any;

    if (source.displayName) {
      suffix = source.displayName;
    }

    return (suffix ? `${suffix}-` : '') + sha512(source.toString());
  }

  public openModal<T extends JSX.Element, TProps extends ModalProps>(
    Builder: (props: TProps) => T,
    props: TProps
  ): ModalDef {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self: LayoutModalServiceImpl = this;
    const id = createGuid();
    let resolvefn: (v: any) => void;
    let rejectfn: (v: any) => void;
    const typeId = this.getModalTypeId(Builder);

    const origFn = props.onClose;
    // eslint-disable-next-line no-param-reassign,func-names
    props.onClose = function (args: any) {
      self.unregisterModal(self.modals[id]);
      if (origFn) {
        origFn.call(this, args);
      }
      resolvefn(args);
      self.updateModalList();
      self.emitChange();
    };

    const element = <Builder {...props} />;

    if (!isModalProps(element.props)) {
      throw new Error('Invalid modal props implementation. See ModalProps type.');
    }

    const promise = new Promise((resolve, reject) => {
      resolvefn = resolve;
      rejectfn = reject;
    });

    const def = {
      id,
      typeId,
      close: promise,
      element,
      resolve: resolvefn!,
      reject: rejectfn!,
    };

    this.registerModal(def);
    return def;
  }

  public rejectModalOfType<T extends JSX.Element, TProps extends ModalProps>(
    Builder: (props: TProps) => T
  ) {
    const typeId = this.getModalTypeId(Builder);

    if (this.modalsByTypeId.hasOwnProperty(typeId) && this.modalsByTypeId[typeId].length) {
      for (const item of this.modalsByTypeId[typeId]) {
        item.reject();
        this.unregisterModal(item, { skipNotify: true });
      }
    }

    this.updateModalList();
    this.emitChange();
  }

  public rejectModal(id: string) {
    if (this.modals.hasOwnProperty(id)) {
      this.modals[id].reject();
      this.unregisterModal(this.modals[id]);
    }
  }

  private unregisterModal(def: ModalDef, args: { skipNotify: boolean } = { skipNotify: false }) {
    this.modalList = exclude(this.modalList, def);

    if (this.modals[def.id]) {
      delete this.modals[def.id];
    }

    if (this.modalsByTypeId[def.typeId]) {
      this.modalsByTypeId[def.typeId] = exclude(this.modalsByTypeId[def.typeId], def);

      if (!this.modalsByTypeId[def.typeId].length) {
        delete this.modalsByTypeId[def.typeId];
      }
    }

    ScrollLockService.pop();

    if (!args.skipNotify) {
      this.updateModalList();
      this.emitChange();
    }
  }

  private registerModal(def: ModalDef, args: { skipNotify: boolean } = { skipNotify: false }) {
    this.modalList.push(def);

    if (!this.modalsByTypeId[def.typeId]) {
      this.modalsByTypeId[def.typeId] = [];
    }

    this.modals[def.id] = def;
    this.modalsByTypeId[def.typeId].push(def);
    ScrollLockService.push();

    if (!args.skipNotify) {
      this.updateModalList();
      this.emitChange();
    }
  }

  private emitChange() {
    this.onChange.emit('');
  }

  private updateModalList() {
    this.modalList = Object.keys(this.modals).map(key => this.modals[key]);
  }
}

export const ModalService = new LayoutModalServiceImpl();
export const SideModalService = new LayoutModalServiceImpl();
