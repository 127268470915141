import { getCreateActionTrio } from '../helpers/action-create';
import { LoginForm } from './models/login-form.model';
import { Profile } from '../../app/models/profile/profile';
import { GetProfilesBySupplierIdApiParams, Responsible } from '../../app/services/profile-service/dtos/get-profiles-by-supplier-id';

export const PROFILE_STORE_NAME = 'profile-state';

const createActionTrio = getCreateActionTrio(PROFILE_STORE_NAME);

export const Login = createActionTrio<LoginForm, Profile>('login');

export const Logout = createActionTrio<void, void>('logout');

export const LoadProfile = createActionTrio<void, Profile>('load profile');

export const GetProfilesBySupplier = createActionTrio<GetProfilesBySupplierIdApiParams, Responsible[]>('get profiles by supplier id ');
