import { AppealConsumer } from 'app/models/appeal/appeal-consumer';
import { AppealAdditionalField, AppealAdditionalFields } from 'app/models/appeal';
import { AppealAction } from '../../models/appeal/appeal-action';
import { AppealActionType, appealActionTypeToLabelMap } from '../../models/appeal/appeal-action-type';
import { Option } from '../../models/common/option';

const getAppealActions = (
  appealType: string,
  appealStatus: string,
  appealConsumer: AppealConsumer | undefined,
  actions: AppealAction[]
): AppealActionType[] =>
  actions
    .filter(action => {
      if (action.type === AppealActionType.CallConsumer && !appealConsumer?.phone) {
        return false;
      }

      return action.appealTypes.some(type => appealType === type) && action.statuses.some(status => appealStatus === status);
    })
    .map(action => action.type);

export const getAppealsActionsOptions = (
  type: string,
  status: string,
  appealConsumer: AppealConsumer | undefined,
  actions: AppealAction[]
): Option<AppealActionType>[] =>
  getAppealActions(type, status, appealConsumer, actions).map(action =>
    Object({
      label: appealActionTypeToLabelMap[action],
      value: action,
    } as Option<AppealActionType>)
  );

export const getAdditionalFields = (
  fields: AppealAdditionalFields,
  category: string,
  type: string,
  kind: string
): AppealAdditionalField[] => {
  let additionalFields: AppealAdditionalField[] = [];
  for (const key in fields) {
    if (key === category || key === 'None') {
      const categoryFields = fields[key];
      for (const key in categoryFields) {
        if (key === type || key === 'None') {
          const typeFields = categoryFields[key];
          for (const key in typeFields) {
            if (key === kind || key === 'None') {
              additionalFields = additionalFields.concat(typeFields[key]);
            }
          }
        }
      }
    }
  }
  return additionalFields;
};
