import { Option } from 'app/components/shared';

export enum PaymentType {
  Cash = 0,
  Cashless = 1,
}

export const PAYMENT_TYPE_OPTIONS: Option[] = [
  { value: PaymentType.Cash, label: 'Наличными' },
  { value: PaymentType.Cashless, label: 'Картой' },
];
