import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { selectSelectedIncident } from '../../../../../store/incidents/incidents.selectors';
import {
  ActionButton,
  Address,
  CommentInput,
  Comments,
  CommonInfoContainer,
  FormFieldInfoItem,
  Info,
  Item,
  VerticalContainer,
} from './styled';
import { GreyTextBig, HeaderSmall, TextRegularBig } from '../../../../typography/text';
import {
  AboutIncidentField,
  aboutIncidentFields,
  aboutIncidentFieldTitleMap,
  ResponsibleField,
  responsibleFields,
  responsibleFieldsNameMap,
} from './constants';

import { DateFormat, localFormat } from '../../../../helpers/date/time';
import { selectAppealKinds } from '../../../../../store/appeals/appeals.selectors';
import { getAppealKind } from './helpers';
import { useBind } from '../../../../helpers';
import { useAction } from '../../../../helpers/actions/use-action';
import { webphoneActions } from '../../../../../store/webphone';
import { formatPhoneNumber } from '../../../../helpers/phone';
import { VerticallyCenteredFlexWithSpaceBetween } from '../../../../typography/flex';
import { AppealsTable } from './sections/AppealsTable/AppealsTable';
import { ModalService } from '../../../../services/modal-service/modal.service';
import { EditAppealsModal } from './sections/components/EditAppealsModal/EditAppealsModal';
import { selectCommentsIds } from '../../../../../store/comments/comments.selectors';
import { CreateComment } from '../../../../../store/comments/comments.actions';
import { CommentItem } from './sections/components/CommentItem/CommentItem';

export const EMPTY_FIELD_TEXT = 'Не назначено';

export const CommonInfo = React.memo(() => {
  const dispatch = useDispatch();
  const incident = useSelector(selectSelectedIncident, shallowEqual);
  const appealsKinds = useSelector(selectAppealKinds, shallowEqual);
  const callAction = useAction(webphoneActions.InitOutgoingRinging.init);
  const commentsIds = useSelector(selectCommentsIds, shallowEqual);

  const header = React.useMemo(() => {
    if (!incident?.incident_type) return '';
    return incident?.incident_type === 'accident' ? 'Об аварии' : 'О плановых работах';
  }, [incident?.incident_type]);

  const call = React.useCallback(() => {
    if (!incident?.responsible?.phone) return;
    callAction({ phone: incident?.responsible?.phone, options: { prefix: incident?.supplier?.prefix || '' } });
  }, [callAction, incident?.responsible?.phone]);

  const callResponsible = useBind(call, incident?.responsible?.phone);

  const getAboutIncidentField = React.useCallback(
    (field: AboutIncidentField) => {
      if (!incident) return EMPTY_FIELD_TEXT;

      switch (field) {
        case AboutIncidentField.CreatedAt:
          return incident[field] ? localFormat(new Date(incident[field]), DateFormat.LocalDateAndTime) : EMPTY_FIELD_TEXT;

        case AboutIncidentField.PlanedDateEnd:
          return incident[field] ? localFormat(new Date(incident[field]), DateFormat.LocalDateAndTime) : EMPTY_FIELD_TEXT;
        case AboutIncidentField.Author:
          return incident?.author?.fullName || EMPTY_FIELD_TEXT;
        case AboutIncidentField.Type:
          return incident?.incident_type === 'accident' ? 'Авария' : 'Плановые работы';
        case AboutIncidentField.AppealKind:
          return getAppealKind(appealsKinds, incident?.appeal_kind) || EMPTY_FIELD_TEXT;

        default:
          return incident[field] || EMPTY_FIELD_TEXT;
      }
    },
    [appealsKinds, incident]
  );

  const getResponsibleField = React.useCallback(
    (field: ResponsibleField) => {
      if (!incident) return EMPTY_FIELD_TEXT;

      switch (field) {
        case ResponsibleField.Responsible:
          return incident?.responsible?.fullName || EMPTY_FIELD_TEXT;
        case ResponsibleField.Supplier:
          return incident?.supplier?.name || EMPTY_FIELD_TEXT;
        case ResponsibleField.Master:
          return incident?.master?.fullName || EMPTY_FIELD_TEXT;
        case ResponsibleField.Phone:
          return incident?.responsible?.phone ? (
            <ActionButton onClick={callResponsible}> {formatPhoneNumber(incident.responsible.phone)}</ActionButton>
          ) : (
            EMPTY_FIELD_TEXT
          );

        default:
          return incident[field] || EMPTY_FIELD_TEXT;
      }
    },
    [callResponsible, incident]
  );

  const onEditAppealsButtonClick = React.useCallback(() => {
    if (!incident?.id) return;

    ModalService.openModal(EditAppealsModal, { incidentId: incident?.id });
  }, [incident?.id]);

  const handleSendMessage = React.useCallback(
    (message: string) => {
      if (!incident?.id) return;
      dispatch(CreateComment.init({ id: String(incident.id), text: message, entity: 'incident' }));
    },
    [dispatch, incident?.id]
  );

  return (
    <CommonInfoContainer>
      <VerticalContainer spacing="20px">
        <Item spacing="20px">
          <HeaderSmall>{header}</HeaderSmall>
          <Info spacing="20px">
            {aboutIncidentFields?.map(field => (
              <FormFieldInfoItem isUseFirstChild spacing="0" key={field} placeholder={aboutIncidentFieldTitleMap[field]}>
                <TextRegularBig>{getAboutIncidentField(field)}</TextRegularBig>
              </FormFieldInfoItem>
            ))}
          </Info>
        </Item>

        <Item spacing="20px">
          <HeaderSmall>Комментарии</HeaderSmall>
          <Info spacing="20px">
            <CommentInput sendMessage={handleSendMessage} placeholder="Введите комментарий" />
          </Info>

          <Comments spacing="24px">
            {commentsIds?.map(commentId => (
              <CommentItem key={commentId} commentId={commentId} />
            ))}
          </Comments>
        </Item>
      </VerticalContainer>

      <VerticalContainer spacing="20px">
        <Item spacing="20px">
          <HeaderSmall>Ответственные</HeaderSmall>
          <Info spacing="20px">
            {responsibleFields?.map(field => (
              <FormFieldInfoItem key={field} placeholder={responsibleFieldsNameMap[field]} spacing="0px">
                <TextRegularBig>{getResponsibleField(field)}</TextRegularBig>
              </FormFieldInfoItem>
            ))}
          </Info>
        </Item>

        <Item spacing="20px">
          <HeaderSmall>Адреса</HeaderSmall>
          <Info spacing="20px">
            {incident?.addresses?.map(address => (
              <Address key={address.address}>{address.address}</Address>
            ))}
          </Info>
        </Item>

        <Item spacing="20px">
          <VerticallyCenteredFlexWithSpaceBetween>
            <HeaderSmall>
              Заявки <GreyTextBig>{incident?.appeals_count || 0}</GreyTextBig>
            </HeaderSmall>
            <ActionButton onClick={onEditAppealsButtonClick}>Добавить</ActionButton>
          </VerticallyCenteredFlexWithSpaceBetween>

          <Info spacing="20px">
            <AppealsTable appeals={incident?.appeals} />
          </Info>
        </Item>
      </VerticalContainer>
    </CommonInfoContainer>
  );
});
