import styled from 'styled-components';
import { Colors } from 'app/themes/colors';
import { ColumnFlexbox } from 'app/typography/flex';

export const AppealSubject = styled.div`
  padding: 15px 30px;
  margin: 25px 30px;
  background-color: ${Colors.Grey500};
  border-radius: 6px;
`;

export const AppealInfoComponent = styled(ColumnFlexbox)`
  height: 100%;
`;
