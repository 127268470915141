import { SpeechNotificationDto } from './dtos';
import { SpeechNotification } from '../../models/speech-notification';
import { TableDto } from '../models/table.dto';

export const getSpeechNotificationsMapperDto = (dto: TableDto<SpeechNotificationDto>): TableDto<SpeechNotification> => ({
  page: dto.page,
  per_page: dto.per_page,
  count: dto.count,
  results: dto.results.map(result => ({
    ...result,
    supplier_name: undefined,
    supplierName: result.supplier_name,
  })),
});

export const updateSpeechNotificationsMapperDto = (dto: SpeechNotificationDto): SpeechNotification => ({
  ...dto,
  supplierName: dto.supplier_name,
});
