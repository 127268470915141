import { StrongTextRegularBig, TextRegular } from 'app/typography/text';
import React, { ReactNode } from 'react';
import { Cell, Column, Row, useTable } from 'react-table';
import styled from 'styled-components';

import { Colors } from 'app/themes/colors';
import { CenteredFlex, ColumnCenteredFlex, ColumnCenteredFlexWithPadding, ColumnFlexWithPadding, Flexbox } from 'app/typography/flex';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Icon } from '../IconBackground';
import { Preloader } from '../Preloader';

interface TableProps<D extends object> {
  columns: Column<D>[];
  data: any[];
  className?: string;
  emptyTableTitle?: string;
  emptyTableDescription?: string;
  dataLength?: number;
  isInfinity?: boolean;
  isLoading?: boolean;
  onRowClick?: (row: Row<D>) => void;
  onCellClick?: (cell: Cell<D>) => void;
  getEmptyCellContent?: (cell: Cell<D>) => ReactNode | undefined;
  rowClassName?: (row: Row<D>) => string;
  nextPage?: () => void;
}

export const Table = <D extends object = {}>({
  columns,
  data,
  className,
  emptyTableDescription,
  emptyTableTitle,
  dataLength = 0,
  isInfinity = false,
  isLoading = false,
  onRowClick = () => {},
  onCellClick = () => {},
  getEmptyCellContent = () => undefined,
  rowClassName = () => '',
  nextPage = () => {},
}: TableProps<D>) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<D>({
    columns,
    data,
  });

  const getTable = () => (
    <>
      {!isLoading && (
        <StyledTable className={!isInfinity ? className : ''} {...(getTableProps() as any)} cellSpacing={0}>
          <TableHead>
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...(headerGroup.getHeaderGroupProps() as any)}>
                {headerGroup.headers.map((column: any) => (
                  <TableHeaderCell key={column.id} className={column.id}>
                    <div className="column-header">
                      <div>{column.render('Header')}</div>
                    </div>
                  </TableHeaderCell>
                ))}
              </tr>
            ))}
          </TableHead>
          <tbody {...(getTableBodyProps() as any)}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <TableRow className={rowClassName(row)} onClick={() => onRowClick(row)} {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <TableCell {...cell.getCellProps()} className={cell.column.id} onClick={() => onCellClick(cell)}>
                      {cell.value ? cell.render('Cell') : getEmptyCellContent(cell) || cell.render('Cell')}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </tbody>
        </StyledTable>
      )}
      {!isLoading && !rows.length && <TableEmptyContent title={emptyTableTitle} description={emptyTableDescription} />}
    </>
  );

  if (isInfinity) {
    return (
      <Scroll
        next={nextPage}
        hasMore={data.length !== dataLength}
        loader={
          data.length !== dataLength && (
            <InfinityTablePreloaderContainer>
              <TablePreloader />
            </InfinityTablePreloaderContainer>
          )
        }
        dataLength={data.length}
        className={className}
      >
        {getTable()}
        {isLoading && (
          <TablePreloaderContainer>
            <TablePreloader />
          </TablePreloaderContainer>
        )}
      </Scroll>
    );
  }

  return (
    <TableComponent className={className} isData={data && data.length > 0}>
      {getTable()}
      {isLoading && (
        <TablePreloaderContainer>
          <TablePreloader />
        </TablePreloaderContainer>
      )}
    </TableComponent>
  );
};

interface TableEmptyContentProps {
  title?: string;
  description?: string;
}

const TableEmptyContent = (props: TableEmptyContentProps) => {
  const { title, description } = props;
  return (
    <TableEmptyContentComponent spacing="2rem">
      <CenteredFlex>
        <EmptyContentIcon icon="/assets/icons.svg#no-results" />
      </CenteredFlex>
      <ColumnCenteredFlexWithPadding spacing="0.25rem">
        <StrongTextRegularBig>{title}</StrongTextRegularBig>
        <Description>{description}</Description>
      </ColumnCenteredFlexWithPadding>
    </TableEmptyContentComponent>
  );
};

const EmptyContentIcon = styled(Icon)`
  height: 50px;
  width: 50px;
  fill: ${Colors.Grey700} !important;
`;

const Description = styled(TextRegular)`
  color: ${Colors.Grey700};
`;

const TableEmptyContentComponent = styled(ColumnFlexWithPadding)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const TableComponent = styled(ColumnCenteredFlex)<{ isData: boolean }>`
  display: flex;
  justify-content: flex-start;
  position: relative;
  flex-grow: 1;
`;

const StyledTable = styled.table`
  width: 100%;
`;

const TableRow = styled.tr`
  :hover {
    background: ${Colors.Grey600};
  }

  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1rem;
  font-weight: normal;
  text-align: left;
  padding: 1.125rem 0.875rem;
  border-bottom: 1px solid ${Colors.Grey600};
`;

const TableHead = styled.thead`
  color: ${Colors.Grey700};
  font-size: 0.875rem;
  line-height: 1rem;
  border-bottom: 1px solid ${Colors.Grey600};
  border-top: 1px solid ${Colors.Grey600};
  text-align: left;
`;
export const TableCell = styled.td`
  padding: 0.625rem;
  white-space: nowrap;

  &[class*='address'],
  &[class*='fullName'],
  &[class*='subscriber'],
  &[class*='comment'],
  &[class*='subject'],
  &[class*='management-company'],
  &[class*='position'],
  &[class*='text'],
  &[class*='message'] {
    white-space: normal;
  }

  &[class*='comment'],
  &[class*='subject'],
  &[class*='message'] {
    max-width: 400px;
  }

  &:last-child {
    overflow: visible;
    text-align: right;
  }
`;
const TableHeaderCell = styled.th`
  font-weight: normal;
  cursor: pointer;
  white-space: nowrap;

  &:last-child {
    text-align: right;
  }

  padding: 0.625rem;
`;

// @ts-ignore
const Scroll = styled(InfiniteScroll)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const TablePreloader = styled(Preloader)`
  height: 40px;
  width: 40px;
`;

const InfinityTablePreloaderContainer = styled(Flexbox)`
  margin-top: 10px;
  height: 60px;
  width: 60px;
`;

const TablePreloaderContainer = styled(Flexbox)`
  height: 60px;
  width: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
