import styled from 'styled-components';
import { createRef, ReactNode, RefObject } from 'react';
import { ColumnFlexbox, VerticallyCenteredFlexWithSpaceBetween } from '../../../typography/flex';
import { HeaderSmall } from '../../../typography/text';
import { Icon } from '../IconBackground';
import { Colors } from '../../../themes/colors';
import { useOnClickOutside } from '../../../helpers/click-outside/click-outside.hook';

interface SideMenuProps {
  title: string;
  body: ReactNode;
  footer: ReactNode;
  className?: string;
  onClose?: () => void;
}

export const SideMenu = (props: SideMenuProps) => {
  const { title, body, footer, className, onClose } = props;
  const modalRef: RefObject<HTMLDivElement> = createRef();

  const onCloseButtonClick = () => {
    onClose?.();
  };
  useOnClickOutside(modalRef, () => onCloseButtonClick(), true);

  return (
    <SideMenuComponent ref={modalRef} className={className}>
      <MenuHeader>
        <HeaderSmall>{title}</HeaderSmall>
        <CloseIcon onClick={onCloseButtonClick} icon="/assets/icons.svg#close" />
      </MenuHeader>
      <MenuBody>{body}</MenuBody>
      <MenuFooter>{footer}</MenuFooter>
    </SideMenuComponent>
  );
};

const SideMenuComponent = styled(ColumnFlexbox)`
  justify-content: space-between;
  border: 1px solid ${Colors.Grey600};
  background: ${Colors.MainBackground};
  height: 100%;
  width: 350px;
`;

const MenuHeader = styled(VerticallyCenteredFlexWithSpaceBetween)`
  padding: 1.25rem 1rem 1.25rem 1.25rem;
  border-bottom: 1px solid ${Colors.Grey600};
`;

const MenuBody = styled(ColumnFlexbox)`
  padding: 0.75rem 1.25rem;
  justify-content: flex-start;
  height: 100%;
  overflow-y: auto;
`;

const MenuFooter = styled(VerticallyCenteredFlexWithSpaceBetween)`
  padding: 1.25rem 1.5625rem 1.25rem 1.5625rem;
  border-top: 1px solid ${Colors.Grey600};
`;

const CloseIcon = styled(Icon)`
  fill: ${Colors.Grey700} !important;
  cursor: pointer;

  :hover {
    fill: ${Colors.Blue600} !important;
  }

  :active {
    fill: ${Colors.Blue700} !important;
  }
`;
