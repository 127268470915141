/* eslint-disable  no-unused-vars */
/* eslint-disable  @typescript-eslint/no-unused-vars  */
import * as AppealsSelectors from 'store/appeals/appeals.selectors';
import { AnyAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { number, object, string } from 'yup';
import { ModalProps } from 'app/services/modal-service/modal.service';
import { Datepicker, FormField, Input } from 'app/components/shared';
import { ColumnFlexWithPadding, FlexWithSpacing, VerticallyCenteredFlexWithSpaceBetween } from 'app/typography/flex';
import { HeaderSmall, TextRegular } from 'app/typography/text';

import * as AppealsActions from 'store/appeals/appeals.actions';
import { useActionListener } from 'app/helpers/actions/action-listener.hook';
import { getMinutesDuration, localFormat } from 'app/helpers/date/time';
import { useAction } from 'app/helpers/actions/use-action';
import { MILLISECONDS_IN_MINUTE } from 'app/constants/time/date';
import React from 'react';
import { AssignMasterButton, Inputs, PlanButton, StyledPreloader, WorkPlanDialogBody, WorkPlanDialogComponent } from './styled';

interface WorkPlanDialogProps extends ModalProps {}

interface WorkPlanForm {
  dateStart?: string;
  dateEnd?: string;
  startTime?: string;
  endTime?: string;

  workDurationMin: number;
  handymanId?: string;
}

const formInitialValue: WorkPlanForm = {
  dateStart: '',
  dateEnd: '',
  startTime: '',
  endTime: '',
  workDurationMin: 0,
};

export const WorkPlanDialog = ({ onClose = () => {} }: WorkPlanDialogProps) => {
  const appeal = useSelector(AppealsSelectors.selectSelectedAppeal);
  const updating = useSelector(AppealsSelectors.selectUpdateInProgress);
  const updateAppeal = useAction(AppealsActions.UpdateAppeal.init);
  const openSelectMasterDialog = useAction(AppealsActions.openSelectMasterDialog);

  const getInitialValues = React.useCallback((): WorkPlanForm => {
    if (appeal) {
      const { handyman, performingStartedAtPlan, expectingTimeForWorkInMin, planned_end_date } = appeal;

      return {
        dateStart: performingStartedAtPlan ? localFormat(new Date(performingStartedAtPlan), 'yyyy-MM-dd') : '',
        startTime: performingStartedAtPlan ? localFormat(new Date(performingStartedAtPlan), 'HH:mm') : '',
        dateEnd: planned_end_date ? localFormat(new Date(planned_end_date), 'yyyy-MM-dd') : '',
        endTime: planned_end_date ? localFormat(new Date(planned_end_date), 'HH:mm') : '',
        workDurationMin: getMinutesDuration(
          new Date(+new Date(performingStartedAtPlan) + expectingTimeForWorkInMin * MILLISECONDS_IN_MINUTE),
          new Date(performingStartedAtPlan)
        ),
        handymanId: handyman?.id || undefined,
      };
    }
    return formInitialValue;
  }, [appeal]);

  const { values, handleChange, setFieldValue, submitForm, isValid, dirty } = useFormik<WorkPlanForm>({
    initialValues: getInitialValues(),
    onSubmit: () => planWorkDate(),
  });

  const planWorkDate = React.useCallback(() => {
    if (!appeal) {
      return;
    }

    const startDate = values.dateStart && values.startTime ? new Date(`${values.dateStart} ${values.startTime}`) : undefined;
    const endDate = values.dateEnd && values.endTime ? new Date(`${values.dateEnd} ${values.endTime}`) : undefined;
    const { id, consumer, address, handyman, dispatcher, managementCompany, utilityCompany } = appeal;

    updateAppeal({
      id,
      consumerId: consumer?.id,
      address: address.address,
      addressId: address?.id,
      handymanId: handyman?.id,
      dispatcherId: dispatcher?.id,
      utilityCompanyId: utilityCompany?.id,
      managementCompanyId: managementCompany?.id,
      performingStartedAtPlan: startDate?.toISOString(),
      expectingTimeForWorkInMin: values.workDurationMin || 0,
      planned_end_date: endDate?.toISOString(),
    });
  }, [appeal, updateAppeal, values.dateEnd, values.dateStart, values.endTime, values.startTime, values.workDurationMin]);

  useActionListener((action: AnyAction) => {
    switch (action.type) {
      case AppealsActions.UpdateAppeal.success.type:
        onClose();
    }
  });

  const handleAssignMaster = React.useCallback(() => {
    if (appeal?.id) {
      openSelectMasterDialog({ appeal });
    }
  }, [appeal, openSelectMasterDialog]);

  return (
    <WorkPlanDialogComponent
      headerContent={<HeaderSmall>Запланировать дату и время работы</HeaderSmall>}
      onClose={onClose}
      body={
        <WorkPlanDialogBody spacing="30px">
          <Inputs spacing="20px">
            {appeal?.handyman && (
              <FormField placeholder="Мастер *">
                <VerticallyCenteredFlexWithSpaceBetween>
                  <TextRegular>{appeal?.handyman?.fullName}</TextRegular>
                  <AssignMasterButton onClick={handleAssignMaster}>Переназначить</AssignMasterButton>
                </VerticallyCenteredFlexWithSpaceBetween>
              </FormField>
            )}

            <FlexWithSpacing spacing="10px">
              <FormField placeholder="Дата начала">
                <Datepicker value={values.dateStart} id="dateStart" onChange={handleChange} type="date" />
              </FormField>
              <FormField placeholder="Время начала">
                <Datepicker value={values.startTime} id="startTime" onChange={handleChange} type="time" />
              </FormField>
            </FlexWithSpacing>

            <FlexWithSpacing spacing="10px">
              <FormField placeholder="Время выполнения, мин">
                <Input value={values.workDurationMin} id="workDurationMin" onChange={handleChange} noError type="number" min={0} />
              </FormField>
            </FlexWithSpacing>

            <FlexWithSpacing spacing="10px">
              <FormField placeholder="Дата окончания">
                <Datepicker value={values.dateEnd} id="dateEnd" onChange={handleChange} type="date" />
              </FormField>
              <FormField placeholder="Время окончания">
                <Datepicker value={values.endTime} id="endTime" onChange={handleChange} type="time" />
              </FormField>
            </FlexWithSpacing>
          </Inputs>
          {updating ? (
            <StyledPreloader />
          ) : (
            <PlanButton
              disabled={
                !dirty ||
                !((values.dateStart && values.startTime) || (values.dateEnd && values.endTime)) ||
                (values.dateStart && !values.startTime) ||
                (values.startTime && !values.dateStart) ||
                (values.dateEnd && !values.endTime) ||
                (values.endTime && !values.dateEnd)
              }
              onClick={submitForm}
            >
              Запланировать
            </PlanButton>
          )}
        </WorkPlanDialogBody>
      }
    />
  );
};
