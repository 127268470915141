import { phoneNumberRegExp } from 'app/constants/regular-expressions';

export const formatPhoneNumber = (phone: string | undefined): string | undefined => {
  if (!phone) {
    return undefined;
  }

  const cleaned = phone.replace(/[-|\D]/g, '');
  const match = cleaned.match(phoneNumberRegExp);

  if (match) {
    return `+7 ${match[2]} ${match[3]} ${match[4]} ${match[5]}`;
  }

  return phone.slice(0, 16);
};
