import { shallowEqual, useSelector } from 'react-redux';
import { AppealsActions, AppealsSelectors } from 'store/appeals';
import { HeaderSmall } from 'app/typography/text';
import { useAction } from 'app/helpers/actions/use-action';
import React from 'react';
import { Column, TabComponent } from '../../styled';
import { AttachmentsUpload } from '../AttachmentsUpload';
import { AppealId } from '../../../../models/appeal';

type AppealFilesProps = {
  appealId: AppealId;
};
export const AppealFiles = React.memo<AppealFilesProps>(props => {
  const attachedFiles = useSelector(AppealsSelectors.selectSelectedAppealAttachedFiles, shallowEqual);
  const uploadAppealAttachments = useAction(AppealsActions.UploadAppealAttachments.init);
  const deleteAppealAttachment = useAction(AppealsActions.DeleteAppealAttachment.init);

  const handleAttachmentsUpload = React.useCallback(
    (files: File[]) => {
      if (!props.appealId) return;

      uploadAppealAttachments({ appealId: props.appealId, files });
    },
    [props.appealId, uploadAppealAttachments]
  );

  const handleAttachmentDelete = React.useCallback(
    (id: string) => {
      deleteAppealAttachment(id);
    },
    [deleteAppealAttachment]
  );

  return (
    <TabComponent>
      <Column spacing="20px">
        <HeaderSmall>Прикреплённые файлы</HeaderSmall>
        <AttachmentsUpload multiple files={attachedFiles} onChange={handleAttachmentsUpload} onDelete={handleAttachmentDelete} />
      </Column>
    </TabComponent>
  );
});
