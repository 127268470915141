import { Address } from 'app/models/accommodation';

import Axios from 'axios';
import { DaDataAddress, DaDataSuggestion } from 'react-dadata';
import { CreateAccommodationDto, UpdateAccommodationDto } from './dtos';
import { HttpService } from '../http.service';
import { environment } from '../../environment';

class AccommodationServiceImpl {
  private apiEndpoint = '/api/addresses';

  getAccommodation = async (id: string): Promise<Address> => {
    const { data } = await HttpService.get<Address>(`${this.apiEndpoint}/${id}/`);

    return data;
  };

  createAccommodation = async (createAccommodationDto: CreateAccommodationDto): Promise<Address> => {
    const { data } = await HttpService.post<Address>(this.apiEndpoint, createAccommodationDto);
    return data;
  };

  updateAccommodation = async ({ id, ...body }: UpdateAccommodationDto): Promise<Address> => {
    const { data } = await HttpService.put<Address>(`${this.apiEndpoint}/${id}`, body);

    return data;
  };

  deleteAccommodation = async (id: string): Promise<void> => {
    await HttpService.delete<void>(`${this.apiEndpoint}/${id}`);
  };

  fetchAddressSuggestions = async (query: string): Promise<DaDataSuggestion<DaDataAddress>[]> => {
    const response = await Axios.post<{ suggestions: DaDataSuggestion<DaDataAddress>[] }>(
      'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',

      { query, count: 10 },
      {
        headers: {
          Authorization: `Token ${environment.dadataAPIKey}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data.suggestions;
  };
}

export const AccommodationService = new AccommodationServiceImpl();
