/* eslint-disable no-alert */
import { OptionMenu } from 'app/models/common/option';
import React from 'react';
import { Menu, MenuIcon } from './styled';

export const ModalMenu = () => {
  const menuOptions: OptionMenu[] = [
    {
      label: 'Отправить ссылку',
      callback: () => {
        alert('Отправить ссылку');
      },
    },
    {
      label: 'Перевод на мастера',
      callback: () => {
        alert('Перевод на мастера');
      },
    },
  ];

  return (
    <Menu options={menuOptions}>
      <MenuIcon icon="/assets/icons.svg#menu" />
    </Menu>
  );
};
