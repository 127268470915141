import React from 'react';
import { IncidentId } from '../../../../../../models/incident/incident';
import { HeaderSmall } from '../../../../../../typography/text';

type HeaderProps = { incidentId?: IncidentId; getToWork?: boolean };

export const Header = React.memo<HeaderProps>(props => {
  if (props.incidentId) {
    return (
      <div>
        <HeaderSmall>{props.getToWork ? 'Взять инцидент в работу' : 'Редактирование инцидента'}</HeaderSmall>
        <br />
      </div>
    );
  }
  return <HeaderSmall>Новый инцидент</HeaderSmall>;
});
