import React from 'react';

import { Cell } from 'react-table';
import { Call } from 'app/models/call';
import { ModalService } from 'app/services/modal-service/modal.service';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ConsumersActions, ConsumersSelectors } from 'store/consumers';
import { TableScrollbar } from 'app/components/shared';
import { CALLS_HISTORY_COLUMNS, ConsumerCallsHistoryCell } from '../../../../components/Tabs/constants/calls-history.columns';
import { CallDialog } from '../../../Calls/components/CallDialog';
import { EmptyTable, FileIcon, Text } from '../../../../components/Tabs/constants/elements';
import { CallHistoryTable } from '../../../../components/Tabs/CallHistoryTab/styled';

interface CallHistoryTabProps {
  consumerId: string | undefined;
}

export const CallHistoryTab = React.memo<CallHistoryTabProps>(props => {
  const dispatch = useDispatch();
  const calls = useSelector(ConsumersSelectors.selectConsumerCalls, shallowEqual);
  const isCallsLoading = useSelector(ConsumersSelectors.selectAreConsumerCallsLoading, shallowEqual);

  const cellHandleClick = (cell: Cell<Call>) => {
    const call = cell.row.original;

    if (cell.column.id !== ConsumerCallsHistoryCell.Record) {
      ModalService.openModal(CallDialog, { call });
    }
  };

  React.useEffect(() => {
    if (!props.consumerId) return;
    dispatch(ConsumersActions.LoadConsumerCalls.init(props.consumerId));
  }, [props.consumerId, dispatch]);

  if (!calls.length) {
    return (
      <EmptyTable>
        <FileIcon icon="/assets/icons.svg#phone down" />
        <Text>В истории нет звонков</Text>
      </EmptyTable>
    );
  }

  return (
    <TableScrollbar>
      <CallHistoryTable columns={CALLS_HISTORY_COLUMNS} data={calls} onCellClick={cellHandleClick} isLoading={isCallsLoading} />
    </TableScrollbar>
  );
});
