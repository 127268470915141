import { addMilliseconds, format as formatFns, isValid } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { ru } from 'date-fns/locale';
import { MILLISECONDS_IN_HOUR, MILLISECONDS_IN_MINUTE } from '../../constants/time/date';

export type DateParam = Date | number | string | undefined | null;

export function localFormat(
  date: Date | number,
  format: string,
  options?: {
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: number;
    useAdditionalWeekYearTokens?: boolean;
    useAdditionalDayOfYearTokens?: boolean;
  }
) {
  return formatFns(date, format, { ...options, locale: ru });
}

export function getUnixDate() {
  return utcToZonedTime(new Date(0), 'UTC');
}

export enum DateFormat {
  ddMMMM = 'dd MMMM',
  HHmmss = 'HH:mm:ss',
  HHmm = 'HH:mm',
  mmss = 'mm:ss',
  mm = 'mm',
  ss = 'ss',
  LocalDateAndTime = 'P p',
}

export function formatAddMillisecondsToUnix(milliseconds: number, format: DateFormat) {
  return localFormat(addMilliseconds(getUnixDate(), milliseconds), format);
}

export function formatAddSecondsToUnix(seconds: number, format: DateFormat) {
  return formatAddMillisecondsToUnix(seconds * 1000, format);
}

export const formatTime = (sec: number, isHours?: boolean) => {
  const hours = Math.floor(sec / 3600);
  const minutes = isHours ? Math.floor((sec / 60) % 60) : Math.floor(sec / 60);
  const seconds = Math.floor(sec % 60);
  if (isHours) {
    return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
  }
  return `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
};

export const getHoursDuration = (secondDate: Date, firstDate: Date) => Math.floor((+secondDate - +firstDate) / MILLISECONDS_IN_HOUR);

export const getMinutesDuration = (secondDate: Date, firstDate: Date) => Math.floor((+secondDate - +firstDate) / MILLISECONDS_IN_MINUTE);

export const checkIsDateValid = (date: Date) => isValid(date);

export const getValidDateObject = (date: DateParam): Date | undefined => {
  if (date === undefined || date === null) return undefined;
  date = date instanceof Date ? date : new Date(date);

  return checkIsDateValid(date) ? date : undefined;
};

/** Возвращает строку в переданном формате. Например dd.MMMM.yyyy */
export const formatDate = (date: DateParam, formatString: string) => {
  date = getValidDateObject(date);
  if (!date) return undefined;
  return formatFns(date, formatString, { locale: ru });
};

export const getUTCDate = (date: DateParam) => {
  const maybeValidDate = getValidDateObject(date);

  if (!maybeValidDate) return undefined;
  return new Date(maybeValidDate.getTime() + maybeValidDate.getTimezoneOffset() * 60000);
};
