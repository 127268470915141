import { TableFilterType } from './table-filter-type';
import { Option } from '../common';

export interface AppealsTableStructure {
  columns: TableColumn[];
  inlineFilters: TableFilter[];
  sideFilters: TableFilter[];
}

export interface CommonTableStructure {
  columns: TableColumn[];
  filters: TableFilter[];
}

export interface TableColumn {
  id: string;
  key: string;
  label: string;
  visible: boolean;
  required: boolean;
  sort?: number;
}

export interface TableFilter {
  label: string;
  key: string;
  filterType: TableFilterType;
  options?: Option<string | number>[];
}

export enum FilterKey {
  Phone = 'phone__contains',
}
