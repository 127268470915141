import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { CallStatus, Participant, WebphoneType } from './models';
import { WEBPHONE_STORE_NAME, webphoneActions } from './webphone.actions';
import { ParticipantSupplier } from './models/participant-supplier';
import { CallId } from '../../app/models/call';

export interface WebPhoneState {
  callStatus: CallStatus;
  participant?: Participant;
  supplier?: ParticipantSupplier;
  participantPhone?: string;
  callStartDate?: number;
  membersQueueCount: number;
  webphoneType: WebphoneType;
  dispatcherComment: string;
  isRegistered: boolean;
  isCallDurationLimitExceeded: boolean;
  openedAppealId?: number;
  appealId?: number;
  callId?: CallId;
}

export const getWebPhoneInitialState: () => WebPhoneState = () => ({
  callStatus: CallStatus.None,
  participantPhone: '',
  membersQueueCount: 0,
  webphoneType: WebphoneType.Modal,
  dispatcherComment: '',
  isRegistered: false,
  isCallDurationLimitExceeded: false,
  appealId: undefined,
  callId: undefined,
});

const webPhoneSlice = createSlice({
  name: WEBPHONE_STORE_NAME,
  initialState: getWebPhoneInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(webphoneActions.InitIncomingRinging.success, (state, { payload }) => {
        const { participant, participantPhone, membersQueueCount } = payload;
        state.callStatus = CallStatus.IncomingRinging;
        state.participant = participant;
        state.participantPhone = participantPhone;
        state.membersQueueCount = membersQueueCount;
        state.isCallDurationLimitExceeded = false;
        state.supplier = payload.supplier;
      })
      .addCase(webphoneActions.InitOutgoingRinging.success, (state, { payload }) => {
        const { participant, participantPhone } = payload;
        state.callStatus = CallStatus.OutgoingRinging;
        state.participant = participant;
        state.participantPhone = participantPhone;
        state.isCallDurationLimitExceeded = false;
        state.supplier = payload.supplier;
        state.appealId = payload.appealId;
        state.callId = payload.callId;
      })
      .addCase(webphoneActions.establishedConnection, state => {
        state.callStatus = CallStatus.InTalk;
        state.callStartDate = Date.now();
      })
      .addCase(webphoneActions.answer, state => {
        state.callStatus = CallStatus.EstablishingIncomingCall;
      })
      .addCase(webphoneActions.establishIncomingConnection, state => {
        state.callStatus = CallStatus.InTalk;
        state.callStartDate = Date.now();
      })
      .addCase(webphoneActions.hangup, state => {
        state.callStatus = CallStatus.None;
      })
      .addCase(webphoneActions.changeDispatcherComment, (state, { payload }) => {
        state.dispatcherComment = payload;
      })
      .addCase(webphoneActions.setWebphoneType, (state, { payload }) => {
        state.webphoneType = payload;
      })
      .addCase(webphoneActions.setIsCallDurationLimitExceeded, (state, { payload }) => {
        state.isCallDurationLimitExceeded = payload;
      })
      .addCase(webphoneActions.setParticipant, (state, { payload }) => {
        state.participant = payload;
      })
      .addCase(webphoneActions.setIsRegistered, (state, { payload }) => {
        state.isRegistered = payload;
      })
      .addCase(webphoneActions.setOpenedAppealId, (state, { payload }) => {
        state.openedAppealId = payload;
      })
      .addCase(webphoneActions.unregister, () => getWebPhoneInitialState())
      .addMatcher(
        isAnyOf(
          webphoneActions.InitOutgoingRinging.failure,
          webphoneActions.InitIncomingRinging.failure,
          webphoneActions.handleSessionTermination.failure,
          webphoneActions.handleSessionTermination.success
        ),
        state => {
          state.callStatus = CallStatus.None;
          state.participant = undefined;
          state.participantPhone = '';
          state.membersQueueCount = -1;
          state.isCallDurationLimitExceeded = false;
          state.supplier = undefined;
          state.callStartDate = undefined;
          state.dispatcherComment = '';
          state.appealId = undefined;
          state.callId = undefined;
        }
      );
  },
});

export const webPhoneReducer = webPhoneSlice.reducer;
