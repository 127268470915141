import styled from 'styled-components';

export const List = styled.ul`
  padding-left: 0;
  list-style: disc !important;

  & li {
    list-style-position: inside;
    padding: 3px;
  }
`;
