import styled from 'styled-components';
import { Button, Dialog } from '../../../../components/shared';

export const ConfirmDialog = styled(Dialog)`
  width: max-content;
  height: auto;
  max-width: 350px;
  text-align: center;
  line-height: 16px;

  .bodyClassName {
    padding: 30px;
    gap: 26px;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
`;

export const ActionButton = styled(Button)`
  padding: 12px 15px;
  min-width: 120px;
  margin-top: 16px;
`;
