import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import * as AppealSelectors from 'store/appeals/appeals.selectors';
import { AppealInfoTable, TabComponent } from '../../styled';
import { TableScrollbar } from '../../../../components/shared';
import { GetAppealChangesHistory } from '../../../../../store/appeals/appeals.actions';
import { AppealId } from '../../../../models/appeal';
import { columns } from './table.columns';

type ChangeHistoryProps = {
  appealId: AppealId | undefined;
};
export const ChangeHistory = React.memo<ChangeHistoryProps>(props => {
  const dispatch = useDispatch();
  const appealHistory = useSelector(AppealSelectors.selectFilteredAppealChangesHistory, shallowEqual);

  React.useEffect(() => {
    if (!props.appealId) return;
    dispatch(GetAppealChangesHistory.init({ id: props.appealId }));
  }, [dispatch, props.appealId]);

  return (
    <TabComponent>
      <TableScrollbar>
        <AppealInfoTable columns={columns} emptyTableTitle="История изменений пуста" data={appealHistory} />
      </TableScrollbar>
    </TabComponent>
  );
});
