import React, { useState, forwardRef } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { ErrorHint } from 'app/typography/text';
import { ColumnFlexWithPadding } from 'app/typography/flex';
import { Colors } from 'app/themes/colors';
import { FormFieldError } from '../FormFieldError';

export const Textarea = forwardRef<
  HTMLTextAreaElement,
  { error?: string; isValid?: boolean; className?: string } & React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  >
>((props, ref) => {
  const { value, isValid, onChange, children, error, className, ...rest } = props;

  const [isFocused, setIsFocused] = useState(false);
  const [isPristine, setIsPristine] = useState(true);

  function getIsPristine() {
    return isPristine && (!value || value.toString().trim().length === 0);
  }

  const textareaProps = {
    onFocus: () => {
      if (getIsPristine()) {
        setIsPristine(false);
      }
      setIsFocused(true);
    },
    onBlur: () => setIsFocused(false),
    ...(rest as any),
    value,
    onChange,
  };

  const isInputInvalid = ((isValid !== undefined && !isValid) || error) && !isFocused && !getIsPristine() && !textareaProps.disabled;

  return (
    <TextareaContainer spacing="0.3125rem">
      <TextareaComponent ref={ref} {...textareaProps} className={classNames(className, { error: isInputInvalid })}>
        {children}
      </TextareaComponent>
      {isInputInvalid && error && (
        <ErrorHint>
          <FormFieldError error={error} />
        </ErrorHint>
      )}
    </TextareaContainer>
  );
});

const TextareaComponent = styled.textarea`
  appearance: none;

  &::-ms-reveal,
  &::-ms-clear {
    display: none;
  }

  &::-webkit-credentials-auto-fill-button {
    margin: 0;
    width: 0;
    background-color: transparent;
  }

  background: ${Colors.Blue400};
  border: 2px solid transparent;
  border-radius: 0.378rem;
  color: ${Colors.Grey900};
  font-size: 0.875em;
  line-height: 1rem;
  padding: 1rem;

  &::placeholder {
    font-weight: normal;
    color: ${Colors.Grey700};
  }

  &:hover {
    box-shadow: 0 0 0 1px ${Colors.Blue600};
  }

  &:focus {
    box-shadow: 0 0 0 2px ${Colors.Blue700};
    background: ${Colors.MainBackground};
    outline: none;
  }

  &:disabled {
    background: ${Colors.Grey500};
    color: ${Colors.Grey700};
    border: none;
  }

  &.error {
    border: 2px solid ${Colors.Red800};
    background: ${Colors.MainBackground};
  }
`;

const TextareaContainer = styled(ColumnFlexWithPadding)`
  width: 100%;
  position: relative;
`;
