import { all, call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { CallsService } from 'app/services/calls-service/calls.service';

import { TableDto } from 'app/services/models/table.dto';
import { DEFAULT_ROWS_COUNT } from 'app/constants/scroll';
import { Call, CallsQueryParams } from 'app/models/call';
import { CreateCallRequest } from 'app/services/calls-service';
import * as CallActions from './calls.actions';
import { UpdateCallPayload } from './models/update-call-payload';

function* loadCalls({ payload }: PayloadAction<CallsQueryParams>) {
  try {
    const response: TableDto<Call> = yield call(CallsService.getCalls, {
      per_page: payload.per_page || DEFAULT_ROWS_COUNT,
      ...payload,
    });

    yield put(
      CallActions.LoadCalls.success({
        calls: response.results,
        callsTotalCount: response.count,
        missedCallsCount: response?.filters_counts?.miss?.count || 0,
        page: response.page,
      })
    );
  } catch (e: any) {
    yield put(CallActions.LoadCalls.failure(e));
  }
}

function* getCallsCreatedGte({ payload }: PayloadAction<CallsQueryParams>) {
  try {
    const response: TableDto<Call> = yield call(CallsService.getCalls, {
      per_page: payload.per_page || DEFAULT_ROWS_COUNT,
      ...payload,
    });

    yield put(
      CallActions.LoadCallsCreatedGte.success({
        calls: response.results,
        callsTotalCount: response.count,
        page: response.page,
      })
    );
  } catch (e: any) {
    yield put(CallActions.LoadCallsCreatedGte.failure(e));
  }
}

function* createCall({ payload }: PayloadAction<CreateCallRequest>) {
  try {
    const response = yield call(CallsService.createCall, payload);

    yield put(CallActions.CreateCall.success(response));
  } catch (e: any) {
    yield put(CallActions.CreateCall.failure(e));
  }
}

function* updateCall({ payload }: PayloadAction<UpdateCallPayload>) {
  try {
    const response: Call = yield call(CallsService.updateCall, payload.id, payload.updateCallDto);

    yield put(CallActions.UpdateCall.success(response));
  } catch (e: any) {
    yield put(CallActions.UpdateCall.failure(e));
  }
}

export default function* watcher() {
  return yield all([
    takeLatest(CallActions.LoadCalls.init.type, loadCalls),
    takeLatest(CallActions.LoadCallsCreatedGte.init.type, getCallsCreatedGte),
    takeLatest(CallActions.CreateCall.init.type, createCall),
    takeLatest(CallActions.UpdateCall.init.type, updateCall),
  ]);
}
