import styled from 'styled-components';
import { css } from 'styled-components/macro';
import { ColumnFlexbox, Flexbox } from '../../../../../../typography/flex';
import { Colors } from '../../../../../../themes/colors';
import { Icons } from '../../../../../../constants/icons';

export const SideBar = styled(ColumnFlexbox)`
  height: 100%;
  width: 64px;
  background: ${Colors.White};
  border-radius: 1rem;

  border-left: 1px solid ${Colors.Grey600};
`;
export const Container = styled(Flexbox)``;

const iconStyles = css<{ active?: string }>`
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;

  ${({ active }) =>
    active === 'true' &&
    css`
      fill: ${Colors.White};
    `}
`;

export const MessageIcon = styled(Icons.Message)<{ active: string | undefined }>`
  ${iconStyles}
`;
export const UtilitiesIcon = styled(Icons.Utilities)<{ active: string | undefined }>`
  ${iconStyles}
`;
export const EmergencyIcon = styled(Icons.Emergency)<{ active: string | undefined }>`
  ${iconStyles}
`;

export const IconsContainer = styled.div<{ active: boolean }>`
  height: 64px;
  width: 64px;

  position: relative;

  ${({ active }) =>
    active &&
    css`
      background-color: ${Colors.Blue800};
    `}
`;
