import { Participant } from '../../store/webphone';
import { Consumer } from '../models/consumer';

export const convertConsumerToParticipant = (consumer: Consumer): Participant => ({
  consumerId: consumer.id,
  fullName: consumer.fio,
  appeals: [],
  addresses: consumer.addresses.map(({ id, address, manageCompany }) => ({
    id,
    address,
    managementCompany: manageCompany,
  })),
});
