/* eslint-disable no-console */
import { Invitation, Inviter, Registerer, RegistererState, Session, SessionState, URI, UserAgent, UserAgentOptions } from 'sip.js';
import { SessionDescriptionHandler } from 'sip.js/lib/platform/web/session-description-handler';
import { IncomingResponse } from 'sip.js/lib/core';

import { getUserNameFromSipUri } from 'app/helpers/webphone/get-user-name-from-sip-uri';
import { environment } from 'app/environment';
import { CallDirection, SipCallId } from '../models/call';

enum CallType {
  Direct = 'Direct',
  Queued = 'Queued',
}

interface WebphoneCredentials {
  sipUri: string;
  sipPassword: string;
}

export interface IncomingCallOptions {
  callerPhone: string;
  phoneLine?: string;
}

export interface OutgoingCallOptions {
  callId: SipCallId;
  direction?: CallDirection;
}

export interface IncomingConnectionEstablishedOptions {
  callerPhone: string;
  callId: SipCallId;
  direction?: CallDirection;
}

export interface HangupOptions {
  callId?: SipCallId;
  direction?: CallDirection;
}

export type IncomingCallListener = (options: IncomingCallOptions) => void;
export type OutgoingCallListener = (options: OutgoingCallOptions) => void;
export type OutgoingCallEstablishingListener = (options: OutgoingCallOptions) => void;
export type HangupListener = (options: HangupOptions) => void;
export type IncomingConnectionEstablishedListener = (options: IncomingConnectionEstablishedOptions) => void;
export type RegisteredListener = (response?: IncomingResponse) => void;
export type UnregisteredListener = (response?: IncomingResponse) => void;

class WebPhoneServiceImplementation {
  private SIP_SERVER_DOMAIN = environment.webphoneSipServerDomain;
  private readonly REFRESH_REGISTRATION_FREQUENCY = 70;

  private userAgent?: UserAgent;
  private session?: Session;
  private audioElem?: HTMLAudioElement;
  private sipUri = '';
  private callerPhone = '';
  private callType: CallType = CallType.Direct;

  private incomingCallListeners: IncomingCallListener[] = [];
  private outgoingCallListeners: OutgoingCallListener[] = [];
  private outgoingCallEstablishingListeners: OutgoingCallEstablishingListener[] = [];
  private hangupListeners: HangupListener[] = [];
  private incomingConnectionEstablishedListener: IncomingConnectionEstablishedListener[] = [];

  private _phoneLines: string[] = [];

  set phoneLines(lines: string[]) {
    this._phoneLines = lines || [];
  }

  get userAgentName(): string {
    return getUserNameFromSipUri(this.sipUri);
  }

  private onInvite = (invitation: Invitation) => {
    const callerPhone = invitation.remoteIdentity.uri.user || '';
    const { displayName } = invitation.request.from;
    const idSegments = invitation.request.callId.split('-');
    const callId = idSegments[idSegments.length - 1] as unknown as SipCallId;
    const phoneLine = this.getSupplierPhoneLine(displayName);

    this.callType = CallType.Direct;

    if (this.callerPhone === callerPhone) {
      this.session = invitation;
      this.callerPhone = '';
      this.callType = CallType.Queued;
      invitation.accept();

      invitation.stateChange.addListener((state: SessionState) => {
        console.log(`%cSecond Call: ${state.toUpperCase()}`, 'color: #90A6CE');
        switch (state) {
          case SessionState.Established:
            console.log('%cSecond Call - Setup Media', 'color: #90A6CE');
            this.setupMedia(invitation);
            this.incomingConnectionEstablishedListener.forEach(cb => cb({ callerPhone, callId, direction: CallDirection.Incoming }));
            break;
          case SessionState.Terminating:
          case SessionState.Terminated:
            console.log('%cSecond Call - Cleanup Media', 'color: #90A6CE');
            this.cleanupMedia();
            this.hangupListeners.forEach(cb => cb({ callId, direction: CallDirection.Incoming }));
            break;
          default:
            break;
        }
      });

      return;
    }

    if (
      this.session &&
      (this.session.state === SessionState.Initial ||
        this.session.state === SessionState.Establishing ||
        this.session.state === SessionState.Established)
    ) {
      void invitation.reject();
      return;
    }

    this.callerPhone = callerPhone;
    this.incomingCallListeners.forEach(cb => cb({ callerPhone, phoneLine }));
    this.session = invitation;

    invitation.stateChange.addListener((state: SessionState) => {
      console.log(`%cFirst Call: ${state.toUpperCase()}`, 'color: #7D8498');
      switch (state) {
        case SessionState.Established:
          console.log('%cFirst Call - Setup Media', 'color: #7D8498');
          this.setupMedia(invitation);
          if (!this.isCallFromLine(displayName)) {
            this.incomingConnectionEstablishedListener.forEach(cb => cb({ callerPhone, callId }));
          }
          break;
        case SessionState.Terminating:
        case SessionState.Terminated:
          if (this.isCallFromLine(displayName) && this.callType !== CallType.Queued && this.session?.id === invitation.id) {
            this.hangupListeners.forEach(cb => cb({}));
          }
          this.callerPhone = '';
          if (!this.isCallFromLine(displayName)) {
            console.log('%cFirst Call - Cleanup Media', 'color: #7D8498');
            this.cleanupMedia();
            this.hangupListeners.forEach(cb => cb({ callId, direction: CallDirection.Incoming }));
          }
          break;
        default:
          break;
      }
    });
  };

  async start(
    audioElem: HTMLAudioElement,
    { sipUri, sipPassword }: WebphoneCredentials,
    onRegistered: RegisteredListener = () => {},
    onUnregistered: UnregisteredListener = () => {}
  ) {
    const transportOptions = {
      server: environment.webphoneWebsocketServerURL,
    };

    const uri = UserAgent.makeURI(sipUri);

    const userAgentOptions: UserAgentOptions = {
      authorizationPassword: sipPassword,
      authorizationUsername: getUserNameFromSipUri(sipUri),
      transportOptions,
      sessionDescriptionHandlerFactoryOptions: {
        constraints: { audio: true, video: false },
      },
      logBuiltinEnabled: true,
      delegate: {
        onInvite: this.onInvite,
      },
      uri,
    };
    this.audioElem = audioElem;

    this.userAgent = new UserAgent(userAgentOptions);

    const register = new Registerer(this.userAgent, { refreshFrequency: this.REFRESH_REGISTRATION_FREQUENCY });
    register.stateChange.addListener(state => {
      if (state === RegistererState.Registered) onRegistered();
      if (state === RegistererState.Unregistered) onUnregistered();
    });
    await this.userAgent.start();

    await register.register({
      requestDelegate: {
        onReject: onUnregistered,
        onAccept: onRegistered,
      },
    });
  }

  answer() {
    if (this.session?.state === SessionState.Terminating || this.session?.state === SessionState.Terminated) {
      return;
    }

    (this.session as Invitation).accept();
  }

  call(phoneNumber: string, options?: { prefix?: string }) {
    const phone = phoneNumber.replace(/[+]/g, '');
    const destination = options?.prefix ? `*${options.prefix}*${phone}` : phone;

    const target = UserAgent.makeURI(`sip:${destination}@${this.SIP_SERVER_DOMAIN}`) || ({} as URI);

    if (!this.userAgent) {
      return;
    }

    const inviter = new Inviter(this.userAgent, target, { earlyMedia: true });

    this.session = inviter;
    const idSegments = inviter.request.callId.split('-');
    const callId = idSegments[idSegments.length - 1] as unknown as SipCallId;

    inviter.stateChange.addListener((state: SessionState) => {
      switch (state) {
        case SessionState.Established:
          this.setupMedia(inviter);
          this.outgoingCallListeners.forEach(cb => cb({ callId, direction: CallDirection.Outgoing }));
          break;
        case SessionState.Establishing:
          this.setupMedia(inviter);
          this.outgoingCallEstablishingListeners.forEach(cb => cb({ callId }));
          break;
        case SessionState.Terminated:
          this.hangupListeners.forEach(cb => cb({ callId, direction: CallDirection.Outgoing }));
          this.cleanupMedia();
          break;
        default:
          break;
      }
    });

    inviter.invite();
  }

  hangupCall() {
    if (!this.session) {
      return;
    }

    switch (this.session.state) {
      case SessionState.Initial:
      case SessionState.Establishing:
        if (this.session instanceof Inviter) {
          this.session.cancel();
        }

        if (this.session instanceof Invitation) {
          this.session.reject();
        }

        break;
      case SessionState.Established:
        this.session.bye();
        break;
      case SessionState.Terminating:
      case SessionState.Terminated:
        console.error('Cannot terminate a session that is already terminated');
        break;
      default:
        break;
    }
  }

  transferCall(phoneNumber: string, options?: { prefix?: string }) {
    if (!this.session) {
      return;
    }

    const phone = phoneNumber.replace(/\s+/g, '');
    const destination = options?.prefix ? `*${options.prefix}*${phone}` : phone;
    const target = UserAgent.makeURI(`sip:${destination}@${this.SIP_SERVER_DOMAIN}`) || ({} as URI);

    this.session.refer(target).then(() => {
      this.hangupCall();
    });
  }

  registerIncomingCallListener(listener: IncomingCallListener) {
    this.incomingCallListeners.push(listener);
  }

  registerOutcomingConnectionEstablishingListener(listener: OutgoingCallListener) {
    this.outgoingCallEstablishingListeners.push(listener);
  }

  registerOutgoingCallListener(listener: OutgoingCallListener) {
    this.outgoingCallListeners.push(listener);
  }

  registerHangupListener(listener: HangupListener) {
    this.hangupListeners.push(listener);
  }

  registerIncomingConnectionEstablishedListener(listener: IncomingConnectionEstablishedListener) {
    this.incomingConnectionEstablishedListener.push(listener);
  }

  unregister() {
    if (this.userAgent) {
      this.userAgent.stop();
    }

    this.hangupCall();

    this.incomingCallListeners = [];
    this.outgoingCallListeners = [];
    this.hangupListeners = [];
    this.incomingConnectionEstablishedListener = [];
    this.outgoingCallEstablishingListeners = [];
  }

  private setupMedia = (session: Session) => {
    const remoteStream = new MediaStream();

    (session.sessionDescriptionHandler as SessionDescriptionHandler).peerConnection?.getReceivers().forEach(({ track }: RTCRtpReceiver) => {
      if (track) {
        remoteStream.addTrack(track);
      }
    });

    if (this.audioElem) {
      this.audioElem.srcObject = remoteStream;
      this.audioElem.play();
    }
  };

  private cleanupMedia = () => {
    if (this.audioElem) {
      this.audioElem.srcObject = null;
      this.audioElem.pause();
    }
  };

  private isCallFromLine = (from: string): boolean => this._phoneLines.includes(from);

  private getSupplierPhoneLine = (phoneLine: string): string | undefined =>
    this._phoneLines.indexOf(phoneLine) === -1 ? undefined : phoneLine;
}

export const WebPhoneService = new WebPhoneServiceImplementation();
