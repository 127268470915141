import { hasValues } from '../../helpers/objects/has-values';

export const defaultScrollLockClass = 'block-scrolling';

class ScrollLockServiceImpl {

  private refresh() {
    if (document && document.body) {
      this.locks.length ? this.addLockClass() : this.removeLockClass();
    }
  }

  public locks: boolean[] = [];

  public push() {
    this.locks.push(true);
    this.refresh();
  }

  public pop() {
    this.locks.pop();
    this.refresh();
  }

  private get documentIsDefined(): boolean {
    return hasValues(document, document.body);
  }

  private get documentClassList(): DOMTokenList {
    return document.body.classList;
  }

  private get documentContainsScrollLockClass() {
    return this.documentIsDefined && this.documentClassList.contains(defaultScrollLockClass);
  }

  private addLockClass() {
    if (this.documentIsDefined && !this.documentContainsScrollLockClass) {
      this.documentClassList.add(defaultScrollLockClass);
    }
  }

  private removeLockClass() {
    if (this.documentIsDefined && this.documentContainsScrollLockClass) {
      this.documentClassList.remove(defaultScrollLockClass);
    }
  }

}

const ScrollLockService = new ScrollLockServiceImpl();

export default ScrollLockService;
