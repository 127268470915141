import { Colors } from 'app/themes/colors';
import {
  CenteredFlex,
  CenteredFlexWithSpacing,
  ColumnCenteredFlex,
  ColumnCenteredFlexWithPadding,
  ColumnFlexbox,
  ColumnFlexWithPadding,
} from 'app/typography/flex';
import { CallStatus } from 'store/webphone/models/call-status.enum';
import styled from 'styled-components';
import { Timer as TimerComponent } from 'app/components/Timer';
import { IconClickable } from 'app/components/shared/IconClickable';
import { Menu as BaseMenu } from 'app/components/shared/Menu';
import { Link } from 'react-router-dom';
import { Textarea } from '../shared';
import { PhoneNumberPad } from './Dialpad/PhoneNumbePad';

const infoTextCss = `
  font-size: 14px;
  line-height: 16px;
  text-align: center;
`;

export const InfoText = styled.div`
  ${infoTextCss}
`;

const primaryTextCss = `
  font-size: 18px;
  line-height: 21px;
`;

export const PrimaryText = `
  ${primaryTextCss}
`;

export const Participant = styled(InfoText)`
  ${primaryTextCss}
  font-weight: bold;
  color: ${Colors.White};
`;

export const Header = styled(ColumnFlexWithPadding)``;

export const PlacementInfo = styled(ColumnFlexWithPadding)`
  ${infoTextCss}
`;

export const ModalPlacementInfo = styled(PlacementInfo)`
  margin-top: 10px;
  min-width: 200px;
`;

export const ActiveTicketsInfo = styled(InfoText)`
  text-decoration-line: underline;
  cursor: pointer;
`;

export const CallInfo = styled(CenteredFlexWithSpacing)`
  font-weight: bold;
`;

export const ExtendedCallInfo = styled(ColumnCenteredFlexWithPadding)``;

export const MembersQueue = styled(CenteredFlexWithSpacing)`
  ${primaryTextCss}
`;

export const TimersContainer = styled(CenteredFlexWithSpacing)``;

export const Timer = styled(TimerComponent)<{ small?: boolean }>`
  font-size: ${({ small }) => (small ? '18px' : '26px')};
  line-height: 30px;
  font-weight: 500;
`;

export const TimerRed = styled(Timer)`
  color: ${Colors.Red800};
`;

export const SmallTimer = styled(TimerComponent)`
  ${primaryTextCss}
`;

export const Buttons = styled(CenteredFlexWithSpacing)``;

export const WhiteIcon = styled(IconClickable)`
  fill: ${Colors.White} !important;
`;

export const BlueIcon = styled(IconClickable)`
  fill: ${Colors.Blue800} !important;
`;

export const Menu = styled(BaseMenu)`
  position: absolute !important;
  right: 15px !important;
  top: 15px !important;
`;

export const MenuIcon = styled(WhiteIcon)``;

export const MembersQueueIcon = styled(WhiteIcon)`
  cursor: default;
`;

const CircleButtonIconCss = `
  width: 40px;
  height: 40px;
  padding: 12px;
  border-radius: 100px;

  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.08);
`;

export const CircleButton = styled.button<{ color: 'red' | 'blue' | 'green' }>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.08);
  outline: none;

  ${({ color }) => {
    if (color === 'red') {
      return `
         background: ${Colors.Red800};
       
         &:hover {
           background: ${Colors.Red900};
         }
         
         &:focus-visible {
           box-shadow: 0 0 12px 10px rgba(205, 32, 20, 0.7);
         }
      `;
    }

    if (color === 'green') {
      return `
         background: ${Colors.Green800};
       
         &:hover {
           background: ${Colors.Green900};
         }
         
         &:focus-visible {
           box-shadow: 0 0 12px 10px rgba(22, 161, 61, 0.7);
         }
      `;
    }

    return `
         background: ${Colors.Blue700};
       
         &:hover {
           background: ${Colors.Blue800};
         }
         
         &:focus-visible {
           box-shadow: 0 0 12px 10px rgba(33, 78, 157, 0.7);
         }
      `;
  }}
`;

export const CircleButtonBlueIcon = styled(BlueIcon)`
  ${CircleButtonIconCss}
`;

export const HangupIcon = styled(WhiteIcon)`
  width: 20px;
  height: 20px;
`;

export const AnswerIcon = styled(WhiteIcon)`
  width: 20px;
  height: 20px;
`;

export const OpenIcon = styled(WhiteIcon)`
  width: 20px;
  height: 20px;
`;

export const PlusIcon = styled(WhiteIcon)`
  width: 20px;
  height: 20px;
`;

export const WhiteBackgroundIcon = styled(CircleButtonBlueIcon)`
  background: ${Colors.White};

  &:hover {
    background: ${Colors.Blue500};
  }
`;

export const Comment = styled(Textarea)`
  max-height: 300px;
  resize: none;
`;

export const DetailedView = styled(ColumnFlexbox)`
  gap: 30px;
`;

export const BriefView = styled(ColumnCenteredFlex)`
  gap: 20px;
`;

export const WebPhoneBaseContainer = styled(CenteredFlex)`
  flex-direction: column;
  gap: 30px;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  background: rgba(16, 16, 16, 0.8);
  color: ${Colors.White};
  padding: 25px 25px 30px;
`;

export const WebPhoneContainer = styled(WebPhoneBaseContainer)`
  padding: 30px 20px 20px;
  border-radius: 0;
  gap: 20px;
`;

export const WebPhoneModalContainer = styled(WebPhoneBaseContainer)<{
  callStatus: CallStatus;
  minWidth?: string;
}>`
  position: fixed;
  z-index: 10;
  overflow: hidden;
  transition: 0.5s;

  ${({ callStatus, minWidth }) =>
    callStatus === CallStatus.IncomingRinging
      ? `
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: ${minWidth};
    `
      : `
      top: 99%;
      left: 99%;
      transform: translate(-100%, -100%);
      width: 200px;
      gap: 20px;
      
      ${Menu} {
        opacity: 0;
      }
    `}
  & > * {
    transition: 0.5s;
  }

  ${BriefView} {
    display: ${({ callStatus }) => (callStatus === CallStatus.IncomingRinging ? 'none' : 'flex')};
  }

  ${DetailedView} {
    display: ${({ callStatus }) => (callStatus !== CallStatus.IncomingRinging ? 'none' : 'flex')};
  }

  &:hover {
    gap: 30px;
    width: ${({ minWidth }) => minWidth};

    ${BriefView} {
      display: none;
    }

    ${DetailedView} {
      display: flex;
    }

    ${Menu} {
      opacity: 1;
    }
  }
`;

export const CustomPhoneNumberPad = styled(PhoneNumberPad)`
  background-color: ${Colors.White};
  box-shadow: 0 8px 18px rgb(0 0 0 / 8%);
  border-radius: 10px;
  z-index: 1000;
  position: absolute;
  bottom: -14rem;
`;

export const ParticipantLink = styled(Link)`
  color: inherit;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
