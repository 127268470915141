import styled from 'styled-components';

import { Colors } from 'app/themes/colors';
import { Icon } from '../IconBackground';
import { IconClickable } from '../IconClickable';
import { Scrollbar } from '../Scrollbar';

export const DropdownContainer = styled.div`
  position: relative;
`;

export const DropdownHeader = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
`;

export const DropdownHeaderIcon = styled(Icon)`
  margin-right: 10px;
`;

export const DropdownHeaderTitle = styled.div`
  margin-right: 5px;
`;

export const DropdownArrow = styled(IconClickable)<{ isOptionsShown: boolean, disabled?: boolean }>`
  color: ${Colors.Grey700};
  transition: 0.5s;
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};

  ${({ isOptionsShown }) => isOptionsShown && 'transform: rotateX(180deg)'};
`;

export const DropdownOptionsContainer = styled.div`
  position: absolute;
  z-index: 10;
  top: 100%;
  left: 0;
  margin-top: 5px;
  padding: 3px 0;
  border: 1px solid ${Colors.Grey600};
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  background: ${Colors.MainBackground};
`;

export const DropdownOptionsScrollbar = styled(Scrollbar)`
  & > div {
    flex-wrap: wrap;
  }
`;

export const DropdownOptionItem = styled.button<{ isActive }>`
  background: none;
  border: none;
  cursor: pointer;
  width: 100%;
  text-align: start;
  padding: 6px 20px;

  &:hover {
    color: ${Colors.Blue600};
  }
  
  ${({ isActive }) =>
    isActive &&
    `
     font-weight: 700; 
  `}}
`;
