import { LoginForm } from 'store/profile/models/login-form.model';
import { LoginSuccessPayload } from '../../../store/profile/models/login-success-payload';
import { ProfileApiRoutes } from '../../constants/api-routes/profile-api-routes';
import { Profile } from '../../models/profile/profile';
import { HttpService } from '../http.service';
import { AuthResponse } from './dtos/auth-response';
import { GetProfilesBySupplierIdApiParams, GetProfilesBySupplierIdApiResponse, Responsible } from './dtos/get-profiles-by-supplier-id';

class ProfileServiceImpl {
  private static apiProfileEndpoint = 'api/profile';

  async getProfile(): Promise<Profile> {
    const response = await HttpService.get<Profile>(ProfileServiceImpl.apiProfileEndpoint);
    return response.data;
  }

  async login(loginPayload: LoginForm): Promise<LoginSuccessPayload> {
    const response = await HttpService.post<LoginSuccessPayload>(ProfileApiRoutes.login, loginPayload);
    return response.data;
  }

  async logout(): Promise<Profile> {
    const response = await HttpService.post<Profile>(ProfileApiRoutes.logout);
    return response.data;
  }

  async authorizeByRefreshToken(refreshToken: string): Promise<AuthResponse> {
    const response = await HttpService.post<AuthResponse>(`${ProfileServiceImpl.apiProfileEndpoint}/update_token`, {
      refresh: refreshToken,
    });
    return response.data;
  }

  async getProfilesBySupplierId(params: GetProfilesBySupplierIdApiParams): Promise<Array<Responsible>> {
    const response = await HttpService.get<GetProfilesBySupplierIdApiResponse>(
      `${ProfileServiceImpl.apiProfileEndpoint}/by_supplier/${params.supplierId}`
    );
    return response.data.data;
  }
}

const ProfileService = new ProfileServiceImpl();

export { ProfileService };
