import { Option } from 'app/components/shared';

export enum PaymentStatus {
  NotPaid = 0,
  Paid = 1,
}

export const PAYMENT_STATUS_OPTIONS: Option[] = [
  { value: PaymentStatus.NotPaid, label: 'Не оплачено' },
  { value: PaymentStatus.Paid, label: 'Оплачено' },
];
