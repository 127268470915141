import styled from 'styled-components';
import { Option } from 'app/models/common/option';
import { TableFilterPlace } from 'app/models/common/table-filter-properties';
import { TableFilterType } from 'app/models/configuration/table-filter-type';
import { TableFilter } from 'app/models/configuration/table-structure';
import { CenteredFlexWithSpacing } from 'app/typography/flex';
import React from 'react';
import { CheckboxGroup, CheckboxGroupProps } from '../Checkbox';
import { DateInterval, DateIntervalProps, FormDatepicker, FormDatepickerProps } from '../Datepicker';
import { FormInput, FormInputProps } from '../Input';
import { RadioGroup, RadioGroupProps } from '../Radio';
import { FormSelect, FormSelectProps } from '../Select';

interface CommonFilterProps {
  filterType: TableFilterType;
  filterProps: TableFilter;
  value?: string | number | string[] | number[];
  onChange: (event) => void;
  filterPlace: TableFilterPlace;
  noError?: boolean;
}

export const CommonFilter = (props: CommonFilterProps) => {
  const { filterType, filterProps, value, onChange, filterPlace, noError } = props;

  const getFilterComponent = React.useCallback(() => {
    const getSelectProps = (): FormSelectProps =>
      ({
        id: value,
        value,
        onChange,
        options: [
          {
            label: `Не выбрано (${filterProps.label})`,
            value: undefined,
          } as Option<string | number | undefined>,
        ].concat(filterProps.options || []),
        label: filterPlace === TableFilterPlace.Side ? filterProps.label : undefined,
        noError,
        placeholder: filterProps.label,
      } as FormSelectProps);

    const getInputProps = (): FormInputProps =>
      ({
        value,
        onChange,
        label: filterPlace === TableFilterPlace.Side ? filterProps.label : undefined,
        noError,
        placeholder: filterProps.label,
      } as FormInputProps);

    const getCheckboxProps = (): CheckboxGroupProps =>
      ({
        value,
        options: filterProps.options,
        onChange,
        label: filterPlace === TableFilterPlace.Side ? filterProps.label : undefined,
      } as CheckboxGroupProps);

    const getRadioProps = (): RadioGroupProps =>
      ({
        value,
        onChange,
        options: filterProps.options,
        name: filterProps.key,
        label: filterPlace === TableFilterPlace.Side ? filterProps.label : undefined,
      } as RadioGroupProps);

    const getDateIntervalProps = (): DateIntervalProps =>
      ({
        value,
        onChange,
        label: filterPlace === TableFilterPlace.Side ? filterProps.label : undefined,
      } as DateIntervalProps);

    const getDatepickerProps = (): FormDatepickerProps =>
      ({
        value,
        onChange,
        label: filterPlace === TableFilterPlace.Side ? filterProps.label : undefined,
        noError,
      } as FormDatepickerProps);

    switch (filterType) {
      case TableFilterType.Input:
        return <FormInput {...getInputProps()} />;
      case TableFilterType.Checkbox:
        return <CheckboxGroup {...getCheckboxProps()} />;
      case TableFilterType.Select:
        return <FormSelect {...getSelectProps()} />;
      case TableFilterType.Radio:
        return <RadioGroup {...getRadioProps()} />;
      case TableFilterType.DateInterval:
        return <DateInterval {...getDateIntervalProps()} />;
      case TableFilterType.DatePicker:
        return <FormDatepicker {...getDatepickerProps()} />;
    }
    return null;
  }, [filterPlace, filterProps.key, filterProps.label, filterProps.options, filterType, noError, onChange, value]);

  return <FilterContainer spacing="0.625rem">{getFilterComponent()}</FilterContainer>;
};

const FilterContainer = styled(CenteredFlexWithSpacing)`
  justify-content: flex-start;
  width: 100%;
`;
