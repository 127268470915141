import styled from 'styled-components';
import { Button, Dialog, Preloader } from 'app/components/shared';
import { ColumnFlexWithPadding } from 'app/typography/flex';
import { TextButton } from 'app/typography/text';
import { Colors } from 'app/themes/colors';

export const PlanButton = styled(Button)`
  padding: 15px 20px;
  width: auto;
`;

export const WorkPlanDialogComponent = styled(Dialog)`
  width: 420px;
`;

export const AssignMasterButton = styled(TextButton)`
  color: ${Colors.Blue800};
  cursor: pointer;
  text-transform: uppercase;
`;

export const StyledPreloader = styled(Preloader)`
  height: 46px;
  width: 46px;
`;

export const WorkPlanDialogBody = styled(ColumnFlexWithPadding)`
  align-items: flex-start;
`;

export const Inputs = styled(ColumnFlexWithPadding)`
  width: 100%;
`;
