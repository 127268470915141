import {
  getCreateActionFromStore,
  getCreateActionTrio,
  getCreatePayloadActionFromStore
} from '../helpers/action-create';
import { TablesConfiguration, Setting } from '../../app/models/configuration';

export const CONFIGURATION_STORE_NAME = 'configuration';

export const createAction = getCreateActionFromStore(CONFIGURATION_STORE_NAME);
export const createPayloadAction = getCreatePayloadActionFromStore(CONFIGURATION_STORE_NAME);
export const createActionTrio = getCreateActionTrio(CONFIGURATION_STORE_NAME);

export const GetTablesConfiguration = createActionTrio<void, TablesConfiguration>('get tables configuration');

export const GetSettings = createActionTrio<void, Setting[]>('get settings');
