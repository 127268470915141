import { createSlice } from '@reduxjs/toolkit';
import { Notification } from 'app/models/notifications/notification';
import { createGuid } from 'app/helpers/guid/create-guid';
import { NOTIFICATIONS_STORE } from './notifications.actions';
import * as NotificationsActions from './notifications.actions';
import * as ProfileActions from '../profile/profile.actions';

export interface NotificationsState {
  notifications: Notification[];

  loading: boolean;
  error: any;
}

export const notificationsInitialState: NotificationsState = {
  notifications: [],
  loading: false,
  error: undefined,
};

const notificationSlice = createSlice({
  name: NOTIFICATIONS_STORE,
  initialState: notificationsInitialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(NotificationsActions.GetNotifications.init, state => {
        state.loading = true;
      })
      .addCase(NotificationsActions.GetNotifications.success, (state, { payload }) => {
        state.loading = false;
        if (!payload) return;
        state.notifications = payload;
      })
      .addCase(NotificationsActions.GetNotifications.failure, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(NotificationsActions.AddNotification.init, (state, { payload }) => {
        state.notifications = state.notifications.concat({ id: createGuid(), ...payload });
      })
      .addCase(NotificationsActions.ClearNotifications, () => notificationsInitialState)
      .addCase(ProfileActions.Login.init, () => notificationsInitialState),
});

export const notificationsReducer = notificationSlice.reducer;
