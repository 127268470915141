import styled from 'styled-components';

import { CenteredFlexWithSpacing, ColumnFlexbox, Flexbox } from 'app/typography/flex';
import { Header as Heading } from 'app/typography/text';
import { Colors } from 'app/themes/colors';
import { IconClickable } from 'app/components/shared/IconClickable';
import { Dropdown, Menu } from 'app/components/shared';

export const ConsumerInfoContainer = styled(ColumnFlexbox)`
  height: 100%;
`;

export const Header = styled(Flexbox)`
  padding: 20px 30px 17px;
  border-bottom: 1px solid ${Colors.Grey600};
  align-items: center;
  margin-bottom: 20px;
`;

export const ChevronLeft = styled(IconClickable)`
  color: ${Colors.Grey700};
  margin-right: 9px;

  &:hover {
    color: ${Colors.Grey800};
  }
`;

export const FullName = styled(Heading)`
  margin: 0 20px 0 0;
`;

export const Dots = styled(IconClickable)`
  color: ${Colors.Grey700};
  width: 30px;
  height: 30px;

  &:hover {
    color: ${Colors.Grey800};
  }
`;

export const ConsumerContextMenu = styled(Menu)`
  margin-left: auto;
`;

export const Phone = styled(CenteredFlexWithSpacing)`
  color: ${Colors.Blue800};
  font-weight: 500;
  cursor: pointer;
`;

export const AddressDropdown = styled(Dropdown)`
  margin-left: 29px;
  align-self: start;

  button {
    white-space: nowrap;
  }
` as typeof Dropdown;

export const CenteredFlexbox = styled(Flexbox)`
  align-items: center;
  margin-bottom: 8px;
`;

export { ColumnFlexbox, Flexbox };
