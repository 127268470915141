import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { componentActionListener } from 'app/helpers/actions/component-action-listener';
import createSagaMiddleware from 'redux-saga';
import * as Sentry from '@sentry/react';
import { AppState } from '../types/AppState.type';
import { appealsInitialState, appealsReducer } from './appeals';
import { callsInitialState, callsReducer } from './calls';
import { configurationInitialState, configurationReducer } from './configuration';
import { consumersInitialState, consumersReducer } from './consumers';
import { setInterceptors } from './interceprors';
import { suppliersInitialState, suppliersReducer } from './suppliers';
import { masterReducer, mastersInitialState } from './masters';
import { profileInitialState, profileReducer } from './profile/profile.reducer';
import rootSaga from './saga';
import { getWebPhoneInitialState, webPhoneReducer } from './webphone';
import { notificationsInitialState, notificationsReducer } from './notifications';
import { mailingsInitialState, mailingsReducer } from './mailings';
import { statInitialState, statReducer } from './stat';
import { speechNotificationInitialState, speechNotificationReducer } from './speech-notification';
import { servicesInitialState, servicesReducer } from './services/services.reducer';
import { incidentsInitialState, incidentsReducer } from './incidents/incidents.reducer';
import { commentsInitialState, commentsReducer } from './comments/comments.reducer';

const initialState: AppState = {
  profileState: profileInitialState,
  consumersState: consumersInitialState,
  configurationState: configurationInitialState,
  appealsState: appealsInitialState,
  webphone: getWebPhoneInitialState(),
  callsState: callsInitialState,
  mastersState: mastersInitialState,
  suppliersState: suppliersInitialState,
  notificationsState: notificationsInitialState,
  mailingsState: mailingsInitialState,
  statState: statInitialState,
  speechNotificationState: speechNotificationInitialState,
  servicesState: servicesInitialState,
  incidentsState: incidentsInitialState,
  commentsState: commentsInitialState,
};

export function configureAppStore() {
  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [sagaMiddleware, componentActionListener];

  const rootReducer = combineReducers({
    profileState: profileReducer,
    consumersState: consumersReducer,
    configurationState: configurationReducer,
    appealsState: appealsReducer,
    webphone: webPhoneReducer,
    callsState: callsReducer,
    mastersState: masterReducer,
    suppliersState: suppliersReducer,
    notificationsState: notificationsReducer,
    mailingsState: mailingsReducer,
    statState: statReducer,
    speechNotificationState: speechNotificationReducer,
    servicesState: servicesReducer,
    incidentsState: incidentsReducer,
    commentsState: commentsReducer,
  });

  const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

  const store = configureStore({
    preloadedState: initialState,
    reducer: rootReducer,
    middleware: middlewares,
    devTools: process.env.NODE_ENV !== 'production',
    enhancers: [sentryReduxEnhancer],
  });

  sagaMiddleware.run(rootSaga);

  setInterceptors(store);

  return store;
}
