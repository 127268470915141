import React from 'react';
import { AnyAction } from '@reduxjs/toolkit';
import { ModalProps } from '../../../../services/modal-service/modal.service';
import { StyledDialog } from './styled';
import { HeaderSmall } from '../../../../typography/text';
import { AddIncidentsTable } from './components/AddIncidentsTable/AddIncidentsTable';
import { useActionListener } from '../../../../helpers/actions/action-listener.hook';
import { UpdateAppeal } from '../../../../../store/appeals/appeals.actions';

type AddIncidentToAppealDialogProps = {} & ModalProps;

export const AddIncidentToAppealDialog = ({ onClose = () => {} }: AddIncidentToAppealDialogProps) => {
  useActionListener((action: AnyAction) => {
    if (action.type === UpdateAppeal.success.type) {
      onClose();
    }
  });
  return (
    <StyledDialog
      headerContent={<HeaderSmall>Прикрепление заявки к инциденту</HeaderSmall>}
      body={<AddIncidentsTable />}
      onClose={onClose}
    />
  );
};
