import styled from 'styled-components';
import { ColumnFlexWithPadding, FlexWithSpacing } from '../../../../../../../typography/flex';
import { Textarea } from '../../../../../../../components/shared';

export const Container = styled(ColumnFlexWithPadding)`
  width: 100%;
`;

export const Header = styled(FlexWithSpacing)`
  align-items: center;
`;

export const Footer = styled(Header)``;

export const Text = styled(Textarea)`
  resize: none;
  background: none;
  padding: 0;
  padding-bottom: 5px;
  line-height: 24px;
  font-size: 16px;
  overflow: hidden;
  min-height: 40px;
  &:hover {
    box-shadow: none;
    border: none;
  }

  &:focus {
    box-shadow: none;
    background: none;
    outline: none;
  }
`;
