import { useCallback, useEffect } from 'react';

import { useBeforeUnload } from 'app/helpers/window/useBeforeUnload';
import {
  HangupListener,
  IncomingCallListener,
  IncomingConnectionEstablishedListener,
  OutgoingCallEstablishingListener,
  OutgoingCallListener,
  WebPhoneService,
} from '../../services/webphone.service';

export const useCallsListener = (
  onIncomingCall: IncomingCallListener,
  onOutgoingCall: OutgoingCallListener,
  onOutgoingEstablishingConnection: OutgoingCallEstablishingListener,
  onIncomingConnectionEstablished: IncomingConnectionEstablishedListener,
  onHangup: HangupListener
) => {
  const unregisterUserAgent = useCallback(() => {
    WebPhoneService.unregister();
  }, []);

  useBeforeUnload(unregisterUserAgent);

  useEffect(() => {
    WebPhoneService.registerIncomingCallListener(onIncomingCall);

    WebPhoneService.registerOutgoingCallListener(onOutgoingCall);
    WebPhoneService.registerOutcomingConnectionEstablishingListener(onOutgoingEstablishingConnection);

    WebPhoneService.registerIncomingConnectionEstablishedListener(onIncomingConnectionEstablished);

    WebPhoneService.registerHangupListener(onHangup);

    return unregisterUserAgent;
  }, []);
};
