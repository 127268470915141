export enum UtilityField {
  Name = 'name',
  Phone = 'phone',
  OwnerPhone = 'ownerPhone',
  Address = 'address',
  Website = 'website',
}

export const utilityFieldTitleMap = {
  [UtilityField.Name]: 'Название',
  [UtilityField.Phone]: 'Телефон поставщика',
  [UtilityField.OwnerPhone]: 'Телефон директора',
  [UtilityField.Address]: 'Адрес приемной',
  [UtilityField.Website]: 'Сайт',
};

export const utilityFields: UtilityField[] = [
  UtilityField.Name,
  UtilityField.Phone,
  UtilityField.OwnerPhone,
  UtilityField.Address,
  UtilityField.Website,
];
