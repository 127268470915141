import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { CommentId } from '../../../../../../../models/comments/comments';
import { selectCommentById } from '../../../../../../../../store/comments/comments.selectors';
import { Container, Header } from './styled';
import { DateFormat, localFormat } from '../../../../../../../helpers/date/time';
import { GreyText } from '../../../styled';

type CommentItemProps = {
  commentId: CommentId;
};

export const CommentItem = React.memo<CommentItemProps>(props => {
  // const dispatch = useDispatch();
  const { text, disp, createdAt } = useSelector(selectCommentById(props.commentId), shallowEqual) ?? {};
  // const textAreaRef = React.useRef<HTMLTextAreaElement>(null);

  // const [comment, setComment] = React.useState<string | undefined>(text);

  // const onDelete = React.useCallback(() => {
  //   dispatch(DeleteComment.init({ id: props.commentId }));
  // }, [dispatch, props.commentId]);
  //
  // const onEdit = React.useCallback(() => {
  //   if (!comment) return;
  //   dispatch(EditComment.init({ id: props.commentId, text: comment }));
  // }, [comment, dispatch, props.commentId]);

  // const handleTextArea = React.useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
  //   setComment(e.target.value);
  // }, []);

  // React.useEffect(() => {
  //   if (textAreaRef && textAreaRef.current) {
  //     textAreaRef.current.style.height = '0px';
  //     const { scrollHeight } = textAreaRef.current;
  //     textAreaRef.current.style.height = `${scrollHeight}px`;
  //   }
  // }, []);
  return (
    <Container spacing="8px">
      <Header spacing="16px">
        <GreyText>
          {disp} <span>{localFormat(new Date(createdAt ?? new Date()), DateFormat.LocalDateAndTime)}</span>
        </GreyText>
      </Header>

      <p>{text}</p>

      {/* <Text ref={textAreaRef} value={comment} onChange={e => handleTextArea(e)} /> */}

      {/* <Footer spacing="16px"> */}
      {/*  /!* <ActionButton onClick={onEdit}>Изменить</ActionButton> *!/ */}
      {/*  /!* <ActionButton onClick={onDelete}>Удалить</ActionButton> *!/ */}
      {/* </Footer> */}
    </Container>
  );
});
