import React from 'react';
import { Navigate } from 'react-router-dom';

import { TokenService } from 'app/services/token-service';
import { RoutePath } from 'app/constants/route-path';

interface GuardedRouteProps {
  children: JSX.Element;
}

export const GuardedRoute = ({ children }: GuardedRouteProps) =>
  TokenService.refreshToken ? children : <Navigate to={RoutePath.Login} />;
