import { useEffect } from 'react';
import { AnyAction } from '@reduxjs/toolkit';
import { ActionListenerService } from '../../services/action-listener-service/action-listener.service';

export function useActionListener<T extends AnyAction>(listeners: (action: T) => void) {
  useEffect(() => {
    ActionListenerService.registerCallback(listeners);
    return () => ActionListenerService.unregisterCallback(listeners);
  });
}
