import React, { ChangeEvent, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { PagesRoutes, RouteConstants, RoutePath } from 'app/constants/route-path';
import { useAction } from 'app/helpers/actions/use-action';
import { CallStatus } from 'store/webphone/models/call-status.enum';
import { webphoneActions } from 'store/webphone/webphone.actions';
import { webPhoneSelectors } from 'store/webphone/webphone.selectors';
import * as ConfigurationSelectors from 'store/configuration/configuration.selectors';
import { formatPhoneNumber } from 'app/helpers/phone';
import { addSeconds } from 'date-fns';
import { NotificationsActions } from 'store/notifications';
import { generatePath } from 'react-router-dom';
import { DateFormat } from '../../helpers/date/time';
import {
  Buttons,
  CircleButton,
  Comment,
  ExtendedCallInfo,
  HangupIcon,
  Header,
  InfoText,
  Participant,
  CustomPhoneNumberPad,
  PlacementInfo,
  PlusIcon,
  Timer,
  TimerRed,
  TimersContainer,
  WebPhoneContainer,
  WhiteBackgroundIcon,
  ParticipantLink,
} from './styled';
import { NotificationType } from '../../models/notifications/notification';

export interface WebPhoneProps {
  isShowPlusButton?: boolean;
}

export const WebPhone = ({ isShowPlusButton = true }: WebPhoneProps) => {
  const [isDialpadOpen, setIsDialpadOpen] = useState<boolean>(false);

  const callStatus = useSelector(webPhoneSelectors.selectCallStatus);
  const participant = useSelector(webPhoneSelectors.selectParticipant);
  const participantPhone = useSelector(webPhoneSelectors.selectParticipantPhone);
  const callStartDate = useSelector(webPhoneSelectors.selectCallStartDate);
  const membersQueueCount = useSelector(webPhoneSelectors.selectMembersQueueCount);
  const isCallDurationLimitExceeded = useSelector(webPhoneSelectors.selectIsCallDurationLimitExceeded);
  const supplier = useSelector(webPhoneSelectors.selectParticipantSupplier);
  const callsDuration = useSelector(ConfigurationSelectors.selectCallsDuration);

  const hangupCall = useAction(webphoneActions.hangup);
  const transferCall = useAction(webphoneActions.transferCall);
  const addNotification = useAction(NotificationsActions.AddNotification.init);

  const changeDispatcherComment = useAction(webphoneActions.changeDispatcherComment);

  const handleCommentChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    changeDispatcherComment(event.target.value);
  };

  const onTransferButtonClick = (phone: string) => {
    addNotification({ body: `Звонок был переведён на номер ${phone}`, type: NotificationType.Info });
    transferCall({ phone });
  };

  const padRef = useRef<HTMLDivElement>(null);

  if (!(participant || participantPhone)) return null;

  return (
    <WebPhoneContainer>
      <Header spacing="5px">
        <Participant>
          {participant?.consumerId ? (
            <ParticipantLink
              to={generatePath(PagesRoutes.PAGES.CONSUMER, {
                consumerId: String(participant?.consumerId),
              })}
            >
              {participant?.fullName}
            </ParticipantLink>
          ) : (
            formatPhoneNumber(participantPhone)
          )}
        </Participant>

        {supplier && (
          <PlacementInfo spacing="5px">
            <span>{supplier.name}</span>
          </PlacementInfo>
        )}
      </Header>

      <ExtendedCallInfo spacing="10px">
        {callStatus === CallStatus.InTalk && callStartDate && (
          <TimersContainer spacing="10px">
            {isCallDurationLimitExceeded && (
              <TimerRed prefix="-" date={new Date(addSeconds(callStartDate, callsDuration))} format={DateFormat.mmss} />
            )}
            <Timer date={new Date(callStartDate)} format={DateFormat.mmss} small={isCallDurationLimitExceeded} />
          </TimersContainer>
        )}

        {membersQueueCount >= 0 && <InfoText>{`Ожидают ответа: ${membersQueueCount}`}</InfoText>}
      </ExtendedCallInfo>

      <Buttons spacing="16px">
        <CircleButton color="red" title="Сбросить" onClick={hangupCall}>
          <HangupIcon icon="/assets/icons.svg#phone down" />
        </CircleButton>
        <div ref={padRef}>
          <WhiteBackgroundIcon
            onClick={() => setIsDialpadOpen(prevState => !prevState)}
            tooltip="Перевести звонок"
            icon="/assets/icons.svg#share"
          />
        </div>
        <WhiteBackgroundIcon tooltip="Ссылка" icon="/assets/icons.svg#link" />
        {isShowPlusButton && (
          <CircleButton color="blue" title="Добавить заявку">
            <PlusIcon navigateLink={`${RoutePath.Appeals}/${RouteConstants.NewEntity}`} icon="/assets/icons.svg#add" />
          </CircleButton>
        )}
      </Buttons>

      <Comment placeholder="Введите комментарий к звонку" onChange={handleCommentChange} />

      {isDialpadOpen && <CustomPhoneNumberPad onCallButtonClick={onTransferButtonClick} />}
    </WebPhoneContainer>
  );
};
