import { GetCommentsApiParams } from './dtos/get-comments-dto';
import { TableDto } from '../models/table.dto';
import { Comment } from '../../models/comments/comments';
import { HttpService } from '../http.service';
import { CreateCommentApiParams } from './dtos/create-comment-dto';
import { EditCommentApiParams } from './dtos/edit-comment-dto';
import { DeleteCommentApiParams, DeleteCommentApiResponse } from './dtos/delete-comment-dto';

class CommentsServiceImpl {
  private static apiEndpoint = '/api/comments';

  async getComments(params: GetCommentsApiParams): Promise<TableDto<Comment>> {
    const res = await HttpService.get<TableDto<Comment>>(`${CommentsServiceImpl.apiEndpoint}/${params.entity}/${params.id}`, {
      params: {
        per_page: params.per_page,
        page: params.page,
      },
    });

    return res.data;
  }

  async createComment(params: CreateCommentApiParams): Promise<Comment> {
    const res = await HttpService.post<Comment>(`${CommentsServiceImpl.apiEndpoint}/${params.entity}/${params.id}`, {
      text: params.text,
    });

    return res.data;
  }

  async editComment(params: EditCommentApiParams): Promise<Comment> {
    const res = await HttpService.put<Comment>(`${CommentsServiceImpl.apiEndpoint}/${params.id}`, {
      text: params.text,
    });

    return res.data;
  }

  async deleteComment(params: DeleteCommentApiParams): Promise<DeleteCommentApiResponse> {
    const res = await HttpService.delete<DeleteCommentApiResponse>(`${CommentsServiceImpl.apiEndpoint}/${params.id}`);

    return res.data;
  }
}

export const CommentsService = new CommentsServiceImpl();
