import { NotificationsActions, NotificationsSelectors } from 'store/notifications';
import { shallowEqual, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Notification, NotificationType } from 'app/models/notifications/notification';
import { TextRegular } from 'app/typography/text';
import { useActionListener } from 'app/helpers/actions/action-listener.hook';
import { CloseIcon, ErrorAlertContainer, ErrorClose, NotificationContainer, NotificationsLayout } from './styled';

const PUSH_NOTIFICATIONS_VISIBLE_DELAY_MS = 5000;
const INITIAL_NOTIFICATION_LAST_PUSH_INDEX = 0;

export const PushNotifications = () => {
  const notifications = useSelector(NotificationsSelectors.selectAllNotifications, shallowEqual);

  const [pushes, setPushes] = useState<Notification[]>([]);
  const [lastPushIndex, setLastPushIndex] = useState<number>(INITIAL_NOTIFICATION_LAST_PUSH_INDEX);

  useEffect(() => {
    if (lastPushIndex >= INITIAL_NOTIFICATION_LAST_PUSH_INDEX) {
      const newPushes = notifications.filter((_, index) => index >= lastPushIndex);
      setPushes(prevState => prevState.concat(newPushes));
      setLastPushIndex(prevState => prevState + newPushes.length);
      setTimeout(() => {
        setPushes(prevState => prevState.filter(push => newPushes.some(newPush => newPush.id !== push.id)));
      }, PUSH_NOTIFICATIONS_VISIBLE_DELAY_MS);
    } else if (notifications.length) setLastPushIndex(notifications.length);
  }, [notifications]);

  useActionListener(action => {
    if (action.type === NotificationsActions.GetNotifications.failure.type) {
      setLastPushIndex(INITIAL_NOTIFICATION_LAST_PUSH_INDEX + 1);
    }
  });

  const handleCloseNotificationClick = id => {
    setPushes(prevState => prevState.filter(push => push.id !== id));
  };

  return (
    <NotificationsLayout>
      {pushes.map(push =>
        push.type !== NotificationType.Error ? (
          <NotificationContainer key={push.id}>
            <TextRegular>
              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: push && push.body ? push.body : '' }} />
            </TextRegular>
            <CloseIcon onClick={() => handleCloseNotificationClick(push.id)} icon="/assets/icons.svg#close" />
          </NotificationContainer>
        ) : (
          <ErrorAlertContainer key={push.id}>
            <TextRegular>
              <div>{push.body}</div>
            </TextRegular>
            <ErrorClose onClick={() => handleCloseNotificationClick(push.id)} icon="/assets/icons.svg#close" />
          </ErrorAlertContainer>
        )
      )}
    </NotificationsLayout>
  );
};
