import { all, call, put, takeLatest } from 'redux-saga/effects';

import { Stat } from 'app/models/stat';
import { StatsService } from 'app/services/stats-service';
import { StatActions } from '.';

function* loadStats() {
  try {
    const stats: Stat[] = yield call(StatsService.loadStats);

    yield put(StatActions.LoadStats.success(stats));
  } catch (error) {
    yield put(StatActions.LoadStats.failure(error));
  }
}

export default function* watcher() {
  yield all([takeLatest(StatActions.LoadStats.init, loadStats)]);
}
