import { shallowEqual, useSelector } from 'react-redux';
import { CenteredFlexWithSpacing, ColumnFlexWithPadding, FlexWithSpacing } from 'app/typography/flex';
import { GreyText, Header, TextRegularBig } from 'app/typography/text';
import { getAppealsActionsOptions } from 'app/helpers/appeals';
import * as AppealsSelectors from 'store/appeals/appeals.selectors';
import { selectSelectedAppealSupplierId } from 'store/appeals/appeals.selectors';
import * as ConfigurationSelectors from 'store/configuration/configuration.selectors';
import { Menu } from 'app/components/shared';
import { OptionMenu } from 'app/models/common';
import { useAppealActions } from 'app/helpers/hooks/use-appeal-actions';
import { APPEAL_STATUS } from 'app/constants/appeals';
import React from 'react';
import { statusColor, statusIcon } from '../../../Appeals/components/AppealStatusTitle';
import {
  AppealInfoHeaderComponent,
  Container,
  DateCard,
  Dates,
  Difference,
  DocumentIcon,
  PdfWatchButton,
  StatusIcon,
  StyledClickableIcon,
  TextRow,
  Theme,
} from './styled';
import { AppealWorkEvaluation } from '../AppealWorkEvaluation';
import { AppealId } from '../../../../models/appeal';
import { TabsPanel } from '../../../../components/TabsPanel/TabsPanel';
import { ModalService } from '../../../../services/modal-service/modal.service';
import { PdwViewerDialog } from '../PdfViewer/PdfViewer';
import { selectSupplierBySupplierId } from '../../../../../store/suppliers/suppliers.selectors';
import { environment } from '../../../../environment';
import { appealDatesColorsMap, AppealDatesField, appealDatesFields, appealDatesTitleMap } from '../../constants/appeal-dates.enum';
import { calculateTimeDifference, findNextAvailableDate, formatDateHelper } from './helpers';
import { SourceDict } from '../../constants/sources';

type DateCardItemProps = {
  title: JSX.Element;
  date: string;
  isGray: boolean;
  createdAt: string;
  isAlwaysFull: boolean;
  borderColor: string;
  nextDate?: string;
  responsible: string;
};
export const DateCardItem = React.memo<DateCardItemProps>(props => {
  return (
    <ColumnFlexWithPadding spacing="4px">
      <DateCard isAlwaysFull={props.isAlwaysFull} isGray={props.isGray} borderColor={props.borderColor}>
        {props.title}

        <GreyText>{formatDateHelper(props.date, props.createdAt, props.isAlwaysFull)}</GreyText>
        <br />
        <GreyText>{props.responsible}</GreyText>
      </DateCard>
      {props.nextDate && <Difference isGray={props.isGray}>{calculateTimeDifference(props.date, props.nextDate)}</Difference>}
    </ColumnFlexWithPadding>
  );
});

type AppealInfoHeaderProps = {
  appealId: AppealId;
  activeTabIndex: number;
  onActiveTabChange: (index: number) => void;
};
export const AppealInfoHeader = React.memo<AppealInfoHeaderProps>(props => {
  const appeal = useSelector(AppealsSelectors.selectSelectedAppeal, shallowEqual);
  const appealStatusLabel = useSelector(AppealsSelectors.selectAppealStatusLabelById(appeal?.status || APPEAL_STATUS.new), shallowEqual);
  const appealsTypes = useSelector(ConfigurationSelectors.selectAppealTypes, shallowEqual);
  const actions = useSelector(ConfigurationSelectors.selectAppealsActions, shallowEqual);
  const appealIsPayment = useSelector(AppealsSelectors.selectSelectedAppealIsPayment, shallowEqual);
  const supplierId = useSelector(selectSelectedAppealSupplierId, shallowEqual);
  const supplier = useSelector(selectSupplierBySupplierId(supplierId), shallowEqual);

  const { dispatchAction } = useAppealActions(appeal);

  const appealActionsOptions: OptionMenu[] = React.useMemo(() => {
    if (!appeal) return [];
    return getAppealsActionsOptions(appeal.type, appeal.status, appeal.consumer, actions)
      ?.filter(option => Boolean(option.label))
      .map(option => ({
        label: option.label,
        callback: () => dispatchAction(option.value),
      }));
  }, [actions, appeal, dispatchAction]);

  const onPdfButtonClickHandler = React.useCallback(() => {
    if (!supplier?.reg_file) return;
    const link = environment.apiBaseUrl + supplier?.reg_file;
    ModalService.openModal(PdwViewerDialog, { link, onClose: () => {} });
  }, [supplier?.reg_file]);

  const tabsList = React.useMemo(
    () => [
      { title: 'Общая информация', index: 0 },
      appealIsPayment ? { title: 'Оплата', index: 1 } : null,
      { title: 'История изменений', index: 2 },
      { title: 'История звонков', index: 3 },
      { title: 'Файлы', index: 4 },
    ],
    [appealIsPayment]
  );

  const header = React.useMemo(() => {
    const label = appealsTypes.find(type => type.id === appeal?.type)?.label;

    return `${label ?? ''} №${appeal?.number ?? 0}`;
  }, [appeal?.number, appeal?.type, appealsTypes]);

  const dates = React.useMemo(() => {
    return (
      <Dates>
        {appealDatesFields?.map((field, index) => {
          if (!appeal) return undefined;
          const date = appeal[field];
          if (!date) return undefined;

          const { title: sourceTitle, icon } = SourceDict[appeal?.source];

          const title =
            field === AppealDatesField.CreatedAt ? (
              <FlexWithSpacing spacing="10px">
                {sourceTitle} {icon}
              </FlexWithSpacing>
            ) : (
              <p>{appealDatesTitleMap[field]}</p>
            );

          const responsibleText = appeal.source === 'manual' || appeal.source === 'phone' ? appeal?.dispatcher?.fullName : 'Автоматически';

          const responsible = field === AppealDatesField.CreatedAt ? responsibleText : '';

          const nextDate = findNextAvailableDate(appeal, index);
          const isGray = (nextDate && index < appealDatesFields.length - 1) || false;

          const isAlwaysFull = field === AppealDatesField.CreatedAt || field === AppealDatesField.ClosedAt;

          const borderColor = appealDatesColorsMap[field];

          return (
            <DateCardItem
              key={field}
              title={title}
              date={date}
              isGray={isGray}
              createdAt={appeal?.createdAt}
              isAlwaysFull={isAlwaysFull}
              borderColor={borderColor}
              nextDate={nextDate}
              responsible={responsible}
            />
          );
        })}
      </Dates>
    );
  }, [appeal]);

  return (
    <Container>
      <AppealInfoHeaderComponent>
        <FlexWithSpacing spacing="30px">
          <FlexWithSpacing spacing="10px">
            <ColumnFlexWithPadding spacing="8px">
              <TextRow spacing="16px ">
                <Header>{header}</Header>
                <TextRow spacing="5px">
                  <StatusIcon color={statusColor[appeal?.status || 0]} icon={statusIcon[appeal?.status || 0]} />
                  <TextRegularBig>{appealStatusLabel}</TextRegularBig>
                </TextRow>
              </TextRow>

              {!!appeal?.subject && (
                <p>
                  <GreyText>Тема:</GreyText>&nbsp;&nbsp;
                  <Theme>{appeal?.subject}</Theme>
                </p>
              )}
            </ColumnFlexWithPadding>
          </FlexWithSpacing>
          {appeal?.status === APPEAL_STATUS.closed && <AppealWorkEvaluation workEvaluation={appeal?.workEvaluation} />}
        </FlexWithSpacing>

        <CenteredFlexWithSpacing spacing="15px">
          {supplier?.reg_file && (
            <PdfWatchButton onClick={onPdfButtonClickHandler}>
              <DocumentIcon />
            </PdfWatchButton>
          )}

          {!!appealActionsOptions.length && (
            <Menu options={appealActionsOptions}>
              <StyledClickableIcon icon="/assets/icons.svg#menu" />
            </Menu>
          )}
        </CenteredFlexWithSpacing>
      </AppealInfoHeaderComponent>

      {dates}

      <TabsPanel activeTabIndex={props.activeTabIndex} onTabIndexChange={props.onActiveTabChange} tabsList={tabsList} />
    </Container>
  );
});

AppealInfoHeader.displayName = 'AppealInfoHeader';
