import React from 'react';
import styled from 'styled-components';

export const FormFieldError = ({ error }: { error: string }) => (
  <FormFieldErrorComponent>{error}</FormFieldErrorComponent>
);

const FormFieldErrorComponent = styled.div`
  font-size: 0.75rem;
  line-height: 0.875em;
`;
