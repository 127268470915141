import React, { ReactNode, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { EventEmitter } from 'events';
import { dialogElementRef } from 'app/helpers/modal-ref/modal-ref';
import ScrollLockService from './scroll-lock.service';

class LayoutModalServiceImpl {

  constructor() {
    dialogElementRef.then(el => {
      this.rootContainer = el;
    });
  }

  public rootContainer: HTMLElement | undefined = undefined;
  public modal: ReactNode;
  public onChange = new EventEmitter();

  public setModal(element: ReactNode) {
    this.modal = element;
    this.onChange.emit('');
    ScrollLockService.push();
  }

  public clearModal() {
    this.modal = undefined;
    this.onChange.emit('');
    ScrollLockService.pop();
  }

}

const ModalService = new LayoutModalServiceImpl();

export function ModalLayer() {
  const service = ModalService;
  const [, setContainer] = useState(service.rootContainer);
  const [, setModal] = useState(service.modal);

  useEffect(() => {
    const handler = () => {
      setContainer(service.rootContainer);
      setModal(service.modal);
    };

    service.onChange.addListener('', handler);

    return () => {
      service.onChange.removeListener('', handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // @ts-ignore
  return <ModalLayerInternal rootContainer={service.rootContainer} modal={service.modal} />;
}

interface ModalLayerInternalProps {
  modal: ReactNode;
  rootContainer: HTMLElement | undefined;
}

function ModalLayerInternal({ modal, rootContainer }: ModalLayerInternalProps): JSX.Element {
  function getLayout() {
    return <div className="backdrop">{modal}</div>;
  }

  return <>{rootContainer && modal && ReactDOM.createPortal(getLayout(), rootContainer)}</>;
}

export function ModalLayerItem(props: { children: ReactNode }): JSX.Element {
  const service = ModalService;

  useEffect(() => {
    service.setModal(props.children);
    return () => service.clearModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
}
