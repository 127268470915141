import { AnyAction } from '@reduxjs/toolkit';
import { exclude } from '../../helpers/arrays';

type ActionListener<T> = (action: T) => void;

class ActionListenerServiceImpl {
  private listeners: ActionListener<any>[] = [];

  public tick(action: AnyAction) {
    for (const listener of this.listeners) {
      listener(action);
    }
  }

  public registerCallback(listener: ActionListener<any>) {
    this.listeners.push(listener);
  }

  public unregisterCallback(listener: ActionListener<any>) {
    this.listeners = exclude(this.listeners, listener);
  }
}

export const ActionListenerService = new ActionListenerServiceImpl();
