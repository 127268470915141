import { PayloadAction } from '@reduxjs/toolkit';
import history from 'app/history';
import { TokenService } from 'app/services/token-service';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ClearNotifications } from 'store/notifications/notifications.actions';
import { PagesRoutes } from '../../app/constants/route-path';
import { Profile } from '../../app/models/profile/profile';
import { ProfileService } from '../../app/services/profile-service/profile.service';
import { LoginForm } from './models/login-form.model';
import { LoginSuccessPayload } from './models/login-success-payload';
import * as ProfileActions from './profile.actions';
import { webphoneActions } from '../webphone';
import { GetProfilesBySupplierIdApiParams } from '../../app/services/profile-service/dtos/get-profiles-by-supplier-id';

function* loginWatcher({ payload }: PayloadAction<LoginForm>) {
  try {
    const loginResponse: LoginSuccessPayload = yield call(ProfileService.login, payload);
    if (loginResponse.tokenData && loginResponse.profile) {
      TokenService.refreshToken = loginResponse.tokenData.refreshToken;
      history.push(PagesRoutes.PAGES.APPEALS);
    } else {
      throw new Error('Token retrieval failed.');
    }
    yield put(ProfileActions.Login.success(loginResponse.profile));
  } catch (e: any) {
    yield put(ProfileActions.Login.failure(e));
  }
}

function* logoutWatcher() {
  try {
    yield call(ProfileService.logout);
    TokenService.token = undefined;
    TokenService.refreshToken = undefined;

    history.push(PagesRoutes.COMMON.LOGIN);
    yield put(ProfileActions.Logout.success());
    yield put(webphoneActions.unregister());
    yield put(ClearNotifications());
  } catch (e: any) {
    yield put(ProfileActions.Logout.failure(e));
  }
}

function* loadProfileWatcher() {
  try {
    const response: Profile = yield call(ProfileService.getProfile);

    if (!response) {
      throw new Error('Could not load your profile');
    }

    yield put(ProfileActions.LoadProfile.success(response));
  } catch (e: any) {
    yield put(ProfileActions.LoadProfile.failure(e));
  }
}
function* getProfilesBySupplierIdWatcher({ payload }: PayloadAction<GetProfilesBySupplierIdApiParams>) {
  try {
    const response = yield call(ProfileService.getProfilesBySupplierId, payload);

    yield response && put(ProfileActions.GetProfilesBySupplier.success(response));
  } catch (e: any) {
    yield put(ProfileActions.GetProfilesBySupplier.failure(e));
  }
}

export default function* watcher() {
  yield all([
    takeLatest(ProfileActions.Login.init, loginWatcher),
    takeLatest(ProfileActions.Logout.init, logoutWatcher),
    takeLatest(ProfileActions.LoadProfile.init, loadProfileWatcher),
    takeLatest(ProfileActions.GetProfilesBySupplier.init, getProfilesBySupplierIdWatcher),
  ]);
}
