import { createSlice } from '@reduxjs/toolkit';

import { Stat } from 'app/models/stat';
import * as StatActions from './stat.actions';
import { STAT_STORE_NAME } from './stat.actions';
import { EntityState } from '../models/entity-state';
import { initialEntityState } from '../constants/initial-entity-state';

export interface StatState extends EntityState {
  stats: Stat[];
  error: any;
}

export const statInitialState: StatState = {
  stats: [],
  error: null,
  ...initialEntityState,
};

const statSlice = createSlice({
  name: STAT_STORE_NAME,
  initialState: statInitialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(StatActions.LoadStats.init, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(StatActions.LoadStats.failure, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(StatActions.LoadStats.success, (state, { payload }) => {
        state.loading = false;
        state.stats = payload;
      }),
});

export const statReducer = statSlice.reducer;
