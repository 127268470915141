import { Column } from 'react-table';
import { generatePath } from 'react-router-dom';
import React from 'react';
import { AppealChangesHistory } from '../../../../models/appeal';
import { localFormat } from '../../../../helpers/date/time';
import { PagesRoutes } from '../../../../constants/route-path';
import { Link } from '../../styled';
import { List } from './styled';

const isValidDateString = (dateString: string) => {
  const timestamp = Date.parse(dateString);
  const isOnlyDigits = /^\d+$/.test(dateString);
  const isValidDateFormat = /^\d{4}-\d{2}-\d{2}/.test(dateString); // Проверка формата yyyy-MM-dd

  return !isNaN(timestamp) && !isOnlyDigits && isValidDateFormat;
};

const getValue = (value: string) => {
  return isValidDateString(value) ? localFormat(new Date(value), 'dd.MM.yyyy HH:mm') : value;
};

export const columns: Column<AppealChangesHistory>[] = [
  {
    Header: 'Дата',
    id: 'createdAt',
    accessor: 'createdAt',
    Cell: cellProps => {
      const { createdAt } = cellProps.row.original;
      return localFormat(new Date(createdAt), 'dd.MM.yyyy HH:mm');
    },
  },
  {
    Header: 'Автор',
    id: 'changedBy',
    accessor: 'changedBy',
    Cell: cellProps => {
      const author = cellProps.row.original.changedBy;
      const isChangeByConsumer = cellProps.row.original.isChangedByConsumer;

      const to = generatePath(PagesRoutes.PAGES.CONSUMER, {
        consumerId: author?.id,
      });

      if (!author.name) return '-';

      return isChangeByConsumer ? <Link to={to}>{author?.name}</Link> : <p>{author?.name}</p>;
    },
  },

  {
    Header: 'Изменения',
    id: 'changes',
    accessor: 'changes',
    Cell: cellProps => {
      const { changes } = cellProps.row.original;

      if (!changes.length) return 'Создано';

      return (
        <List>
          {changes?.map(change => {
            const oldValue = getValue(change.oldValue);
            const newValue = getValue(change.newValue);

            return (
              <li key={change.id}>
                <b>{change.field}</b> : {oldValue} → {newValue}
              </li>
            );
          })}
        </List>
      );
    },
  },
];
