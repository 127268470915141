import { SpeechNotification } from 'app/models/speech-notification';
import {
  GetSpeechNotificationApiParams,
  UpdateSpeechNotificationApiParams,
  UpdateSpeechNotificationsApiParams,
} from 'app/services/speech-notification-service';
import { getCreateActionTrio } from '../helpers/action-create';
import { TableDto } from '../../app/services/models/table.dto';
import { createPayloadAction } from '../configuration/configuration.actions';

export const SPEECH_NOTIFICATION_STORE_NAME = 'speech-notification';

const createActionTrio = getCreateActionTrio(SPEECH_NOTIFICATION_STORE_NAME);

export const GetSpeechNotifications = createActionTrio<GetSpeechNotificationApiParams, TableDto<SpeechNotification>>(
  'get speech notifications list'
);

export const UpdateSpeechNotification = createActionTrio<UpdateSpeechNotificationApiParams, SpeechNotification>(
  'update speech notification'
);

export const UpdateSpeechNotifications = createActionTrio<UpdateSpeechNotificationsApiParams, void>('update speech notifications');

export const SetSpeechNotificationsStatus = createPayloadAction<number | undefined>('set speech notifications status');
