import styled from 'styled-components';
import { Colors } from '../../../themes/colors';

interface PreloaderProps {
  className?: string;
}

export const Preloader = ({ className }: PreloaderProps) => (
  <StyledPreloader className={className} />
);

const StyledPreloader = styled.div`
  display: inline-block;
  width: inherit;
  height: inherit;

  :after {
    content: ' ';
    display: block;
    width: inherit;
    height: inherit;
    border-radius: 50%;
    border: 6px solid ${Colors.Grey700};
    border-color: ${Colors.Grey700} transparent ${Colors.Grey700} transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
