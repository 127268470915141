import { createSlice } from '@reduxjs/toolkit';

import { SpeechNotification } from 'app/models/speech-notification';
import { EntityState } from '../models/entity-state';
import { initialEntityState } from '../constants/initial-entity-state';
import {
  GetSpeechNotifications,
  SetSpeechNotificationsStatus,
  SPEECH_NOTIFICATION_STORE_NAME,
  UpdateSpeechNotification,
} from './speech-notification.actions';
import { replaceOnce } from '../../app/helpers/arrays';
import { GetSpeechNotificationApiParams } from '../../app/services/speech-notification-service';

export interface SpeechNotificationState extends EntityState {
  speechNotifications: Array<SpeechNotification>;
  totalCount: number;
  query?: GetSpeechNotificationApiParams;
  error: any;
}

export const speechNotificationInitialState: SpeechNotificationState = {
  speechNotifications: [],
  totalCount: 0,
  query: undefined,
  error: null,
  ...initialEntityState,
};

const speechNotificationSlice = createSlice({
  name: SPEECH_NOTIFICATION_STORE_NAME,
  initialState: speechNotificationInitialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(GetSpeechNotifications.init, (state, { payload }) => {
        state.loading = true;
        state.query = payload || speechNotificationInitialState.query;
      })
      .addCase(GetSpeechNotifications.success, (state, { payload }) => {
        state.loading = false;
        state.speechNotifications = payload.results;
        state.totalCount = payload.count;
      })
      .addCase(GetSpeechNotifications.failure, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(UpdateSpeechNotification.init, state => {
        state.updating = true;
      })
      .addCase(UpdateSpeechNotification.success, (state, { payload }) => {
        state.updating = false;
        state.speechNotifications = replaceOnce(state.speechNotifications, ({ id }) => id === payload.id, payload);
      })
      .addCase(UpdateSpeechNotification.failure, (state, { payload }) => {
        state.updating = false;
        state.error = payload;
      })
      .addCase(SetSpeechNotificationsStatus, (state, { payload }) => {
        state.query = { ...state.query, active: payload };
      }),
});

export const speechNotificationReducer = speechNotificationSlice.reducer;
