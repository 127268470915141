import styled from 'styled-components';
import { Button, Dialog } from '../../shared';
import { HeaderSmall } from '../../../typography/text';

export const AccessDeniedDialog = styled(Dialog)`
  width: 600px;
  height: 340px;

  .bodyClassName {
    gap: 20px;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
`;

export const CloseButton = styled(Button)`
  width: 100px;
  min-height: 46px;
  margin: 10px auto;
`;

export const Container = styled.div`
  flex-direction: column;
  align-items: center;
`;

export const ErrorText = styled(HeaderSmall)`
  white-space: pre-wrap;
`;
