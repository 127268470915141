import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { TableFilterDirection, TableFilterPlace, ChosenFilters } from '../../../models/common';
import { FilterKey, TableFilter } from '../../../models/configuration';
import { CenteredFlexWithSpacing, ColumnFlexWithPadding } from '../../../typography/flex';
import { CommonFilter } from '../CommonFilter';
import { ChangeChosenFilterPayload } from '../../../../store/models/change-chosen-filter-payload';

interface DynamicsFiltersProps {
  filters: TableFilter[];
  className?: string;
  spacing?: string;
  direction?: TableFilterDirection;
  filterPlace: TableFilterPlace;
  onFilterChange?: () => void;
  changeFilterAction: ActionCreatorWithPayload<ChangeChosenFilterPayload>;
  chosenFilters: ChosenFilters;
  noError?: boolean;
}
export const DynamicsFilters = (props: DynamicsFiltersProps) => {
  const { direction, className, spacing, filters, changeFilterAction, chosenFilters, noError } = props;

  const dispatch = useDispatch();

  const filterChange = React.useCallback(
    (key, event) => {
      let value = event?.target ? event.target.value : event;

      if (key === FilterKey.Phone) {
        value = value.replace(/\s+/g, '').replace(/\+/g, '');
      }

      dispatch(
        changeFilterAction({
          key,
          value,
          isSideFilter: props.filterPlace === TableFilterPlace.Side,
        })
      );
      props.onFilterChange?.();
    },
    [changeFilterAction, dispatch, props]
  );

  const FiltersContainer: any = direction && direction === TableFilterDirection.Column ? ColumnContainer : RowContainer;

  return (
    <FiltersContainer className={className} spacing={spacing}>
      {filters?.map(filter => (
        <CommonFilter
          key={filter.key}
          filterType={filter.filterType}
          filterProps={filter}
          value={chosenFilters[filter.key]}
          onChange={e => filterChange(filter.key, e)}
          filterPlace={props.filterPlace}
          noError={noError}
        />
      ))}
    </FiltersContainer>
  );
};

const ColumnContainer = styled(ColumnFlexWithPadding)`
  justify-content: flex-start;
`;

const RowContainer = styled(CenteredFlexWithSpacing)`
  width: 100%;
  align-items: start;

  input {
    padding: 12px;
  }
`;
