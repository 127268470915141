import React from 'react';
import styled from 'styled-components';
import { Icon, Scrollbar } from 'app/components/shared';
import { Colors } from 'app/themes/colors';
import { AddressesData } from './addresses-list-type';

interface AddressesListProps {
  addresses: AddressesData[];
  onDeleteClick: (index: number) => void;
  readOnly?: boolean;
}

export const AddressesList = ({ addresses, onDeleteClick, readOnly }: AddressesListProps) => {
  const handleDelete = (index: number) => {
    if (!readOnly) {
      onDeleteClick(index);
    }
  };

  return (
    <StyledScrollbar autoHeight>
      <AddressesContainer>
        {addresses.map((address, index) => (
          <Address key={index}>
            <span>{address.address}</span>
            <Icon icon="/assets/icons.svg#close" onClick={() => handleDelete(index)} />
          </Address>
        ))}
      </AddressesContainer>
    </StyledScrollbar>
  );
};

const StyledScrollbar = styled(Scrollbar)`
  margin: 5px auto 0;

  & > .view {
    padding-right: 11px;
  }
`;

const AddressesContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
`;

const Address = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: ${Colors.Blue600};
  padding: 5px;
  font-size: 14px;
  line-height: 1rem;
  color: ${Colors.White};
  border-radius: 4px;

  & > svg {
    width: 15px;
    height: 15px;
    fill: ${Colors.White} !important;
    cursor: pointer;
  }
`;
