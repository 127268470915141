import { Button } from 'app/components/shared';
import { Colors } from 'app/themes/colors';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  width: 120px;
  height: 46px;
`;

export const Link = styled.a`
  color: ${Colors.Blue800};
  font-weight: bold;
  text-decoration: none;
`;
