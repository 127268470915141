import { AddressesData } from '../../../../../Mailings/components/AddressesList';

export const incidentsOptionsTypes = [
  { key: 'accident', label: 'Aвария', value: 'accident' },
  { key: 'scheduled_work', label: 'Плановые работы', value: 'scheduled_work' },
];

export type IncidentFormInitialValues = {
  validateExtraFields: boolean;
  incidentType: 'accident' | 'scheduled_work';
  appealKindId: string;
  text: string;
  supplierId: string;
  responsibleId: string;
  masterId: string;
  date: string;
  time: string;
  addresses: Array<AddressesData>;
};
