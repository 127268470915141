export enum WeekDay {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 7,
}

export const WeekDayShortName = {
  [WeekDay.Monday]: 'пн',
  [WeekDay.Tuesday]: 'вт',
  [WeekDay.Wednesday]: 'ср',
  [WeekDay.Thursday]: 'чт',
  [WeekDay.Friday]: 'пт',
  [WeekDay.Saturday]: 'сб',
  [WeekDay.Sunday]: 'вс',
};
