import { CallScriptContainer, Heading, Paragraph } from './styled';

export const CallScript = () => (
  <CallScriptContainer>
    <Heading>Скрипт звонка</Heading>
    <Paragraph>
      1. Приветствие «Добрый день, “ФИО”. Меня зовут “свое имя”. Чем могу помочь?»
    </Paragraph>
    <Paragraph>2. Выяснение потребностей «Расскажите подробнее о проблеме»</Paragraph>
    <Paragraph>
      3. Подведение итогов «Тогда я сейчас подведу итоги, чтобы ничего не упустить. Итак, у вас
      “Текст обращения”. Спасибо за обращение. Хорошего дня!»
    </Paragraph>
  </CallScriptContainer>
);
