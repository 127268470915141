import { createSlice } from '@reduxjs/toolkit';

import { Consumer } from 'app/models/consumer';
import { replaceOnce } from 'app/helpers/arrays';
import { ChosenFilters } from 'app/models/common/chosen-filters';
import { getChosenFilter, resetSomeFilters } from 'app/helpers/filters/get-chosen-filter';
import { Call } from 'app/models/call';
import { getNewFilterValue } from 'app/helpers/filters/get-filter-new-value';
import { Appeal } from 'app/models/appeal';
import * as ConsumerActions from './consumers.actions';
import { CONSUMERS_STORE_NAME } from './consumers.actions';
import { EntityState } from '../models/entity-state';
import { initialEntityState } from '../constants/initial-entity-state';
import * as CallsActions from '../calls/calls.actions';
import { ErrorPayload } from '../models/error-payload';

export interface ConsumersState extends EntityState {
  consumers: Consumer[];
  consumersTotalCount: number;

  chosenFilters: ChosenFilters;
  appeals: Appeal[];
  calls: Call[];
  consumersAreLoading: boolean;
  areAppealsLoading: boolean;
  areCallsLoading: boolean;
  consumersLoadingError: any;
  appealsLoadingError: any;
  callsLoadingError: any;
  consumersCreatingError: ErrorPayload | null;
  consumersUpdatingError: any;
  selectedConsumer?: Consumer;
  selectedConsumerIsLoading: boolean;
  selectedConsumerLoadingError: any;
}

export const consumersInitialState: ConsumersState = {
  consumers: [],
  consumersTotalCount: 0,

  chosenFilters: {},
  appeals: [],
  calls: [],
  consumersAreLoading: false,
  areAppealsLoading: false,
  areCallsLoading: false,
  consumersLoadingError: '',
  appealsLoadingError: '',
  callsLoadingError: '',
  consumersCreatingError: null,
  consumersUpdatingError: '',
  selectedConsumer: undefined,
  selectedConsumerIsLoading: false,
  selectedConsumerLoadingError: '',

  ...initialEntityState,
};

const consumersSlice = createSlice({
  name: CONSUMERS_STORE_NAME,
  initialState: consumersInitialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(ConsumerActions.LoadConsumers.init, state => {
        state.consumersAreLoading = true;
      })
      .addCase(ConsumerActions.LoadConsumers.success, (state, { payload }) => {
        state.consumersAreLoading = false;
        if (!payload.consumers) return;
        state.consumers = payload.page === 1 ? payload.consumers : state.consumers.concat(payload.consumers);
        state.consumersTotalCount = payload.consumersTotalCount;
      })
      .addCase(ConsumerActions.LoadConsumers.failure, (state, { payload }) => {
        state.consumersAreLoading = false;
        state.consumersLoadingError = payload;
      })
      .addCase(ConsumerActions.LoadSelectedConsumer.init, state => {
        state.selectedConsumerIsLoading = true;
      })
      .addCase(ConsumerActions.LoadSelectedConsumer.success, (state, { payload }) => {
        state.selectedConsumer = payload;
        state.selectedConsumerIsLoading = false;
      })
      .addCase(ConsumerActions.LoadSelectedConsumer.failure, (state, { payload }) => {
        state.selectedConsumerIsLoading = false;
        state.selectedConsumerLoadingError = payload;
      })
      .addCase(ConsumerActions.LoadConsumerAppeals.init, state => {
        state.areAppealsLoading = true;
      })
      .addCase(ConsumerActions.LoadConsumerAppeals.success, (state, { payload }) => {
        state.appeals = payload;
        state.areAppealsLoading = false;
      })
      .addCase(ConsumerActions.LoadConsumerAppeals.failure, (state, { payload }) => {
        state.areAppealsLoading = false;
        state.appealsLoadingError = payload;
      })
      .addCase(ConsumerActions.LoadConsumerCalls.init, state => {
        state.areCallsLoading = true;
      })
      .addCase(ConsumerActions.LoadConsumerCalls.success, (state, { payload }) => {
        state.calls = payload;
        state.areCallsLoading = false;
      })
      .addCase(ConsumerActions.LoadConsumerCalls.failure, (state, { payload }) => {
        state.areCallsLoading = false;
        state.callsLoadingError = payload;
      })
      .addCase(ConsumerActions.CreateConsumer.init, state => {
        state.consumersCreatingError = null;
        state.creating = true;
      })
      .addCase(ConsumerActions.CreateConsumer.success, (state, { payload }) => {
        state.consumers.push(payload);
        state.creating = false;
      })
      .addCase(ConsumerActions.CreateConsumer.failure, (state, { payload }) => {
        state.consumersCreatingError = payload;
        state.creating = false;
      })
      .addCase(ConsumerActions.UpdateConsumer.init, state => {
        state.updating = true;
      })
      .addCase(ConsumerActions.UpdateConsumer.success, (state, { payload }) => {
        state.consumers = replaceOnce(state.consumers, ({ id }) => id === payload.id, payload);
        state.updating = false;
      })
      .addCase(ConsumerActions.UpdateConsumer.failure, (state, { payload }) => {
        state.consumersUpdatingError = payload;
        state.updating = false;
      })
      .addCase(ConsumerActions.DeleteConsumer.init, state => {
        state.deleting = true;
      })
      .addCase(ConsumerActions.DeleteConsumer.success, (state, { payload }) => {
        state.consumers = state.consumers.filter(consumer => consumer.id !== payload.id);
        state.selectedConsumer = state.selectedConsumer?.id === payload.id ? undefined : state.selectedConsumer;
        state.deleting = false;
      })
      .addCase(ConsumerActions.DeleteConsumer.failure, (state, { payload }) => {
        state.consumersLoadingError = payload;
        state.deleting = false;
      })
      .addCase(ConsumerActions.setChosenFilter, (state, { payload }) => {
        state.chosenFilters = getChosenFilter([], payload.filters);
      })
      .addCase(ConsumerActions.changeChosenFilter, (state, { payload }) => {
        state.chosenFilters[payload.key] = getNewFilterValue(payload.value);
      })
      .addCase(ConsumerActions.resetSomeFilters, (state, { payload }) => {
        state.chosenFilters = resetSomeFilters(state.chosenFilters, payload);
      })
      .addCase(CallsActions.UpdateCall.success, (state, { payload }) => {
        state.calls = replaceOnce(state.calls, ({ id }) => id === payload.id, payload);
      })
      .addDefaultCase(() => {});
  },
});

export const consumersReducer = consumersSlice.reducer;
