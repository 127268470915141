import styled from 'styled-components';
import { ColumnFlexbox, Flexbox } from 'app/typography/flex';
import { Colors } from 'app/themes/colors';
import { IconClickable, Tab, Tabs, WindowLikePage } from 'app/components/shared';
import { Header as Heading } from 'app/typography/text';

export const SupplierInfoContainer = styled(ColumnFlexbox)`
  height: 100%;
`;

export const Header = styled(Flexbox)`
  padding: 20px 30px 17px;
  border-bottom: 1px solid ${Colors.Grey600};
  align-items: center;
  margin-bottom: 20px;
`;

export const ChevronLeft = styled(IconClickable)`
  color: ${Colors.Grey700};
  margin-right: 9px;

  &:hover {
    color: ${Colors.Grey800};
  }
`;

export const FullName = styled(Heading)`
  margin: 0 20px 0 0;
`;

export const CenteredFlexbox = styled(Flexbox)`
  align-items: center;
  margin-bottom: 8px;
`;

export { Tab, Tabs, WindowLikePage, ColumnFlexbox };
