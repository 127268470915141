import styled from 'styled-components';
import { FlexWithSpacing } from 'app/typography/flex';
import { Button, DynamicsFilters } from 'app/components/shared';

export const StyledButton = styled(Button)`
  padding: 12px 20px;
`;

export const ControlsContainer = styled(FlexWithSpacing)`
  width: 100%;
`;

export const SideDynamicsFilters = styled(DynamicsFilters)`
  justify-content: flex-start;
`;
