import styled from 'styled-components';
import { Button, Dialog } from '../../shared';

export const AccessDeniedDialog = styled(Dialog)`
  width: 600px;
  height: 340px;

  .bodyClassName {
    gap: 20px;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
`;

export const CloseButton = styled(Button)`
  width: 100px;
  min-height: 46px;
  margin: 10px auto;
`;
