import { FC, useRef } from 'react';

import { Option } from './models';
import { Checkbox } from '../Checkbox';
import { useKey } from '../../../helpers/hooks/use-key';
import { Key } from '../../../constants/key';

interface Props {
  option: Option;
  checked?: boolean;
  tabIndex?: number;
  disabled?: boolean;
  onSelectionChanged: (checked: boolean) => void;
  onClick;
}

export const SelectOption: FC<Props> = ({
  onClick,
  onSelectionChanged,
  option,
  tabIndex,
  checked,
  disabled,
}) => {
  const itemRef: any = useRef();

  const onOptionCheck = e => {
    toggleChecked();
    e.preventDefault();
  };

  const toggleChecked = () => {
    if (!disabled) {
      onSelectionChanged(!checked);
    }
  };

  const handleClick = e => {
    toggleChecked();
    onClick(e);
  };

  useKey([Key.Enter, Key.Space], onOptionCheck, { target: itemRef });

  return (
    <Checkbox
      tabIndex={tabIndex}
      id={option.label}
      checked={checked}
      disabled={disabled}
      onChange={handleClick}
      label={option.label}
    />
  );
};
