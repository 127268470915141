import { ChangeEvent, DragEvent, FC } from 'react';

import { NotificationsActions } from 'store/notifications';
import { useAction } from 'app/helpers/actions/use-action';
import { NotificationType } from 'app/models/notifications/notification';
import { AppealAttachedFile } from 'app/models/appeal/appeal';
import { getFileType } from 'app/helpers/filex/get-file-type';
import { AttachmentPreview } from './AttachmentPreview';
import { FileUpload, PlusIcon, UploadBox, UploadContainer } from './styled';

export interface AttachmentsUploadProps {
  files: AppealAttachedFile[] | undefined;
  types?: string[];
  maxSize?: number;
  multiple?: boolean;
  onChange(files: File[]): void;
  onDelete(id: string): void;
}

export const AttachmentsUpload: FC<AttachmentsUploadProps> = ({
  files,
  types,
  maxSize,
  multiple,
  onChange,
  onDelete,
}) => {
  const addNotification = useAction(NotificationsActions.AddNotification.init);

  const checkType = (file: File): boolean => {
    const type = getFileType(file);

    if (!types?.length) {
      return false;
    }

    return types.includes(type);
  };

  const checkSize = (file: File): boolean => {
    if (!maxSize) {
      return false;
    }

    return maxSize >= file.size;
  };

  const processFiles = (fileList: FileList): void => {
    const files = Array.from(fileList);

    for (const file of files) {
      let errorMessage = '';

      if (!types?.length && !maxSize) {
        break;
      }

      if (!checkType(file)) {
        errorMessage = 'Ошибка: Выбран не верный тип файла';
      }

      if (!checkSize(file)) {
        errorMessage = 'Ошибка: Превышен лимит размера файла';
      }

      if (errorMessage) {
        addNotification({
          body: errorMessage,
          type: NotificationType.Error,
        });
        return;
      }
    }

    onChange(files);
  };

  const handleAttachmentSelect = (event: ChangeEvent<HTMLInputElement>): void => {
    if (!event.target.files) {
      return;
    }

    processFiles(event.target.files);
  };

  const handleAttachmentDrop = (event: DragEvent<HTMLInputElement>): void => {
    event.preventDefault();
    event.stopPropagation();

    processFiles(event.dataTransfer.files);
  };

  return (
    <UploadContainer>
      <UploadBox>
        <FileUpload
          type="file"
          multiple={multiple}
          title=""
          onChange={handleAttachmentSelect}
          onDrop={handleAttachmentDrop}
        />
        <PlusIcon icon="/assets/icons.svg#add" />
      </UploadBox>
      {files?.map(file => (
        <AttachmentPreview key={file.id} file={file} onDelete={onDelete} />
      ))}
    </UploadContainer>
  );
};
