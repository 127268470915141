export const environment = {
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL!,
  environment: process.env.REACT_APP_ENVIRONMENT!,
  webphoneWebsocketServerURL: process.env.REACT_APP_WEBPHONE_WEBSOCKET_SERVER_URL!,
  webphoneSipServerDomain: process.env.REACT_APP_WEBPHONE_SIP_SERVER_DOMAIN!,
  webphoneSipApiUrl: process.env.REACT_APP_WEBPHONE_SIP_API_URL!,

  dadataAPIKey: process.env.REACT_APP_DADATA_API_KEY!,
  yandexAPIKey: process.env.REACT_APP_YANDEX_API_KEY!,
  yandexSpeechKitURL: process.env.REACT_APP_YANDEX_SPEECH_KIT_URL!,

  firebaseAPIKey: process.env.REACT_APP_FIREBASE_API_KEY!,
  firebaseAuthDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN!,
  firebaseProjectId: process.env.REACT_APP_FIREBASE_API_KEY!,
  firebaseStorageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET!,
  firebaseMessagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID!,
  firebaseAppId: process.env.REACT_APP_FIREBASE_APP_ID!,
  firebaseMeasurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID!,
  firebaseMessagingAPIKey: process.env.REACT_APP_FIREBASE_MESSAGING_VAPID_KEY!,
  sentryDSN: process.env.REACT_APP_SENTRY_DSN_KEY!,
  isDevelopment: process.env.NODE_ENV === 'development',
};
