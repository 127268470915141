import styled from 'styled-components';
import { Colors } from 'app/themes/colors';
import { Icon, IconClickable } from 'app/components/shared';
import { CenteredFlex, Flexbox } from 'app/typography/flex';
import { Hint, TextRegular } from 'app/typography/text';

export const UploadContainer = styled(Flexbox)`
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 15px;
  align-content: baseline;
`;

export const FileUpload = styled.input`
  opacity: 0;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: calc(100% + 40px);
  margin-top: -40px;
  top: 0;
  left: 0;
  cursor: pointer;
`;

export const PlusIcon = styled(Icon)`
  color: ${Colors.Grey700};
  transition: 0.4s;
`;

export const UploadBox = styled(CenteredFlex)`
  cursor: pointer;
  overflow: hidden;
  position: relative;
  width: 73px;
  height: 73px;
  background: ${Colors.MainBackground};
  border: 2px solid ${Colors.Grey600};
  border-radius: 8px;
  transition: 0.4s;

  &:hover {
    border: 2px solid ${Colors.Blue600};

    ${PlusIcon} {
      color: ${Colors.Blue700};
    }
  }
`;

export const DeleteButton = styled(IconClickable)`
  min-width: 20px;
  height: 20px;
  color: ${Colors.Grey750};
  display: none;

  :hover {
    color: ${Colors.Red800};
  }
`;

export const AttachmentPreviewWrapper = styled(CenteredFlex)`
  flex-direction: column;
  cursor: pointer;
  overflow: hidden;
  height: 73px;
  background: ${Colors.MainBackground};
  border: 1px solid ${Colors.Grey600};
  border-radius: 8px;
  transition: 0.4s;
`;
export const ImagePreview = styled.img`
  transition: 0.6s;
`;

export const FileTypePlaceholder = styled(TextRegular)`
  font-weight: 500;
  color: ${Colors.Grey750};
  text-transform: uppercase;
  transition: 0.4s;
`;

export const IconPlaceholder = styled(IconClickable)`
  width: 20px;
  height: 20px;
  color: ${Colors.Grey750};
  margin-bottom: 5px;
  transition: 0.4s;
`;

export const Title = styled(Hint)`
  line-height: 20px;
  cursor: pointer;
  color: ${Colors.Grey750};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const AttachmentName = styled(Flexbox)``;

export const AttachmentPreviewContainer = styled.a`
  width: 73px;
  text-decoration: none;

  :hover {
    ${DeleteButton} {
      display: block;
    }

    ${AttachmentPreviewWrapper} {
      border: 1px solid ${Colors.Blue600};
    }

    ${ImagePreview} {
      transform: scale(1.15);
    }

    ${FileTypePlaceholder}, ${IconPlaceholder} {
      color: ${Colors.Blue600};
    }
  }
`;
