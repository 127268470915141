import { Call, CallId, CallsQueryParams } from 'app/models/call';
import { HttpService } from '../http.service';
import { TableDto } from '../models/table.dto';
import { CreateCallRequest, UpdateCallRequest } from './dtos';

class CallsServiceImpl {
  private static apiEndpoint = '/api/calls';

  getCalls(params: CallsQueryParams): Promise<TableDto<Call>> {
    const { per_page, page, ...queryFilters } = params;

    const queryParams = {
      per_page,
      page,
      ...queryFilters,
    };
    return HttpService.get<TableDto<Call>>(`${CallsServiceImpl.apiEndpoint}`, {
      params: queryParams,
    }).then(response => response.data);
  }

  createCall(createCallDto: CreateCallRequest): Promise<Call> {
    return HttpService.post<Call>(CallsServiceImpl.apiEndpoint, createCallDto).then(response => response.data);
  }

  updateCall(callId: CallId, updateCallDto: UpdateCallRequest): Promise<Call> {
    return HttpService.put<Call>(`${CallsServiceImpl.apiEndpoint}/${callId}`, updateCallDto).then(response => response.data);
  }

  getCallRecord = async (id: CallId): Promise<string> => {
    const { data } = await HttpService.get<any>(`${CallsServiceImpl.apiEndpoint}/${id}/record`, {
      responseType: 'arraybuffer',
      headers: { 'Content-Type': 'audio/mpeg' },
    });

    const blob = new Blob([data], {
      type: 'audio/mpeg',
    });

    return URL.createObjectURL(blob);
  };
}

export const CallsService = new CallsServiceImpl();
