import { Option, OptionMenu } from 'app/models/common/option';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ErrorHint } from 'app/typography/text';
import { ColumnFlexWithPadding } from 'app/typography/flex';
import { FormField } from '../FormField';
import { Input, InputProps } from '../Input';
import { Menu } from '../Menu';
import { FormFieldError } from '../FormFieldError';

export type SelectProps<T> = {
  id?: string;
  options: Option<T>[];
  onChange?: (value: string | number) => void;
  value?: string | number;
  children?: ReactNode;
  className?: string;
  error?: string;
  disabled?: boolean;
  noError?: boolean;
  search?: boolean;
  placeholder?: string;
} & Pick<InputProps, 'onBlur' | 'name' | 'icon'>;

export const Select = ({
  id,
  options,
  onChange,
  value,
  children,
  className,
  error,
  disabled,
  noError,
  search,
  placeholder,
  onBlur,
  name,
  icon,
}: SelectProps<any>) => {
  const selectedLabel = options.find(opt => opt.value === value)?.label;
  const [selectLabel, setSelectLabel] = useState((value ? selectedLabel || placeholder : placeholder || selectedLabel) || '');
  const [menuOptions, setMenuOptions] = useState<OptionMenu[]>([]);

  const onOptionClick = useCallback(
    value => {
      const label = options.find(option => option.value === value)?.label;
      if (label) setSelectLabel(label);
      onChange?.(value?.toString());
    },
    [onChange, options]
  );

  useEffect(() => {
    if (selectedLabel) {
      setSelectLabel(selectedLabel);
    } else {
      setSelectLabel('');
    }
  }, [selectedLabel]);

  useEffect(() => {
    if (search) {
      setMenuOptions(
        options
          .filter(option => option.label.toLowerCase().includes(selectLabel.toLowerCase()))
          .map(({ label, value }) => ({
            label,
            callback: () => {
              onOptionClick(value);
            },
          }))
      );
    } else {
      setMenuOptions(
        options.map(({ label, value }) => ({
          label,
          callback: () => {
            onOptionClick(value);
          },
        }))
      );
    }
  }, [onOptionClick, options, search, selectLabel]);

  return (
    <SelectComponent className={className} spacing="0.3125rem">
      <SelectMenu disabled={disabled} fullWidth={!children} options={menuOptions}>
        {children || (
          <Input
            id={id}
            error={error}
            value={selectLabel}
            noError
            readOnly={!search}
            disabled={disabled}
            onChange={e => setSelectLabel(e.target.value)}
            autoComplete="off"
            onBlur={onBlur}
            name={name}
            icon={icon}
            placeholder={placeholder}
          />
        )}
      </SelectMenu>
      {!noError && <ErrorHint>{error && <FormFieldError error={error} />}</ErrorHint>}
    </SelectComponent>
  );
};

const SelectComponent = styled(ColumnFlexWithPadding)<{ fullWidth?: boolean }>`
  position: relative;
`;

const SelectMenu = styled(Menu)`
  border: -1rem;
`;

export interface FormSelectProps extends SelectProps<any> {
  label?: string;
}

export const FormSelect = (props: FormSelectProps) => {
  const { label, ...rest } = props;

  return (
    <StyledForm placeholder={label}>
      <Select {...rest} />
    </StyledForm>
  );
};

const StyledForm = styled(FormField)`
  width: 100%;
`;
