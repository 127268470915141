import { HttpService } from '../http.service';
import {
  GetSpeechNotificationApiParams,
  GetSpeechNotificationResponse,
  UpdateSpeechNotificationApiParams,
  UpdateSpeechNotificationApiResponse,
  UpdateSpeechNotificationsApiParams,
} from './dtos';
import { SpeechNotification } from '../../models/speech-notification';
import { TableDto } from '../models/table.dto';
import { MAX_ROWS_COUNT } from '../../constants/scroll';
import { getSpeechNotificationsMapperDto, updateSpeechNotificationsMapperDto } from './speech-notification.mappers';

class SpeechNotificationServiceImpl {
  private readonly apiEndpoint = '/api/calls/speech-notification';

  getSpeechNotifications = async (params: GetSpeechNotificationApiParams): Promise<TableDto<SpeechNotification>> => {
    const { per_page, page, ...queryFilters } = params;

    const queryParams = {
      per_page: per_page || MAX_ROWS_COUNT,
      page,
      ...queryFilters,
    };
    const response = await HttpService.get<GetSpeechNotificationResponse>(this.apiEndpoint, { params: queryParams });

    return getSpeechNotificationsMapperDto(response.data);
  };

  updateSpeechNotification = async (params: UpdateSpeechNotificationApiParams): Promise<SpeechNotification> => {
    const { id, ...body } = params;

    const { data } = await HttpService.put<UpdateSpeechNotificationApiResponse>(`${this.apiEndpoint}/${id}`, body);

    return updateSpeechNotificationsMapperDto(data);
  };

  updateSpeechNotifications = async (params: UpdateSpeechNotificationsApiParams) => {
    const { data } = await HttpService.put(`${this.apiEndpoint}/batch`, params);

    return data;
  };
}

export const SpeechNotificationService = new SpeechNotificationServiceImpl();
