import styled from 'styled-components';

export const PopupBackdrop = styled.div<{ withoutBackdrop?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 8;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  padding: 2em 0;

  ${({ withoutBackdrop }) =>
          withoutBackdrop ? 'pointer-events: none' : ''
  };
`;

export const PopupContainer = styled.div`
  position: absolute;
  z-index: 9;
`;
