import styled from 'styled-components';
import { Colors } from '../../../themes/colors';

export const SuggestionsContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const SuggestionsList = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  background: white;
  border-radius: 10px;
  border: 1px solid #dadfea;
  z-index: 1000;
  max-height: 300px;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const SuggestionItem = styled.div`
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  color: black;
  &:hover {
    background-color: ${Colors.Blue400};
  }
`;
