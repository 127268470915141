import { Children, ReactElement, useState } from 'react';

import { TabProps } from './Tab';
import { TabList, TabListItem, TabPanel, TabsContainer } from './styled';

interface TabsProps {
  children: ReactElement<TabProps> | ReactElement<TabProps>[];
  defaultActiveKey?: string;
}

export const Tabs = ({ children, defaultActiveKey }: TabsProps) => {
  const [activeTab, setActiveTab] = useState(defaultActiveKey);

  const handleTabClick = (tabKey: string) => () => {
    setActiveTab(tabKey);
  };

  return (
    <TabsContainer>
      <TabList>
        {Children.map(children, child =>
          child.props.tabKey && child.props.title ? (
            <TabListItem
              isActive={child.props.tabKey === activeTab}
              onClick={handleTabClick(child.props.tabKey)}
            >
              {child.props.title}
            </TabListItem>
          ) : null
        )}
      </TabList>
      <TabPanel>
        {Children.map(children, child => (child.props.tabKey === activeTab ? child : null))}
      </TabPanel>
    </TabsContainer>
  );
};
