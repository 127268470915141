import { differenceInSeconds } from 'date-fns';
import React from 'react';
import { timerRefreshTimeMs } from '../../constants/intervals';
import { useIntervalUpdate } from '../../helpers/date/use-interval-update.hook';
import { DateFormat, formatAddSecondsToUnix, formatTime } from '../../helpers/date/time';

interface TimerProps {
  date: Date;
  onTimerEnd?: () => void;
  className?: string;
  format: DateFormat;
  hourFormat?: DateFormat;
  timerAccelerate?: number;
  prefix?: string;
}

export function Timer(props: TimerProps) {
  useIntervalUpdate(timerRefreshTimeMs, true);
  return getTimerFormatHelper(props);
}

export const getTimerFormatHelper = (props: TimerProps) => {
  const { prefix = '', timerAccelerate, onTimerEnd, date, className, hourFormat, format } = props;

  const seconds = Math.abs(differenceInSeconds(new Date(), date));
  const accelerate = timerAccelerate || 0;
  const formattedSeconds = seconds < accelerate ? 0 : seconds;
  if (formattedSeconds === 0 && onTimerEnd) {
    onTimerEnd();
  }

  if (formattedSeconds >= 86400) {
    return <span className={className}>{`${prefix}${formatTime(seconds, true)}`}</span>;
  }

  const timeFormat = hourFormat && formattedSeconds > 3600 ? hourFormat : format;

  const formattedDate = formatAddSecondsToUnix(formattedSeconds, timeFormat);
  return <span className={className}>{`${prefix}${formattedDate}`}</span>;
};
