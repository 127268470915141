import { Colors } from 'app/themes/colors';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components/macro';
import { ColumnCenteredFlexWithPadding } from 'app/typography/flex';
import { TextRegular } from 'app/typography/text';
import { Page } from '../shared';

export function NotFoundPage() {
  return (
    <>
      <Helmet>
        <title>404 Страница не найдена</title>
        <meta name="description" content="Page not found" />
      </Helmet>
      <Page title="Страница не найдена">
        <Wrapper spacing="2rem">
          <Title>404</Title>
          <Text>Страница не найдена</Text>
        </Wrapper>
      </Page>
    </>
  );
}

const Title = styled(TextRegular)`
  font-size: 105px;
  line-height: 110px;
  color: ${Colors.Grey700};
`;

const Text = styled(TextRegular)`
  color: ${Colors.Grey700};
`;

const Wrapper = styled(ColumnCenteredFlexWithPadding)`
  background-color: ${Colors.Grey500};
  height: calc(100vh - 72px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
