import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { IncidentId, IncidentStatus } from '../../../../../../models/incident/incident';
import { EditIncident } from '../../../../../../../store/incidents/incidents.actions';
import { ModalService } from '../../../../../../services/modal-service/modal.service';
import { IncidentForm } from '../../../../../Incidents/components/IncidentForm/IncidentForm';
import { OptionMenu } from '../../../../../../models/common';
import { Icon, Menu } from '../../../../../../components/shared';
import { Colors } from '../../../../../../themes/colors';
import { LoadComments } from '../../../../../../../store/comments/comments.actions';

type IncidentsActionsProps = {
  incidentId: IncidentId | undefined;
  status: IncidentStatus | undefined | null;
};

export const IncidentsActions = React.memo<IncidentsActionsProps>(props => {
  const dispatch = useDispatch();
  const { status } = props;

  const onOptionClickHandler = React.useCallback(
    (status: IncidentStatus) => {
      if (!props.incidentId) return;
      dispatch(EditIncident.init({ id: props.incidentId, status }));
      dispatch(LoadComments.init({ id: String(props.incidentId), entity: 'incident' }));
    },
    [dispatch, props.incidentId]
  );

  const getToWorkHandler = React.useCallback(() => {
    ModalService.openModal(IncidentForm, { incidentId: props.incidentId, getToWork: true });
  }, [props.incidentId]);

  const options: OptionMenu[] = React.useMemo(() => {
    if (status === IncidentStatus.Canceled || status === IncidentStatus.Completed) return [];
    const isNew = status === IncidentStatus.New;
    return [
      {
        label: 'Редактировать',
        callback: () => ModalService.openModal(IncidentForm, { incidentId: props.incidentId, getToWork: false }),
      },
      {
        label: 'Отменить',
        callback: () => onOptionClickHandler(IncidentStatus.Canceled),
      },
      {
        label: isNew ? 'В работу' : 'Выполнено',
        callback: () => (isNew ? getToWorkHandler() : onOptionClickHandler(IncidentStatus.Completed)),
      },
    ];
  }, [getToWorkHandler, onOptionClickHandler, props.incidentId, status]);

  if (!options.length) return null;
  return (
    <Menu options={options}>
      <MenuIcon icon="/assets/icons.svg#menu" />
    </Menu>
  );
});

const MenuIcon = styled(Icon)`
  fill: ${Colors.Grey700} !important;
  cursor: pointer;
`;
