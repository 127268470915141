import React from 'react';
import { format } from 'date-fns';
import { Cell, Column } from 'react-table';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router-dom';
import { EmptyTable, Text } from '../../../../../Consumer/styled';
import { AddressesData } from '../../../../../Mailings/components/AddressesList';
import { Incident } from '../../../../../../models/incident/incident';
import { selectSelectedAppeal } from '../../../../../../../store/appeals/appeals.selectors';
import { EditAppealIncidents } from '../../../../../../../store/appeals/appeals.actions';
import { AddButton, IncidentAppealsTable } from './styled';
import { ModalService } from '../../../../../../services/modal-service/modal.service';
import { ConfirmModal } from '../../../../../../components/Modals/ConfirmModal/ConfirmModal';
import { Icons } from '../../../../../../constants/icons';
import { PagesRoutes } from '../../../../../../constants/route-path';

const DateCell: React.FC<{ value: string | undefined }> = ({ value }) => (
  <span>{format(new Date(value || new Date()), 'dd.MM.yyyy HH:mm')}</span>
);

const AddressCell: React.FC<{ addresses: AddressesData[] | undefined }> = ({ addresses }) => <span>{addresses?.[0].address}</span>;

const AddCell: React.FC<{ row: Incident; onClick: (incident: Incident) => void }> = ({ row, onClick }) => (
  <AddButton onClick={() => onClick(row)}>
    <Icons.Plus />
  </AddButton>
);
export const IncidentsTable = React.memo(() => {
  const appeal = useSelector(selectSelectedAppeal, shallowEqual);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onAddClick = React.useCallback(
    (incident: Incident) => {
      if (!appeal?.id || !incident) return;

      dispatch(
        EditAppealIncidents.init({
          id: appeal?.id,
          incidentId: incident?.id,
        })
      );
    },
    [appeal?.id, dispatch]
  );

  const onCellClick = React.useCallback(
    (cell: Cell<Incident>) => {
      if (cell.column.id === 'actions') return;

      navigate(
        generatePath(PagesRoutes.PAGES.INCIDENT, {
          incidentId: String(cell.row.original.id),
        })
      );
    },
    [navigate]
  );

  const onAddButtonClick = React.useCallback(
    (incident: Incident) => {
      if (!appeal?.id) return;
      const text = incident?.incident_type === 'accident' ? 'Авария' : 'Плановые работы ';

      ModalService.openModal(ConfirmModal, {
        title: 'Привязка заявки к инциденту',
        text: `Вы действительно хотите привязать заявку ${appeal?.number} к инциденту ${text} №${incident?.number}?`,
        textButton: 'Привязать',
        onClick: () => onAddClick(incident),
        onClose: () => {},
      });
    },
    [appeal?.id, appeal?.number, onAddClick]
  );

  const columns: Column<Incident>[] = React.useMemo(
    () => [
      {
        Header: 'Номер',
        accessor: 'number',
        width: 70,
      },
      {
        Header: 'Дата создания',
        accessor: 'created_at',
        Cell: ({ value }) => <DateCell value={value} />,
      },
      {
        Header: 'Адрес',
        accessor: 'addresses',
        Cell: ({ value }) => <AddressCell addresses={value} />,
        width: 100,
      },

      {
        id: 'actions',
        accessor: 'id',
        Cell: ({ row }) => <AddCell row={row.original as Incident} onClick={() => onAddButtonClick(row.original)} />,
      },
    ],
    [onAddButtonClick]
  );

  if (!appeal?.relatedIncidents.length) {
    return (
      <EmptyTable>
        <Text>Инцидентов нет</Text>
      </EmptyTable>
    );
  }
  return <IncidentAppealsTable columns={columns} onCellClick={onCellClick} data={appeal?.relatedIncidents} />;
});
