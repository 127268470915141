/* eslint-disable func-names */
import { createAction } from '@reduxjs/toolkit';
import { createActionTrio } from './create-action-trio';
import { ErrorPayload } from '../models/error-payload';

export function createActionName(storeName: string, actionName: string) {
  return `[${storeName}] ${actionName}`;
}

export function getCreatePayloadActionFromStore(storeName: string) {
  // eslint-disable-next-line space-before-function-paren
  return function <T>(actionName: string) {
    return createAction<T>(createActionName(storeName, actionName));
  };
}

export function getCreateActionFromStore(storeName: string) {
  // eslint-disable-next-line func-names
  return function (actionName: string) {
    return createAction(createActionName(storeName, actionName));
  };
}

export function getCreateActionTrio(storeName: string) {
  // eslint-disable-next-line space-before-function-paren
  return function <TPayload, TResult, TError extends ErrorPayload = ErrorPayload>(actionName: string) {
    return createActionTrio<TPayload, TResult, TError>(createActionName(storeName, actionName));
  };
}
