export enum IncidentField {
  Number = 'number',
  Type = 'incident_type',
  Status = 'status',
  Supplier = 'supplier',
  CreatedAt = 'created_at',
  PlannedDateEnd = 'planned_end_date',
}

export const incidentInfoFieldNameMap = {
  [IncidentField.Number]: 'Номер',
  [IncidentField.Type]: 'Тип',
  [IncidentField.Status]: 'Статус',
  [IncidentField.Supplier]: 'Поставщик',
  [IncidentField.CreatedAt]: 'Дата создания',
  [IncidentField.PlannedDateEnd]: 'Дата планового устранения',
};

export const incidentInfoFields: IncidentField[] = [
  IncidentField.Number,
  IncidentField.Type,
  IncidentField.Status,
  IncidentField.Supplier,
  IncidentField.CreatedAt,
  IncidentField.PlannedDateEnd,
];
