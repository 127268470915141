import { AppState } from '../types/AppState.type';
import { Primitive } from './types/types.common';

export const getValidSelectorCacheKey = (...args: any[]): string => {
  let validCacheKey = '';
  for (let i = 0; i < args.length; i++) {
    validCacheKey += typeof args[i] === 'string' ? args[i] : String(args[i]);
  }
  return validCacheKey;
};

export const defaultCachedSelectorKeySelector = (_S: AppState, ...args: Primitive[]) => getValidSelectorCacheKey(...args);
