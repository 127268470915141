import { Column } from 'react-table';

import { CommentCell, Icon } from 'app/components/shared';
import { shallowEqual, useSelector } from 'react-redux';
import { DateFormat, localFormat } from 'app/helpers/date/time';
import { ConfigurationSelectors } from 'store/configuration';
import { Appeal } from 'app/models/appeal';
import { AppealsSelectors } from 'store/appeals';
import { AppealStatusTitle } from '../../../pages/Appeals/components/AppealStatusTitle';
import { AppealActionsMenu } from '../../../pages/Appeals/components/AppealActionsMenu';
import { AppealCustomNumber } from '../../../pages/Appeals/components/AppealCustomNumber';

export enum ConsumerAppealHistoryCell {
  Actions = 'actions',
}

export const APPEAL_HISTORY_COLUMNS: Column<Appeal>[] = [
  {
    Header: 'Номер',
    accessor: 'number',
    width: 50,
    Cell: AppealCustomNumber,
  },
  {
    Header: 'Дата',
    accessor: 'createdAt',
    Cell: ({ value }) => <div>{localFormat(new Date(value), DateFormat.LocalDateAndTime)}</div>,
  },
  {
    Header: 'Тема',
    accessor: 'subject',
    Cell: ({ value }) => value,
  },
  {
    Header: 'Тип',
    accessor: 'appealRequisitionType',
    Cell: ({ value }) => {
      const appealTypeLabel = useSelector(AppealsSelectors.selectAppealTypeLabelById(value), shallowEqual);
      return <div>{appealTypeLabel}</div>;
    },
  },
  {
    Header: 'Вид',
    accessor: 'appealRequisitionKind',
    Cell: ({ value }) => {
      const requisitionKindOptions = useSelector(ConfigurationSelectors.selectRequisitionKinds);

      return <div>{requisitionKindOptions.find(kind => kind.value === value)?.label}</div>;
    },
  },
  {
    Header: 'Диспетчер',
    accessor: 'dispatcher',
    Cell: ({ value }) => <div>{value ? value.fullName : 'Не назначен'}</div>,
  },
  {
    Header: 'Мастер',
    accessor: 'handyman',
    Cell: ({ value }) => <div>{value ? value.fullName : 'Не назначен'}</div>,
  },
  {
    Header: 'Поставщик',
    accessor: 'managementCompany',
    Cell: ({ value }) => <div>{value?.name || 'Не назначен'}</div>,
  },
  {
    Header: 'Комментарий',
    id: 'message',
    Cell: CommentCell,
  },
  {
    Header: 'Статус',
    accessor: 'status',
    Cell: AppealStatusTitle,
  },
  {
    id: ConsumerAppealHistoryCell.Actions,
    Header: <Icon icon="/assets/icons.svg#settings" />,
    width: 25,
    Cell: AppealActionsMenu,
  },
];
