import styled from 'styled-components';
import { Colors } from 'app/themes/colors';
import { css } from 'styled-components/macro';
import { HeaderSmall, TextRegularBig } from '../../../../typography/text';
import { ColumnCenteredFlexWithPadding, ColumnFlexWithPadding, FlexWithSpacing } from '../../../../typography/flex';
import { FormField } from '../../../../components/shared';
import { media } from '../../../../../styles/media';

export const RedText = styled.span`
  color: ${Colors.Red800};
`;

export const TextButton = styled(HeaderSmall)`
  color: ${Colors.Blue800};
  cursor: pointer;
`;

export const Info = styled(FlexWithSpacing)`
  flex-wrap: wrap;
`;

export const FormFieldInfoItem = styled(FormField)<{ isUseFirstChild?: boolean; isUseThirdChild?: boolean }>`
  flex-basis: 48%;

  ${({ isUseFirstChild }) =>
    isUseFirstChild &&
    css`
      &:first-child {
        flex-basis: 100%;
      }
    `}${({ isUseThirdChild }) =>
    isUseThirdChild &&
    css`
      &:nth-child(3) {
        flex-basis: 100%;
      }
    `}
`;

export const VerticalContainer = styled(ColumnCenteredFlexWithPadding)`
  width: 100%;
`;

export const CommonInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 20px;
  width: 100%;
  margin-top: 20px;

  & > * {
    margin-right: auto;
  }

  ${media.md`
    flex-direction: row;
  `}
`;

export const Item = styled(ColumnFlexWithPadding)`
  align-self: start;
  width: 100%;
  background-color: ${Colors.White};
  padding: 20px 24px;
  border-radius: 10px;
`;

export const Comments = styled(ColumnFlexWithPadding)`
  max-height: 550px;
  overflow-y: auto;
`;

export const ActionButton = styled(TextRegularBig)`
  color: ${Colors.Blue800};
  font-weight: 500;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
