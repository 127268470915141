import styled from 'styled-components';

import { Flexbox } from 'app/typography/flex';
import { Colors } from 'app/themes/colors';

export const TabsContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

export const TabList = styled(Flexbox)`
  gap: 35px;
  padding: 0 30px;
  border-bottom: 1px solid ${Colors.Grey600};
`;

export const TabListItem = styled.button<{ isActive: boolean }>`
  cursor: pointer;
  border: none;
  background: none;
  position: relative;
  padding: 0 4px 8px;
  color: ${({ isActive }) => (isActive ? Colors.Grey900 : Colors.Grey700)};
  font-size: 14px;
  &:hover {
    color: ${({ isActive }) => (isActive ? Colors.Grey900 : Colors.Blue700)};
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    border-radius: 2px;
    left: 0;
    bottom: -1px;
    background: ${({ isActive }) => (isActive ? Colors.Blue800 : 'transparent')};
  }
`;

export const TabPanel = styled.div`
  height: 100%;
`;
