import React from 'react';
import { DaDataAddress as ExternalDaDataAddress } from 'react-dadata';
import useInputDebounce from '../../../helpers/hooks/useInputDebounce';
import { Input, InputProps } from '../Input';
import { getValidFormattedAddress } from './helpers';
import { AccommodationService } from '../../../services/accommodation-service';
import { SuggestionItem, SuggestionsContainer, SuggestionsList } from './styled';

type DaDataSuggestion<T> = {
  value: string;
  unrestricted_value: string;
  data: T;
};

interface DaDataAddress extends ExternalDaDataAddress {}

type AddressSuggestionsProps = {
  value: string;
  onChange: (event: DaDataSuggestion<DaDataAddress>) => void;
  inputProps?: InputProps;
  onBlur?: () => void;
  onClearInput?: () => void;
};

const AddressSuggestions: React.FC<AddressSuggestionsProps> = ({ value, onChange, onBlur, inputProps, onClearInput }) => {
  const [suggestions, setSuggestions] = React.useState<DaDataSuggestion<DaDataAddress>[]>([]);
  const [inputValue, setInputValue] = React.useState(value || '');
  const [isSelected, setIsSelected] = React.useState(false); // флаг для отслеживания выбора
  const [isFirstRender, setFirstRender] = React.useState(true); // флаг для отслеживания выбора

  const debouncedValue = useInputDebounce(inputValue);

  const fetchSuggestions = React.useCallback(async (query: string) => {
    if (!query) {
      setSuggestions([]);
      return;
    }

    try {
      const suggestions = await AccommodationService.fetchAddressSuggestions(query);

      setSuggestions(suggestions || []);
      setFirstRender(false);
    } catch (error) {
      console.error('Ошибка при получении подсказок:', error);
    }
  }, []);

  const handleInputChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFirstRender(false);
      const newValue = e.target.value;

      if (newValue !== inputValue) {
        setInputValue(newValue);
        setIsSelected(false); // Сброс флага при вводе текста

        // Если текст пустой, очищаем подсказки и устанавливаем пустое значение
        if (newValue.length === 0) {
          setFirstRender(true);
          setSuggestions([]);
          onChange({ value: '', data: {} } as DaDataSuggestion<DaDataAddress>);
          onClearInput?.();
          return;
        }

        // Вызываем onChange при изменении значения
        onChange({ value: newValue, data: {} } as DaDataSuggestion<DaDataAddress>);
      }
    },
    [inputValue, onChange, onClearInput]
  );

  const handleSelectSuggestion = React.useCallback(
    (suggestion: DaDataSuggestion<DaDataAddress>) => {
      setInputValue(suggestion.value);
      setIsSelected(true); // отмечаем, что был сделан выбор
      setSuggestions([]);
      onChange(suggestion);
    },
    [onChange]
  );

  React.useEffect(() => {
    value && setInputValue(value);
  }, [value]);

  React.useEffect(() => {
    if (isFirstRender) return;

    if (debouncedValue && debouncedValue.length >= 3 && !isSelected) {
      fetchSuggestions(debouncedValue);
    }
  }, [debouncedValue, fetchSuggestions, isFirstRender, isSelected]);

  const handleBlur = React.useCallback(() => {
    setSuggestions([]); // Скрыть список на blur
    onBlur?.(); // Вызвать onBlur, если он передан
  }, [onBlur]);

  return (
    <SuggestionsContainer>
      <Input {...inputProps} value={inputValue} onChange={e => handleInputChange(e)} onBlur={handleBlur} />

      {!!suggestions?.length && (
        <SuggestionsList>
          {suggestions?.map(suggestion => (
            <SuggestionItem
              onMouseDown={e => e.preventDefault()} // Отключаем blur на SuggestionItem
              key={suggestion.value}
              onClick={() => handleSelectSuggestion(suggestion)}
            >
              {getValidFormattedAddress(suggestion.data)}
            </SuggestionItem>
          ))}
        </SuggestionsList>
      )}
    </SuggestionsContainer>
  );
};

export default React.memo(AddressSuggestions);
