import React from 'react';
import { TabList, TabListItem, TabsContainer } from './styled';

export type TabObject = {
  title: string;
  index: number;
  disabled?: boolean;
};

type TabsPanelProps = {
  activeTabIndex: number;
  onTabIndexChange: (tabIndex: number) => void;
  tabsList: Array<TabObject | null>;
};

export const TabsPanel = React.memo<TabsPanelProps>(props => {
  const { activeTabIndex, tabsList, onTabIndexChange } = props;

  const handleTabClick = React.useCallback(
    (index: number) => {
      onTabIndexChange(index);
    },
    [onTabIndexChange]
  );

  return (
    <TabsContainer>
      <TabList>
        {tabsList.map((tab, index) => {
          if (!tab) return null;

          return (
            <TabListItem key={index} isActive={tab?.index === activeTabIndex} onClick={() => handleTabClick(index)}>
              {tab?.title}
            </TabListItem>
          );
        })}
      </TabList>
    </TabsContainer>
  );
});
