import { Supplier, SupplierCall } from 'app/models/supplier';
import { DefaultQueryParams, PaginationQueryParams } from 'app/models/common';
import { HttpService } from '../http.service';
import { TableDto } from '../models/table.dto';
import { SupplierByFiasResponse } from './dtos';

class SuppliersServiceImpl {
  private static apiEndpoint = '/api/suppliers';

  getPaginationSuppliers(params: DefaultQueryParams): Promise<TableDto<Supplier>> {
    const { per_page, page, ...queryFilters } = params;

    const queryParams = {
      per_page,
      page,
      filters: queryFilters,
    };

    return HttpService.get<TableDto<Supplier>>(`${SuppliersServiceImpl.apiEndpoint}/filtered-paged/`, {
      params: queryParams,
    }).then(response => response.data);
  }

  getSuppliers(): Promise<Supplier[]> {
    return HttpService.get<Supplier[]>(`${SuppliersServiceImpl.apiEndpoint}`).then(response => response.data);
  }

  getSupplierById(id: string): Promise<Supplier> {
    return HttpService.get<Supplier>(`${SuppliersServiceImpl.apiEndpoint}/${id}`).then(response => response.data);
  }

  getSupplierCalls(id: string, params?: PaginationQueryParams): Promise<SupplierCall[]> {
    return HttpService.get<SupplierCall[]>(`${SuppliersServiceImpl.apiEndpoint}/${id}/calls`, {
      params,
    }).then(response => response.data);
  }

  getSupplierByFiasId(fias: string): Promise<SupplierByFiasResponse> {
    return HttpService.get<SupplierByFiasResponse>(`api/v1/hwo_supplier/${fias}`).then(response => response.data);
  }
}

export const SuppliersService = new SuppliersServiceImpl();
