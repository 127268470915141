import styled from 'styled-components';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { LoginForm } from 'store/profile/models/login-form.model';
import { Login } from 'store/profile/profile.actions';
import { Button, Input, Page } from 'app/components/shared';
import { RouteName, RoutePath } from 'app/constants/route-path';
import { Colors } from 'app/themes/colors';
import Background from 'app/themes/images/bg.png';
import { ReactComponent as Logo } from 'app/themes/images/big-logo.svg';
import { ColumnCenteredFlex, ColumnCenteredFlexWithPadding, ColumnFlexbox } from 'app/typography/flex';
import { ErrorHint, HeaderBig } from 'app/typography/text';
import { KeyCode } from 'app/constants/key-code';
import { useAction } from 'app/helpers/actions/use-action';
import { shallowEqual, useSelector } from 'react-redux';
import { selectError } from '../../../store/profile/profile.selectors';

enum LoginFormField {
  Username = 'username',
  Password = 'password',
}

const LoginFormPlaceholder = { [LoginFormField.Username]: 'Введите имя пользователя', [LoginFormField.Password]: 'Введите пароль' };

export const LoginPage = () => {
  const login = useAction(Login.init);
  const loginError = useSelector(selectError, shallowEqual);

  const { handleChange, resetForm, isValid, errors, values, handleSubmit, isSubmitting } = useFormik<LoginForm>({
    initialValues: { [LoginFormField.Username]: '', [LoginFormField.Password]: '' },
    validationSchema: object().shape({
      [LoginFormField.Username]: string().required('Введите имя пользователя'),
      [LoginFormField.Password]: string().required('Ввведите пароль'),
    }),
    onSubmit: () => {
      login({ username: values.username.trim(), password: values.password.trim() });
      resetForm();
    },
  });

  const handleKeyPress = event => {
    if (event.charCode === KeyCode.Enter) {
      handleSubmit();
    }
  };

  return (
    <Page title={RouteName[RoutePath.Login]}>
      <LoginPageContainer>
        <div>
          <LoginFormContainer spacing="1rem">
            <LogoIcon />
            <HeaderBig>Портал Коммуналка</HeaderBig>
            <LoginFieldsContainer>
              <Input
                placeholder={LoginFormPlaceholder[LoginFormField.Username]}
                error={errors.username ?? loginError}
                value={values.username}
                onChange={handleChange(LoginFormField.Username)}
                onKeyPress={handleKeyPress}
                noError
              />
              <Input
                placeholder={LoginFormPlaceholder[LoginFormField.Password]}
                type="password"
                error={errors.password ?? loginError}
                value={values.password}
                onChange={handleChange(LoginFormField.Password)}
                onKeyPress={handleKeyPress}
                noError
              />

              {loginError && <Error>{loginError}</Error>}

              <LoginButton disabled={!isValid || isSubmitting} type="submit" mod="primary" onClick={() => handleSubmit()}>
                Войти
              </LoginButton>
            </LoginFieldsContainer>
          </LoginFormContainer>
        </div>
      </LoginPageContainer>
    </Page>
  );
};

const LoginPageContainer = styled(ColumnCenteredFlex)`
  background: url(${Background}) no-repeat center;
  height: 100vh;
  background-size: cover;
  background-color: ${Colors.Blue800};
`;

const LoginFormContainer = styled(ColumnCenteredFlexWithPadding)`
  padding: 3.125rem 6rem;
  background-color: ${Colors.MainBackground};
  border-radius: 0.625rem;
  width: 100%;
`;

const LoginFieldsContainer = styled(ColumnFlexbox)`
  width: 250px;
  gap: 1rem;
`;

const LogoIcon = styled(Logo)`
  margin-bottom: 2rem !important;
`;

const LoginButton = styled(Button)`
  height: 46px;
`;

const Error = styled(ErrorHint)`
  margin-bottom: 1rem;
`;
