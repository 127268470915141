import { Supplier } from 'app/models/supplier/supplier';
import { useAction } from 'app/helpers/actions/use-action';
import { formatPhoneNumber } from 'app/helpers/phone';
import { webphoneActions } from 'store/webphone';
import { ColumnFlexbox, ColumnFlexWithPadding, HeaderSmall, Label, PhoneText, SupplierInfoComponent, Text } from './styled';

interface SupplierInfoTabProps {
  supplier?: Supplier;
}

enum AboutSupplierField {
  Address = 'address',
  CeoFullName = 'ceoFullName',
  ManagedHouses = 'managedHouses',
  TaxReferenceNumber = 'taxReferenceNumber',
  PrimaryStateRegistrationNumber = 'primaryStateRegistrationNumber',
  Email = 'email',
  Website = 'website',
}

enum SupplierContactField {
  ContactPhone = 'phone',
  OwnerPhone = 'ownerPhone',
  DispatcherPhone = 'dispatcherPhone',
}

const aboutSupplierFields = [
  AboutSupplierField.Address,
  AboutSupplierField.CeoFullName,
  AboutSupplierField.ManagedHouses,
  AboutSupplierField.TaxReferenceNumber,
  AboutSupplierField.PrimaryStateRegistrationNumber,
  AboutSupplierField.Email,
  AboutSupplierField.Website,
];

const supplierContactFields = [SupplierContactField.ContactPhone, SupplierContactField.DispatcherPhone, SupplierContactField.OwnerPhone];

const aboutSupplierFieldLabelMap = {
  [AboutSupplierField.Address]: 'Адрес',
  [AboutSupplierField.CeoFullName]: 'ФИО руководителя',
  [AboutSupplierField.ManagedHouses]: 'Дома в управлении',
  [AboutSupplierField.TaxReferenceNumber]: 'ИНН',
  [AboutSupplierField.PrimaryStateRegistrationNumber]: 'ОГРН',
  [AboutSupplierField.Email]: 'E-mail',
  [AboutSupplierField.Website]: 'Веб-сайт',
};

const supplierContactsFieldLabelMap = {
  [SupplierContactField.ContactPhone]: 'Контактный телефон',
  [SupplierContactField.DispatcherPhone]: 'Телефон диспетчера',
  [SupplierContactField.OwnerPhone]: 'Телефон ответственного',
};

export const AboutSupplierInfoTab = ({ supplier }: SupplierInfoTabProps) => {
  const call = useAction(webphoneActions.InitOutgoingRinging.init);

  const getAboutSupplierFieldValue = (field: AboutSupplierField | SupplierContactField) => supplier?.[field] || 'Не назначено';

  const phoneNumberHandleClick = (phone: string) => {
    call({ phone, options: { prefix: supplier?.prefix } });
  };

  return (
    <SupplierInfoComponent>
      <ColumnFlexWithPadding spacing="10px">
        <HeaderSmall>О компании</HeaderSmall>
        <ColumnFlexWithPadding spacing="20px">
          {aboutSupplierFields.map(field => (
            <ColumnFlexbox key={field}>
              <Label>{aboutSupplierFieldLabelMap[field]}</Label>
              <Text>{getAboutSupplierFieldValue(field)}</Text>
            </ColumnFlexbox>
          ))}
        </ColumnFlexWithPadding>
      </ColumnFlexWithPadding>

      <ColumnFlexWithPadding spacing="10px">
        <HeaderSmall>Контакты</HeaderSmall>
        <ColumnFlexWithPadding spacing="20px">
          {supplierContactFields.map(field => (
            <ColumnFlexbox key={field}>
              <Label>{supplierContactsFieldLabelMap[field]}</Label>
              {supplier?.[field] ? (
                <PhoneText onClick={() => phoneNumberHandleClick(supplier[field])}>
                  {formatPhoneNumber(getAboutSupplierFieldValue(field))}
                </PhoneText>
              ) : (
                <Text>Не назначено</Text>
              )}
            </ColumnFlexbox>
          ))}
        </ColumnFlexWithPadding>
      </ColumnFlexWithPadding>
    </SupplierInfoComponent>
  );
};
