import React from 'react';
import { AboutAppealField } from '../constants/about-appeal-field.enum';
import { Appeal, AppealDictionary } from '../../../models/appeal';
import { DateFormat, formatTime, localFormat } from '../../../helpers/date/time';
import { EMPTY_FIELD_TEXT } from '../components/CommonInfo';
import { MILLISECONDS_IN_MINUTE } from '../../../constants/time/date';
import { Strong } from '../../../typography/text';
import { RedText } from '../components/CommonInfo/styled';

export const getDate = (field: AboutAppealField, appeal: Appeal) =>
  appeal[field] ? localFormat(new Date(appeal[field].toString()), DateFormat.LocalDateAndTime) : EMPTY_FIELD_TEXT;

export const getAppealType = (appealTypes: AppealDictionary[], appeal: Appeal) => {
  const type = appealTypes.find(type => type.id === appeal.appealRequisitionType)?.label;
  return type || '';
};

export const getAppealKind = (appealsKinds: AppealDictionary[], appeal: Appeal) => {
  const kind = appealsKinds.find(kind => kind.id === appeal.appealRequisitionKind)?.label;
  return kind || '';
};

export const getExpectingTimeForWork = (appeal: Appeal) =>
  localFormat(new Date(appeal!.performingStartedAtPlan), DateFormat.LocalDateAndTime) +
  localFormat(new Date(+new Date(appeal!.performingStartedAtPlan) + appeal!.expectingTimeForWorkInMin * MILLISECONDS_IN_MINUTE), '-p');

export const getDateWithOverdue = (date: Date, overdue?: number) => (
  <>
    {localFormat(date, DateFormat.LocalDateAndTime)}
    {overdue && (
      <Strong>
        <RedText> (+{formatTime(overdue, true)})</RedText>
      </Strong>
    )}
  </>
);
