import styled from 'styled-components';
import { StrongTextRegular, TextRegularBig } from 'app/typography/text';
import { Colors } from 'app/themes/colors';
import { Button, Icon } from 'app/components/shared';
import { FlexWithSpacing } from 'app/typography/flex';
import { CallDirection } from 'app/models/call';

export const AppealNumberText = styled(StrongTextRegular)`
  color: ${Colors.Blue700};
  font-size: 18px;
  line-height: 21px;
  font-weight: 500;
`;

export const SaveButton = styled(Button)`
  & {
    width: 120px;
    height: 46px;
  }
`;

export const FormRow = styled(FlexWithSpacing)`
  align-items: center;
`;

export const CallTypeIcon = styled(Icon)<{ type: CallDirection }>`
  color: ${props => (props.type === CallDirection.Incoming ? Colors.Blue800 : Colors.Green800)};
`;

export const Consumer = styled(TextRegularBig)`
  cursor: pointer;
  color: ${Colors.Blue700};
  font-weight: 500;
`;
