import { ModalProps } from 'app/services/modal-service/modal.service';
import { useDispatch } from 'react-redux';
import * as AppealsActions from 'store/appeals/appeals.actions';
import { useState, useEffect } from 'react';
import { Textarea } from 'app/components/shared';
import { HeaderSmall } from 'app/typography/text';
import { CenteredFlexWithSpacing, ColumnFlexWithPadding } from 'app/typography/flex';
import { Colors } from 'app/themes/colors';
import { AddFeedbackDto } from 'app/services/appeals-service/dtos/add-feedback-dto';
import { AnyAction } from '@reduxjs/toolkit';
import { Appeal } from 'app/models/appeal';
import { useActionListener } from 'app/helpers/actions/action-listener.hook';
import {
  CloseAppealButton,
  CloseAppealDialogComponent,
  EvaluationIcon,
  StyledCheckbox,
} from './styled';

const STARS_COUNT = 5;

interface AddFeedbackDialogProps extends ModalProps {
  appeal: Appeal;
}

export const AddFeedbackDialog = ({ onClose = () => {}, appeal }: AddFeedbackDialogProps) => {
  const dispatch = useDispatch();

  const [evaluation, setEvaluation] = useState<number>(0);
  const [hoverStar, setHoverStar] = useState<number>(0);
  const [notCall, setNotCall] = useState<boolean>(false);
  const [comment, setComment] = useState<string>('');

  useEffect(() => {
    if (notCall) {
      setComment('');
      setEvaluation(0);
    }
  }, []);

  const getIconColor = (index: number) => {
    if (notCall) return Colors.Grey500;
    return index < hoverStar || index < evaluation ? Colors.Blue800 : Colors.Grey700;
  };

  const onSaveClick = () => {
    const request: AddFeedbackDto = {
      id: appeal.id,
      comment: notCall ? null : comment,
      evaluation: notCall ? null : evaluation,
    };
    dispatch(AppealsActions.CloseAppeal.init(request));
  };

  useActionListener((action: AnyAction) => {
    switch (action.type) {
      case AppealsActions.CloseAppeal.success.type:
        onClose();
    }
  });

  return (
    <CloseAppealDialogComponent
      headerContent={<HeaderSmall>Закрыть заявку</HeaderSmall>}
      body={
        <ColumnFlexWithPadding spacing="30px">
          <CenteredFlexWithSpacing spacing="15px">
            {new Array(STARS_COUNT).fill(1).map((_, index) => (
              <EvaluationIcon
                disabled={notCall}
                color={getIconColor(index)}
                icon="/assets/icons.svg#star"
                onClick={() => setEvaluation(index + 1)}
                onMouseEnter={() => setHoverStar(index + 1)}
                onMouseLeave={() => setHoverStar(0)}
              />
            ))}
          </CenteredFlexWithSpacing>
          <ColumnFlexWithPadding spacing="20px">
            <Textarea
              disabled={notCall}
              value={comment}
              onChange={e => setComment(e.target.value)}
            />
            <StyledCheckbox
              id="not-call"
              label="Недозвон"
              checked={notCall}
              onChange={setNotCall}
            />
          </ColumnFlexWithPadding>
          <CloseAppealButton onClick={onSaveClick}>Сохранить</CloseAppealButton>
        </ColumnFlexWithPadding>
      }
      onClose={onClose}
    />
  );
};
