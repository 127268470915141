import { AppealChangesHistoryDto } from '../dtos/appeal-changes-dto';
import { AppealChangesHistory } from '../../../models/appeal';

export const appealChangeHistoryDtoMapper = (changeHistory: AppealChangesHistoryDto): AppealChangesHistory => ({
  reason: changeHistory.history_change_reason,
  createdAt: changeHistory.date,
  changedBy: {
    id: changeHistory?.changed_by_consumer?.id || changeHistory?.changed_by_dispatcher?.id || '',
    name: changeHistory?.changed_by_consumer?.name || changeHistory?.changed_by_dispatcher?.name || '',
  },
  isChangedByConsumer: !!changeHistory?.changed_by_consumer?.id,
  changes: changeHistory.changes.map((history, index) => ({
    id: index,
    field: history.field,
    newValue: history.new_value,
    oldValue: history.old_value,
  })),
});
