export enum AboutIncidentField {
  Text = 'text',
  Type = 'incident_type',
  AppealKind = 'appeal_kind',
  Author = 'author',
  CreatedAt = 'created_at',
  PlanedDateEnd = 'planned_end_date',
}

export const aboutIncidentFieldTitleMap = {
  [AboutIncidentField.Text]: 'Описание проблемы',
  [AboutIncidentField.Type]: 'Тип',
  [AboutIncidentField.AppealKind]: 'Услуга',
  [AboutIncidentField.Author]: 'Автор',
  [AboutIncidentField.CreatedAt]: 'Дата создания',
  [AboutIncidentField.PlanedDateEnd]: 'Дата планового устранения',
};

export const aboutIncidentFields: AboutIncidentField[] = [
  AboutIncidentField.Text,
  AboutIncidentField.Type,
  AboutIncidentField.AppealKind,
  AboutIncidentField.Author,
  AboutIncidentField.CreatedAt,
  AboutIncidentField.PlanedDateEnd,
];

export enum ResponsibleField {
  Supplier = 'supplier',
  Responsible = 'responsible',
  Master = 'master',
  Phone = 'phone',
}

export const responsibleFieldsNameMap = {
  [ResponsibleField.Supplier]: 'Поставщик',
  [ResponsibleField.Phone]: 'Телефон ответственного',
  [ResponsibleField.Responsible]: 'Ответственный',
  [ResponsibleField.Master]: 'Мастер',
};

export const responsibleFields: ResponsibleField[] = [
  ResponsibleField.Supplier,
  ResponsibleField.Responsible,
  ResponsibleField.Master,
  ResponsibleField.Phone,
];
