import { createSelector } from '@reduxjs/toolkit';
import { NotificationType } from 'app/models/notifications/notification';
import { AppState } from '../../types/AppState.type';
import { NotificationsState } from './notifications.reducer';

const selectNotificationsState: (state: AppState) => NotificationsState = state =>
  state.notificationsState;

export const selectInfoNotifications = createSelector(
  selectNotificationsState,
  state => state.notifications.filter(item => item.type !== NotificationType.Error)
);

export const selectAllNotifications = createSelector(
  selectNotificationsState,
  state => state.notifications
);

export const selectLoading = createSelector(selectNotificationsState, state => state.loading);

export const selectError = createSelector(selectNotificationsState, state => state.error);
