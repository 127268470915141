import React from 'react';
import { Supplier } from '../../models/supplier';
import { SuppliersService } from '../../services/suppliers-service/suppliers.service';

const defaultState = {
  supplier: null,
  sector: null,
  emergency: false,
};

export const useGetSupplierByFiasId = (fias: string | undefined) => {
  const [state, setState] = React.useState<{ supplier: Supplier | null; sector: string | null; emergency: boolean }>(defaultState);

  const resetState = React.useCallback(() => {
    setState({ supplier: null, sector: null, emergency: false });
  }, []);

  const fetchSupplierData = React.useCallback(async () => {
    try {
      if (!fias) {
        resetState();
        return;
      }

      const { manageCompany: managementCompanyId, sector, emergency } = await SuppliersService.getSupplierByFiasId(fias);

      if (!managementCompanyId) {
        resetState();
        return;
      }

      const supplier = await SuppliersService.getSupplierById(managementCompanyId);

      setState({ supplier, sector, emergency });
    } catch (error) {
      console.error('Failed to fetch supplier data:', error);
      resetState();
    }
  }, [fias, resetState]);

  React.useEffect(() => {
    fetchSupplierData().then();
  }, [fetchSupplierData]);

  return state;
};
