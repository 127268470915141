import React, { FC } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { AppealsActions, AppealsSelectors } from 'store/appeals';

import { ChatHeader, ChatTitle, ChatWindow } from './styled';
import { ChatInput } from './components/ChatInput/ChatInput';
import { MessageList } from './components/MessageList/MessageList';

interface ChatProps {}

export const Chat: FC<ChatProps> = () => {
  const dispatch = useDispatch();
  const appeal = useSelector(AppealsSelectors.selectSelectedAppeal, shallowEqual);
  const unreadMessagesLength = useSelector(AppealsSelectors.selectUnreadAppealMessagesLength, shallowEqual);

  const handleSendMessage = React.useCallback(
    (message: string) => {
      if (appeal?.id) {
        dispatch(AppealsActions.CreateAppealMessage.init({ id: appeal.id, text: message }));
      }
    },
    [appeal, dispatch]
  );

  React.useEffect(() => {
    if (!appeal?.id) return;
    !!unreadMessagesLength && dispatch(AppealsActions.SetReadAppealMessages.init({ id: appeal?.id }));
  }, [appeal?.id, dispatch, unreadMessagesLength]);

  return (
    <ChatWindow>
      <ChatHeader>
        <ChatTitle>Сообщения</ChatTitle>
      </ChatHeader>
      <MessageList />
      <ChatInput placeholder="Введите сообщение" sendMessage={handleSendMessage} />
    </ChatWindow>
  );
};
