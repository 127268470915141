import { TextRegularBig } from 'app/typography/text';
import { ColumnFlexWithPadding, FlexWithSpacing } from 'app/typography/flex';
import { WorkEvaluation } from 'app/models/appeal/appeal';
import { Colors } from 'app/themes/colors';
import { EvaluationIcon, NoComment } from './styled';

interface AppealWorkEvaluationProps {
  workEvaluation?: WorkEvaluation;
}

export const AppealWorkEvaluation = ({ workEvaluation }: AppealWorkEvaluationProps) => (
  <ColumnFlexWithPadding spacing="10px">
    <Stars evaluation={workEvaluation?.evaluation} />
    {workEvaluation?.comment ? (
      <TextRegularBig>{workEvaluation.comment}</TextRegularBig>
    ) : (
      <NoComment>Недозвон</NoComment>
    )}
  </ColumnFlexWithPadding>
);

interface StarsProps {
  evaluation?: number;
}

const Stars = ({ evaluation }: StarsProps) => {
  const starsCount = 5;
  const evaluationStartCount = evaluation || 0;

  return (
    <FlexWithSpacing spacing="5px">
      {new Array(evaluationStartCount).fill(1).map((_, index) => (
        <EvaluationIcon iconColor={Colors.Blue800} key={index} icon="/assets/icons.svg#star" />
      ))}
      {new Array(starsCount - evaluationStartCount).fill(1).map((_, index) => (
        <EvaluationIcon
          iconColor={Colors.Grey700}
          key={evaluationStartCount + index}
          icon="/assets/icons.svg#star"
        />
      ))}
    </FlexWithSpacing>
  );
};
