import styled, { css } from 'styled-components';

import { Colors } from 'app/themes/colors';
import { TextRegularBig } from 'app/typography/text';
import { ColumnFlexbox, Flexbox } from 'app/typography/flex';
import { Icon, IconClickable } from '../../shared';

const roundShape = css`
  width: 50px;
  height: 50px;
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.08);
  border-radius: 50%;
  border: none;
  cursor: pointer;
`;

export const Wrapper = styled.div<{ isExpanded }>`
  position: fixed;
  right: 20px;
  bottom: 20px;
  color: ${Colors.MainBackground};
  background: ${Colors.Blue800};
  transition: width 0.25s, height 0.25s, border-radius 0.25s, color 0.1s;
  z-index: 100;
  overflow: hidden;

  ${roundShape}

  ${({ isExpanded }) =>
    isExpanded
      ? `
    height: 385px;
    width: 250px;
    border-radius: 10px;
    background: ${Colors.MainBackground}; 
    `
      : `
  
    ${PhoneIcon} {
      margin: 13px 0px 0px 13px;
    }
    
    &:hover { 
      background ${Colors.Blue900};
    }
  `}
`;

export const Header = styled(Flexbox)`
  justify-content: space-between;
  height: 49px;
  background: ${Colors.Grey600};
  padding: 15px;
`;

export const Title = styled(TextRegularBig)`
  font-weight: 700;
  color: ${Colors.Grey900};
`;

export const CloseIcon = styled(IconClickable)`
  color: ${Colors.Grey700};

  &:hover {
    color: ${Colors.Grey800};
  }
` as typeof IconClickable;

export const PhoneIcon = styled(Icon)`
  color: ${Colors.MainBackground};
` as typeof IconClickable;

export const Pad = styled(ColumnFlexbox)`
  padding: 20px 20px 15px;
`;

export const PhoneInput = styled.input`
  height: 40px;
  padding: 12px 15px;
  margin-bottom: 21px;
  background: ${Colors.Blue400};
  border: none;
  border-radius: 6px;

  &::placeholder {
    font-weight: normal;
    color: ${Colors.Grey700};
    font-size: 14px;
  }

  &:hover {
    box-shadow: 0 0 0 1px ${Colors.Blue600};
  }

  &:focus {
    box-shadow: 0 0 0 2px ${Colors.Blue700};
    background: ${Colors.MainBackground};
    outline: none;
  }
`;

export const Digits = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
  margin-bottom: 15px;
`;

export const Dig = styled.button`
  height: 40px;
  font-size: 20px;
  color: ${Colors.Grey900};
  background: ${Colors.Grey500};
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: 0.3s;

  &:active {
    background: ${Colors.Blue500};
  }
`;

export const CallButton = styled.button`
  background: ${Colors.Green800};
  align-self: center;

  ${roundShape}
`;
