import styled from 'styled-components';
import { ColumnFlexbox } from '../../typography/flex';
import { Button } from '../../components/shared';

export const InfoComponent = styled(ColumnFlexbox)`
  height: 100%;
`;

export const GetToWorkButton = styled(Button)`
  padding: 12px 15px;
  width: 130px;
`;
