/* eslint-disable camelcase */
import { environment } from 'app/environment';
import { Participant } from 'store/webphone';
import { CancelToken } from 'axios';
import { AppealsService } from '../appeals-service/appeals.service';
import { ConsumersService } from '../consumers-service';
import { HttpService } from '../http.service';
import { ClientInfosDto } from './dtos/client-infos.dto';
import { SipInfoDto } from './dtos/sip-info.dto';

class WebPhoneDataServiceImpl {
  // private static sipApi = environment.isDevelopment ? '/asus' : environment.webphoneSipApiUrl;
  // private static axiosAsusInstance = environment.isDevelopment ? axios.post : HttpService.post;

  private static sipApi = environment.webphoneSipApiUrl;

  async getParticipant(phone: string, cancelToken: CancelToken) {
    const cleanedPhone = phone.replace(/\D/g, '');
    const consumer = await ConsumersService.getConsumerByPhone(`+${cleanedPhone}`, cancelToken);

    if (!consumer) {
      return null;
    }

    const { fio, addresses, id: consumerId } = consumer;
    const appeals = await AppealsService.getAppeals({ consumer: consumerId }, cancelToken);
    const participant: Participant = {
      consumerId,
      fullName: fio,
      addresses: addresses.map(({ id, address, manageCompany }) => ({
        id,
        address,
        managementCompany: manageCompany,
      })),
      appeals: appeals.results.map(({ id, status }) => ({ id, status })),
    };
    return participant;
  }

  async getClientId(clientName: string) {
    const clientsInfoResponse = (
      await HttpService.post<ClientInfosDto>(WebPhoneDataServiceImpl.sipApi, {
        entity: 'sipuser',
        action: 'read',
        options: { 'ext.license': 'pbx' },
      })
    ).data;

    const client = clientsInfoResponse.data.find(c => c.login === clientName);
    return client?.id;
  }

  async getQueueCount(clientName: string, cancelToken: CancelToken) {
    try {
      const [sipInfoDto, clientId] = await Promise.all([
        HttpService.post<SipInfoDto>(
          WebPhoneDataServiceImpl.sipApi,
          {
            entity: 'bpmsync',
            action: 'huntoper',
            options: {},
          },
          { cancelToken }
        ).then(({ data }) => data),
        this.getClientId(clientName),
      ]);

      if (!clientId) {
        return 0;
      }
      const clientQueues = sipInfoDto.data[0].data.data.filter(info => info.resources.includes(clientId));

      return clientQueues.reduce((acc, { objects_cnt }) => acc + objects_cnt, 0);
    } catch (error) {
      return -1;
    }
  }
}

export const WebPhoneDataService = new WebPhoneDataServiceImpl();
