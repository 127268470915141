import { createSlice } from '@reduxjs/toolkit';
import * as ProfileActions from './profile.actions';
import { Profile } from '../../app/models/profile/profile';
import { EntityState } from '../models/entity-state';
import { initialEntityState } from '../constants/initial-entity-state';
import { ErrorPayload } from '../models/error-payload';
import { Responsible } from '../../app/services/profile-service/dtos/get-profiles-by-supplier-id';

export const PROFILE_STORE_NAME = 'profile-state';

export interface ProfileState extends EntityState {
  profile?: Profile;
  profilesBySupplierId: Array<Responsible>;
  loading: boolean;
  creating: boolean;
  updating: boolean;
  error: ErrorPayload | null;
}

export const profileInitialState: ProfileState = {
  profile: undefined,
  profilesBySupplierId: [],
  error: null,

  ...initialEntityState,
};

const profileSlice = createSlice({
  name: PROFILE_STORE_NAME,
  initialState: profileInitialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(ProfileActions.Login.init, state => {
        state.loading = true;
      })
      .addCase(ProfileActions.Login.success, (state, { payload }) => {
        state.loading = false;
        state.profile = payload;
        state.error = null;
      })
      .addCase(ProfileActions.Login.failure, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(ProfileActions.LoadProfile.init, state => {
        state.loading = true;
      })
      .addCase(ProfileActions.LoadProfile.success, (state, { payload }) => {
        state.loading = false;
        state.profile = payload;
      })
      .addCase(ProfileActions.LoadProfile.failure, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(ProfileActions.GetProfilesBySupplier.init, state => {
        state.loading = true;
      })
      .addCase(ProfileActions.GetProfilesBySupplier.success, (state, { payload }) => {
        state.loading = false;
        state.profilesBySupplierId = payload;
      })
      .addCase(ProfileActions.GetProfilesBySupplier.failure, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(ProfileActions.Logout.success, () => profileInitialState)
      .addDefaultCase(() => {}),
});

export const profileReducer = profileSlice.reducer;
