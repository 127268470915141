import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { IncidentsService } from '../../app/services/incidents-service/incidents.service';
import { AddAppealsToIncident, CreateIncident, EditIncident, GetIncidentById, GetIncidents, GetRelatedAppeals } from './incidents.actions';
import { IncidentId } from '../../app/models/incident/incident';
import { CreateIncidentApiParams } from '../../app/services/incidents-service/dtos/create-incident.request';
import { IncidentsQueryParams } from '../../app/services/incidents-service/dtos/incidents-query-params';
import { EditIncidentApiParams } from '../../app/services/incidents-service/dtos/edit-incident';
import { AddAppealsToIncidentApiParams } from '../../app/services/incidents-service/dtos/add-appeals-to-incident';
import { GetRelatedAppealsApiParams } from '../../app/services/incidents-service/dtos/get-related-appeals';
import { AddNotification } from '../notifications/notifications.actions';
import { NotificationType } from '../../app/models/notifications/notification';

function* getIncidentsWorker({ payload }: PayloadAction<IncidentsQueryParams>) {
  try {
    const response = yield call(IncidentsService.getIncidents, payload);
    yield response && put(GetIncidents.success(response));
  } catch (e: any) {
    yield put(GetIncidents.failure(e));
  }
}
function* getIncidentWorker({ payload }: PayloadAction<IncidentId>) {
  try {
    const response = yield call(IncidentsService.getIncident, payload);
    yield response && put(GetIncidentById.success(response));
  } catch (e: any) {
    yield put(GetIncidentById.failure(e));
  }
}
function* createIncidentWorker({ payload }: PayloadAction<CreateIncidentApiParams>) {
  try {
    const response = yield call(IncidentsService.createIncident, payload);
    yield response && put(CreateIncident.success(response));
  } catch (e: any) {
    yield put(CreateIncident.failure(e));
  }
}
function* editIncidentWorker({ payload }: PayloadAction<EditIncidentApiParams>) {
  try {
    const response = yield call(IncidentsService.editIncident, payload);
    yield response && put(EditIncident.success(response));
  } catch (e: any) {
    yield put(EditIncident.failure(e));
  }
}
function* addAppealsToIncidentWorker({ payload }: PayloadAction<AddAppealsToIncidentApiParams>) {
  try {
    const response = yield call(IncidentsService.addAppealsToIncident, payload);
    yield response && put(AddAppealsToIncident.success(response));
    yield response && put(AddNotification.init({ body: response.message, type: NotificationType.Info }));
  } catch (e: any) {
    yield put(AddAppealsToIncident.failure(e));
    put(AddNotification.init({ body: 'Произошла ошибка. Пожалуйста, проверьте данные.', type: NotificationType.Error }));
  }
}
function* getRelatedAppealsWorker({ payload }: PayloadAction<GetRelatedAppealsApiParams>) {
  try {
    const response = yield call(IncidentsService.getRelatedAppeals, payload);
    yield response && put(GetRelatedAppeals.success(response));
  } catch (e: any) {
    yield put(GetRelatedAppeals.failure(e));
  }
}

export default function* watcher() {
  return yield all([
    takeLatest(GetIncidents.init, getIncidentsWorker),
    takeLatest(GetIncidentById.init, getIncidentWorker),
    takeLatest(CreateIncident.init, createIncidentWorker),
    takeLatest(EditIncident.init, editIncidentWorker),
    takeLatest(AddAppealsToIncident.init, addAppealsToIncidentWorker),
    takeLatest(GetRelatedAppeals.init, getRelatedAppealsWorker),
  ]);
}
