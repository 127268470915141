import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { SuppliersActions, SuppliersSelectors } from 'store/suppliers';
import { RouteName, RoutePath } from 'app/constants/route-path';
import { useNavigate } from 'react-router-dom';
import { Tab, Tabs, Header, ChevronLeft, SupplierInfoContainer, FullName, WindowLikePage, CenteredFlexbox, ColumnFlexbox } from './styled';
import { AboutSupplierInfoTab } from './components/AboutSupplierInfoTab';
import { useParams } from '../../helpers/hooks/use-params';
import { AppealsHistoryTab } from './components/AppealsHistoryTab/AppealsHistoryTab';
import { CallHistoryTab } from './components/CallsHistoryTab/CallHistoryTab';

enum SupplierInfoTab {
  SupplierInfo = 'supplierInfo',
  AppealsHistory = 'appealsHistory',
  CallsHistory = 'CallsHistory',
}

const supplierInfoTabLabelMap = {
  [SupplierInfoTab.SupplierInfo]: 'Основная информация',
  [SupplierInfoTab.AppealsHistory]: 'История заявок',
  [SupplierInfoTab.CallsHistory]: 'История звонков',
};
export const SupplierPage = () => {
  const { supplierId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectedSupplier = useSelector(SuppliersSelectors.selectSelectedSupplier, shallowEqual);

  const goBack = () => {
    navigate(-1);
  };

  React.useEffect(() => {
    if (!supplierId) return;
    dispatch(SuppliersActions.GetSupplierById.init(supplierId));
  }, [dispatch, supplierId]);

  return (
    <WindowLikePage title={RouteName[RoutePath.Supplier]}>
      <SupplierInfoContainer>
        <Header>
          <ColumnFlexbox>
            <CenteredFlexbox>
              <ChevronLeft icon="/assets/icons.svg#chevron-left" tooltip="Вернуться назад" onClick={goBack} />
              <FullName>{selectedSupplier?.name}</FullName>
            </CenteredFlexbox>
          </ColumnFlexbox>
        </Header>
        <Tabs defaultActiveKey={SupplierInfoTab.SupplierInfo}>
          <Tab tabKey={SupplierInfoTab.SupplierInfo} title={supplierInfoTabLabelMap[SupplierInfoTab.SupplierInfo]}>
            <AboutSupplierInfoTab supplier={selectedSupplier} />
          </Tab>
          <Tab tabKey={SupplierInfoTab.AppealsHistory} title={supplierInfoTabLabelMap[SupplierInfoTab.AppealsHistory]}>
            <AppealsHistoryTab supplierId={supplierId} />
          </Tab>
          <Tab tabKey={SupplierInfoTab.CallsHistory} title={supplierInfoTabLabelMap[SupplierInfoTab.CallsHistory]}>
            <CallHistoryTab supplierId={supplierId} />
          </Tab>
        </Tabs>
      </SupplierInfoContainer>
    </WindowLikePage>
  );
};
