import styled from 'styled-components';
import { Colors } from 'app/themes/colors';
import { Button, Icon, IconClickable } from 'app/components/shared';
import { CenteredFlexWithSpacing, ColumnFlexbox, Flexbox, VerticallyCenteredFlexWithSpaceBetween } from 'app/typography/flex';
import { Icons } from '../../../../constants/icons';
import { TextRegular } from '../../../../typography/text';

export const Container = styled(ColumnFlexbox)`
  padding: 20px 24px 0;
  border-bottom: 1px solid ${Colors.Grey600};
  background-color: ${Colors.White};
  border-radius: 10px;
`;
export const AppealInfoHeaderComponent = styled(VerticallyCenteredFlexWithSpaceBetween)`
  position: relative;
  align-items: flex-start;
`;

export const StatusIcon = styled(Icon)<{ color: string }>`
  fill: ${props => props.color} !important;
`;

export const StyledClickableIcon = styled(IconClickable)`
  fill: ${Colors.Grey700} !important;

  :hover {
    fill: ${Colors.Blue600} !important;
  }

  :active {
    fill: ${Colors.Blue700} !important;
  }
`;

export const TextRow = styled(CenteredFlexWithSpacing)`
  justify-content: flex-start;
`;

export const CloseAppealButton = styled(Button)`
  padding: 12px 20px;
`;

export const PdfWatchButton = styled.button`
  background-color: unset;
  outline: none;
  border: none;
`;

export const DocumentIcon = styled(Icons.Document)`
  cursor: pointer;
  width: 24px;
  height: 24px;
`;

export const Dates = styled(Flexbox)`
  margin-top: 20px;
  gap: 16px;
`;

export const DateCard = styled.div<{ isGray: boolean; isAlwaysFull: boolean; borderColor: string }>`
  background-color: ${props => (props.isGray ? '#EEF1F5' : '#fff')};
  padding: 6px 12px;
  border-radius: 6px;
  border: 1px solid ${props => (props.isGray ? 'none' : props.borderColor)};
  min-width: ${props => (props.isAlwaysFull ? '250px' : 'max-content')};
  width: ${props => (props.isAlwaysFull ? 'unset' : 'max-content')};
  color: ${props => (props.isGray ? '#BDC2CE' : '#101010')};
  position: relative;
  min-height: 82px;
`;

export const Difference = styled(TextRegular)<{ isGray: boolean }>`
  color: ${props => (props.isGray ? '#BDC2CE' : '#101010')};
`;

export const Theme = styled.span`
  font-size: 17px;
  font-weight: 500;
`;
