import { Cell } from 'react-table';
import React from 'react';
import { GreyText, TextRegular } from '../../../../typography/text';
import { DateFormat, localFormat } from '../../../../helpers/date/time';
import { SpeechNotification } from '../../../../models/speech-notification';
import { Icons } from '../../../../constants/icons';
import { Status } from '../styled';

export enum SpeechNotificationsCustomColumn {
  Status = 'active',
  ModifiedAt = 'modifiedAt',
}

export const isTableCustomColumn = (key: string): key is SpeechNotificationsCustomColumn => {
  return Object.values(SpeechNotificationsCustomColumn).includes(key as SpeechNotificationsCustomColumn);
};
export const getCustomCell = (key: SpeechNotificationsCustomColumn) => {
  switch (key) {
    case SpeechNotificationsCustomColumn.ModifiedAt:
      return (props: Cell<SpeechNotification>) => {
        const speechNotification = props.row.original;

        return <TextRegular>{localFormat(new Date(speechNotification?.modifiedAt), DateFormat.LocalDateAndTime)}</TextRegular>;
      };

    case SpeechNotificationsCustomColumn.Status:
      return (props: Cell<SpeechNotification>) => {
        const isActive = props.row.original.active;

        return isActive ? (
          <Status isActive>
            <Icons.InProgress /> Активный
          </Status>
        ) : (
          <Status isActive={false}>
            <Icons.Cancel /> Не активный
          </Status>
        );
      };

    default:
      return null;
  }
};

export const getLabel = (label?: string) => label || <GreyText>Пусто</GreyText>;
