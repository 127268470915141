import { Button, Dialog } from 'app/components/shared';
import { ColumnFlexWithPadding } from 'app/typography/flex';
import styled from 'styled-components';

export const SelectSupplierDialog = styled(Dialog)`
  width: 420px;
`;

export const SelectSupplierDialogBody = styled(ColumnFlexWithPadding)`
  align-items: flex-start;
`;

export const SelectButton = styled(Button)`
  width: auto;
  padding: 15px 20px;
`;
