import styled from 'styled-components';
import { Colors } from 'app/themes/colors';
import { IconClickable } from '../../../shared';

export const SEND_BUTTON_CLASSNAME = 'send-button';

export const ChatInputContainer = styled.div`
  border-top: 1px solid ${Colors.Grey600};
  padding: 15px;
`;

export const ChatWrapper = styled.div`
  position: relative;
`;

export const Input = styled.div`
  border-radius: 6px;
  background: ${Colors.Blue400};
  min-height: 46px;
  outline: none;
  padding: 15px 45px 15px 15px;
  cursor: pointer;

  &:empty:not(:focus)::before {
    content: attr(placeholder);
    color: ${Colors.Grey700};
    pointer-events: none;
  }

  &:not(:empty) {
    cursor: text;
  }
`;

export const SendButton = styled(IconClickable)`
  position: absolute;
  right: 15px;
  bottom: 13px;
  color: ${Colors.Grey700};
  transition: 200ms ease-out;
  opacity: 0;
  transform: scale(0.5);

  :hover {
    color: ${Colors.Blue600};
  }

  :active {
    color: ${Colors.Blue700};
  }

  &.${SEND_BUTTON_CLASSNAME}-enter {
    opacity: 1;
    transform: scale(1);
  }

  &.${SEND_BUTTON_CLASSNAME}-enter-done {
    opacity: 1;
    transform: scale(1);
  }
`;
