import React from 'react';
import { format } from 'date-fns';
import { Column, Row } from 'react-table';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { AppealDictionary } from '../../../../../../models/appeal';
import { Incident, IncidentLabelStatusToLabelMap } from '../../../../../../models/incident/incident';
import { AddressesData } from '../../../../../Mailings/components/AddressesList';
import { getAppealKind } from '../../../../../Incident/sections/CommonInfo/helpers';
import { IncidentAppealsTable } from '../../../../../Incident/sections/CommonInfo/sections/AppealsTable/styled';
import { incidentStatusDict, incidentStatusIconDict } from '../../../../../Incidents/components/IncidentStatusTitle/IncidentStatusTitle';
import { StatusIcon } from '../../../../../Incident/sections/Header/styled';
import { UpdateAppeal } from '../../../../../../../store/appeals/appeals.actions';
import { selectAppealKinds, selectSelectedAppeal } from '../../../../../../../store/appeals/appeals.selectors';
import { TextRegularBig } from '../../../../../../typography/text';

const DateCell: React.FC<{ value: string | undefined }> = ({ value }) => (
  <span>{format(new Date(value || new Date()), 'dd.MM.yyyy HH:mm')}</span>
);

const AddressCell: React.FC<{ addresses: AddressesData[] | undefined }> = ({ addresses }) => <span>{addresses?.[0].address}</span>;

const StatusCell: React.FC<{ status: string | undefined }> = ({ status }) => (
  <>
    <StatusIcon color={incidentStatusDict[status || '']} icon={incidentStatusIconDict[status || '']} />{' '}
    <span>{IncidentLabelStatusToLabelMap[status || '']}</span>
  </>
);

const AppealKind: React.FC<{ kind: string | undefined; kinds: AppealDictionary[] }> = ({ kind, kinds }) => (
  <span>{getAppealKind(kinds, kind || '')}</span>
);

export const AddIncidentsTable = React.memo(() => {
  const dispatch = useDispatch();

  const appealsKinds = useSelector(selectAppealKinds, shallowEqual);
  const appeal = useSelector(selectSelectedAppeal, shallowEqual);

  const columns: Column<Incident>[] = React.useMemo(
    () => [
      {
        Header: 'Номер',
        accessor: 'number',
        width: 70,
      },
      {
        Header: 'Дата создания',
        accessor: 'created_at',
        Cell: ({ value }) => <DateCell value={value} />,
      },
      {
        Header: 'Услуга',
        accessor: 'appeal_kind',
        Cell: ({ value }) => <AppealKind kind={value} kinds={appealsKinds} />,
      },
      {
        Header: 'Адрес',
        accessor: 'addresses',
        Cell: ({ value }) => <AddressCell addresses={value} />,
        width: 100,
      },
      {
        Header: 'Статус',
        accessor: 'status',
        Cell: ({ value }) => <StatusCell status={value} />,
      },
    ],
    [appealsKinds]
  );

  const onRowClick = React.useCallback(
    (cell: Row<Incident>) => {
      const incidentId = cell.original.id;

      if (!appeal?.id || !incidentId) return;

      dispatch(
        UpdateAppeal.init({
          incidentId,
          id: appeal?.id,
          consumerId: appeal?.consumer.id,
          address: appeal?.address?.address,
          addressId: appeal.address.id,
          dispatcherId: appeal?.dispatcher?.id,
        })
      );
    },
    [appeal, dispatch]
  );

  if (!appeal?.relatedIncidents?.length) return <TextRegularBig>Инцидентов нет</TextRegularBig>;

  return (
    <div>
      <IncidentAppealsTable onRowClick={onRowClick} columns={columns} data={appeal?.relatedIncidents} />
    </div>
  );
});
