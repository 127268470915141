import { Mailing, MailingsQueryParams, MailingStatus } from 'app/models/mailing';
import { CreateMailingRequest } from 'app/services/mailings-service/dtos/create-mailing-request';
import { UpdateMailingRequest } from 'app/services/mailings-service/dtos/update-mailing-request';
import { AddressesData } from 'app/pages/Mailings/components/AddressesList';
import { TableDto } from 'app/services/models/table.dto';
import { getCreateActionTrio } from '../helpers/action-create';
import { MailingTemplates } from '../../app/services/mailings-service/dtos/get-mailing-templates-request';
import { createPayloadAction } from '../configuration/configuration.actions';

export const MAILINGS_STORE_NAME = 'mailings';

export const createActionTrio = getCreateActionTrio(MAILINGS_STORE_NAME);

export const GetMailings = createActionTrio<MailingsQueryParams | void, TableDto<Mailing>>('get mailings');

export const CreateMailing = createActionTrio<CreateMailingRequest, Mailing>('create mailing');

export const UpdateMailing = createActionTrio<UpdateMailingRequest, Mailing>('update mailing');

export const DeleteMailing = createActionTrio<{ id: string }, { id: string }>('delete mailing');

export const GetNumberRecipients = createActionTrio<{ addresses: AddressesData[] }, { number: number }>('get number recipients');
export const GetMailingTemplates = createActionTrio<void, MailingTemplates>('get mailing templates');
export const SetActiveStatusTab = createPayloadAction<MailingStatus | undefined>('set mailing status');
