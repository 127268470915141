import styled from 'styled-components';

import { ColumnFlexbox } from 'app/typography/flex';
import { HeaderSmall, TextRegular, TextRegularBig } from 'app/typography/text';
import { Colors } from 'app/themes/colors';
import { media } from 'styles/media';

export const AccommodationInfoTabContainer = styled(ColumnFlexbox)`
  padding: 20px 30px;
  gap: max(30px, 12%);

  ${media.md`
    flex-direction: row;
  `}
`;

export const Column = styled(ColumnFlexbox)``;

export const AccommodationColumn = styled(ColumnFlexbox)`
  min-width: 330px;
`;

export const CustomerAccountsColumn = styled(ColumnFlexbox)`
  min-width: 220px;
`;

export const ManagementCompanyColumn = styled(ColumnFlexbox)`
  min-width: 220px;
`;

export const TextGroup = styled(ColumnFlexbox)``;

export const Heading = styled(HeaderSmall)`
  margin-bottom: 10px;
`;

export const Label = styled(TextRegular)`
  margin-bottom: 5px;
  color: ${Colors.Grey700};
`;

export const Text = styled(TextRegularBig)`
  margin-bottom: 20px;
`;
