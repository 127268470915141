import { environment } from 'app/environment';
import Axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { CustomAxiosRequestConfig } from './models/custom-axios-request-config';

export type HttpHeaders = { [key: string]: string };
export const IS_RETRY_HEADER = 'Is-Retry';
export type FULLFILLED<V = any> = (value: V) => V | Promise<V>;
export type REJECTED<V = any> = (err: AxiosError | any) => Promise<AxiosResponse<V>> | any | Error;
export type REQUEST_INTERCEPTOR<VNewResponse = any> = {
  onFulfilled: FULLFILLED<AxiosRequestConfig>;
  onRejected: REJECTED<VNewResponse>;
};
export type RESPONSE_INTERCEPTOR<VResponse = any, VNewResponse = any> = {
  onFulfilled: FULLFILLED<AxiosResponse<VResponse>>;
  onRejected: REJECTED<VNewResponse>;
};

class HttpServiceImpl {
  private _client!: AxiosInstance;
  responseInterceptors: RESPONSE_INTERCEPTOR[] = [];
  requestInterceptors: REQUEST_INTERCEPTOR[] = [];

  private static addSlash(url: string): string {
    return url.lastIndexOf('/') === url.length - 1 ? url : url.concat('/');
  }

  private baseUrl = environment.apiBaseUrl;

  setUp() {
    this._client = Axios.create(this.mergeConfig());
    for (const x of this.requestInterceptors) {
      this._client.interceptors.request.use(x.onFulfilled, x.onRejected);
    }

    for (const x of this.responseInterceptors) {
      this._client.interceptors.response.use(x.onFulfilled, x.onRejected);
    }
  }

  addResponseInterceptor = ({ onFulfilled, onRejected }: RESPONSE_INTERCEPTOR) => {
    this.responseInterceptors.push({ onFulfilled, onRejected });
  };

  addRequestInterceptor = ({ onFulfilled, onRejected }: REQUEST_INTERCEPTOR) => {
    this.requestInterceptors.push({ onFulfilled, onRejected });
  };

  fromConfig<T = any>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this._client.request(this.mergeConfig(config));
  }

  public get<ResponseType>(url: string, config?: CustomAxiosRequestConfig) {
    return this._client.get<ResponseType>(HttpServiceImpl.addSlash(url), this.mergeConfig(config));
  }

  public post<ResponseType>(url: string, body?: any, config?: CustomAxiosRequestConfig) {
    return this._client.post<ResponseType>(HttpServiceImpl.addSlash(url), body, this.mergeConfig(config));
  }

  public put<ResponseType>(url: string, body?: any, config?: CustomAxiosRequestConfig) {
    return this._client.put<ResponseType>(HttpServiceImpl.addSlash(url), body, this.mergeConfig(config));
  }

  public patch<ResponseType>(url: string, body?: any, config?: CustomAxiosRequestConfig) {
    return this._client.patch<ResponseType>(HttpServiceImpl.addSlash(url), body, this.mergeConfig(config));
  }

  public delete<ResponseType>(url: string, config?: CustomAxiosRequestConfig) {
    return this._client.delete<ResponseType>(HttpServiceImpl.addSlash(url), this.mergeConfig(config));
  }

  private mergeConfig(config?: CustomAxiosRequestConfig) {
    return {
      ...config,
      headers: {
        ...config?.headers,
      },
      withCredentials: true,
      baseURL: this.baseUrl,
    } as CustomAxiosRequestConfig;
  }
}

const HttpService = new HttpServiceImpl();

export { HttpService };
