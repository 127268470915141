import { FC } from 'react';
import { CallDirection as CallType } from 'app/models/call';
import { CallDirectionIcon } from './styled';

interface CallDirectionProps {
  direction: CallType | 'missed';
}

export const CallDirection: FC<CallDirectionProps> = ({ direction }) => {
  direction = direction || 'missed';

  const callDirectionIcon = {
    [CallType.Incoming]: 'incoming',
    [CallType.Outgoing]: 'outgoing',
    missed: 'incoming',
  }[direction];

  if (!callDirectionIcon) {
    return <>-</>;
  }

  return (
    <CallDirectionIcon icon={`/assets/icons.svg#${callDirectionIcon}`} direction={direction} />
  );
};
