export interface Setting {
  id: number;
  name: string;
  key: SettingType;
  value: string | SelectSupplierDashboard[];
}

export type SelectSupplierDashboard = {
  supplierName: string;
  dashboard_url: string;
};

export enum SettingType {
  DashboardUrl = 'dashboard_url',
  AppealAcceptingDelayInSec = 'appealAcceptingDelayInSec',
  CallAcceptingDelayInSec = 'callAcceptingDelayInSec',
  PercentTax = 'percentTax',
  MinTax = 'minTax',
}
