import styled from 'styled-components';
import { ColumnFlexbox, FlexWithSpacing } from 'app/typography/flex';
import { ErrorHint, TextRegular } from 'app/typography/text';

export const DatepickerContainer = styled(ColumnFlexbox)`
  position: relative;
  width: 100%;
`;

export const DatepickerComponent = styled(ColumnFlexbox)`
  margin-bottom: 5px;
  height: 48px;
`;

export const IntervalFilter = styled(FlexWithSpacing)`
  align-items: center;
  justify-content: flex-start;
`;

export { ErrorHint, TextRegular };
