import React from 'react';
import { FormField } from '../FormField';
import { Datepicker } from './datepicker';

export interface FormDatepickerProps {
  label?: string;
  value?: string | number;
  onChange: (date: string) => void;
  noError: boolean;
}

export const FormDatepicker = ({ label, value = '', onChange, noError }: FormDatepickerProps) => {
  const onChangeHandler = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // const selectedDate = new Date(e.target.value);
      //
      // // Получаем смещение часового пояса клиента в минутах
      // const timezoneOffset = selectedDate.getTimezoneOffset();
      //
      // // Вычитаем смещение из выбранной даты
      // const adjustedDate = new Date(selectedDate.getTime() + timezoneOffset * 60000);
      //
      // // Форматируем дату как 'YYYY-MM-DD HH:mm:ss'
      // const adjustedDateString = adjustedDate.toISOString();

      onChange(e.target.value);
    },
    [onChange]
  );
  return (
    <FormField placeholder={label}>
      <Datepicker type="date" value={value} onChange={onChangeHandler} noError={noError} />
    </FormField>
  );
};
