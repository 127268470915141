import React, { FC, KeyboardEvent, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { KeyCode } from 'app/constants/key-code';
import { ChatInputContainer, ChatWrapper, Input, SEND_BUTTON_CLASSNAME, SendButton } from './styled';

interface ChatInputProps {
  placeholder: string;
  sendMessage(message: string): void;
  className?: string;
}

export const ChatInput: FC<ChatInputProps> = ({ placeholder, className, sendMessage }) => {
  const [newMessage, setNewMessage] = useState('');
  const inputRef = useRef<HTMLDivElement>(null);

  const isSendButtonActive = newMessage.length > 0;

  const handleInput = () => {
    setNewMessage(inputRef.current?.innerText || '');
  };

  const handleInputClick = () => {
    inputRef.current?.focus();
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.charCode === KeyCode.Enter && !event.shiftKey && inputRef.current) {
      sendMessage(inputRef.current.innerText);
      inputRef.current.innerText = '';
      setNewMessage('');
      event.preventDefault();
    }
  };

  const handleSendButtonClick = () => {
    if (inputRef.current?.innerText.trim()) {
      sendMessage(inputRef.current.innerText);
      inputRef.current.innerText = '';
      setNewMessage('');
    }
  };

  return (
    <ChatInputContainer className={className}>
      <ChatWrapper>
        <Input
          ref={inputRef}
          placeholder={placeholder}
          contentEditable
          onInput={handleInput}
          onClick={handleInputClick}
          onKeyPress={handleKeyDown}
        />
        <CSSTransition in={isSendButtonActive} timeout={200} classNames={SEND_BUTTON_CLASSNAME}>
          <SendButton icon="/assets/icons.svg#move" onClick={handleSendButtonClick} />
        </CSSTransition>
      </ChatWrapper>
    </ChatInputContainer>
  );
};
