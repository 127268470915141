import { LocalStorageService } from '../local-storage-service';

class TokenServiceImpl {
  private tokenPath: string = 'accessToken';
  private userIdPath: string = 'userId';
  private refreshTokenPath: string = 'refreshToken';

  get token(): string | undefined {
    return this.localStorage.getItem(this.tokenPath);
  }

  set token(token: string | undefined) {
    token ? this.localStorage.setItem(this.tokenPath, token) : this.localStorage.removeItem(this.tokenPath);
  }

  get refreshToken(): string | undefined {
    return this.localStorage.getItem(this.refreshTokenPath);
  }

  set refreshToken(token: string | undefined) {
    token ? this.localStorage.setItem(this.refreshTokenPath, token) : this.localStorage.removeItem(this.refreshTokenPath);
  }

  set userId(userId: string | undefined) {
    userId ? this.localStorage.setItem(this.userIdPath, userId) : this.localStorage.removeItem(this.userIdPath);
  }

  get userId() {
    return this.localStorage.getItem(this.userIdPath);
  }

  get isTokenExpired() {
    return false;
  }

  get isRefreshTokenExpired() {
    return false;
  }

  get canBeRefreshed() {
    return !!this.refreshToken && !this.isRefreshTokenExpired;
  }

  // eslint-disable-next-line no-empty-function
  constructor(private localStorage: LocalStorageService) {}
}

export const TokenService = new TokenServiceImpl(new LocalStorageService('auth'));
