import { FC } from 'react';
import { intervalToDuration } from 'date-fns';
import { useSelector } from 'react-redux';
import { ConfigurationSelectors } from 'store/configuration';
import { RedText } from './styled';

interface CallDurationProps {
  durationInSec: number;
}

export const CallDuration: FC<CallDurationProps> = ({ durationInSec = 0 }) => {
  const callAcceptingDelay = useSelector(ConfigurationSelectors.selectCallsDuration);
  const duration = intervalToDuration({ start: 0, end: durationInSec * 1000 });
  const limitExceed = intervalToDuration({
    start: 0,
    end: (durationInSec - callAcceptingDelay) * 1000,
  });
  const isLimitExceed = durationInSec > callAcceptingDelay;

  return (
    <>
      {duration.minutes} м {duration.seconds} c{' '}
      {isLimitExceed && (
        <RedText>
          (+ {limitExceed.minutes} м {limitExceed.seconds} с)
        </RedText>
      )}
    </>
  );
};
