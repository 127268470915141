import styled from 'styled-components';
import { css } from 'styled-components/macro';
import { Table } from '../../../../../../components/shared';
import { Colors } from '../../../../../../themes/colors';

export const IncidentAppealsTable = styled(Table)<{ themeWhite?: boolean }>`
  ${({ themeWhite }) =>
    themeWhite &&
    css`
      background-color: ${Colors.White};
      border-radius: 20px;
      margin-top: 20px;
    `}

  max-height: 600px;
  overflow-y: auto;
  margin-top: 10px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: #eceef2;
    border-radius: 180px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #90a6ce;
    border-radius: 180px;
  }

  & .bodyClassName {
    padding: 10px 15px;
  }

  thead {
    th {
      padding: 7px 10px 15px;

      &:last-child {
        text-align: left;
      }
    }
  }

  td {
    &:last-child {
      text-align: left;
    }
  }
` as typeof Table;

export const AddButton = styled.div`
  width: 46px;
  height: 46px;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 10px;
  color: ${Colors.Grey700};
  cursor: pointer;

  :hover {
    border: 2px solid ${Colors.Grey700};
    color: ${Colors.Grey800};
  }
`;
