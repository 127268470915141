/**
 * Replaces first found item in source array with new one based on predicate function.
 */
export function replaceOnce<T>(source: T[], predicate: (val: T) => boolean, withValue: T): T[] {
  return _replaceOnce(source, predicate, withValue).items;
}

// eslint-disable-next-line no-underscore-dangle
export function _replaceOnce<T>(
  source: T[],
  predicate: (val: T) => boolean,
  withValue: T,
): { items: T[]; isReplaced: boolean; sourceIndex: number } {
  const newArray = source.slice();
  const currentIndex = source.findIndex(t => predicate(t));
  if (currentIndex >= 0) {
    newArray[currentIndex] = withValue;
  }
  return { items: newArray, isReplaced: currentIndex >= 0, sourceIndex: currentIndex };
}
