import { Colors } from 'app/themes/colors';

export const autocompleteStyles = `
   .react-dadata__container {
     position: relative;
     width: 100%;
     
     &--error .react-dadata__input {
       border: 1px solid ${Colors.Red800};
     }
   }
   
  .react-dadata__input {
    width: 100%;
    background: ${Colors.Blue400};
    padding: 15px 20px;
    line-height: 16px;
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid #dadfea;
    
    &::placeholder {
      color: #a5abbc;
    }
  
    &:hover {
      border: 1px solid #a5abbc;
    }

    &:focus {
      outline: none;
      border: 1px solid #214E9D;
    } 
    
   &:disabled {
    background: #e6eaf1;
    border: none;
  }
    
  
  }
  
  .react-dadata__suggestions {
    border: 1px solid ${Colors.Grey600};
    background: ${Colors.MainBackground};
    padding: 30px;
    box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    position: absolute;
    top: calc(100% + 5px);
    width: 100%;
    z-index: 10;
    overflow: hidden;
  }
  
  .react-dadata__suggestion {
    cursor: pointer;
    width: 100%;
    background: none;
    border: none;
    padding: 6px 20px;
    text-align: left;
    font-size: 14px;
    
    &:last-of-type {
      padding-bottom: 15px;
    }
    
    &:first-of-type {
      padding-top: 15px;
    }
  }
  
  .react-dadata--highlighted {
    color: ${Colors.Blue700};
  }
`;
