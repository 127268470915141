export enum AboutAppealField {
  IsUrgent = 'isUrgent',
  AppealRequisitionType = 'appealRequisitionType',
  AppealRequisitionKind = 'appealRequisitionKind',
  Message = 'message',
  Id = 'id',
  isPayment = 'isPayment',
}

export const aboutAppealFieldTileMap = {
  [AboutAppealField.Id]: 'Сквозной номер',
  [AboutAppealField.IsUrgent]: 'Срочность',
  [AboutAppealField.AppealRequisitionType]: 'Тип заявки',
  [AboutAppealField.AppealRequisitionKind]: 'Вид заявки',
  [AboutAppealField.Message]: 'Описание проблемы',
  [AboutAppealField.isPayment]: 'Стоимость',
};

export const aboutAppealFields: AboutAppealField[] = [
  AboutAppealField.Message,
  AboutAppealField.IsUrgent,
  AboutAppealField.isPayment,
  AboutAppealField.AppealRequisitionType,
  AboutAppealField.AppealRequisitionKind,
];
