import { useRef, useState } from 'react';

import { Option } from 'app/models/common/option';
import { useOnClickOutside } from 'app/helpers/click-outside/click-outside.hook';
import {
  DropdownArrow,
  DropdownContainer,
  DropdownHeader,
  DropdownHeaderIcon,
  DropdownHeaderTitle,
  DropdownOptionItem,
  DropdownOptionsContainer,
  DropdownOptionsScrollbar,
} from './styled';

interface DropdownProps<DropdownValue> {
  value?: Option<DropdownValue>;
  options: Option<DropdownValue>[];
  disabled?: boolean;
  placeholder?: string;
  arrow?: string;
  icon?: string;
  className?: string;
  autoHeightMax?: number;
  onChange?: (option: Option<DropdownValue>) => void;
}

export function Dropdown<DropdownValue>({
  value,
  options = [],
  disabled,
  placeholder,
  arrow = '/assets/icons.svg#chevron-down',
  icon,
  className,
  autoHeightMax,
  onChange = () => {},
}: DropdownProps<DropdownValue>) {
  const [isOptionsShown, setIsOptionsShown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleHeaderClick = () => {
    setIsOptionsShown(prevIsOpen => !prevIsOpen);
  };

  const handleOptionClick = (option: Option<DropdownValue>) => () => {
    setIsOptionsShown(false);
    onChange(option);
  };

  useOnClickOutside(dropdownRef, () => setIsOptionsShown(false));

  return (
    <DropdownContainer ref={dropdownRef} className={className}>
      <DropdownHeader onClick={handleHeaderClick} disabled={disabled}>
        {icon && <DropdownHeaderIcon icon={icon} />}
        <DropdownHeaderTitle>{value?.label || placeholder}</DropdownHeaderTitle>
        <DropdownArrow icon={arrow} isOptionsShown={isOptionsShown} disabled={disabled} />
      </DropdownHeader>
      {isOptionsShown && (
        <DropdownOptionsContainer>
          <DropdownOptionsScrollbar autoHeight autoHeightMax={autoHeightMax}>
            {options.map(option => (
              <DropdownOptionItem
                key={option.label}
                onClick={handleOptionClick(option)}
                isActive={value?.value === option.value}
              >
                {option.label}
              </DropdownOptionItem>
            ))}
          </DropdownOptionsScrollbar>
        </DropdownOptionsContainer>
      )}
    </DropdownContainer>
  );
}
