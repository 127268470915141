import styled from 'styled-components';
import { Table, Icon } from 'app/components/shared';

export const StyledTable = styled(Table)`
  padding: 0 1.25rem;
  min-height: calc(100vh - (72px + 1.25rem * 4 + 72px));

  .actions {
    overflow: inherit;
    width: 45px !important;
  }

  .address {
    overflow: hidden;
  }
` as typeof Table;

export { Icon };
