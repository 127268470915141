import styled from 'styled-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Page, Document } from 'react-pdf';
import { Dialog } from '../../../../components/shared';
import { Colors } from '../../../../themes/colors';

export const StyledDialog = styled(Dialog)`
  width: 1000px;
  height: 673px;
  overflow: scroll;
  position: relative;

  & .headerClassName {
    background: ${Colors.White};
    position: fixed;
    width: 999px;
    z-index: 999;
    padding: 10px 24px;
    border-radius: 6px;
  }

  & .bodyClassName {
  }
`;

export const Header = styled.header``;

export const StyledDocument = styled(Document)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledPage = styled(Page)`
  width: 100%;
  height: 100%;
  canvas {
    width: 100% !important;
    height: auto !important;
  }
`;
