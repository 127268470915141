import { TablesConfiguration } from 'app/models/configuration';
import { HttpService } from '../http.service';

class ConfigurationServiceImpl {
  private static apiEndpoint = '/api/configuration';

  getTablesConfiguration(): Promise<TablesConfiguration> {
    return HttpService.get<TablesConfiguration>(
      `${ConfigurationServiceImpl.apiEndpoint}/tables`
    ).then(response => response.data);
  }
}

export const ConfigurationService = new ConfigurationServiceImpl();
