import { getCreateActionTrio } from '../helpers/action-create';
import { GetCommentsApiParams } from '../../app/services/comments-service/dtos/get-comments-dto';
import { TableDto } from '../../app/services/models/table.dto';
import { Comment } from '../../app/models/comments/comments';
import { CreateCommentApiParams } from '../../app/services/comments-service/dtos/create-comment-dto';
import { EditCommentApiParams } from '../../app/services/comments-service/dtos/edit-comment-dto';
import { DeleteCommentApiParams } from '../../app/services/comments-service/dtos/delete-comment-dto';

export const COMMENTS_STORE_NAME = 'comments-state';

const createActionTrio = getCreateActionTrio(COMMENTS_STORE_NAME);

export const LoadComments = createActionTrio<GetCommentsApiParams, TableDto<Comment>>('load comments');
export const CreateComment = createActionTrio<CreateCommentApiParams, Comment>('create comment');
export const EditComment = createActionTrio<EditCommentApiParams, Comment>('edit comment');
export const DeleteComment = createActionTrio<DeleteCommentApiParams, DeleteCommentApiParams>('delete comment');
