import styled from 'styled-components';

import { TextRegular, TextRegularBig, HeaderSmall } from 'app/typography/text';
import { Colors } from 'app/themes/colors';
import { ColumnFlexbox, ColumnFlexWithPadding, Flexbox } from 'app/typography/flex';

export const SupplierInfoComponent = styled(Flexbox)`
  padding: 20px 30px;
  gap: max(30px, 12%);

  & > * {
    min-width: 260px;
  }
`;

export const Label = styled(TextRegular)`
  margin-bottom: 5px;
  color: ${Colors.Grey700};
`;

export const Text = styled(TextRegularBig)`
  margin-bottom: 20px;
`;

export const PhoneText = styled(Text)`
  cursor: pointer;
  color: ${Colors.Blue800};
`;

export { HeaderSmall, ColumnFlexbox, ColumnFlexWithPadding };
