import styled from 'styled-components';
import {
  DropdownHeader,
  DropdownHeaderTitle,
  DropdownOptionsContainer,
  DropdownOptionItem
} from 'app/components/shared/Dropdown/styled';
import { Button, Dialog, Dropdown, Preloader } from 'app/components/shared';
import { Colors } from 'app/themes/colors';
import { ColumnFlexWithPadding } from 'app/typography/flex';

export const SelectMasterDialogComponent = styled(Dialog)`
  width: 420px;
`;

export const SelectMasterDialogBody = styled(ColumnFlexWithPadding)`
  align-items: flex-start;
`;

export const AssignButton = styled(Button)`
  width: auto;
  padding: 15px 20px;
`;

export const ErrorText = styled.div`
  font-size: 12px;
  margin: 5px auto 0;
  color: ${Colors.Red800};
`;

export const StyledDropdown = styled(Dropdown)`
  ${DropdownHeader} {
    width: 100%;
    padding: 10px 10px 10px 15px;
    background-color: ${Colors.Blue400};
    border-radius: 6px;
  }

  ${DropdownHeaderTitle} {
    text-align: left;
    font-size: 14px;
    line-height: 16px;
    color: ${Colors.Grey900};
    flex: 1;
    text-overflow: ellipsis;
  }

  ${DropdownOptionItem} {
    color: ${Colors.Grey900};
    font-size: 14px;
    padding: 6px 10px;

    &:hover {
      color: ${Colors.Blue600};
    }
  }

  ${DropdownOptionsContainer} {
    width: 100%;
  }
`;

export const StyledPreloader = styled(Preloader)`
  width: 46px;
  height: 46px;
`;
