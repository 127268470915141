import { Notification } from 'app/models/notifications/notification';
import { HttpService } from '../http.service';

class NotificationsServiceImpl {
  private static apiNotificationsEndpoint = 'api/notifications/';

  getNotifications(): Promise<Notification[]> {
    return HttpService.get<Notification[]>(NotificationsServiceImpl.apiNotificationsEndpoint).then(
      response => response.data,
    );
  }
}

export const NotificationsService = new NotificationsServiceImpl();
