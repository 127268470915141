import React from 'react';
import { AnyAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { StyledDialog } from './styled';
import { Header } from './components/Header/Header';
import { ModalProps } from '../../../../services/modal-service/modal.service';
import { Form } from './components/Form/Form';
import { IncidentId } from '../../../../models/incident/incident';
import { useActionListener } from '../../../../helpers/actions/action-listener.hook';
import { CreateIncident, EditIncident } from '../../../../../store/incidents/incidents.actions';
import { LoadComments } from '../../../../../store/comments/comments.actions';
import { AppealId } from '../../../../models/appeal';
import { GetAppealById } from '../../../../../store/appeals/appeals.actions';
import { useLockBodyScroll } from '../../../../helpers';

export type IncidentFormProps = { getToWork: boolean; incidentId?: IncidentId; appealId?: AppealId } & ModalProps;

export const IncidentForm = ({ getToWork, incidentId, appealId, onClose = () => {} }: IncidentFormProps) => {
  const dispatch = useDispatch();

  useActionListener((action: AnyAction) => {
    if (action.type === CreateIncident.success.type || action.type === EditIncident.success.type) {
      onClose();
      incidentId && dispatch(LoadComments.init({ id: String(incidentId), entity: 'incident' }));
      appealId && dispatch(GetAppealById.init({ id: appealId }));
    }
  });

  useLockBodyScroll();

  return (
    <StyledDialog
      headerContent={<Header getToWork={getToWork} incidentId={incidentId} />}
      body={<Form incidentId={incidentId} getToWork={getToWork} />}
      onClose={onClose ?? (() => {})}
    />
  );
};
