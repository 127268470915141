import React, { useEffect, useState } from 'react';
import { TableColumn } from 'app/models/configuration/table-structure';
import { Cell, Column } from 'react-table';
import { useSelector } from 'react-redux';
import { ConfigurationSelectors } from 'store/configuration';
import { useAction } from 'app/helpers/actions/use-action';
import { SuppliersActions, SuppliersSelectors } from 'store/suppliers';
import { PagesRoutes } from 'app/constants/route-path';
import { Supplier } from 'app/models/supplier';
import { formatPhoneNumber } from 'app/helpers/phone';
import { generatePath, useNavigate } from 'react-router-dom';
import { Icon, StyledTable } from './styled';

export enum SupplierCustomCells {
  Phone = 'phone',
  DispatcherPhone = 'dispatcherPhone',
  OwnerPhone = 'ownerPhone',
}

interface SuppliersTableProps {
  isSideMenuOpen: boolean;
  isSearchFocus: boolean;
}

export const SuppliersTable = ({ isSideMenuOpen, isSearchFocus }: SuppliersTableProps) => {
  const navigate = useNavigate();

  const [pages, setPages] = useState(1);

  const columns = useSelector(ConfigurationSelectors.selectSuppliersColumns);
  const suppliers = useSelector(SuppliersSelectors.selectSuppliersForTable);
  const suppliersTotalCount = useSelector(SuppliersSelectors.selectSuppliersTotalCount);
  const suppliersFilters = useSelector(SuppliersSelectors.selectChosenFilters);
  const isLoading = useSelector(SuppliersSelectors.selectSuppliersLoading);

  const loadSuppliers = useAction(SuppliersActions.GetSuppliersForTable.init);

  useEffect(() => {
    if (!isSideMenuOpen && !isSearchFocus && !isLoading) {
      setPages(1);
      loadSuppliers({ page: 1, ...suppliersFilters });
    }
  }, [suppliersFilters]);

  const getTableColumns = (columns: TableColumn[]): Column<Supplier>[] =>
    columns
      .map(column =>
        Object({
          Header: column.label,
          id: column.key,
          accessor: column.key,
          Cell: getCell(column.key),
        })
      )
      .concat(
        Object({
          Header: <Icon icon="/assets/icons.svg#settings" />,
          id: 'settings',
          accessor: 'settings',
        })
      );

  const getCell = (key: string) => {
    switch (key) {
      case SupplierCustomCells.Phone:
        return (cell: Cell<Supplier>) => <>{formatPhoneNumber(cell.row.original.phone)}</>;
      case SupplierCustomCells.DispatcherPhone:
        return (cell: Cell<Supplier>) => <>{formatPhoneNumber(cell.row.original.dispatcherPhone)}</>;
      case SupplierCustomCells.OwnerPhone:
        return (cell: Cell<Supplier>) => <>{formatPhoneNumber(cell.row.original.ownerPhone)}</>;
      default:
        return (cell: Cell<Supplier>) => <>{cell.row.original[key]}</>;
    }
  };

  const cellHandleClick = (cell: Cell<Supplier>) => {
    const supplier = cell.row.original;
    navigate(
      generatePath(PagesRoutes.PAGES.SUPPLIER, {
        supplierId: String(supplier?.id),
      })
    );
  };

  const handleNextPageLoad = () => {
    loadSuppliers({ page: pages + 1, ...suppliersFilters });
    setPages(prevState => prevState + 1);
  };

  return (
    <StyledTable
      columns={getTableColumns(columns)}
      emptyTableTitle="Контрагентов не найдено"
      onCellClick={cellHandleClick}
      data={suppliers}
      dataLength={suppliersTotalCount}
      isInfinity
      nextPage={handleNextPageLoad}
      isLoading={isLoading && pages === 1}
    />
  );
};
