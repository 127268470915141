import React, { Component, ReactNode } from 'react';
import { Wrapper, Title } from './styled';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  public static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return { hasError: true };
  }

  public state: ErrorBoundaryState = {
    hasError: false,
  };

  public render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <>
          <Wrapper spacing="2rem">
            <Title>Что-то пошло не так</Title>
          </Wrapper>
        </>
      );
    }

    return children;
  }
}
