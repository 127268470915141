import React from 'react';
import styled from 'styled-components';
import { Colors } from 'app/themes/colors';
import { ErrorHint, TextRegular } from 'app/typography/text';
import { ColumnFlexWithPadding, FlexWithSpacing } from 'app/typography/flex';
import { Option } from 'app/models/common/option';
import { FormField } from '../FormField';
import { FormFieldError } from '../FormFieldError';

export interface CheckboxProps {
  id: string;
  checked?: boolean;
  onChange: (checked) => void;
  disabled?: boolean;
  label?: string;
  className?: string;
  tabIndex?: number;
}

export const Checkbox = ({ id, checked, onChange, disabled, label, className, tabIndex }: CheckboxProps) => (
  <Styled className={className} tabIndex={tabIndex}>
    <input id={id} type="checkbox" disabled={disabled} checked={checked} onChange={onChange} />
    <label htmlFor={id}>
      <TextRegular>{label}</TextRegular>
    </label>
  </Styled>
);

const onCheckClick = (checkValue: string, value: string[], onChange: (check: string[]) => void) => {
  if (value) {
    if (value.some(check => check === checkValue)) {
      onChange(value.filter(check => check !== checkValue));
    } else {
      onChange([...value, checkValue]);
    }
  } else onChange([checkValue]);
};

const getCheckboxValue = (value: string[], option: Option<string>) => (value ? value.some(check => check === option.value) : false);

export interface CheckboxGroupProps {
  value: string[];
  onChange: (check: string[]) => void;
  options: Option<string>[];
  label?: string;
  error?: string;
}

export const CheckboxGroup = ({ value, label, options, onChange }: Omit<CheckboxGroupProps, 'error'>) => (
  <FormField placeholder={label}>
    <ColumnFlexWithPadding spacing="0.625re">
      {options.map(option => (
        <Checkbox
          id={option.value}
          key={option.value}
          checked={getCheckboxValue(value, option)}
          onChange={() => onCheckClick(option.value, value, onChange)}
          label={option.label}
        />
      ))}
    </ColumnFlexWithPadding>
  </FormField>
);

export const CheckboxGroupRow = ({ value, label, options, onChange, error }: CheckboxGroupProps) => (
  <FormField placeholder={label}>
    <ColumnFlexWithPadding spacing="0.3125rem">
      <FlexWithSpacing spacing="0.625rem">
        {options.map(option => (
          <RowCheckbox
            id={option.value}
            key={option.value}
            checked={getCheckboxValue(value, option)}
            onChange={() => onCheckClick(option.value, value, onChange)}
            label={option.label}
          />
        ))}
      </FlexWithSpacing>
      <ErrorHint>{error && <FormFieldError error={error} />}</ErrorHint>
    </ColumnFlexWithPadding>
  </FormField>
);

const RowCheckbox = styled(Checkbox)`
  padding: 13px 15px;
  background: ${Colors.Blue400};
  border-radius: 6px;
`;

const Styled = styled.div`
  display: inline-block;
  width: 100%;

  > input {
    opacity: 0;
    display: none;
  }

  > input + label {
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    white-space: nowrap;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 1px;
      width: 1.25rem;
      height: 1.25rem;
      border: 2px solid ${Colors.Blue600};
      border-radius: 3px;
    }

    &:after {
      content: '';
      position: absolute;
      top: 7.5px;
      left: 5.5px;
      height: 6px;
      width: 10px;
      transform: rotate(-45deg);
      border: 2px solid ${Colors.MainBackground};
      border-radius: 1px;
      border-top: 0;
      border-right: 0;
      margin-top: -1px;
    }
  }

  > input:checked:focus + label,
  input:not(:checked):focus + label {
    &:before {
      border-color: ${Colors.Blue700};
    }
  }

  > input:checked + label {
    &:after {
      opacity: 1;
    }

    &:before {
      border-color: ${Colors.Blue800};
      background: ${Colors.Blue800};
    }
  }

  > input:not(:checked) + label {
    &:after {
      opacity: 0;
    }

    :hover {
      &:before {
        border-color: ${Colors.Blue700};
      }
    }
  }

  > input:disabled:not(:checked) + label {
    &:before {
      border-color: ${Colors.Grey700};
    }
  }

  > input:disabled + label {
    color: ${Colors.Grey700};

    &:before {
      border-color: ${Colors.Grey700};
    }
  }

  > input:disabled:checked + label {
    color: ${Colors.Grey700};

    &:before {
      border-color: ${Colors.Grey700};
      background: ${Colors.Grey700};
    }
  }
`;
