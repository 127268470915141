import { Appeal } from 'app/models/appeal/appeal';
import { formatPhoneNumber } from 'app/helpers/phone/phone';
import { APPEAL_CATEGORIES, APPEAL_STATUS } from 'app/constants/appeals';
import { AboutAppealField } from '../../constants/about-appeal-field.enum';
import { ResponsibleField } from '../../constants/responsible-field.enum';

export const aboutAppealFieldConditions = (field: AboutAppealField, appeal?: Appeal) =>
  notRequisitionCondition(field, appeal) ||
  requisitionCondition(field, appeal) ||
  urgentCondition(field, appeal) ||
  performingStartCondition(field, appeal) ||
  performingEndCondition(field, appeal);

const notRequisitionCondition = (field: AboutAppealField, appeal?: Appeal) =>
  appeal?.type !== APPEAL_CATEGORIES.requisition &&
  appeal?.[field] &&
  field !== AboutAppealField.ExpectingTimeForWorkInMin &&
  field !== AboutAppealField.AppealRequisitionType;

const urgentCondition = (field: AboutAppealField, appeal?: Appeal) =>
  field === AboutAppealField.IsUrgent && (appeal?.isUrgent || appeal?.isEmergency);

const performingStartCondition = (field: AboutAppealField, appeal?: Appeal) =>
  appeal?.[field] &&
  field === AboutAppealField.PerformingStartedAt &&
  appeal?.status !== APPEAL_STATUS.new;

const performingEndCondition = (field: AboutAppealField, appeal?: Appeal) =>
  appeal?.[field] &&
  field === AboutAppealField.PerformingEndedAt &&
  (appeal?.status === APPEAL_STATUS.completed || appeal?.status === APPEAL_STATUS.closed);

const expectingWorkTimeCondition = (field: AboutAppealField, appeal?: Appeal) =>
  field === AboutAppealField.ExpectingTimeForWorkInMin && appeal?.handyman?.id;

const requisitionCondition = (field: AboutAppealField, appeal?: Appeal) =>
  appeal?.type === APPEAL_CATEGORIES.requisition &&
  (appeal?.[field] || expectingWorkTimeCondition(field, appeal));

export const responsibleFieldConditions = (field: ResponsibleField, appeal?: Appeal) => {
  if (appeal?.type === APPEAL_CATEGORIES.requisition) {
    return field === ResponsibleField.MasterPhone
      ? formatPhoneNumber(appeal?.handyman?.phone)
      : true;
  }
  return field === ResponsibleField.Dispatcher;
};
