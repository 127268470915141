// eslint-disable-next-line import/no-extraneous-dependencies
import createCachedSelector from 're-reselect';
import { AppState } from '../../types/AppState.type';
import { defaultCachedSelectorKeySelector } from '../../utils/selectors';
import { CommentId } from '../../app/models/comments/comments';

const selectCommentsState = (state: AppState) => state.commentsState;

const selectCommentsIdsSelector = (() => {
  const selector = createCachedSelector([selectCommentsState], state => {
    return state.comments?.filter(comment => Boolean(comment?.id)).map(comment => comment.id) || [];
  })(defaultCachedSelectorKeySelector);

  return (state: AppState) => selector(state);
})();

export const selectCommentsIds = (state: AppState) => selectCommentsIdsSelector(state);

const selectCommentByIdSelector = (() => {
  const selector = createCachedSelector(
    [selectCommentsState, (_S: AppState, commentId: CommentId | undefined) => commentId],
    (state, commentId) => {
      if (!commentId) return undefined;
      return state.comments?.find(comment => comment?.id === commentId);
    }
  )(defaultCachedSelectorKeySelector);

  return (state: AppState, commentId: CommentId | undefined) => selector(state, commentId);
})();

export const selectCommentById = (commentId: CommentId | undefined) => (state: AppState) => selectCommentByIdSelector(state, commentId);
