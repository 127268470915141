import { ReactNode } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { HorizontalScrollTrack, ScrollThumb, ScrollView, VerticalScrollTrack } from './styled';

export interface ScrollbarProps {
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  horizontalScrollDisabled?: boolean;
  verticalScrollDisabled?: boolean;
  autoHide?: boolean;
  autoHideTimeout?: number;
  autoHideDuration?: number;
  autoHeight?: boolean;
  autoHeightMin?: number;
  autoHeightMax?: number;
}

export const Scrollbar = ({
  children,
  className,
  disabled,
  autoHide = false,
  autoHideTimeout = 1500,
  autoHideDuration = 500,
  autoHeight = false,
  autoHeightMin,
  autoHeightMax,
  horizontalScrollDisabled,
  verticalScrollDisabled,
}: ScrollbarProps) => {
  if (disabled) {
    return <>{children}</>;
  }

  return (
    <Scrollbars
      className={className}
      renderThumbHorizontal={props => <ScrollThumb {...props} className="thumb-horizontal" />}
      renderThumbVertical={props => <ScrollThumb {...props} className="thumb-vertical" />}
      renderTrackHorizontal={props => (
        <HorizontalScrollTrack
          {...props}
          className="track-horizontal"
          disabled={horizontalScrollDisabled}
        />
      )}
      renderTrackVertical={props => (
        <VerticalScrollTrack
          {...props}
          className="track-vertical"
          disabled={verticalScrollDisabled}
        />
      )}
      renderView={props => <ScrollView {...props} className="view" />}
      autoHide={autoHide}
      autoHideTimeout={autoHideTimeout}
      autoHideDuration={autoHideDuration}
      autoHeight={autoHeight}
      autoHeightMin={autoHeightMin}
      autoHeightMax={autoHeightMax}
      hideTracksWhenNotNeeded
    >
      {children}
    </Scrollbars>
  );
};
