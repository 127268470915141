import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';

import { PageContainer, PageWrapper } from './styled';

interface WindowLikePageProps {
  title: string;
  children: ReactNode;
  className?: string;
}

export const WindowLikePage = ({ title, children, className }: WindowLikePageProps) => (
  <PageContainer className={className}>
    <Helmet title={title} />
    <PageWrapper>{children}</PageWrapper>
  </PageContainer>
);
