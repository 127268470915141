import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { FormikProvider, useFormik, useFormikContext } from 'formik';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import { Incident, IncidentId } from '../../../../../../../models/incident/incident';
import { IncidentAppealsTable } from '../../AppealsTable/styled';
import { selectRelatedAppeals } from '../../../../../../../../store/incidents/incidents.selectors';
import { AddAppealsToIncident } from '../../../../../../../../store/incidents/incidents.actions';
import { Checkbox } from '../../../../../../../components/shared';
import { SubmitButton } from '../../../styled';
import { TextRegularBig } from '../../../../../../../typography/text';

type AppealsTableProps = {
  incidentId: IncidentId | undefined;
};

type Appeal = Incident['appeals'][number];

const DateCell: React.FC<{ value: string | undefined }> = ({ value }) => (
  <span>{format(new Date(value || new Date()), 'dd.MM.yyyy HH:mm')}</span>
);

export type FormValues = {
  selectedAppeals: string[];
};

const initialValues: FormValues = {
  selectedAppeals: [],
};

const CheckboxCell: React.FC<{
  row: any;
}> = ({ row }) => {
  const formik = useFormikContext<FormValues>();

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = e.target.checked;
      const appealId = row.original.id;

      let updatedSelectedAppeals;
      if (isChecked) {
        updatedSelectedAppeals = [...formik.values.selectedAppeals, appealId];
      } else {
        updatedSelectedAppeals = formik.values.selectedAppeals.filter((id: string) => id !== appealId);
      }

      formik.setFieldValue('selectedAppeals', updatedSelectedAppeals);
    },
    [formik, row.original.id]
  );

  return <Checkbox id={row.original.id} checked={formik.values.selectedAppeals.includes(row.original.id)} onChange={handleChange} />;
};

export const EditRelatedAppealsTable = React.memo(({ incidentId }: AppealsTableProps) => {
  const dispatch = useDispatch();
  const appeals = useSelector(selectRelatedAppeals, shallowEqual);

  const [allSelected, setAllSelected] = React.useState(false);

  const onSubmit = React.useCallback(
    values => {
      if (!values || !incidentId) return;

      dispatch(AddAppealsToIncident.init({ id: incidentId, appeals: values.selectedAppeals }));
    },
    [dispatch, incidentId]
  );

  const formik = useFormik<FormValues>({
    initialValues,
    onSubmit,
  });

  const handleSelectAll = React.useCallback(() => {
    if (allSelected) {
      formik.setFieldValue('selectedAppeals', []);
    } else {
      const allAppealIds = appeals.map(appeal => appeal.id);
      formik.setFieldValue('selectedAppeals', allAppealIds);
    }
    setAllSelected(!allSelected);
  }, [allSelected, appeals, formik]);

  const data = useMemo(() => appeals || [], [appeals]);

  const columns: Column<Appeal>[] = [
    {
      Header: <Checkbox checked={allSelected} onChange={handleSelectAll} id="all-in" />,
      id: 'checkbox',
      Cell: ({ row }) => <CheckboxCell row={row} />,
    },
    {
      Header: 'Номер',
      accessor: 'number',
      width: 70,
    },
    {
      Header: 'Дата создания',
      accessor: 'createdAt',
      width: 130,
      Cell: ({ value }) => <DateCell value={value} />,
    },
    {
      Header: 'Адрес',
      accessor: 'address',
    },
    {
      Header: 'Описание проблемы',
      accessor: 'subject',
    },
  ];

  if (!appeals?.length) return <TextRegularBig>Нет заявок по указанному адресу</TextRegularBig>;

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <IncidentAppealsTable columns={columns} data={data} />
        <SubmitButton disabled={!formik.values.selectedAppeals.length} type="submit">
          Сохранить
        </SubmitButton>
      </form>
    </FormikProvider>
  );
});
