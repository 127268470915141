import styled from 'styled-components';

import { Colors } from 'app/themes/colors';
import { Flexbox } from 'app/typography/flex';

export const ScrollThumb = styled.div`
  border-radius: 10px;
  background: ${Colors.Blue600};
`;

export const HorizontalScrollTrack = styled.div<{ disabled: boolean }>`
  width: 100%;
  border-radius: 10px;
  background: ${Colors.Grey600};
  height: 10px !important;
  left: 0;
  bottom: 0;
  z-index: 2;
  display: ${({ disabled }) => (disabled ? 'none' : 'block')};
`;

export const VerticalScrollTrack = styled.div<{ disabled: boolean }>`
  height: 100%;
  border-radius: 10px;
  background: ${Colors.Grey600};
  width: 10px !important;
  right: 0;
  top: 0;
  z-index: 2;
  display: ${({ disabled }) => (disabled ? 'none' : 'block')};
`;

export const ScrollView = styled(Flexbox)``;
