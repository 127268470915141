import classNames from 'classnames';
import React, { FC } from 'react';
import styled from 'styled-components';

import { Colors } from 'app/themes/colors';

export type ButtonMode = 'primary' | 'secondary';

export type ButtonNativeProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export interface ButtonCustomProps {
  children?: React.ReactNode | JSX.Element | JSX.Element[];
  mod?: ButtonMode;
}

export type ButtonProps = ButtonNativeProps & ButtonCustomProps;

export const Button: FC<ButtonProps> = ({ disabled, onClick, className, mod = 'primary', children, type }) => (
  <ButtonComponent type={type} disabled={disabled} className={classNames(className, mod)} onClick={onClick}>
    {children}
  </ButtonComponent>
);

const ButtonComponent = styled.button`
  border-radius: 6px;
  width: 100%;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  cursor: pointer;
  appearance: none;
  border: none;
  outline: none;

  &.primary {
    color: ${Colors.MainBackground};
    background: ${Colors.Blue800};
    box-shadow: 0 0 0 2px ${Colors.Blue800};
  }

  &.secondary {
    color: ${Colors.Blue800};
    background: ${Colors.Blue400};
    box-shadow: 0 0 0 2px ${Colors.Blue600};
  }

  :hover {
    &.primary {
      color: ${Colors.MainBackground};
      background: ${Colors.Blue900};
      box-shadow: 0 0 0 2px ${Colors.Blue900};
    }

    &.secondary {
      color: ${Colors.Blue900};
      background: ${Colors.Blue400};
      box-shadow: 0 0 0 2px ${Colors.Blue800};
    }
  }

  :disabled {
    cursor: not-allowed;

    &.primary {
      color: ${Colors.MainBackground};
      background: ${Colors.Grey700};
      box-shadow: 0 0 0 2px ${Colors.Grey700};
    }

    &.secondary {
      color: ${Colors.Grey700};
      background: ${Colors.Grey500};
      box-shadow: 0 0 0 2px ${Colors.Grey600};
    }
  }
`;
