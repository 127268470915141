import { createSelector } from '@reduxjs/toolkit';
import createCachedSelector from 're-reselect';
import { AppState } from '../../types/AppState.type';
import { defaultCachedSelectorKeySelector } from '../../utils/selectors';

export const selectMailingsState = (state: AppState) => state.mailingsState;

export const selectMailings = createSelector(selectMailingsState, state => state.mailings);

export const selectQuery = createSelector(selectMailingsState, state => state.query);

export const selectLoading = createSelector(selectMailingsState, state => state.loading);
export const selectCreateInProgress = createSelector(selectMailingsState, state => state.creating);
export const selectUpdateInProgress = createSelector(selectMailingsState, state => state.updating);
export const selectDeleteOnProgress = createSelector(selectMailingsState, state => state.deleting);
export const selectError = createSelector(selectMailingsState, state => state.error);
export const selectCount = createSelector(selectMailingsState, state => state.mailingsCount);

export const selectMailingTemplates = createSelector(selectMailingsState, state => state.mailingTemplates?.templates || []);

const mailingTemplatesOptionsSelector = (() => {
  const selector = createCachedSelector([selectMailingTemplates], templates => {
    if (!templates?.length) return [];

    return templates?.map(template => ({
      label: template.title,
      value: template.id,
    }));
  })(defaultCachedSelectorKeySelector);

  return (state: AppState) => selector(state);
})();

export const selectMailingTemplatesOptions = (state: AppState) => mailingTemplatesOptionsSelector(state);
