import { createSlice } from '@reduxjs/toolkit';
import { EntityState } from '../models/entity-state';
import { initialEntityState } from '../constants/initial-entity-state';
import { COMMENTS_STORE_NAME, CreateComment, DeleteComment, EditComment, LoadComments } from './comments.actions';
import { Comment } from '../../app/models/comments/comments';
import { replaceOnce } from '../../app/helpers/arrays';

export type CommentState = {
  comments: Comment[];
  comment: Comment | null;
  totalCount: number;
  error: any;
} & EntityState;

export const commentsInitialState: CommentState = {
  comments: [],
  comment: null,
  totalCount: 0,
  error: null,
  ...initialEntityState,
};

const commentsSlice = createSlice({
  name: COMMENTS_STORE_NAME,
  initialState: commentsInitialState,
  reducers: {},

  extraReducers: builder => {
    builder

      .addCase(LoadComments.init, state => {
        state.loading = true;
      })

      .addCase(LoadComments.success, (state, { payload }) => {
        state.loading = false;
        state.comments = payload.page === 1 ? payload.results : state.comments.concat(payload.results);
        state.totalCount = payload.count;
      })

      .addCase(LoadComments.failure, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(CreateComment.init, state => {
        state.creating = true;
      })

      .addCase(CreateComment.success, (state, { payload }) => {
        state.creating = false;
        state.comment = payload;
        state.comments = [payload].concat(state.comments);
      })

      .addCase(CreateComment.failure, (state, { payload }) => {
        state.creating = false;
        state.error = payload;
      })
      .addCase(EditComment.init, state => {
        state.updating = true;
      })

      .addCase(EditComment.success, (state, { payload }) => {
        state.updating = false;
        state.comments = replaceOnce(state.comments, ({ id }) => id === payload.id, payload);
      })

      .addCase(EditComment.failure, (state, { payload }) => {
        state.updating = false;
        state.error = payload;
      })

      .addCase(DeleteComment.init, state => {
        state.deleting = true;
      })

      .addCase(DeleteComment.success, (state, { payload }) => {
        console.log(state.comments, payload);
        state.deleting = false;
        state.comments = state.comments.filter(comment => comment.id !== payload.id);
      })

      .addCase(DeleteComment.failure, (state, { payload }) => {
        state.deleting = false;
        state.error = payload;
      })

      .addDefaultCase(() => {});
  },
});

export const commentsReducer = commentsSlice.reducer;
