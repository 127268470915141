import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { CreateMailingRequest } from 'app/services/mailings-service/dtos/create-mailing-request';
import { UpdateMailingRequest } from 'app/services/mailings-service/dtos/update-mailing-request';
import { Mailing, MailingId, MailingsQueryParams } from 'app/models/mailing';
import { MailingsService } from 'app/services/mailings-service/mailings.service';
import { AddressesData } from 'app/pages/Mailings/components/AddressesList';
import * as MailingsActions from './mailings.actions';
import { DEFAULT_ROWS_COUNT } from '../../app/constants/scroll';

function* getMailingsWorker({ payload }: PayloadAction<MailingsQueryParams>) {
  try {
    const response = yield call(MailingsService.getMailings, {
      ...payload,
      per_page: DEFAULT_ROWS_COUNT,
    });
    yield put(MailingsActions.GetMailings.success(response));
  } catch (e: any) {
    yield put(MailingsActions.GetMailings.failure(e));
  }
}

function* createMailingWorker({ payload }: PayloadAction<CreateMailingRequest>) {
  try {
    const response: Mailing = yield call(MailingsService.createMailing, payload);
    yield put(MailingsActions.CreateMailing.success(response));
    yield put(MailingsActions.GetMailings.init({ page: 1, per_page: 50 }));
  } catch (e: any) {
    yield put(MailingsActions.CreateMailing.failure(e));
  }
}

function* updateMailingWorker({ payload }: PayloadAction<UpdateMailingRequest>) {
  try {
    const response: Mailing = yield call(MailingsService.updateMailing, payload);
    yield put(MailingsActions.UpdateMailing.success(response));
  } catch (e: any) {
    yield put(MailingsActions.UpdateMailing.failure(e));
  }
}

function* deleteMailingWorker({ payload }: PayloadAction<{ id: MailingId }>) {
  try {
    yield call(MailingsService.deleteMailing, payload.id);
    yield put(MailingsActions.DeleteMailing.success(payload));
  } catch (e: any) {
    yield put(MailingsActions.DeleteMailing.failure(e));
  }
}

function* getNumberRecipientsWorker({ payload }: PayloadAction<{ addresses: AddressesData[] }>) {
  try {
    const response = yield call(MailingsService.getNumberRecipients, payload);
    yield put(MailingsActions.GetNumberRecipients.success(response));
  } catch (e: any) {
    yield put(MailingsActions.GetNumberRecipients.failure(e));
  }
}

function* getMailingTemplatesWorker() {
  try {
    const response = yield call(MailingsService.getMailingTemplates);
    yield response.templates && put(MailingsActions.GetMailingTemplates.success(response));
  } catch (e: any) {
    yield put(MailingsActions.GetMailingTemplates.failure(e));
  }
}

export default function* watcher() {
  return yield all([
    takeLatest(MailingsActions.GetMailings.init, getMailingsWorker),
    takeLatest(MailingsActions.CreateMailing.init, createMailingWorker),
    takeLatest(MailingsActions.UpdateMailing.init, updateMailingWorker),
    takeLatest(MailingsActions.DeleteMailing.init, deleteMailingWorker),
    takeLatest(MailingsActions.GetNumberRecipients.init, getNumberRecipientsWorker),
    takeLatest(MailingsActions.GetMailingTemplates.init, getMailingTemplatesWorker),
  ]);
}
