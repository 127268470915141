export enum AppealActionType {
  CallConsumer = 'call-consumer',
  CallMaster = 'call-master',

  EditAppeal = 'edit-appeal',
  AssignMaster = 'assign-master',
  EditWorkPlan = 'edit-work-plan',

  ChangeStatusToInTheWork = 'change-status-to-in-the-work',
  ChangeStatusToPostponed = 'change-status-to-postponed',
  ChangeStatusToCompleted = 'change-status-to-completed',
  ChangeStatusToClosed = 'change-status-to-closed',
  ChangeStatusToCanceled = 'cancel-appeal',

  AddFeedBack = 'add-feedback',

  ChangeSupplier = 'change-supplier',
}

export const appealActionTypeToLabelMap = {
  [AppealActionType.CallConsumer]: 'Позвонить потребителю',
  [AppealActionType.CallMaster]: 'Позвонить мастеру',
  [AppealActionType.ChangeStatusToCanceled]: 'Отменить заявку',
  [AppealActionType.EditAppeal]: 'Редактировать',
  [AppealActionType.AssignMaster]: 'Назначить мастера',
  [AppealActionType.EditWorkPlan]: 'Редактировать инф по работе',
  [AppealActionType.ChangeStatusToInTheWork]: 'Сменить статус на “В работе”',
  [AppealActionType.ChangeStatusToPostponed]: 'Сменить статус на “Отложена”',
  [AppealActionType.ChangeStatusToCompleted]: 'Сменить статус на “Выполнена”',
  [AppealActionType.ChangeStatusToClosed]: 'Закрыть заявку',
  [AppealActionType.AddFeedBack]: 'Добавить оценку',
  [AppealActionType.ChangeSupplier]: 'Сменить поставщика',
};
