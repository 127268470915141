import { useEffect } from 'react';
import { useForceUpdate } from './use-force-update.hook';

export function useIntervalUpdate(ms: number, enabled: boolean = true) {
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    let useEffectIsCleared = false;
    let interval: number;
    if (enabled) {
      const startIntervalAfterMs = 1000 - new Date().getMilliseconds();

      window.setTimeout(() => {
        if (!useEffectIsCleared) {
          interval = window.setInterval(() => {
            forceUpdate();
          }, ms);
        }
      }, startIntervalAfterMs);
    }
    return () => {
      useEffectIsCleared = true;
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [ms, enabled, forceUpdate]);
}
