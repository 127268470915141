import { TableColumn, TablesConfiguration } from '../../../app/models/configuration';

export const sortTableColumnsOfEachEntity = (
  entities: TablesConfiguration
): TablesConfiguration => {
  for (const entityKey of Object.keys(entities)) {
    if (Object.prototype.hasOwnProperty.call(entities, entityKey) && entities[entityKey].columns) {
      entities[entityKey].columns.sort(compareTableColumns);
    }

    if (
      Object.prototype.hasOwnProperty.call(entities, entityKey) &&
      entities[entityKey].tableStructures
    ) {
      for (const tableStructureKey of Object.keys(entities[entityKey].tableStructures)) {
        if (
          Object.prototype.hasOwnProperty.call(
            entities[entityKey].tableStructures,
            entities[entityKey].tableStructures[tableStructureKey]
          ) &&
          entities[entityKey].tableStructures[tableStructureKey].columns
        ) {
          entities[entityKey].tableStructures[tableStructureKey].columns.sort(compareTableColumns);
        }
      }
    }
  }

  return entities;
};

const compareTableColumns = (a: TableColumn, b: TableColumn) => {
  const orderA = a.sort || 0;
  const orderB = b.sort || 0;

  return orderA - orderB;
};
