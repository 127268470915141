import {
  HangupOptions,
  IncomingCallOptions,
  IncomingConnectionEstablishedOptions,
  OutgoingCallOptions,
} from 'app/services/webphone.service';
import { getCreateActionFromStore, getCreateActionTrio, getCreatePayloadActionFromStore } from '../helpers/action-create';
import { IncomingRingingPayload, OutgoingRingingInitPayload, OutgoingRingingSuccessPayload, Participant, WebphoneType } from './models';

export const WEBPHONE_STORE_NAME = 'webphone';

const createPayloadAction = getCreatePayloadActionFromStore(WEBPHONE_STORE_NAME);
const createAction = getCreateActionFromStore(WEBPHONE_STORE_NAME);
const createActionTrio = getCreateActionTrio(WEBPHONE_STORE_NAME);

const InitIncomingRinging = createActionTrio<IncomingCallOptions, IncomingRingingPayload>('init incoming ringing');

const InitOutgoingRinging = createActionTrio<OutgoingRingingInitPayload, OutgoingRingingSuccessPayload>('init outgoing ringing');

const establishedConnection = createPayloadAction<OutgoingCallOptions>('established outgoing connection');
const establishingOutgoingConnection = createPayloadAction<OutgoingCallOptions>('establishing outgoing connection');

const establishIncomingConnection = createPayloadAction<IncomingConnectionEstablishedOptions>('established incoming connection');

const setWebphoneType = createPayloadAction<WebphoneType>('set webphone type');

const setIsCallDurationLimitExceeded = createPayloadAction<boolean>('set is call duration limit exceeded');

const setIsRegistered = createPayloadAction<boolean>('set is registered');

const setParticipant = createPayloadAction<Participant>('set is participant');

const setOpenedAppealId = createPayloadAction<number | undefined>('set opened appeal id');

const changeDispatcherComment = createPayloadAction<string>('change dispatcher comment');

const transferCall = createPayloadAction<OutgoingRingingInitPayload>('transfer call');

const answer = createAction('answer');
const call = createAction('call');
const hangup = createAction('hangup');
const unregister = createAction('unregister');
const handleSessionTermination = createActionTrio<HangupOptions, void>('handle session termination');

export const webphoneActions = {
  InitIncomingRinging,

  InitOutgoingRinging,

  establishedConnection,
  establishingOutgoingConnection,
  establishIncomingConnection,

  setWebphoneType,
  setParticipant,
  setIsRegistered,
  setIsCallDurationLimitExceeded,
  setOpenedAppealId,

  changeDispatcherComment,

  transferCall,

  answer,
  call,
  hangup,
  unregister,
  handleSessionTermination,
};
