import styled from 'styled-components';
import { Button, Datepicker, Dialog, FormField, FormSelect } from '../../../../components/shared';
import { FlexWithSpacing } from '../../../../typography/flex';

export const StyledDialog = styled(Dialog)`
  width: 760px;
`;

export const IncidentSelect = styled(FormSelect)`
  width: 340px;
`;

export const StyledFormField = styled(FormField)`
  position: relative;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const IncidentDatePicker = styled(Datepicker)`
  height: 46px;
`;

export const SubmitButton = styled(Button)`
  max-width: 170px;
  padding: 15px 20px;

  margin-left: auto;
`;

export const DateContainer = styled(FlexWithSpacing)`
  width: 100%;
`;
