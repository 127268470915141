import { Cell, Column } from 'react-table';
import { format } from 'date-fns';
import { CallDirection, CallDuration, CallTableCallActionsMenu, CommentCell } from 'app/components/shared';
import { formatPhoneNumber } from 'app/helpers/phone';
import { Call } from 'app/models/call';
import { GreyText, RedText } from 'app/typography/text';

export enum ConsumerCallsHistoryCell {
  Record = 'record',
}

export const CALLS_HISTORY_COLUMNS: Column<Call>[] = [
  {
    Header: 'Дата',
    accessor: 'createdAt',
    width: 70,
    Cell: ({ value }) => format(new Date(value), 'dd.MM.yyyy'),
  },
  {
    id: 'time',
    Header: 'Время',
    accessor: 'createdAt',
    width: 45,
    Cell: ({ value }) => format(new Date(value), 'HH:mm'),
  },
  {
    Header: 'Потребитель',
    accessor: 'consumer',
    Cell: ({ value, row }: Cell<Call>) => value?.fio || formatPhoneNumber(row.original.callerPhone) || <GreyText>Не определен</GreyText>,
  },
  {
    Header: 'Тип',
    accessor: 'type',
    Cell: ({ value }: Cell<Call>) => <CallDirection direction={value} />,
  },
  {
    Header: 'Оператор',
    accessor: 'dispatcher',
    Cell: ({ value }) => value?.fullName || <RedText>Пропущенный</RedText>,
  },
  {
    Header: 'Продолжительность',
    accessor: 'durationInSec',
    Cell: ({ value }) => <CallDuration durationInSec={value} />,
  },
  {
    Header: 'Комментарий',
    accessor: 'comment',
    Cell: CommentCell,
  },
  {
    id: ConsumerCallsHistoryCell.Record,
    width: 25,
    Cell: CallTableCallActionsMenu,
  },
];
