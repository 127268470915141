import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'types/AppState.type';
// eslint-disable-next-line import/no-extraneous-dependencies
import createCachedSelector from 're-reselect';
import { MastersState } from './masters.reducer';
import { defaultCachedSelectorKeySelector } from '../../utils/selectors';

const selectMasterState: (state: AppState) => MastersState = state => state.mastersState;

export const selectMasters = createSelector(selectMasterState, state => state.masters);
export const selectChosenFilters = createSelector(selectMasterState, state => state.chosenFilters);
export const selectMastersPositions = createSelector(selectMasterState, state => state.mastersPositions);
export const selectMastersTotalCount = createSelector(selectMasterState, state => state.mastersTotalCount);

export const selectIsFiltered = createSelector(selectMasterState, state => {
  // eslint-disable-next-line
  const { fullName__icontains, ...filters } = state.chosenFilters;
  return Object.values(filters).filter(Boolean).length > 0;
});

export const selectLoading = createSelector(selectMasterState, state => state.loading);

const mastersOptionsSelector = (() => {
  const selector = createCachedSelector([selectMasters], masters => {
    if (!masters?.length) return [];

    return masters.map(service => ({
      key: service.id,
      value: service.id,
      label: service.fullName,
    }));
  })(defaultCachedSelectorKeySelector);

  return (state: AppState) => selector(state);
})();

export const selectMastersOptions = (state: AppState) => mastersOptionsSelector(state);
