import styled from 'styled-components';
import { VerticallyCenteredFlexWithSpaceBetween } from 'app/typography/flex';
import { Colors } from 'app/themes/colors';
import { IconClickable } from '../shared';

export const NotificationContainer = styled(VerticallyCenteredFlexWithSpaceBetween)`
  max-width: 346px;
  padding: 15px 20px;
  background-color: ${Colors.MainBackground};
  border: 1px solid ${Colors.Grey600};
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.08);
  border-radius: 6px;

  &:not(:first-child) {
    margin-top: 5px;
  }
`;

export const ErrorAlertContainer = styled(NotificationContainer)`
  background-color: ${Colors.Red800};
  border-color: ${Colors.Red800};
  color: ${Colors.White};
`;

export const NotificationsLayout = styled.div`
  z-index: 1000;
  right: 100px;
  bottom: 20px;
  position: fixed;
`;

export const CloseIcon = styled(IconClickable)`
  height: 15px;
  width: 15px;
  margin-left: 20px;

  fill: ${Colors.Grey700} !important;

  :hover {
    fill: ${Colors.Blue600} !important;
  }

  :active {
    fill: ${Colors.Blue700} !important;
  }
`;

export const ErrorClose = styled(CloseIcon)`
  fill: ${Colors.White} !important;

  :hover,
  :active {
    fill: ${Colors.White} !important;
    opacity: 0.7;
  }
`;
