import { Fragment, useEffect, useRef } from 'react';

import { format, isSameDay } from 'date-fns';
import { shallowEqual, useSelector } from 'react-redux';
import { AppealsSelectors } from 'store/appeals';
import { Message, MessageDate, MessageListContainer, MessageRow, MessageSender, MessageTime } from './styled';
import { MONTHS } from '../../../../constants/time/date';

export const MessageList = () => {
  const messageListRef = useRef<HTMLDivElement>(null);
  const messagesLength = useSelector(AppealsSelectors.selectAppealMessagesLength, shallowEqual);
  const sortedMessages = useSelector(AppealsSelectors.selectSortedAppealMessages, shallowEqual);

  useEffect(() => {
    messageListRef.current?.scrollTo({ top: messageListRef.current?.scrollHeight, behavior: 'smooth' });
  }, [messagesLength]);

  return (
    <MessageListContainer ref={messageListRef}>
      {!messagesLength && (
        <MessageRow>
          <MessageDate>Пока сообщений нет. Начните диалог.</MessageDate>
        </MessageRow>
      )}
      {sortedMessages?.map((message, index) => {
        const date = new Date(message.createdAt);

        return (
          <Fragment key={index}>
            {(index === 0 || !isSameDay(date, new Date(sortedMessages[index - 1].createdAt))) && (
              <MessageRow>
                <MessageDate>
                  {format(date, 'dd')} {MONTHS[date.getMonth()]} {format(date, 'yyyy')} года
                </MessageDate>
              </MessageRow>
            )}
            <MessageRow>
              <Message my={!message.income}>
                {message.income ? <>{message.disp && <MessageSender>{message.disp}</MessageSender>}</> : <MessageSender>Вы</MessageSender>}
                {message.text} <MessageTime>{format(date, 'HH:mm')}</MessageTime>
              </Message>
            </MessageRow>
          </Fragment>
        );
      })}
    </MessageListContainer>
  );
};
