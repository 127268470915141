import { format } from 'date-fns';
import React from 'react';
import { IntervalFilter, TextRegular } from './styled';
import { FormField } from '../FormField';
import { Datepicker } from './datepicker';

export interface DateIntervalProps {
  label?: string;
  value: string;
  noError?: boolean;
  disabled?: boolean;
  className?: string;
  onChange: (dateInterval: string) => void;
}

export const DateInterval = ({
  label,
  value,
  onChange,
  noError,
  disabled,
  className,
}: DateIntervalProps) => {
  const getDateArray = () => {
    if (value) {
      return value.split['-'];
    }
    return ['', ''];
  };

  const onChangeFirstDate = e => {
    const oldValue = value ? value.split('-') : ['', ''];
    oldValue[0] = format(new Date(e.target.value), 'dd.MM.yyyy');
    onChange(getDate(oldValue));
  };

  const onChangeSecondDate = e => {
    const oldValue = value ? value.split('-') : ['', ''];
    oldValue[1] = format(new Date(e.target.value), 'dd.MM.yyyy');
    onChange(getDate(oldValue));
  };

  const getDate = (dates: string[]) => `${dates[0]}-${dates[1]}`;

  return (
    <FormField placeholder={label} className={className}>
      <IntervalFilter spacing="0.625rem">
        <Datepicker
          type="date"
          placeholder="Создание от"
          value={getDateArray[0]}
          onChange={onChangeFirstDate}
          noError={noError}
          disabled={disabled}
        />
        <TextRegular>-</TextRegular>
        <Datepicker
          type="date"
          placeholder="до"
          value={getDateArray[1]}
          onChange={onChangeSecondDate}
          noError={noError}
          disabled={disabled}
        />
      </IntervalFilter>
    </FormField>
  );
};
