import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { ChosenFilters } from '../../models/common/chosen-filters';
import { hasValue } from '../objects/has-value';
import { useAction } from '../actions/use-action';

interface UseFiltersProps {
  chosenFilters: ChosenFilters;
  onFilterChangeAction: ActionCreatorWithPayload<any, string>;
  someFiltersResetAction?: ActionCreatorWithPayload<any, string>;
}

export const useFilters = ({
  chosenFilters,
  someFiltersResetAction,
  onFilterChangeAction,
}: UseFiltersProps) => {
  const dispatch = useDispatch();
  const onFilterChange = useAction(onFilterChangeAction);

  const getValue = (key: string) => (chosenFilters[key] || '').toString();

  const handleFilterChange = () => {
    if (hasValue(chosenFilters)) {
      onFilterChange(chosenFilters);
    }
  };

  const resetSomeFilters = (filters: string[]) => {
    if (someFiltersResetAction) {
      dispatch(someFiltersResetAction(filters));
    }
  };

  return { getValue, onFilterChange: handleFilterChange, resetSomeFilters };
};
