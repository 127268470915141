import { createSlice } from '@reduxjs/toolkit';
import { getAppealsFilters, getChosenFilter, resetSomeFilters } from 'app/helpers/filters/get-chosen-filter';
import {
  Appeal,
  AppealAdditionalFields,
  AppealChangesHistory,
  AppealDictionary,
  AppealMessage,
  AppealsQueryParams,
  AppealWork,
} from 'app/models/appeal';
import { Option } from 'app/models/common/option';
import { ChosenFilters } from 'app/models/common/chosen-filters';
import { getNewFilterValue } from 'app/helpers/filters/get-filter-new-value';
import { Call } from 'app/models/call';
import { EntityState } from '../models/entity-state';
import { initialEntityState } from '../constants/initial-entity-state';
import { AppealsFilters } from './models/appeals-filters';
import * as AppealsActions from './appeals.actions';
import * as CallsActions from '../calls/calls.actions';
import { replaceOnce } from '../../app/helpers/arrays';

export const APPEALS_STORE_NAME = 'appeals';

export interface AppealsState extends EntityState {
  appeals: Appeal[];
  appealTypes: AppealDictionary[];
  appealCategories: AppealDictionary[];
  appealKinds: AppealDictionary[];
  appealStatuses: AppealDictionary[];
  appealAdditionalFields: AppealAdditionalFields;
  appealWorks: AppealWork[];
  calls: Call[];
  appealsTotalCount: number;
  selectedAppealType?: Option<string>;
  selectedAppeal?: Appeal;
  selectedAppealMessages: AppealMessage[];
  filters: AppealsFilters;
  query?: AppealsQueryParams;
  chosenFilters: ChosenFilters;
  callsLoading: boolean;
  callsLoadingError: any;
  activeStatusTab?: string;
  changesHistory: Array<AppealChangesHistory>;
  statusesCounts:
    | {
        [key: string]: {
          [key: string]: number | undefined | null;
        };
      }
    | undefined;
  error?: any;
}

export const appealsInitialState: AppealsState = {
  appeals: [],
  appealCategories: [],
  appealKinds: [],
  appealStatuses: [],
  appealTypes: [],
  appealAdditionalFields: {},
  appealWorks: [],
  calls: [],

  selectedAppealType: undefined,
  selectedAppeal: undefined,
  selectedAppealMessages: [],
  chosenFilters: {},
  filters: {},
  query: undefined,
  appealsTotalCount: 0,
  changesHistory: [],
  callsLoading: false,
  callsLoadingError: undefined,
  error: undefined,
  activeStatusTab: undefined,
  statusesCounts: undefined,
  ...initialEntityState,
};

const appealsSlice = createSlice({
  name: APPEALS_STORE_NAME,
  initialState: appealsInitialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(AppealsActions.GetAppeals.init, (state, { payload }) => {
        state.loading = true;
        state.query = payload || appealsInitialState.query;
      })
      .addCase(AppealsActions.GetAppeals.success, (state, { payload }) => {
        state.loading = false;
        if (!payload.results) return;
        state.appeals = payload.page === 1 ? payload.results : state.appeals.concat(payload.results);
        state.appealsTotalCount = payload.count;
        state.statusesCounts = payload.filters_counts;
      })
      .addCase(AppealsActions.GetAppeals.failure, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(AppealsActions.GetAppealsCreatedGte.success, (state, { payload }) => {
        state.loading = false;
        if (!payload.results) return;
        state.appeals = [...payload.results, ...state.appeals];
      })
      .addCase(AppealsActions.GetAppealsCreatedGte.failure, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(AppealsActions.GetAppealById.init, state => {
        state.loading = true;
      })
      .addCase(AppealsActions.GetAppealById.success, (state, { payload }) => {
        state.loading = false;
        state.selectedAppeal = payload;
      })
      .addCase(AppealsActions.GetAppealById.failure, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(AppealsActions.CreateAppeal.init, state => {
        state.creating = true;
      })
      .addCase(AppealsActions.CreateAppeal.success, (state, { payload }) => {
        state.creating = false;
        state.selectedAppeal = payload;
      })
      .addCase(AppealsActions.CreateAppeal.failure, (state, { payload }) => {
        state.creating = false;
        state.error = payload;
      })
      .addCase(AppealsActions.UpdateAppeal.init, state => {
        state.updating = true;
      })
      .addCase(AppealsActions.UpdateAppeal.success, (state, { payload }) => {
        state.updating = false;
        state.selectedAppeal = payload;
      })
      .addCase(AppealsActions.UpdateAppeal.failure, (state, { payload }) => {
        state.updating = false;
        state.error = payload;
      })
      .addCase(AppealsActions.DeleteAppeal.init, state => {
        state.deleting = true;
      })
      .addCase(AppealsActions.DeleteAppeal.success, (state, { payload }) => {
        state.deleting = false;
        state.appeals = state.appeals.filter(appeal => appeal.id !== payload.id);
      })
      .addCase(AppealsActions.DeleteAppeal.failure, (state, { payload }) => {
        state.deleting = false;
        state.error = payload;
      })
      .addCase(AppealsActions.setFilters, (state, { payload }) => {
        state.filters = getAppealsFilters(payload);
      })

      .addCase(AppealsActions.setChosenFilter, (state, { payload }) => {
        state.chosenFilters = getChosenFilter(state.filters[payload].inlineFilters, state.filters[payload].sideFilters);
      })
      .addCase(AppealsActions.changeAppealType, (state, { payload }) => {
        state.selectedAppealType = payload.types.find(type => type.value === payload.type);
      })
      .addCase(AppealsActions.changeChosenFilter, (state, { payload }) => {
        state.chosenFilters[payload.key] = getNewFilterValue(payload.value);
      })
      .addCase(AppealsActions.CloseAppeal.init, state => {
        state.updating = true;
      })
      .addCase(AppealsActions.CloseAppeal.success, (state, { payload }) => {
        state.updating = false;
        state.selectedAppeal = payload;
      })
      .addCase(AppealsActions.CloseAppeal.failure, (state, { payload }) => {
        state.updating = false;
        state.error = payload;
      })
      .addCase(AppealsActions.AssignMaster.init, state => {
        state.updating = true;
      })
      .addCase(AppealsActions.AssignMaster.success, (state, { payload }) => {
        state.updating = false;
        state.selectedAppeal = payload;
      })
      .addCase(AppealsActions.AssignMaster.failure, (state, { payload }) => {
        state.updating = false;
        state.error = payload;
      })
      .addCase(AppealsActions.ChangeAppealStatus.init, state => {
        state.updating = true;
      })
      .addCase(AppealsActions.ChangeAppealStatus.success, (state, { payload }) => {
        state.updating = false;
        if (state.selectedAppeal?.id === payload.id) {
          state.selectedAppeal.status = payload.status;
        } else {
          state.appeals = replaceOnce(state.appeals, ({ id }) => id === payload.id, payload);
        }
      })
      .addCase(AppealsActions.ChangeAppealStatus.failure, (state, { payload }) => {
        state.updating = false;
        state.error = payload;
      })
      .addCase(AppealsActions.UploadAppealAttachments.success, (state, { payload }) => {
        state.selectedAppeal?.attachedFiles.unshift({ id: payload.id, name: payload.name });
      })
      .addCase(AppealsActions.DeleteAppealAttachment.success, (state, { payload }) => {
        if (!state.selectedAppeal) {
          return;
        }

        state.selectedAppeal.attachedFiles = state.selectedAppeal?.attachedFiles.filter(({ id }) => id !== payload);
      })
      .addCase(AppealsActions.resetSomeFilters, (state, { payload }) => {
        state.chosenFilters = resetSomeFilters(state.chosenFilters, payload);
      })
      .addCase(AppealsActions.GetAppealCalls.init, state => {
        state.callsLoading = true;
      })
      .addCase(AppealsActions.GetAppealCalls.success, (state, { payload }) => {
        state.callsLoading = false;
        state.calls = payload;
      })
      .addCase(AppealsActions.GetAppealCalls.failure, (state, { payload }) => {
        state.callsLoading = false;
        state.callsLoadingError = payload;
      })
      .addCase(CallsActions.UpdateCall.success, (state, { payload }) => {
        state.calls = replaceOnce(state.calls, ({ id }) => id === payload.id, payload);
      })
      .addCase(AppealsActions.ChangeAppealSupplier.init, state => {
        state.loading = true;
      })
      .addCase(AppealsActions.ChangeAppealSupplier.success, state => {
        state.loading = false;
      })
      .addCase(AppealsActions.ChangeAppealSupplier.failure, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(AppealsActions.GetAppealTypes.success, (state, { payload }) => {
        state.appealTypes = payload;
      })
      .addCase(AppealsActions.GetAppealCategories.success, (state, { payload }) => {
        state.appealCategories = payload;
      })
      .addCase(AppealsActions.GetAppealKinds.success, (state, { payload }) => {
        state.appealKinds = payload;
      })
      .addCase(AppealsActions.GetAppealStatuses.success, (state, { payload }) => {
        state.appealStatuses = payload;
      })
      .addCase(AppealsActions.GetAppealAdditionalFields.success, (state, { payload }) => {
        state.appealAdditionalFields = payload;
      })
      .addCase(AppealsActions.GetAppealWorks.success, (state, { payload }) => {
        state.appealWorks = payload;
      })
      .addCase(AppealsActions.GetAppealMessages.success, (state, { payload }) => {
        state.selectedAppealMessages = payload;
      })
      .addCase(AppealsActions.GetAppealMessages.failure, (state, { payload }) => {
        state.error = payload;
      })
      .addCase(AppealsActions.SetReadAppealMessages.success, state => {
        state.selectedAppealMessages = state.selectedAppealMessages.map(message => (message.income ? { ...message, read: true } : message));
      })
      .addCase(AppealsActions.SetReadAppealMessages.failure, (state, { payload }) => {
        state.error = payload;
      })
      .addCase(AppealsActions.CreateAppealMessage.success, (state, { payload }) => {
        state.selectedAppealMessages = state.selectedAppealMessages.concat(payload);
      })
      .addCase(AppealsActions.CreateAppealMessage.failure, (state, { payload }) => {
        state.error = payload;
      })
      .addCase(AppealsActions.SetAppealsStatus, (state, { payload }) => {
        state.activeStatusTab = payload;
      })

      .addCase(AppealsActions.GetAppealChangesHistory.init, state => {
        state.loading = true;
      })
      .addCase(AppealsActions.GetAppealChangesHistory.success, (state, { payload }) => {
        state.loading = false;
        state.changesHistory = payload;
      })
      .addCase(AppealsActions.GetAppealChangesHistory.failure, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })

      .addDefaultCase(() => {}),
});

export const appealsReducer = appealsSlice.reducer;
