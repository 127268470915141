import React from 'react';
import { format } from 'date-fns';
import { Cell } from 'react-table';
import { generatePath, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Incident } from '../../../../../../models/incident/incident';
import { Appeal, AppealId } from '../../../../../../models/appeal';
import { PagesRoutes } from '../../../../../../constants/route-path';
import { EmptyTable, Text } from '../../../../../Consumer/styled';
import { DeleteButton, IncidentAppealsTable } from './styled';
import { Icon } from '../../../../../../components/shared';
import { EditAppealIncidents } from '../../../../../../../store/appeals/appeals.actions';
import { ModalService } from '../../../../../../services/modal-service/modal.service';
import { ConfirmModal } from '../../../../../../components/Modals/ConfirmModal/ConfirmModal';

type AppealsTableProps = {
  appeals: Incident['appeals'] | undefined;
};

const DateCell: React.FC<{ value: string | undefined }> = ({ value }) => (
  <span>{format(new Date(value || new Date()), 'dd.MM.yyyy HH:mm')}</span>
);

const DeleteCell: React.FC<{ row: Appeal; onClick: (appeal: Appeal) => void }> = ({ row, onClick }) => (
  <DeleteButton onClick={() => onClick(row)}>
    <Icon icon="/assets/icons.svg#delete" />
  </DeleteButton>
);

export const AppealsTable = React.memo<AppealsTableProps>(props => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onCellClick = React.useCallback(
    (cell: Cell) => {
      if (cell.column.id === 'actions') return;
      const appeal = cell.row.original as Appeal;

      navigate(
        generatePath(PagesRoutes.PAGES.APPEAL, {
          appealId: String(appeal?.id),
        })
      );
    },
    [navigate]
  );

  const onDelete = React.useCallback(
    (id: AppealId) => {
      if (!id) return;

      dispatch(
        EditAppealIncidents.init({
          id,
          incident: null,
        })
      );
    },
    [dispatch]
  );

  const onDeleteButtonClick = React.useCallback(
    (appeal: Appeal) => {
      ModalService.openModal(ConfirmModal, {
        title: 'Отвязка заявки от инцидента',
        text: `Вы действительно хотите отвязать заявку ${appeal.number} ?`,
        textButton: 'Отвязать',
        onClick: () => onDelete(appeal.id),
        onClose: () => {},
      });
    },
    [onDelete]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: 'Номер',
        accessor: 'number',
        width: 70,
      },
      {
        Header: 'Дата создания',
        accessor: 'createdAt',
        width: 130,
        Cell: DateCell,
      },
      {
        Header: 'Адрес',
        accessor: 'address',
      },
      {
        id: 'actions',
        accessor: 'id',
        Cell: ({ row }) => <DeleteCell row={row.original as Appeal} onClick={() => onDeleteButtonClick(row.original)} />,
      },
    ],
    [onDeleteButtonClick]
  );

  if (!props?.appeals?.length) {
    return (
      <EmptyTable>
        <Text>Заявок нет</Text>
      </EmptyTable>
    );
  }

  return <IncidentAppealsTable onCellClick={onCellClick} columns={columns} data={props.appeals} />;
});
