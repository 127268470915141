import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as ServicesActions from './services.actions';
import { ServicesService } from '../../app/services/services-service/services.service';

function* loadServicesWatcher() {
  try {
    const res = yield call(ServicesService.fetchServices);

    yield put(ServicesActions.LoadServices.success(res));
  } catch (e: any) {
    yield put(ServicesActions.LoadServices.failure(e));
  }
}

export default function* watcher() {
  return yield all([takeLatest(ServicesActions.LoadServices.init, loadServicesWatcher)]);
}
