import React, { useEffect, useRef, useState } from 'react';
import { Cell } from 'react-table';

import { Call } from 'app/models/call';
import { CallsService } from 'app/services/calls-service/calls.service';
import { useOnClickOutside } from 'app/helpers/click-outside/click-outside.hook';
import { environment } from 'app/environment';
import { useDispatch } from 'react-redux';
import { AudioPlayerType } from '../AudioPlayer';
import { DownloadButton, MenuComponent, PlayButton, StyledIcon, StyledPlayer, CallButton } from './styled';
import { webphoneActions } from '../../../../store/webphone';
import { UpdateCall } from '../../../../store/calls/calls.actions';

export const CallTableCallActionsMenu = (cell: Cell<Call>) => {
  const call = cell.row.original;

  return <CallActionsMenu call={call} />;
};

interface CallActionsMenuProps {
  call: Call;
}

const CallActionsMenu = ({ call }: CallActionsMenuProps) => {
  const dispatch = useDispatch();

  const [isPlayerVisible, setIsPlayerVisible] = useState<boolean>(false);
  const [record, setRecord] = useState<string>('');
  const [error, setError] = useState<any>(undefined);
  const playerRef = useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const loadAudio = async () => {
      if (isPlayerVisible) {
        try {
          const record = await CallsService.getCallRecord(call?.id);

          setRecord(record);
        } catch (e) {
          setError(e);
        }
      }
    };

    loadAudio().then();
  }, [call?.id, isPlayerVisible]);

  useEffect(() => {
    if (!isPlayerVisible) setRecord('');
  }, [isPlayerVisible]);

  useOnClickOutside(playerRef, () => {
    setIsPlayerVisible(false);
  });

  const onCallClick = React.useCallback(() => {
    if (call?.callerPhone) {
      if (!call.callback && call.isMissed) {
        const { id, consumer, appeal, dispatcher, managecompany, ...rest } = call;
        dispatch(
          UpdateCall.init({
            id,
            updateCallDto: {
              ...rest,
              appealId: appeal?.id,
              consumerId: consumer?.id,
              dispatcherId: dispatcher?.id,
              managecompanyId: managecompany?.id,
              callback: true,
            },
          })
        );
      }
      dispatch(
        webphoneActions.InitOutgoingRinging.init({
          phone: call.callerPhone,
          appealId: call?.appeal?.id,
          options: { prefix: call?.managecompany?.prefix },
        })
      );
    }
  }, [call, dispatch]);

  return (
    <MenuComponent spacing="1.25rem">
      <CallButton onClick={onCallClick}>
        <StyledIcon icon="/assets/icons.svg#phone-in-talk" />
      </CallButton>
      <PlayButton>
        <StyledIcon icon="/assets/icons.svg#play" onClick={() => setIsPlayerVisible(true)} />
        {isPlayerVisible && (
          <StyledPlayer
            playerRef={playerRef}
            src={record}
            error={!!error}
            type={AudioPlayerType.Simplified}
            onClose={() => setIsPlayerVisible(false)}
          />
        )}
      </PlayButton>
      <DownloadButton>
        <a href={`${environment.apiBaseUrl}api/calls/${call?.id}/record/`}>
          <StyledIcon icon="/assets/icons.svg#download" />
        </a>
      </DownloadButton>
    </MenuComponent>
  );
};
