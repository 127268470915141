import { FC } from 'react';
import styled from 'styled-components';

import { Colors } from 'app/themes/colors';
import { Scrollbar, ScrollbarProps } from './Scrollbar';
import { HorizontalScrollTrack, ScrollThumb, VerticalScrollTrack } from './styled';

interface TableScrollbarProps extends ScrollbarProps {}

export const TableScrollbar: FC<TableScrollbarProps> = ({
  children,
  ...rest
}: TableScrollbarProps) => (
  <ScrollbarContainer {...rest} verticalScrollDisabled>
    {children}
  </ScrollbarContainer>
);

export const ScrollbarContainer = styled(Scrollbar)`
  ${HorizontalScrollTrack} {
    position: relative;
    padding: 20px 10px;
    height: 50px !important;
    background: ${Colors.MainBackground};

    &:before {
      position: absolute;
      content: '';
      height: 10px;
      border-radius: 10px;
      width: calc(100% - 20px);
      background: ${Colors.Grey600};
    }
  }

  ${VerticalScrollTrack} {
    position: relative;
    margin: 0 -40px;
    width: 50px !important;
    background: none;

    &:before {
      position: absolute;
      content: '';
      width: 10px;
      border-radius: 10px;
      height: calc(100% - 50px);
      background: ${Colors.Grey600};
    }

    ${ScrollThumb} {
      width: 10px !important;
    }
  }
`;
