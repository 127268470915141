import { DaDataAddress } from 'react-dadata';

export const getValidFormattedAddress = (data: DaDataAddress): string | null => {
  const fiasIds = {
    region: data.region_fias_id,
    area: data.area_fias_id,
    city: data.city_fias_id,
    city_district: data.city_district_fias_id,
    settlement: data.settlement_fias_id,
    settlement_plan: data.settlement_fias_id,
    street: data.street_fias_id,
    stead: data.stead_fias_id,
    house: data.house_fias_id,
    flat: data.flat_fias_id,
  };

  // Собираем строку адреса из всех доступных FIAS уровней
  const addressParts: string[] = [];
  if (fiasIds.region) addressParts.push(data.region_with_type || '');
  if (fiasIds.area) addressParts.push(data.area_with_type || '');
  if (fiasIds.city) addressParts.push(data.city_with_type || '');
  if (fiasIds.settlement) addressParts.push(data.settlement_with_type || '');
  if (fiasIds.settlement_plan && data.fias_level === '65') addressParts.push(data.settlement_with_type || '');
  if (fiasIds.street) addressParts.push(data.street_with_type || '');
  if (fiasIds.stead) addressParts.push(`${data.stead_type} ${data.stead}` || '');
  if (fiasIds.house) addressParts.push(`${data?.house_type} ${data?.house}` || '');
  if (fiasIds.flat) addressParts.push(`кв. ${data.flat || ''}`);

  const filteredAddressParts = addressParts.filter(part => part.trim() !== '');

  // Если нет ни одной части адреса, возвращаем null
  if (filteredAddressParts.length === 0) {
    return null;
  }

  return filteredAddressParts.join(', ');
};
