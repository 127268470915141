import { Appeal } from '../../../models/appeal';
import { Icons } from '../../../constants/icons';

export const SourceDict: Record<Appeal['source'], { title: string; icon: JSX.Element }> = {
  manual: {
    title: 'Создана вручную',
    icon: <Icons.Manual />,
  },
  rads: {
    title: 'Создана из РАДС',
    icon: <Icons.Robot />,
  },
  app: {
    title: 'Создана из МП',
    icon: <Icons.Mobile />,
  },
  phone: {
    title: 'Создана из звонка',
    icon: <Icons.CallSource />,
  },
};
