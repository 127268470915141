import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { AnyAction } from '@reduxjs/toolkit';

export const useAction = <T extends (...args: any) => AnyAction>(action: T) => {
  const dispatch = useDispatch();

  return useCallback(
    (...args: Parameters<T>) => {
      dispatch(action(...args));
    },
    [dispatch, action]
  );
};
