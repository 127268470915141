import { ChangeEvent, KeyboardEvent, useState } from 'react';
import { KeyCode } from '../../../constants/key-code';
import { Tone } from '../tone.service';
import { CallButton, Dig, Digits, Pad, PhoneIcon, PhoneInput } from './styled';

interface PhoneNumberPadProps {
  onCallButtonClick: (number: string) => void;
  className?:string
}

export const PhoneNumberPad = (
  { onCallButtonClick, className }: PhoneNumberPadProps,
) => {
  const digits = new Array(9).fill(0).map((_, d) => d + 1);

  const [phoneNumber, setPhoneNumber] = useState('');

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === KeyCode.Enter) {
      handleCallButtonClick();
    }
  };

  const handleDigitClick = (digit: number) => () => {
    setPhoneNumber(prevPhone => prevPhone + digit);

    if (!Tone.status) {
      Tone.start(digit);
    }
  };

  const handleDigitUnclick = () => {
    if (Tone.status) {
      Tone.stop();
    }
  };

  const handleCallButtonClick = () => {
    onCallButtonClick(phoneNumber);
    setPhoneNumber('');
  };

  return (
    <Pad className={className}>
      <PhoneInput
        autoFocus
        value={phoneNumber}
        placeholder="Введите номер"
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
      />
      <Digits>
        {digits.map(d => (
          <Dig
            key={d}
            onMouseDown={handleDigitClick(d)}
            onMouseUp={handleDigitUnclick}
            onTouchEnd={handleDigitUnclick}
            onMouseLeave={handleDigitUnclick}
          >
            {d}
          </Dig>
        ))}
        <Dig />
        <Dig
          onMouseDown={handleDigitClick(0)}
          onMouseUp={handleDigitUnclick}
          onTouchEnd={handleDigitUnclick}
          onMouseLeave={handleDigitUnclick}
        >
          0
        </Dig>
        <Dig />
      </Digits>
      <CallButton onClick={handleCallButtonClick}>
        <PhoneIcon icon="/assets/icons.svg#phone-in-talk" />
      </CallButton>
    </Pad>
  );
};
