export enum AppealDatesField {
  CreatedAt = 'createdAt',
  ClosedAt = 'closedAt',
  PerformingEndedAt = 'performingEndedAt',
  PerformingStartedAt = 'performingStartedAt',
  PerformingStartedAtPlan = 'performingStartedAtPlan',
  PerformingEndedAtPlan = 'planned_end_date',
}

export const appealDatesTitleMap = {
  [AppealDatesField.CreatedAt]: 'Создана',
  [AppealDatesField.ClosedAt]: 'Закрыта',
  [AppealDatesField.PerformingEndedAt]: 'Выполнена',
  [AppealDatesField.PerformingStartedAt]: 'В работе',
  [AppealDatesField.PerformingStartedAtPlan]: 'План. начало',
  [AppealDatesField.PerformingEndedAtPlan]: 'План. конец',
};

export const appealDatesColorsMap = {
  [AppealDatesField.CreatedAt]: '#EEC905',
  [AppealDatesField.ClosedAt]: '#214E9D',
  [AppealDatesField.PerformingEndedAt]: '#16A13D',
  [AppealDatesField.PerformingStartedAt]: '#EE7A05',
  [AppealDatesField.PerformingStartedAtPlan]: '#EE7A05',
  [AppealDatesField.PerformingEndedAtPlan]: '#EE7A05',
};

export const appealDatesFields: AppealDatesField[] = [
  AppealDatesField.CreatedAt,
  AppealDatesField.PerformingStartedAt,
  AppealDatesField.PerformingStartedAtPlan,
  AppealDatesField.PerformingEndedAtPlan,
  AppealDatesField.PerformingEndedAt,
  AppealDatesField.ClosedAt,
];
