import React from 'react';
import { useSelector } from 'react-redux';
import { CallStatus, webphoneActions, webPhoneSelectors, WebphoneType } from 'store/webphone';
import { useAction } from 'app/helpers/actions/use-action';
import { matchPath, useLocation } from 'react-router-dom';
import { ComponentWrapper, InnerContainer, OuterContainer } from './styled';
import { SideBarMenu } from './components/SideBarMenu/SideBarMenu';
import { CallBox, WebPhoneSideModal } from '../../../../components/WebPhone/withWebPhone/styled';
import { WebPhone } from '../../../../components/WebPhone/WebPhone';
import { CallScript } from '../../../../components/WebPhone/CallScript';
import { PagesRoutes } from '../../../../constants/route-path';

export const withWebPhoneAppealPage =
  <T extends object>(Component: React.FC<T>) =>
  (props: T) => {
    const isComponentWebPhoneShown = useSelector(webPhoneSelectors.selectIsComponentWebPhoneShown);
    const location = useLocation();
    const callStatus = useSelector(webPhoneSelectors.selectCallStatus);

    const setWebPhoneType = useAction(webphoneActions.setWebphoneType);

    const match = !!matchPath(PagesRoutes.PAGES.APPEAL, location.pathname);

    React.useEffect(() => {
      if (callStatus === CallStatus.InTalk && match) {
        setWebPhoneType(WebphoneType.Component);
      }

      return () => setWebPhoneType(WebphoneType.Modal);
    }, [callStatus]);

    return (
      <OuterContainer>
        <InnerContainer>
          <ComponentWrapper>
            <Component {...props} />
          </ComponentWrapper>
          {match && <SideBarMenu />}

          {isComponentWebPhoneShown && match && (
            <WebPhoneSideModal
              onClose={() => setWebPhoneType(WebphoneType.Modal)}
              body={
                <CallBox>
                  <WebPhone />
                  <CallScript />
                </CallBox>
              }
            />
          )}
        </InnerContainer>
      </OuterContainer>
    );
  };
