import { ReactComponent as Message } from '../assets/icons/chat.svg';
import { ReactComponent as Utilities } from '../assets/icons/utilities.svg';
import { ReactComponent as Emergency } from '../assets/icons/emergency.svg';
import { ReactComponent as Forbidden } from '../assets/icons/403.svg';
import { ReactComponent as ServerError } from '../assets/icons/serverError.svg';
import { ReactComponent as Document } from '../assets/icons/document.svg';
import { ReactComponent as Checkbox } from '../assets/icons/checkbox.svg';
import { ReactComponent as Plus } from '../assets/icons/add.svg';
import { ReactComponent as CallSource } from '../assets/icons/callSource.svg';
import { ReactComponent as Robot } from '../assets/icons/robot.svg';
import { ReactComponent as Mobile } from '../assets/icons/mobile.svg';
import { ReactComponent as Manual } from '../assets/icons/manual.svg';
import { ReactComponent as Calendar } from '../assets/icons/calendar.svg';
import { ReactComponent as Search } from '../assets/icons/search.svg';
import { ReactComponent as Cancel } from '../assets/icons/cancel.svg';
import { ReactComponent as InProgress } from '../assets/icons/in progress.svg';

export const Icons = {
  Forbidden,
  ServerError,
  Message,
  Utilities,
  Emergency,
  Document,
  Checkbox,
  Plus,
  CallSource,
  Robot,
  Mobile,
  Manual,
  Calendar,
  Search,
  Cancel,
  InProgress,
};
