import styled from 'styled-components';
import { Button, Dialog } from '../../../../components/shared';

export const StyledDialog = styled(Dialog)`
  width: 760px;
  & .bodyClassName {
    padding: 10px 15px;
  }
`;

export const CreateButton = styled(Button)`
  max-width: 210px;
  padding: 15px 20px;

  margin-right: auto;
  margin-top: 20px;
`;
