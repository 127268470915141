import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { Colors } from '../../../themes/colors';
import { ModalService } from '../modal.service';
import { ModalDef } from '../models/modal-def';

export function Modal() {
  const [modals, setModals] = useState(ModalService.modalList);
  const [rootContainer, setRootContainer] = useState<HTMLElement | undefined>(
    ModalService.rootContainer
  );

  useEffect(() => {
    const handler = () => {
      setModals(ModalService.modalList);
      setRootContainer(ModalService.rootContainer);
    };

    ModalService.onChange.addListener('', handler);

    return () => {
      ModalService.onChange.removeListener('', handler);
    };
  }, []);

  return <ModalInternal rootContainer={rootContainer} modalList={modals} />;
}

interface ModalInternalProps {
  modalList: ModalDef[];
  rootContainer: HTMLElement | undefined;
}

export function ModalInternal({ modalList, rootContainer }: ModalInternalProps): JSX.Element {
  function renderModal(modal: ModalDef) {
    return <Backdrop key={modal.id}>{modal.element}</Backdrop>;
  }

  return (
    <>
      {rootContainer &&
        modalList.map(modal => ReactDOM.createPortal(renderModal(modal), rootContainer!, modal.id))}
    </>
  );
}

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${Colors.Backdrop};
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
`;
