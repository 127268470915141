import React from 'react';
import { Cell } from 'react-table';
import styled from 'styled-components';
import { Incident, IncidentLabelStatusToLabelMap, IncidentStatus } from '../../../../models/incident/incident';
import { Colors } from '../../../../themes/colors';
import { Icon } from '../../../../components/shared';
import { StrongTextRegular, TextRegular } from '../../../../typography/text';

export const incidentStatusDict = {
  [IncidentStatus.New]: Colors.Yellow800,
  [IncidentStatus.Canceled]: Colors.Red800,
  [IncidentStatus.InProgress]: Colors.Orange800,
  [IncidentStatus.Completed]: Colors.Green800,
};

export const incidentStatusIconDict = {
  [IncidentStatus.New]: '/assets/icons.svg#new',
  [IncidentStatus.Canceled]: '/assets/icons.svg#cancel',
  [IncidentStatus.InProgress]: '/assets/icons.svg#in progress',
  [IncidentStatus.Completed]: '/assets/icons.svg#done',
};

export const IncidentStatusTitle = React.memo((props: Cell<Incident>) => {
  const status = props.row?.original.status;

  if (!status) return <TextRegular>Не определен</TextRegular>;
  return (
    <div>
      <StatusIcon color={incidentStatusDict[status]} icon={incidentStatusIconDict[status]} />
      <StatusLabel color={incidentStatusDict[status]}>{IncidentLabelStatusToLabelMap[status]}</StatusLabel>
    </div>
  );
});

export const StatusIcon = styled(Icon)<{ color: string }>`
  fill: ${props => props.color} !important;
  margin-right: 5px;
`;

const StatusLabel = styled(StrongTextRegular)<{ color: string }>`
  color: ${props => props.color} !important;
`;
