import { ResponseError } from '../../app/services/api.types';

export enum ErrorType {
  BACKEND = 'Backend',
  FRONTEND = 'Frontend',
}

export interface ErrorPayload {
  errorCode?: any;
  message: string;
  errorType?: ErrorType;
  status?: number;
  method?: string;
  data: ResponseError;
}
