/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-param-reassign */
import { AnyAction, EnhancedStore } from '@reduxjs/toolkit';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { ErrorCode } from '../app/constants/error-code';
import { ErrorType } from './models/error-payload';
import { AppState } from '../types/AppState.type';
import { HttpService, IS_RETRY_HEADER } from '../app/services/http.service';
import { TokenService } from '../app/services/token-service';
import { ProfileService } from '../app/services/profile-service/profile.service';
import { ProfileApiRoutes } from '../app/constants/api-routes/profile-api-routes';
import { ModalService } from '../app/services/modal-service/modal.service';
import { ServerErrorModal } from '../app/components/Modals/ServerErrorModal/ServerErrorModal';
import { AccessDeniedModal } from '../app/components/Modals/AccessDeniedModal/AccessDeniedModal';

let isTokenRefreshing = false;
let tokenPromise: Promise<any> | undefined;
let isModalOpen = false;

function setRetryFlag(config: AxiosRequestConfig): AxiosRequestConfig {
  config.headers[IS_RETRY_HEADER] = true;
  return config;
}

function hasRetryFlag(config: AxiosRequestConfig): boolean {
  return config?.headers[IS_RETRY_HEADER] || false;
}

function isRequestBypassedByInterceptor(config: AxiosRequestConfig): boolean {
  if (config.url?.startsWith(ProfileApiRoutes.login) || config.url?.startsWith('api/profile/update_token/')) {
    return true;
  }
  return false;
}

function refreshToken(): Promise<void> | undefined {
  if (!TokenService.refreshToken) return undefined;

  return ProfileService.authorizeByRefreshToken(TokenService.refreshToken).then(response => {
    TokenService.refreshToken = response.tokenData.refreshToken;
    tokenPromise = undefined;
    isTokenRefreshing = false;
  });
}

export function setInterceptors(store: EnhancedStore<AppState, AnyAction, any[]>) {
  const { getState } = store;

  HttpService.addRequestInterceptor({
    onFulfilled: async config => {
      if (isRequestBypassedByInterceptor(config)) return config;

      if (isTokenRefreshing) await tokenPromise;

      return config;
    },
    onRejected: error => Promise.reject(error),
  });

  HttpService.addResponseInterceptor({
    onFulfilled: data => Promise.resolve(data),
    onRejected: async (error: AxiosError) => {
      if (!error?.response) {
        return Promise.reject(error);
      }

      const status = error.response?.status;

      if (isRequestBypassedByInterceptor(error.config)) {
        tokenPromise = undefined;
      }

      if (status === 401) {
        if (!hasRetryFlag(error.config) && TokenService.canBeRefreshed) {
          try {
            if (!isTokenRefreshing) {
              isTokenRefreshing = true;
              tokenPromise = refreshToken();
            }

            return HttpService.fromConfig(setRetryFlag(error.config));
          } catch (error) {
            if (error.response && error.response.data) {
              return Promise.reject(error.response.data);
            }
            return Promise.reject(error);
          }
        }
      }

      if (status === 403) {
        if (error.config.method !== 'get') {
          // открываем модалку для методов, отличных от GET
          if (!isModalOpen) {
            isModalOpen = true;
            ModalService.openModal(AccessDeniedModal, {
              errorText: 'У вас нет прав для этого действия',
              onClose: () => {
                isModalOpen = false;
              },
            });
          }
        }
        // возвращаем null, чтобы приложение не крашилось
        return Promise.resolve(null);
      }

      if (status === 400) {
        const responseData = error?.response?.data;

        // Сбор всех ошибок в массив
        const errorMessages: string[] = [];

        if (responseData?.data) {
          // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
          for (const [_, errors] of Object.entries(responseData.data)) {
            if (Array.isArray(errors)) {
              // Проходим по каждой ошибке и добавляем в массив
              errors.forEach((errMsg: string) => {
                errorMessages.push(errMsg);
              });
            }
          }
        } else {
          errorMessages.push('Что-то пошло не так, попробуйте повторить позже');
        }

        // Объединяем все ошибки в одну строку с разделением на новые строки
        const errorText = errorMessages.join('\n');

        // Открываем модалку с текстом ошибок
        ModalService.openModal(ServerErrorModal, {
          errorText, // передаем собранный текст ошибок
          onClose: () => {
            isModalOpen = false;
          },
        });
      }

      if (status && status >= 500 && status <= 599) {
        if (!isModalOpen) {
          isModalOpen = true;
          ModalService.openModal(ServerErrorModal, {
            errorText: 'Что-то пошло не так, попробуйте повторить позже',
            onClose: () => {
              isModalOpen = false;
            },
          });
        }
      }

      const errorCode = ErrorCode.UnknownError;

      return Promise.reject({
        errorCode,
        status: error.response?.status || error.response?.data?.status,
        method: error.response?.config.method,
        message: error.response?.data.message || ErrorCode.UnknownError,
        errorType: ErrorType.BACKEND,
        payload: error.response?.config.data,
        url: error.response?.config.url,
        user: getState().profileState.profile,
        data: error.response?.data,
      });
    },
  });

  HttpService.setUp();
}
