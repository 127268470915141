import React, { MouseEvent } from 'react';
import styled from 'styled-components';

export interface IconBackgroundProps {
  icon: string;
  className?: string;
  onClick?: (event: MouseEvent) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  tooltip?: string;
}

export function Icon({
  onClick,
  icon,
  tooltip,
  className,
  onMouseEnter,
  onMouseLeave,
}: IconBackgroundProps) {
  return (
    <IconComponent
      onClick={onClick}
      className={className}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {tooltip && <title>{tooltip}</title>}
      <use xlinkHref={icon} />
    </IconComponent>
  );
}

const IconComponent = styled.svg`
  height: 25px;
  width: 25px;
`;
