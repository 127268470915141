import styled from 'styled-components';
import { CallDirection } from 'app/models/call';
import { Colors } from 'app/themes/colors';
import { Icon } from '../IconBackground';

export const CallDirectionIcon = styled(Icon)<{ direction: CallDirection | 'missed' }>`
  color: ${({ direction }) =>
    // eslint-disable-next-line no-nested-ternary
    direction === CallDirection.Incoming
      ? Colors.Blue800
      : direction === 'missed'
      ? Colors.Red800
      : Colors.Green800};
`;
