import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { CreateComment, DeleteComment, EditComment, LoadComments } from './comments.actions';
import { GetCommentsApiParams } from '../../app/services/comments-service/dtos/get-comments-dto';
import { CommentsService } from '../../app/services/comments-service/comments.service';
import { CreateCommentApiParams } from '../../app/services/comments-service/dtos/create-comment-dto';
import { EditCommentApiParams } from '../../app/services/comments-service/dtos/edit-comment-dto';
import { DeleteCommentApiParams } from '../../app/services/comments-service/dtos/delete-comment-dto';
import { AddNotification } from '../notifications/notifications.actions';
import { NotificationType } from '../../app/models/notifications/notification';

function* loadCommentsWatcher({ payload }: PayloadAction<GetCommentsApiParams>) {
  try {
    const response = yield call(CommentsService.getComments, payload);

    yield response && put(LoadComments.success(response));
  } catch (e: any) {
    yield put(LoadComments.failure(e));
  }
}
function* createCommentWatcher({ payload }: PayloadAction<CreateCommentApiParams>) {
  try {
    const response = yield call(CommentsService.createComment, payload);

    yield response && put(CreateComment.success(response));
  } catch (e: any) {
    yield put(CreateComment.failure(e));
  }
}
function* editCommentWatcher({ payload }: PayloadAction<EditCommentApiParams>) {
  try {
    const response = yield call(CommentsService.editComment, payload);

    yield response && put(EditComment.success(response));
  } catch (e: any) {
    yield put(EditComment.failure(e));
  }
}
function* deleteCommentWatcher({ payload }: PayloadAction<DeleteCommentApiParams>) {
  try {
    yield call(CommentsService.deleteComment, payload);

    yield put(DeleteComment.success({ id: payload.id }));
    yield put(AddNotification.init({ body: 'Комментарий удален', type: NotificationType.Info }));
  } catch (e: any) {
    yield put(AddNotification.init({ body: 'Произошла ошибка. Комментарий не удален', type: NotificationType.Error }));
  }
}

export default function* watcher() {
  yield all([
    takeLatest(LoadComments.init, loadCommentsWatcher),
    takeLatest(CreateComment.init, createCommentWatcher),
    takeLatest(EditComment.init, editCommentWatcher),
    takeLatest(DeleteComment.init, deleteCommentWatcher),
  ]);
}
