import React from 'react';
import { useDispatch } from 'react-redux';
import { Page } from '../../components/shared';
import { RouteName, RoutePath } from '../../constants/route-path';
import { useParams } from '../../helpers/hooks/use-params';
import { IncidentId } from '../../models/incident/incident';
import { GetIncidentById } from '../../../store/incidents/incidents.actions';
import { IncidentHeader } from './sections/Header/IncidentHeader';
import { InfoComponent } from './styled';
import { withWebPhoneAppealPage } from '../Appeal/components/withWebPhoneAppealPage/withWebPhoneAppealPage';
import { CommonInfo } from './sections/CommonInfo/CommonInfo';
import { LoadComments } from '../../../store/comments/comments.actions';

export const IncidentPage = withWebPhoneAppealPage(() => {
  const { incidentId: maybeIncidentIdFromParams } = useParams();
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = React.useState<number>(0);

  const incidentId = isNaN(Number(maybeIncidentIdFromParams)) ? undefined : (Number(maybeIncidentIdFromParams) as IncidentId);

  const content = React.useMemo(() => {
    const contentList = [<CommonInfo />];

    return contentList[activeTab];
  }, [activeTab]);

  React.useEffect(() => {
    if (!incidentId) return;

    dispatch(GetIncidentById.init(incidentId));
    dispatch(LoadComments.init({ id: String(incidentId), entity: 'incident' }));
  }, [dispatch, incidentId]);

  if (!incidentId) return null;

  return (
    <Page title={RouteName[RoutePath.Incident]}>
      <InfoComponent>
        <IncidentHeader activeTabIndex={activeTab} onActiveTabChange={setActiveTab} />
        {content}
      </InfoComponent>
    </Page>
  );
});
