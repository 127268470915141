import styled from 'styled-components';
import { CenteredFlex } from '../../../typography/flex';
import { TextRegularBig } from '../../../typography/text';
import { Colors } from '../../../themes/colors';
import { Icon } from '../../shared';

export const EmptyTable = styled(CenteredFlex)`
  flex-direction: column;
  height: 100%;
  background-color: ${Colors.White};
  border-radius: 10px;
  margin-top: 20px;
`;

export const Text = styled(TextRegularBig)`
  color: ${Colors.Grey700};
`;

export const FileIcon = styled(Icon)`
  color: ${Colors.Grey700};
  width: 40px;
  height: 40px;
  margin-bottom: 16px;
`;
