import { WindowLikePage } from 'app/components/shared';
import { useState } from 'react';
import { RouteName, RoutePath } from 'app/constants/route-path';
import { SuppliersFilters } from './components/SuppliersFilters';
import { SuppliersTable } from './components/SuppliersTable';

export const SuppliersPage = () => {
  const [isSideMenuOpen, setIsSideMenuOpen] = useState<boolean>(false);
  const [isSearchFocus, setIsSearchFocus] = useState<boolean>(false);

  return (
    <WindowLikePage title={RouteName[RoutePath.Suppliers]}>
      <SuppliersFilters setIsSearchFocus={setIsSearchFocus} setIsSideMenuOpen={setIsSideMenuOpen} />

      <SuppliersTable isSideMenuOpen={isSideMenuOpen} isSearchFocus={isSearchFocus} />
    </WindowLikePage>
  );
};
