import { useRef, useState } from 'react';
import { useOnClickOutside } from 'app/helpers/click-outside/click-outside.hook';
import { useAction } from 'app/helpers/actions/use-action';
import { OutgoingRingingInitPayload, webphoneActions } from 'store/webphone';
import {
  CloseIcon,
  Header,
  PhoneIcon,
  Title,
  Wrapper,
} from './styled';
import { PhoneNumberPad } from './PhoneNumbePad';

export interface DialpadProps {
  isShown: boolean;
}

export const Dialpad = ({ isShown }: DialpadProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const dialpadRef = useRef(null);

  const initOutgoingRinging = useAction(webphoneActions.InitOutgoingRinging.init);

  const expandDialpad = () => {
    if (!isExpanded) {
      setIsExpanded(true);
    }
  };

  const collapseDialpad = () => {
    setIsExpanded(false);
  };

  const getOutgoingRingingInitPayload = (phone: string): OutgoingRingingInitPayload => {
    const result = phone.match(/\*(\d{4})\*(\d{11})/);

    if (!result || !result[1] || !result[2]) {
      return { phone };
    }

    return { phone: result[2], options: { prefix: result[1] } };
  };

  const call = (phone: string) => {
    initOutgoingRinging(getOutgoingRingingInitPayload(phone));
    setIsExpanded(false);
  };

  const handleDialpadClick = () => {
    expandDialpad();
  };

  useOnClickOutside(dialpadRef, collapseDialpad, isExpanded);

  if (!isShown) {
    return null;
  }

  return (
    <Wrapper ref={dialpadRef} isExpanded={isExpanded} onClick={handleDialpadClick}>
      {isExpanded ? (
        <>
          <Header>
            <Title>Web телефон</Title>
            <CloseIcon icon="/assets/icons.svg#close" onClick={collapseDialpad} />
          </Header>
          <PhoneNumberPad onCallButtonClick={call} />
        </>
      ) : (
        <PhoneIcon icon="/assets/icons.svg#phone-in-talk" />
      )}
    </Wrapper>
  );
};
