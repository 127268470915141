import styled from 'styled-components';

import { ColumnFlexbox, Flexbox } from 'app/typography/flex';

export const OuterContainer = styled(Flexbox)`
  padding: 20px 30px 30px;
  justify-content: center;
  min-height: calc(100vh - 72px);
`;

export const InnerContainer = styled(Flexbox)`
  gap: 20px;
  width: 100%;
`;

export const CallBox = styled(ColumnFlexbox)`
  gap: 20px;
  min-width: 350px;
  max-width: 350px;
`;

export const ComponentWrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;
