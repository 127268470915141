import { ConsumerId } from '../consumer';
import { ProfileId } from '../profile/profile';
import { Brand } from '../../../utils/types/types.common';
import { SupplierId } from '../supplier';

export type CallId = Brand<number, 'CallId'>; // айдишник звонка из базы
export type SipCallId = Brand<number, 'SipCallId'>; // айдишник звонка из сип сервера

export enum CallDirection {
  Incoming = 1,
  Outgoing = 2,
}

export interface CallAppeal {
  id: number;
  number: string;
  isEmergency: boolean;
  isUrgent: boolean;
  subject: string;
}

export interface CallConsumer {
  id: ConsumerId;
  fio: string;
  phone: string;
}

export interface CallDispatcher {
  id: ProfileId;
  fullName: string;
  phone: string;
}

export interface CallSupplier {
  id: SupplierId;
  dispatcherPhone: string;
  email: string;
  name: string;
  phone: string;
  webSite: string;
  prefix?: string;
}

export interface Call {
  id: CallId;
  callId: SipCallId;
  appeal?: CallAppeal;
  consumer?: CallConsumer;
  dispatcher?: CallDispatcher;
  managementCompany?: CallSupplier;
  managecompany?: CallSupplier;
  type: CallDirection;
  callerPhone: string;
  callStartDatetime: string;
  durationInSec: number;
  comment: string;
  createdAt: string;
  overdueCallTime?: number;
  callback: boolean;
  isMissed?: boolean;
}
