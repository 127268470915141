import styled from 'styled-components';
import { ReactNode } from 'react';
import { TextRegular } from '../../../typography/text';
import { ColumnFlexWithPadding } from '../../../typography/flex';
import { Colors } from '../../../themes/colors';

interface FormFieldProps {
  children: ReactNode;
  placeholder?: string;
  id?: string;
  spacing?: string;
  className?: string;
}

export const FormField = ({ placeholder, children, id, spacing, className }: FormFieldProps) => (
  <FormFieldComponent className={className} spacing={spacing || '0.5rem'}>
    {placeholder && (
      <label htmlFor={id}>
        <LabelText>{placeholder}</LabelText>
      </label>
    )}
    <div>{children}</div>
  </FormFieldComponent>
);

const LabelText = styled(TextRegular)`
  color: ${Colors.Grey700};
`;

const FormFieldComponent = styled(ColumnFlexWithPadding)`
  width: 100%;
  position: relative;
`;
